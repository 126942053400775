import { intl } from 'di18n-react';
import React from 'react';
import { message } from 'antd';
import { searchShareMember } from './service';
import { ReadPerm, DownloadPerm } from '@/utils/perm';
import { post } from '@/utils/request/cooper';
import api from '@/utils/request/api/CooperApi';
import './DirectShare.less';
import { MEMBER_TYPE } from '@/constants/user';
import { EpClickShareBatchAddFns } from '@/constants/cooperConstants';
import DirectAdd from '@didi/add-member-search-react'

// 定向分享
class DirectShare extends React.Component {
  createShare = async (mbList) => {
    if (mbList.length === 0) {
      message.error(intl.t('请至少添加一个要分享的人或组'));
      return;
    }

    const { protocol, host } = window.location;
    const shareFromMe = `${protocol}//${host}/fromme`;
    const suc = (
      <span>
        {intl.t('分享成功，你可以在“')}
        <a
          href={shareFromMe}
          style={{ color: '#0066FF' }}
        >
          {intl.t('我分享的')}
        </a>
        {intl.t('”中查看分享详情')}
      </span>
    );
    await post(api.DIRECT_SHARE, {
      resource_id: this.props.fileId,
      direct_shares: mbList.map((u) => ({
        share_with: u.id,
        share_with_cn: u.title,
        share_with_type: u.type,
        // permission值为permis权限列表求和
        permission: u.permission,
      })),
    })
      .then(() => {
        message.success(suc);
        this.props.onClose();
      })
      .catch(() => {
        this.props.onClose();
      });
  };

  doSearch = async (params) => {
    const data = await searchShareMember(params);
    return data;
  }

  getDefaultPermis = () => {
    const defaultPer = [ReadPerm];
    return defaultPer;
  }

  getDirectSharePermission = (options) => {
    const permissionList = [ReadPerm, DownloadPerm];
    return options.filter((item) => permissionList.includes(item.id));
  }

  onChange = (member) => {
    if (!member) {
      return;
    }
    const EventMap = {
      [MEMBER_TYPE.USER]: 'DIRECT_SHARE_ADD_USER',
      [MEMBER_TYPE.OE]: 'DIRECT_SHARE_ADD_OE',
      [MEMBER_TYPE.COOPER]: 'DIRECT_SHARE_ADD_COOPER',
      [MEMBER_TYPE.MAIL]: 'DIRECT_SHARE_ADD_MAIL',
      [MEMBER_TYPE.DC]: 'DIRECT_SHARE_ADD_DC',
    };
    const EventMapOmega = {
      [MEMBER_TYPE.OE]: 'ep_fileshare_specific_shareoe_ck',
      [MEMBER_TYPE.COOPER]: 'ep_fileshare_specific_sharecooperck',
      [MEMBER_TYPE.MAIL]: 'ep_fileshare_specific_shareemail_ck',
      [MEMBER_TYPE.DC]: 'ep_fileshare_specific_sharedc_ck',
    };
    const eventType = EventMap[member.type];

    window.__OmegaEvent(EventMapOmega[member.type]);
  }

  epClickSearchTab = () => {

  }

  epClickSeeMore = () => {

  }

  epClickChangePermis = () => {
  }

  batchAdd = async (invitees, permission, checkInvitees) => {
    const data = await post(api.BATCH_DIRECT_SHARE,
      {
        resourceId: this.props.fileId,
        invitees,
        permission,
        limitCount: 500,
        checkInvitees,
      });
    if (data?.addStatus === 1) {
      message.success(intl.t('分享成功'));
    }
    return data;
  }

  render() {
    const { fileId, onClose, show = true, showBatchAdd, setShowBatchAdd } = this.props;
    const footerTip = (<div className='warn'>
      {intl.t('您需对分享内容负责，请确认分享内容及访问权限')}
    </div>);

    return (
      <div className={`direct-share ${show ? '' : ' hidden'}`}>
        <div className='foot'>
          <DirectAdd
            currId={fileId}
            onClose={onClose}
            onChange={this.onChange}
            onOk={this.createShare}
            okText={intl.t('通知')}
            existText={intl.t('已分享')}
            searchFun={this.doSearch}
            defaultPermis={this.getDefaultPermis()}
            permissionFilter={this.getDirectSharePermission}
            footerTip={footerTip}
            epClickSearchTab={this.epClickSearchTab}
            epClickSeeMore={this.epClickSeeMore}
            epClickChangePermis={this.epClickChangePermis}
            existMessage={intl.t('文件已被分享给该用户')}
            showBatchAdd={showBatchAdd}
            setShowBatchAdd={setShowBatchAdd}
            batchAdd={this.batchAdd}
            EpClickBatchAddFns={EpClickShareBatchAddFns}
            isShowOutDes={false}
          />
        </div>
      </div>
    );
  }
}

export default DirectShare;
