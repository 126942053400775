/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-11-01 12:03:14
 * @LastEditTime: 2023-11-14 11:45:09
 * @Description: 枚举值
 * @FilePath: /knowledgeforge/src/constants/dkList.js
 *
 */

export const TAB_TYPE = {
  ALL: 0,
  OWN: 1,
  JOIN: 2,
}

export const DK_FROM_TYPE = {
  PERSON: 0,
  TEAM: 1,
}

export const SORT_TYPE = ['createtime', 'visittime', 'jointime']
