
import classBind from 'classnames/bind';
import styles from './style.module.less';
import { intl, getLocale } from 'di18n-react';
import { Modal, Button, Checkbox } from 'antd';
import mountAnywhere from '@/utils/mountAnywhere';
import { InfoCircleOutlined } from '@ant-design/icons';
import { get, post } from '@/utils/request';
import CooperApi from '@/utils/request/api/CooperApi';
import { Fragment, useEffect, useState } from 'react';
import { isDC, inPhone } from '@/utils';


const cx = classBind.bind(styles);

const linkConf = [
  {
    text: intl.t('如何更新浏览器版本或下载最新浏览器'),
    linkZh: 'https://cooper.didichuxing.com/knowledge/share/book/V2fuj0ncesoZ/2203332991450?anchor=f24074a9-b20e-440e-afbb-d4d857d72e1b',
    linkEn: 'https://cooper.didichuxing.com/knowledge/share/book/V2fuj0ncesoZ/2203332991450?anchor=f24074a9-b20e-440e-afbb-d4d857d72e1b',
  },
  {
    text: intl.t('如何将Cooper支持的浏览器设置为默认浏览器'),
    linkZh: 'https://cooper.didichuxing.com/knowledge/share/book/V2fuj0ncesoZ/2203332991450?anchor=4c748b30-6c4e-4096-971e-26d95586b597',
    linkEn: 'https://cooper.didichuxing.com/knowledge/share/book/V2fuj0ncesoZ/2203332991450?anchor=4c748b30-6c4e-4096-971e-26d95586b597',
  },
]

async function isChrome115OrHigher() {
  const { userAgent } = navigator;
  let browserName = 'Unknown';
  let needUpgrade = false;
  let isHigherLimitVersion = false;
  let tipsId = '';
  let tipsContent = 'Cooper推荐使用Chrome V115及以上版本浏览器，兼容性表现优秀。若使用其他浏览器或者较低版本Chrome浏览器，可能存在使用问题，建议切换为Cooper平台推荐的浏览器';
  if (userAgent.match(/edge|edg/i)) {
    browserName = 'browser-edge';
    const match = userAgent.match(/Edg\/(\d+\.\d+\.\d+\.\d+)/);
    tipsContent = 'Edge当前版本过低，使用Cooper平台相关功能时可能存在问题，推荐使用Edge 115及以上版本，兼容性表现优秀'
    if (match) {
      const majorVersion = parseInt(match[1].split('.')[0], 10);
      isHigherLimitVersion = majorVersion >= 115;
    }
  } else if (userAgent.match(/opera|opr\//i)) {
    browserName = 'browser-opera';
  } else if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'browser-chrome';
    const match = userAgent.match(/Chrome\/(\d+\.\d+\.\d+\.\d+)/);
    tipsContent = 'Chrome当前版本过低，使用Cooper平台相关功能时可能存在问题，推荐使用Chrome115及以上版本，兼容性表现优秀'
    if (match) {
      const majorVersion = parseInt(match[1].split('.')[0], 10);
      isHigherLimitVersion = majorVersion >= 115;
    }
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'browser-firefox';
  } else if (userAgent.match(/safari/i)) {
    browserName = 'browser-safari';
    tipsContent = 'Safari当前版本过低，使用Cooper平台相关功能时可能存在问题，推荐使用Safari 14及以上版本，兼容性表现优秀'
    const match = userAgent.match(/Version\/(\d+\.\d+(?:\.\d+)?)/);
    if (match) {
      const majorVersion = parseInt(match[1].split('.')[0], 10);
      isHigherLimitVersion = majorVersion >= 14;
    }
  } else {
    browserName = 'browser-other';
  }
  // 当浏览器版本高于限定版本以上,不再做请求、并且不再弹窗
  if (isHigherLimitVersion) {
    return { browserName, needUpgrade: false, tipsId, tipsContent };
  }
  // 获取是否需要升级
  await get(CooperApi.APPLY_NOTICE_UNREAD.replace(':position', browserName), {
    params: {
      frontType: 'WEB',
      noticeType: 'BUBBLE',
    },
    headers: {
      'X-App-Id': 2,
    },
  }).then((res) => {
    if (JSON.stringify(res) === '{}') {
      needUpgrade = false;
    } else {
      needUpgrade = true;
      tipsId = res.id;
    }
  }).catch(() => {
    needUpgrade = false;
  })
  return { browserName, needUpgrade, tipsId, tipsContent };
}

const GetBrowserInfo = () => {
  const [visible, setVisible] = useState(true)
  const [isChecked, setIsChecked] = useState(false)
  const [browserInfo, setBrowserInfo] = useState({})
  useEffect(() => {
    async function getData() {
      const { browserName, needUpgrade, tipsId, tipsContent } = await isChrome115OrHigher()
      setBrowserInfo({ browserName, needUpgrade, tipsId, tipsContent })
    }
    getData()
  }, [])

  const clickHandle = () => {
    if (isChecked) {
      post(CooperApi.APPLY_NOTICE_READ.replace(':noticeId', browserInfo.tipsId), {}, {
        headers: {
          'X-App-Id': 2,
        },
      }).then(() => {
        setVisible(false)
      }).catch(() => {
        setVisible(false)
      })
    } else {
      setVisible(false)
    }
  }
  const renderFooter = () => (
    <div className={cx('chrome-115-tips-footer')}>
      <Checkbox
        checked={isChecked}
        onChange={(e) => {
          window.__OmegaEvent('ep_service_browser_notip_ck', '');
          setIsChecked(e.target.checked)
        }}> {intl.t('不再提示1')} </Checkbox>
      <Button
        type='primary'
        onClick={() => {
          window.__OmegaEvent('ep_service_browser_ck', '');
          clickHandle()
        }}>{intl.t('确定')}</Button>
    </div>
  )
  const renderContent = () => {
    return (
      <div className={cx('chrome-115-tips')}>
        <div className={cx('chrome-115-tips-title')}>
          <InfoCircleOutlined className={cx('chrome-115-tips-icon')}/>
          <span>{intl.t('提示')}</span>
        </div>
        <div className={cx('chrome-115-tips-content')}>
          <span>{browserInfo.tipsContent && intl.t(browserInfo.tipsContent)}</span>
        </div>
        <div className={cx('chrome-115-tips-link')}>
          {
            linkConf.map((item, index) => (
              <a
                href={getLocale() === 'zh-CN' ? item.linkZh : item.linkEn}
                key={index}
                className={cx('text-link')}
                target='_blank'>{item.text}</a>
            ))
          }
        </div>
      </div>
    )
  }
  return browserInfo.needUpgrade && !isDC() && !inPhone() ? <Fragment>
    <Modal
      visible={visible}
      footer={renderFooter(browserInfo.browserName)}
      closable={false}
      zIndex={99999}
      width={416}
      mask={false}
      wrapClassName={cx('chrome-115-tips-modal')}
      onOk={() => {}}>
      {renderContent()}
    </Modal>
  </Fragment> : null
}


function getBrowserInfoContent() {
  let close = () => { };

  const Comp = <GetBrowserInfo onClose={() => close()}/>

  close = mountAnywhere(Comp);
}

export default getBrowserInfoContent
