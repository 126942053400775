import classBind from 'classnames/bind';
import styles from './style.module.less';
import DynamicPopover from '@/components/DynamicPopover';
import { getLocale, intl } from 'di18n-react';
import { Button } from 'antd';
import ImageWithLoader from '../ImageWithLoader';

const cx = classBind.bind(styles);

function newFeaturePopup({ config = {}, iknowCallback, ilookupCallback, showOrNot = false }) {
  const { newFeatureDesc, newFeatureDesc_en, newFeatureImageUrl, newFeatureImageUrl_en } = config;
  const isZH = getLocale() === 'zh-CN';
  // eslint-disable-next-line react/jsx-max-props-per-line
  const getNewFeatureContent = () => <div className={cx('popup-main')} key='popup-main'>
    <div className='customer-service-popup-newfeature'>
      <div className='customer-service-popup-newfeature-title'>{isZH ? newFeatureDesc : newFeatureDesc_en}</div>
      <div className='customer-service-popup-newfeature-img'>
        <ImageWithLoader
          clickHandler={ilookupCallback}
          // eslint-disable-next-line camelcase
          src={isZH ? newFeatureImageUrl : newFeatureImageUrl_en}/>
      </div>
      <div className='customer-service-popup-newfeature-op'>
        <Button onClick={iknowCallback}>{intl.t('我知道了')}</Button>
        <Button onClick={ilookupCallback} type="primary">{intl.t('查看')}</Button>
      </div>
    </div>
  </div>;

  return (
    <div className={cx('newfeature-popup')}>
      <DynamicPopover
        content={getNewFeatureContent()}
        overlayClassName={'customer-service-popover'}
        placement={'bottomRight'}
        align={{offset: [40, 30] }}
        showOrNot={showOrNot}>
        <span className={cx('cooper-customer-service-empty')}/>
      </DynamicPopover>
    </div>
  );
}

export default newFeaturePopup;
