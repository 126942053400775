import classBind from 'classnames/bind';
import styles from './style.module.less';


const cx = classBind.bind(styles);

const Loading = () => {
  return (
    <div
      className={cx('my-spin')}
      id={cx('mySpinContainer')}>
      <span className={cx('my-spin-dot-span')}>
        <i className={cx('my-spin-dot-item')} />
        <i className={cx('my-spin-dot-item')}/>
        <i className={cx('my-spin-dot-item')}/>
        <i className={cx('my-spin-dot-item')}/>
      </span>
      <div className={cx('my-text-container')}>
        <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"/>
      </div>
    </div>
  );
};

export default Loading;
