export default {
  state: {
    isResizingAside: false,
  },
  reducers: {
    changeIsResizingAside(state, isResizingAside) {
      return {
        ...state,
        isResizingAside,
      };
    },
  },
};
