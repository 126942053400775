import { intl } from 'di18n-react';
/* eslint-disable */
import { useEffect, useState, useRef, useMemo } from 'react';
import { Tree, Spin, message } from 'antd';
import { getTreeById, getFileList } from '@/service/knowledge/fileTree';
import {
  getsubChecked,
  formateTree,
  updateTreeData,
  getNodeById,
  deepClone,
} from '../utils';
import classBind from 'classnames/bind';
import styles from './style.module.less';

const cx = classBind.bind(styles);

const Component = (props) => {
  const { space, checked } = props;
  const [expandedKeys, setExpandKeys] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const treeRef = useRef();

  const onLoadData = async (node) => {
    let { key, children } = node;
    if (children.length) {
      return;
    }
    let res = await getFileList(
      space.id,
      space.id == 0 ? 'PERSONAL' : 'TEAM',
      key
    );
    setTreeData((list) =>
      updateTreeData(list, key, formateTree(res.items, false))
    );
  };

  const handleExpand = (v) => {
    setExpandKeys(v);
  };

  const setTreeByNode = async (v, spaceList) => {
    let parentId;
    let path = v.pathFromRoot.map((v) => v.id).reverse();
    let parents = path.slice(1);
    if (spaceList) {
      parentId = v.pathFromRoot[1].id;
    } else {
      parentId = path.find((v) => getNodeById(v, treeData));
    }

    let res = await getTreeById(v.spaceId, parentId);
    if (spaceList) {
      spaceList.map((v) => {
        if (v.resourceId === res.files.resourceId) {
          v.isAll = true;
          v.children = formateTree(res.files.children);
        }
      });
      setTreeData(spaceList);
    } else {
      setTreeData((list) =>
        updateTreeData(list, parentId, formateTree(res.files.children))
      );
    }

    if (v.resourceType === 'DIR') {
      setExpandKeys([...new Set([...expandedKeys, ...path])]);
    } else {
      setChecked([...checkedKeys, v.resourceId]);
      setExpandKeys([...new Set([...expandedKeys, ...parents])]);
    }
    setTimeout(() => {
      treeRef.current.scrollTo({ key: v.resourceId });
    }, 300);
  };

  const getFileListById = async () => {
    if (!space.id && space.id !== 0) return;
    let res = await getFileList(space.id, space.id == 0 ? 'PERSONAL' : 'TEAM');
    const fomatData = formateTree(res.items, false);
    if (space.node) {
      // 搜索过来的
      await setTreeByNode(space.node, fomatData);
    } else {
      setTreeData(fomatData);
    }
  };

  const setChecked = (checklist, count) => {
    if ((count || checklist.length) > 50) {
      message.error(intl.t('一次最多可选择50个文件，请分开选择后再导入'));
      return;
    }
    checked({ count: count || checklist.length, treeRef });
    setCheckedKeys(checklist);
  };

  const onChecked = async (checkPos, checkedList) => {
    let currentNode = checkedList.node;
    if (!currentNode.checked && !currentNode.isLeaf && !currentNode.isAll) {
      let newTree = deepClone(treeData);
      newTree = formateTree(newTree, false); // symbol没办法copy过来 需要重新格式化一下
      let cnode = getNodeById(currentNode.resourceId, newTree);
      let res = await getTreeById(space.id, currentNode.key);
      cnode.children = formateTree(res.files.children);
      cnode.isAll = true;
      setTreeData(newTree);
      let currentChecked = getsubChecked(currentNode);
      let count = checkPos.filter((v) => !currentChecked.includes(v));
      setChecked(checkPos, count.length + res.count);
    } else {
      setChecked(checkPos);
    }
  };

  const searchInSpace = async (v) => {
    const hasCurrentNode = getNodeById(v.resourceId, treeData);
    let path = v.pathFromRoot.map((v) => '' + v.id).reverse();
    let parents = path.slice(1);
    if (hasCurrentNode && v.resourceType !== 'DIR') {
      setChecked([...checkedKeys, v.resourceId]);
      setExpandKeys([...new Set([...expandedKeys, ...parents])]);
      setTimeout(() => {
        treeRef.current.scrollTo({ key: v.resourceId });
      }, 300);
    } else {
      await setTreeByNode(v);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      // 切换空间，或者搜索要换空间
      if (!space.node || space.switch) {
        await getFileListById();
      } else {
        // 当前空间内搜索
        await searchInSpace(space.node);
      }
      setLoading(false);
    })();
    if (space.node) {
      setSelectedKeys([space.node.resourceId]);
    }
  }, [space]);

  return (
    <Spin spinning={loading} style={{ height: 200 }}>
      <Tree
        ref={treeRef}
        className={cx('file-tree-wrap')}
        checkable
        showIcon
        height={242}
        checkedKeys={checkedKeys}
        onCheck={onChecked}
        treeData={treeData}
        selectedKeys={selectedKeys}
        expandedKeys={expandedKeys}
        onExpand={handleExpand}
        loadData={onLoadData}
      />
    </Spin>
  );
};

export default Component;
