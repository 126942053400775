import { intl } from 'di18n-react';

import { useState, useEffect, useMemo } from 'react';
import classBind from 'classnames/bind';
import styles from './style.module.less';

const cx = classBind.bind(styles);

const SearchOnScope = (props) => {
  const [current, setCurrent] = useState(!!props.value);

  const clearScope = () => {
    props.clearScope('');
  };
  useEffect(() => {
    setCurrent(props.value);
  }, [props.value]);

  return (
    <div className={cx('search-before')}>
      <i
        className={`${cx('dk-icon-sousuo', 'dk-iconfont')}`}
        style={{ color: '#bbb' }}
      />

      {current && !props?.isShare ? (
        <p className={cx('search-tag')}>
          {intl.t('当前知识库')}
          <span
            style={{ cursor: 'pointer', height: 24 }}
            onClick={clearScope}>
            <i
              className={`${cx('dk-icon-guanbi', 'dk-iconfont')}`}
              style={{ fontSize: 20 }}
            />
          </span>
        </p>
      ) : null}
    </div>
  );
};

export default SearchOnScope;
