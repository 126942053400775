
import { apolloSwitch } from '@/utils/ab';

export default {
  name: 'KnowledgePortal',
  state: {
    portalABSwitch: null,
    editorIframeABSwitch: null,
    editorBlockABSwitch: null,
  },

  reducers: {
    setPortalABSwitch(state, payload) {
      return { ...state, portalABSwitch: payload }
    },
    setEditorIframeABSwitch(state, payload) {
      window.editorIframeABSwitch = payload;
      return { ...state, editorIframeABSwitch: payload }
    },
    setEditorBlockABSwitch(state, payload) {
      window.editorBlockABSwitch = payload;
      return { ...state, editorBlockABSwitch: payload }
    },
  },

  effects: () => ({
    async getPortalABSwitch() {
      const data = await apolloSwitch('knowledge-gateway-web')
      this.setPortalABSwitch(data)
    },
    async getEditorIframeABSwitch() {
      const data = await apolloSwitch('knowledge')
      this.setEditorIframeABSwitch(data)
    },
    async getEditorBlockABSwitch() {
      const data = await apolloSwitch('block-editor')
      this.setEditorBlockABSwitch(data)
    },
  }),
};


