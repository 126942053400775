import { TREE_VIEW_TYPE } from '@/constants/index';
import NotificationStatus from '@/constants/notification';
import useNotification from '@/hooks/useNotification';
import usePermission from '@/hooks/usePermission';
import { getPageOwnerInfo, getPagePermission, getPageRequired } from '@/service/knowledge/page';
import { intl } from 'di18n-react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';


export default function useInitDKData({ knowledgeId, pageId, shareId }) {
  const [hasDkPerm, setHasDKPerm] = useState(null);
  const [hasPagePerm, setHasPagePerm] = useState(null);
  const [pageError, setPageError] = useState(null);
  const [pageInfo, setPageInfo] = useState({});
  const [pageOwnerInfo, setPageOwnerInfo] = useState({});
  const navigate = useNavigate();
  const notification = useNotification();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { checkOperationPermission } = usePermission();
  const {
    getKnowledgeDetail, setTreeViewType, updateDkOwnerInfo, setHasCompleteLinkPerm,
  } = dispatch.KnowledgeData;

  useEffect(() => {
    setPageError(false);
    if (shareId) return;

    if (!knowledgeId) return;

    getKnowledgeDetail(knowledgeId)
      .then(() => {
        setHasDKPerm(true);
        setTreeViewType(TREE_VIEW_TYPE.PAGE);
        setHasCompleteLinkPerm({
          hasCompleteLinkPerm: true,
          pageId: '0',
        });
        setPageError(false);
      })
      .catch((err) => {
        setPageError(false);
        if (err.errorCode === 501082) { // 没有查看权限时
          setHasDKPerm(false);
          updateDkOwnerInfo(knowledgeId);
        } else if (err.errorCode !== 401) {
          setPageError(true);
          notification(NotificationStatus.ERROR, err.errorMessage || err.errorType);
        }
      });
  }, [knowledgeId]);


  useEffect(() => {
    setPageError(false);
    if (shareId) return;
    if (!pageId) return;
    // TODO:接口加上删除情况后，兼容删除
    // get perm 和 get details 拆开是为了尽快获取权限，没有权限就不需要进行后续操作
    let startTime = new Date().getTime();
    getPageRequired({ pageId })
      .then((pageRequireInfo) => {
        setPageInfo({
          ...pageRequireInfo,
          pageId,
        });
        let endTime = new Date().getTime();
        window.__OmegaEvent('tech_ep_network_load_time', '知识库核心接口加载时长', {
          source: 'require',
          time: endTime - startTime,
        })

        setPageError(false);
        const hasReadPerm = checkOperationPermission('READ_PAGE', pageRequireInfo.permission);
        setHasPagePerm(hasReadPerm);
        setHasCompleteLinkPerm({
          hasCompleteLinkPerm: pageRequireInfo.hasCompleteLinkPerm,
          pageId,
        });

        if (!hasReadPerm) {
          getPageOwnerInfo(pageId).then((data) => {
            setPageOwnerInfo(data);
          });
          return;
        }
        // 没有编辑权限时，跳转到预览页面
        if (pathname.indexOf('edit') !== -1) {
          const hasEditPerm = checkOperationPermission('MANAGE_PAGE_CONTEXT', pageRequireInfo.permission);
          if (!hasEditPerm) {
            notification(NotificationStatus.ERROR, intl.t('您没有编辑权限，即将跳转到预览页'));
            setTimeout(() => {
              navigate(pathname.replace('/edit', ''));
            }, 1000);
          }
        }
      })
      .catch((err) => {
        let endTime = new Date().getTime();
        window.__OmegaEvent('tech_ep_network_load_time', '知识库核心接口加载时长', {
          source: 'require',
          time: endTime - startTime,
        })
        setPageError(false);
        if (err.errorCode === 201082) {   // 显示申请权限页面
          setHasPagePerm(false);
        } else if (err.errorCode === 201003) {  // 页面被删除，放行，去下游组件内处理
          setHasPagePerm(true);
          return;
        } else if (err.errorCode !== 401) {
          setPageError(true);
          notification(NotificationStatus.ERROR, err.errorMessage || err.errorType);
        }
        // if (err.errorCode === 300002) { // 页面被删除
        //   setHasPagePerm(true);
        // } else if (err.errorCode !== 401) {
        //   setPageError(true);
        //   notification(NotificationStatus.ERROR, err.errorMessage || err.errorType);
        // }
      });
  }, [pageId]);

  const hasPerm = useMemo(() => {
    if (hasDkPerm === null && hasPagePerm === null) return null;
    return pageId ? hasPagePerm : hasDkPerm;
  }, [shareId, hasDkPerm, hasPagePerm]);

  return {
    pageOwnerInfo,
    hasDkPerm,
    hasPagePerm,
    hasPerm,
    pageError,
    pageInfo,
    setPageInfo
  };
}

