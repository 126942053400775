import React from 'react';
import onClickOutside from 'react-onclickoutside';
import classNames from 'classnames';
import './style.less';

class DropdownRadio extends React.Component {
  constructor(props) {
    super(props);
    const options = props.options || [];
    const value = props.value || '';
    options.forEach((item) => {
      item.checked = item.id === value;
    });
    const values = options.filter((p) => p.checked).map((p) => p.label);
    this.state = {
      text: values.length ? values.join('/') : (this.props.placeholder || ''),
      options: options.slice(),
      show: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      const newOptions = this.state.options.slice();
      const value = nextProps.value || '';
      newOptions.forEach((item) => {
        item.checked = item.id === value;
      });
      const values = newOptions.filter((p) => p.checked).map((p) => p.label);
      this.setState({
        text: values.length ? values.join('/') : (this.props.placeholder || ''),
        options: newOptions,
      });
    }
  }

  handleClickOutside = () => {
    if (this.state.show) {
      this.setState({ show: false });
    }
  }

  handleSelect(item) {
    if (item.disabled) {
      return;
    }
    const { options } = this.state;
    const { onChange = () => {} } = this.props;
    const newOptions = options.slice();
    let value = '';
    newOptions.forEach((p) => {
      p.checked = p.id === item.id;
      if (p.id === item.id) {
        value = item.id;
      }
    });
    onChange(value, newOptions);
    const values = newOptions.filter((p) => p.checked).map((p) => p.label);
    this.setState({
      options: newOptions,
      text: values.length ? values.join('/') : (this.props.placeholder || ''),
      show: false,
    });
  }

  handleClick = () => {
    this.setState({ show: !this.state.show });
  }


  render() {
    const { options, text, show } = this.state;
    return (
      <div className={classNames('dropdown-radio', { active: show })} >
        <span
          className='dropdown-radio__value'
          onClick={this.handleClick} >
          <i className='dropdown-radio__caret' />
          <span>{text}</span>
        </span>
        {
          show
          && <div className='dropdown-radio__wrapper'>
            <div className='dropdown-radio__items'>
              {
                options.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={classNames('dropdown-radio__item', {
                        'dropdown-radio__chkitem': item.checked,
                        'dropdown-radio__disableitem': item.disabled,
                      })}
                      onClick={this.handleSelect.bind(this, item)}
                    >
                      <span>{item.label}</span>
                    </div>
                  );
                })
              }
            </div>
          </div>
        }
      </div>
    );
  }
}

const WrappedDropdownRadio = onClickOutside(DropdownRadio);
export default WrappedDropdownRadio;
