/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-08-07 13:36:48
 * @LastEditTime: 2023-11-20 11:22:22
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/CooperOperation/OpenDkPage/index.js
 *
 */
import { openNewWindow } from '@/utils/index';
import { FileType } from '@/constants/cooper';

function openDkPage({
  dkShareType,
  pageId,
  knowledgeId,
  shareLink,
}) {
  switch (dkShareType) {
    case '':
    case undefined:
    case FileType.DK_RESOURCE:
    case null: {
      openNewWindow(`/knowledge/${knowledgeId}/${pageId}`);
      break;
    }
    case FileType.PAGE_SHARE:
      openNewWindow(`/knowledge/share/page/${shareLink}`);
      break;
    case FileType.DK_SHARE:
      openNewWindow(`/knowledge/share/book/${shareLink}/${pageId}`);
      break;

    default:
      break;
  }
}

export function getDkPageUrl({ dkShareType, pageId, knowledgeId, shareLink }) {
  switch (dkShareType) {
    case FileType.PAGE_SHARE:
      return `${window.location.origin}/knowledge/share/page/${shareLink}`;
    case FileType.DK_SHARE:
      return `${window.location.origin}/knowledge/share/book/${shareLink}/${pageId}`;
    default:
      return `${window.location.origin}/knowledge/${knowledgeId}/${pageId}`;
  }
}

export default openDkPage;
