import { intl } from 'di18n-react';

export const ADMIN_SUITE_TIP = () => {
  return {
    COOPER: intl.t('启用后在此可一键触达指定团队空间'),
    JZD: intl.t('启用后在此可一键触达指定圈子'),
    IM: intl.t('启用后在此可一键触达指定群聊'),
  }
};

export const MEMBER_SUITE_TIP = () => intl.t('请联系知识库所有者或管理员开启该套件');
