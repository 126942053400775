
import MProgress from './index';
import mountAnywhere from '@/utils/mountAnywhere';

function showProgress(type, copyId, copyNum, link, doneCallback) {
  const Comp = (
    <MProgress
      type={type}
      copyId={copyId}
      copyNum={copyNum}
      link={link}
      doneCallback={doneCallback}
    />
  );

  mountAnywhere(Comp);
}

export default showProgress;
