import { get, post } from '@/utils/request';
import api from '@/utils/request/api/DkApi';

export async function getDkApplyStatus(knowledgeId) {
  const res = await get(`${api.GET_DK_APPLY_STATUS.replace(':knowledgeId', knowledgeId)}`);
  return res;
}

export async function appplyJoinDk(knowledgeId, params) {
  const res = await post(`${api.APPLY_JOIN_DK.replace(':knowledgeId', knowledgeId)}`, params);
  return res;
}

export async function applyJoinDkUrge(knowledgeId, params) {
  const res = await post(`${api.APPLY_URGE_DK.replace(':knowledgeId', knowledgeId)}`, params);
  return res;
}

export async function getPageApplyStatus(pageId) {
  const res = await get(`${api.GET_PAGE_APPLY_STATUS.replace(':pageId', pageId)}`);
  return res;
}

export async function appplyJoinPage(pageId, params) {
  const res = await post(`${api.APPLY_JOIN_PAGE.replace(':pageId', pageId)}`, params);
  return res;
}

export async function applyJoinPageUrge(pageId, params) {
  const res = await post(`${api.APPLY_URGE_PAGE.replace(':pageId', pageId)}`, params);
  return res;
}


