import { useState, useMemo, useRef } from 'react';

const formatTree = (data, formatNode, flattenData) => {
  return data.map((v) => {
    let node = v;
    if (!node.isFormated) {
      node = formatNode(v);
    }
    node.isFormated = true;
    node.type = v.type;
    node.markedStar = v.markStar
    flattenData[node.key] = node;
    node.children = v?.children?.length ? formatTree(v.children, formatNode, flattenData) : [];
    return node;
  });
};

export default function (formatNode = (v) => v) {
  const [update, setUpdate] = useState('init');
  const flattenData = useRef({});
  const treeData = useRef({ children: [] });
  const proxyData = useMemo(() => {
    return new Proxy(treeData.current.children, {});
  }, [update]);

  const setData = (data, root = '0') => {
    flattenData.current = {};
    const rootData = formatTree(data, formatNode, flattenData.current);
    treeData.current = { key: root, children: rootData };
    flattenData.current[root] = treeData.current;
    setUpdate(`set${Math.random()}`);
  };

  const updateNode = (key, newState, clear = true) => {
    let currentNode = getNodeByKey(key);
    if (newState.children) { // 这里的更新是push的操作
      newState.children = clear
        ? formatTree(newState.children, formatNode, flattenData.current)
        : [...currentNode.children, ...formatTree(newState.children, formatNode, flattenData.current)];
    }
    let { parentId } = currentNode;
    let parent = getNodeByKey(parentId);

    let { children } = parent;
    const index = children.findIndex((v) => v.key === key);
    let node = {
      ...currentNode,
      ...newState,
    };
    flattenData.current[key] = children[index] = node;
    setUpdate(`update${Math.random()}`);
  };

  /**
   *
   * @param  key parentId
   * @param  node node
   * @param  position 插入的位置
   * @param  sourceFirstPageId  用于辅助计算position，创建副本
   *
   * 创建子页面: children.push()
   * 恢复页面: 根节点最上边
   * 创建副本: 当前页面的下一个
   */
  const addNode = (key, node, position) => {
    let currentNode = getNodeByKey(key);
    let { children } = currentNode;
    currentNode.isLeaf = false;
    let [child] = formatTree([node], formatNode, flattenData.current);
    position === undefined ? children.push(child) : children.splice(position, 0, child);
    setUpdate(`add${Math.random()}`);
  };

  const deleteNode = (key, cancelUpdate = false) => {
    const currentNode = getNodeByKey(key);
    if (currentNode) {
      let { parentId } = currentNode;
      let parentNode = getNodeByKey(parentId);
      let { children } = parentNode;
      const index = children.findIndex((v) => v.key === key);
      flattenData.current[key] = undefined;
      children.splice(index, 1);
      if (!children.length) {
        parentNode.isLeaf = true;
      }
      if (!cancelUpdate) { // 移动的时候会先删除节点，但不触发更新
        setUpdate(`delete${Math.random()}`);
      }
    }
    return currentNode
  };
  const getNodeByKey = (key) => flattenData.current[key];

  const moveNode = ({ key, node }, { preNode, parentNode, parentKey, preKey, position = 0 }) => {
    deleteNode(key, true);
    // 隐藏的坑。哎。。想想咋整
    const { isFormated, children, title, isLeaf, pageId, pageName, type, fullPath } = node;
    const newNode = {
      key: node.key,
      isFormated,
      children,
      title,
      isLeaf,
      pageId,
      pageName,
      type,
      fullPath,
    };
    if (preKey || preNode) {
      if (!preNode) {
        preNode = getNodeByKey(preKey);
      }
      const parentNode = getNodeByKey(preNode.parentId);
      const index = parentNode.children.findIndex((v) => v.key === preNode.key);
      newNode.parentId = parentNode.key;
      addNode(parentNode.key, newNode, index + 1);
    }
    if (parentKey || parentNode) {
      if (!parentKey) {
        parentKey = parentNode.key;
      }
      newNode.parentId = parentKey;
      addNode(parentKey, newNode, position);
    }
  };

  const getChildKeyById = (node, arr) => {
    arr.push(node.key);
    if (node.children?.length) {
      node.children.map((v) => getChildKeyById(v, arr));
    }
  };

  const getChildKeysById = (key) => {
    const node = getNodeByKey(key);
    let arr = [];
    getChildKeyById(node, arr);
    return arr;
  };

  const getParentsKeysById = (key) => {
    const nodeStart = getNodeByKey(key);
    let arr = []
    const fn = (node) => {
      arr.push(node.parentId)
      if (node.parentId && node.parentId !== '0') {
        fn(getNodeByKey(node.parentId))
      }
    }
    fn(nodeStart);
    return arr;
  }

  return {
    treeData: proxyData,
    addNode,
    deleteNode,
    setData,
    updateNode,
    moveNode,
    getNodeByKey,
    getChildKeysById,
    getParentsKeysById,
    flattenData: flattenData.current,
  };
}
