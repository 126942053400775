import { get, patch, post } from '@/utils/request';
import api from '@/utils/request/api/DkApi';

// 初始化formate为Tree组件需要的数据格式
export function formatePageTreeData(treeData) {
  let result = [];
  treeData.forEach((item) => {
    let newObj = {
      ...item,
      title: item.pageName,
      key: item.pageId,
      isLeaf: !item.hasChild,
      children: item.children && item.children.length > 0 ? formatePageTreeData(item.children) : [],
    };

    result.push(newObj);
  });
  return result;
}

export function loopTreeData(data, key, callback) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].key === key) {
      return callback(data[i], i, data);
    }
    if (data[i].children) {
      loopTreeData(data[i].children, key, callback);
    }
  }
  return null;
}

// eslint-disable-next-line consistent-return
export function findCurrNode(data, key) {
  let res = {};
  for (let i = 0; i < data.length; i++) {
    if (data[i].key === key) {
      res = {
        node: data[i],
        index: i,
      };
      break;
    }
    if (data[i].children) {
      findCurrNode(data[i].children, key);
    }
  }
  return res;
}


export async function getPageTreeData({ pageId, type, knowledgeId }) {
  // let id = string62to10(knowledgeId);
  const res = await get(api.GET_CATALOG_LIST.replace(':knowledgeId', knowledgeId), {
    params: {
      pageId,
      type,
    },
  });

  return res;
}

export async function getSharePageTreeData({ pageId, type, shareId }) {
  // let id = string62to10(knowledgeId);


  const res = await get(api.SHARE_GET_CATALOG_LIST.replace(':shareId', shareId), {
    params: {
      pageId: pageId || '0',
      type,
    },
  });

  return res.items;
}

export async function getSubSharePageTreeData({ pageId, type, shareId }) {
  // let id = string62to10(knowledgeId);


  const res = await get(api.GET_SUB_SHARE_CATALOGTREE_DATA.replace(':shareId', shareId), {
    params: {
      pageId,
      type,
    },
  });

  return res.items;
}


export async function createPage({ parentId, knowledgeId }) {
  // let id = string62to10(knowledgeId);
  const res = await post(api.CREATE_SUB_PAGE.replace(':knowledgeId', knowledgeId), { parentId });
  return res;
}

export async function createExcel({ parentId, knowledgeId }) {
  // let id = string62to10(knowledgeId);
  const res = await post(api.CREATE_SUB_PAGE.replace(':knowledgeId', knowledgeId), { parentId, type: 'DK_SHEET' });
  return res;
}

export async function deleteCurrPage({ pageId, knowledgeId }) {
  // TODO： 是否要改成单属性
  const res = await post(api.DELETE_CURR_PAGE, { pageIds: [pageId], knowledgeId });
  return res;
}

export async function renameCurrPage({ pageId, name }) {
  const res = await patch(api.RENAME_CURR_PAGE.replace(':pageId', pageId), { name });
  return res;
}


export async function moveCurrPage({ knowledgeId, toPrePageId, toParentPageId, pageId }) {
  // let id = string62to10(knowledgeId);
  const res = await post(api.MOVE_CURR_PAGE.replace(':knowledgeId', knowledgeId), { toPrePageId, toParentPageId, pageId });
  return res;
}

export async function copyPage(params) {
  const res = await post(api.COPY_PAGE, { ...params }, { keepSilent: true });
  return res;
}

export async function getFirstPageId(shareId) {
  const res = await getSharePageTreeData({ pageId: '0', shareId });
  return res[0].pageId;
}
