
export default {
  state: {
    pageSize: 1000,
    pageNum: 0,
    treeComment() { },
    isOpenCatalog: true,
  },
  reducers: {

    // 'otherModel/actionName': (state, payload) => state + payload,
    setState: (state, payload = {}) => ({ ...state, ...payload }),
    setTreeFn(state, tree) {
      return { ...state, treeComment: { ...tree, initTree: true }};
    },
  },
  effects: {

  },
};
