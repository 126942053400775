import * as templateService from '@/service/knowledge/template';

const getDefaultState = () => ({
  docInfo: {},
  initLoading: true,
  view: {},
});

export default {
  state: getDefaultState(),
  reducers: {
    resetState() {
      return getDefaultState();
    },
    changeInitLoading(state, initLoading) {
      return {
        ...state,
        initLoading,
      };
    },
    changeDocInfo(state, docInfo) {
      return {
        ...state,
        docInfo,
      };
    },
    changeView(state, view) {
      return {
        ...state,
        view,
      };
    },
    changeTemplateName(state, name) {
      const docInfo = {
        ...state.docInfo,
        name,
      };
      return {
        ...state,
        docInfo,
      };
    },
  },
  effects: {
    async getTemplateDetail(params) {
      const data = await templateService.getTemplateDetail(params);
      if (data) {
        this.changeDocInfo(data);
      }
      return data;
    },
    async createTemplate(params) {
      const data = await templateService.createTemplate(params);
      return data;
    },
    async updateTemplate(params) {
      const data = await templateService.updateTemplate(params);
      const { formData } = params;
      this.changeTemplateName(formData.get('name'));
      return data;
    },
  },
};
