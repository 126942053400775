import { intl } from 'di18n-react';
import { Modal } from 'antd';
import classBind from 'classnames/bind';
import useDebounceFn from '@/hooks/useDebounceFn';
import styles from './style.module.less';

const cx = classBind.bind(styles);

const TextOnlyModal = ({
  handleOk,
  handleCancel,
  modalVisible,
  title,
  tip,
  okText,
  cancelText,
  customClass = '',
  confirmLoading,
  styleIndex,
  getContainer,
}) => {
  const { run: debounceOk } = useDebounceFn(handleOk);
  return (
    <div>
      <Modal
        centered
        visible={modalVisible}
        onOk={debounceOk}
        okText={okText || intl.t('确定')}
        cancelText={cancelText || intl.t('取消')}
        onCancel={handleCancel}
        wrapClassName={`${cx(
          'text-only-modal',
          'dk-ant-modal_reset',
        )} ${customClass}`}
        maskClosable={false}
        width={480}
        closable={false}
        confirmLoading={confirmLoading}
        getContainer={getContainer ?? false}
        maskStyle={styleIndex}
      >
        <p
          className={cx('title')}
        >{title}</p>
        <p className={cx('tip')} >{tip}</p>
      </Modal>
    </div>
  );
};

TextOnlyModal.confirm = ({
  onCancel = () => {},
  okText = intl.t('确认'),
  cancelText = intl.t('取消'),
  icon = null,
  ...params
}) => Modal.confirm({
  className: cx('custom-modal-confirm'),
  width: 480,
  centered: true,
  icon,
  okText,
  cancelText,
  onCancel,
  ...params,
});

export default TextOnlyModal;
