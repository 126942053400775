/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-11-02 11:19:00
 * @LastEditTime: 2023-11-23 15:46:42
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/FilePreview/ImgPreview/index.js
 *
 */
import Viewer from './js/viewer';
import './viewer.less'

window.Viewer = Viewer;
export default Viewer;
