import { intl } from 'di18n-react';
import { CHUNKSIZE } from '@/constants/uploadWiki';
import api from '@/utils/request/api/DkApi';
import { putWithCancel, post } from './request';
import {
  filePPT,
  fileDoc,
  fileExcel,
  unknown,
  filePdf,
  video,
  pic,
  smDoc,
  folder,
  diDoc,
  smDocNew,
} from '@/assets/icon/fileIcon';
import { getFileHash, getChunkHash, createUploadQuery } from '@/utils/fileHash';

export function openLocalFileSelector(type, isDir = false) {
  return new Promise((resolve) => {
    const ele = document.createElement('input');
    ele.multiple = true;
    ele.type = 'file';
    ele.webkitdirectory = isDir;
    if (type !== null) {
      ele.accept = type;
    }

    // ele.removeEventListener('change', checkFileEvent(hooks, config));
    // ele.removeEventListener('change', checkFolderEvent(hooks, config));
    ele.addEventListener('change', (e) => {
      resolve(e.target.files);
    });
    ele.click();
  });
}

// 混入对象
export function objMixin(obj, ...mixins) {
  return Object.assign({}, obj, ...mixins);
}

/* eslint-disable */
export function formatSize(size) {
  if (size === -1) return '--';

  // 按 1000 换算
  if (size < 1024) return size + 'B';
  if (size < 1024 * 1024) return (size / 1024).toFixed(1) + 'KB';
  if (size < 1024 * 1024 * 1024) return (size / 1024 / 1024).toFixed(1) + 'MB';
  return (size / 1024 / 1024 / 1024).toFixed(1) + 'GB';
}

// 根据文件类型选择icon
export function getIconByFileName(name, resourceType) {
  let type = name.split('.').pop();
  if (resourceType) {
    type = resourceType;
  }
  switch (type) {
    case 'docx':
    case 'doc':
      return (clsName) => <img src={fileDoc} className={clsName} />;
    case 'COOPER_DOC':
      return (clsName) => <img src={smDoc} className={clsName} />;
    case 'SHIMO2_WORD':
      return (clsName) => <img src={smDocNew} className={clsName} />;
    case 'DIR':
      return (clsName) => <img src={folder} className={clsName} />;
    case 'DI_DOC':
      return (clsName) => <img src={diDoc} className={clsName} />;
    case 'ppt':
    case 'pptx':
      return (clsName) => <img src={filePPT} className={clsName} />;
    case 'xls':
    case 'xlsx':
      return (clsName) => <img src={fileExcel} className={clsName} />;
    case 'mp4':
    case 'avi':
    case 'wma':
    case 'rmvb':
    case '3gp':
    case 'ogg':
    case 'mov':
    case 'mkv':
    case 'm4v':
      return (clsName) => <img src={video} className={clsName} />;
    case 'pdf':
      return (clsName) => <img src={filePdf} className={clsName} />;
    case 'png':
    case 'jpeg':
    case 'jpg':
      return (clsName) => <img src={pic} className={clsName} />;
    // 默认文件
    default:
      return (clsName) => <img src={unknown} className={clsName} />;
  }
}
export class TaskManager {
  constructor(updateTask) {
    this.taskPool = new Map();
    this.cancelPool = new Map();
    this.timerQueue = []; // task
    this.maxTasksNums = 5;
    this.currentTasksNums = 0;
    this.updateTask = updateTask;
  }

  addTask(task) {
    if (this.taskPool.get(task.id) !== undefined) {
      return;
    }
    this.taskPool.set(task.id, task);
    this.timerQueue.push(task);
    this.nextTask();
  }

  pauseAll() {
    this.taskPool.forEach((task) => {
      this.pause(task.id);
    });
  }

  pause(id) {
    const task = this.taskPool.get(id);

    if (!task) {
      return;
    }

    const cancel = this.cancelPool.get(id);
    if (typeof cancel === 'function') {
      cancel();
    }
  }

  nextTask() {
    if (
      this.currentTasksNums >= this.maxTasksNums ||
      this.timerQueue.length === 0
    ) {
      return;
    }

    const task = this.timerQueue.shift();
    this.currentTasksNums++;
    this.goUploadTask(task);
  }

  // 创建、继续上传任务
  goUploadTask(task) {
    const { size, isImport, isImportFromDK } = task;
    if (isImport || isImportFromDK) {
      this.goUnUpload(task);
      return;
    }
    if (size < CHUNKSIZE) {
      this.goUpLoadSingle(task);
    } else {
      this.goUpLoadSplit(task);
    }
  }

  goUnUpload(task) {
    const { id } = task;
    this.updateTask(id, { percent: 100, taskId: id });
  }

  // 单个上传
  async goUpLoadSingle(task) {
    const {
      size,
      fileName,
      id,
      percent,
      file,
      knowledgeId,
      callback,
      parentId,
      type,
      currPageId,
      modeType = 0,
    } = task;

    let sha256Value = null;
    if (type === 'dk_file') {
      try {
        let res = await getFileHash(file);
        sha256Value = res.sha256;
      } catch (error) {}
    }

    const authParams = createUploadQuery(sha256Value, size);

    function getPreSignUrl() {
      return new Promise((resolve, reject) => {
        post(api.UPLOAD_SINGLE.replace(':knowledgeId', knowledgeId), {
          parentId,
          fileName,
          type,
          modeType,
          pageId: currPageId,
          ...authParams,
        })
          .then((res) => resolve(res))
          .catch(reject);
      });
    }

    let preSignUrl = '';
    let taskId = '';
    try {
      const res = await getPreSignUrl();
      preSignUrl = res.preSignUrl;
      taskId = res.taskId;
    } catch (err) {
      this.updateTask(task.id, { err: intl.t('上传失败'), taskId });
      this.currentTasksNums--;
      this.nextTask();

      return;
    }

    if (file === null) {
      this.currentTasksNums--;
      this.nextTask();

      return;
    }

    let header = {};

    if (sha256Value !== null) {
      header['X-Amz-Content-Sha256'] = sha256Value;
    }

    const [request, cancel] = putWithCancel(preSignUrl, file, {
      headers: {
        ...header,
      },
    });

    this.cancelPool.set(id, () => {
      cancel();
      this.currentTasksNums--;
      this.nextTask();
      this.cancelPool.delete(id);
    });

    request
      .then(async (res) => {
        if (res.status === 200) {
          this.updateTask(id, { percent: 100, taskId });
          if (typeof callback === 'function') {
            callback(id);
          }
        } else if (res.status === 404) {
          this.goUpLoadSingle(task);
          return;
        }
      })
      .catch((err) => {
        this.updateTask(task.id, { err: intl.t('上传失败'), taskId });

        // if (err?.message === CANCELPUT) {
        //   return
        // }
        // this.cancelPool.delete(id)
      })
      .finally(() => {
        this.currentTasksNums--;
        this.nextTask();
      });
  }

  // 切片上传
  async goUpLoadSplit(task, force = false) {
    const {
      size,
      fileName,
      id,
      percent,
      file,
      knowledgeId,
      callback,
      parentId,
      type,
      currPageId,
      modeType,
    } = task;

    // 申请上传地址
    const batch = Math.ceil(size / CHUNKSIZE);

    let res;
    try {
      res = await post(api.UPLOAD_SLICE.replace(':knowledgeId', knowledgeId), {
        parentId,
        pageId: currPageId, // 普通文件上传新版本时需要
        fileName,
        type,
        batch,
        modeType,
      });
    } catch (error) {
      this.updateTask(task.id, { err: intl.t('上传失败') });
      this.currentTasksNums--;
      this.nextTask();

      return;
    }
    const { batchUploadPreSignUrl, fileId, taskId } = res;

    const batchUploadNo = batchUploadPreSignUrl[0].batchUploadNo;

    // 限制并发1个切片同时上传，调整个数需要考虑取消请求判断
    let requestQueue = [];
    let cancelQueue = [];
    const maxReqNum = 1;
    let currentReqNums = 0;
    let uploadedSize = 0;
    let eTags = [];
    let isCancel = false;
    const cancelRequest = (isRerequest = false) => {
      if (isCancel === true) {
        return;
      }
      try {
        // 开始下一个任务
        if (!isRerequest) {
          this.currentTasksNums--;
          this.nextTask();
        }
        this.cancelPool.delete(id);

        isCancel = true;
        requestQueue = [];
        cancelQueue.forEach((fn) => fn && fn());
        cancelQueue = [];
      } catch (err) {
        console.log(err);
      }
    };
    this.cancelPool.set(id, cancelRequest);

    function nextRequest() {
      if (currentReqNums < maxReqNum) {
        const request = requestQueue.shift();
        request && request();
      }
    }

    const request = async (i) => {
      currentReqNums++;
      const chunk = file.slice(i * CHUNKSIZE, (i + 1) * CHUNKSIZE);
      let sha256Value = null;
      if (type === 'dk_file') {
        sha256Value = getChunkHash(chunk);
      }

      const chunkSize = chunk.size;

      let header = {};

      if (sha256Value !== null) {
        header['X-Amz-Content-Sha256'] = sha256Value;
      }

      const [uploadRequest, cancel] = putWithCancel(
        batchUploadPreSignUrl[i].preSignUrl,
        // 切第i片
        chunk,
        {
          headers: {
            ...header,
          },
        }
      );
      cancelQueue.push(cancel);
      uploadRequest
        .then(async (res) => {
          if (res.status === 200) {
            const indexDel = cancelQueue.findIndex((item) => item === cancel);
            cancelQueue.splice(indexDel, 1);
            uploadedSize += chunkSize;
            eTags.push({
              index: i + 1,
              size,
              eTag: res.headers.etag,
            });

            if (uploadedSize === size) {
              const eTagsReq = eTags.map((item) => ({
                batchNo: item.index,
                etag: item.eTag,
              }));

              post(
                `${api.UPLOAD_SPLIT_DONE}?batchUploadNo=${batchUploadNo}&bizNo=knowledge_sync&fileId=${fileId}&resourceId=0`,
                eTagsReq
              )
                .then(async () => {
                  this.updateTask(task.id, { percent: 100, taskId });
                  if (typeof callback === 'function') {
                    callback(id);
                  }
                })
                .catch((err) => {
                  this.updateTask(task.id, { err: intl.t('上传失败'), taskId });
                })
                .finally(() => {
                  this.currentTasksNums--;
                  this.nextTask();
                  this.cancelPool.delete(id);
                });
            } else {
              this.updateTask(task.id, {
                percent: Math.floor((uploadedSize / size) * 100),
                taskId,
              });
            }
          } else if (res.status === 404) {
            this.goUpLoadSingle(task);
            return;
          }
        })
        .catch((err) => {
          this.updateTask(task.id, { err: intl.t('上传失败'), taskId });
          // if (err.message === CANCELPUT) {
          //   return
          // }

          cancelRequest();
        })
        .finally(() => {
          currentReqNums--;
          nextRequest();
        });
    };

    for (let i = 0; i < batch; ++i) {
      if (currentReqNums < maxReqNum) {
        request(i);
      } else {
        requestQueue.push(() => request(i));
      }
    }
  }
}
