import { intl } from 'di18n-react';
/* eslint-disable */
import { useEffect, useState, useRef } from 'react';
import { Button, Modal, message, Tooltip } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import classBind from 'classnames/bind';
import {
  getTreeById,
  importFile,
  getFileList,
  getSpaceList,
} from '@/service/knowledge/fileTree';
import { formateParamsTree, image } from './utils';
import NotificationStatus from '@/constants/notification';
import useNotification from '@/hooks/useNotification';
import useUpload from '@/hooks/uploadWiki';
import SearchFile from './SearchFile';
import Tree from './Tree';
import styles from './style.module.less';

const cx = classBind.bind(styles);

const Component = (props) => {
  const dispatch = useDispatch();
  const { closeFileModal } = dispatch.fileTree;
  const { addTask } = useUpload();
  const { pageName, visible, knowledgeId, resourceId } = props;
  const [modalType, setModalType] = useState('cooper');
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const notification = useNotification();
  const [key, setKey] = useState('');
  const [space, setSpace] = useState({ id: '', name: '' });
  const [checkedInfo, setCheckInfo] = useState({ count: 0, treeRef: null });

  const getList = async () => {
    let res = await getSpaceList();
    setFileList(res);
  };

  const onSubmit = async () => {
    const { checkedKeys, keyEntities } = checkedInfo.treeRef.current.state;
    let nodeList = checkedKeys.map((v) => keyEntities[v]);
    let tree = formateParamsTree(nodeList, space.id);
    try {
      setLoading(true);
      await importFile(knowledgeId, {
        parentId: resourceId,
        tree,
      });
      setLoading(false);
      closeFileModal();
      nodeList.map((v) => {
        let node = v.node;
        addTask({
          fileName: node.resourceName,
          knowledgeId,
          parentId: resourceId,
          currPageId: 0,
          taskId: node.resourceId,
          resourceType: node.resourceType,
        });
      });
    } catch (error) {
      closeFileModal();
      notification(NotificationStatus.ERROR, error.errorMessage);
    }
  };

  const handleSwitch = (type) => {
    const fn = () => {
      setSpace({ id: '', name: '' });
      setModalType(type);
    };
    if (checkedInfo.count > 0) {
      Modal.confirm({
        title: intl.t('提示'),
        content: intl.t(
          '暂不支持跨空间选择文件，切换空间后需重选文件，是否继续'
        ),
        okText: intl.t('继续'),
        cancelText: intl.t('取消'),
        onOk() {
          fn();
        },
      });
    } else {
      fn();
    }
  };

  const onSearch = async (v) => {
    if (!v) return;
    let currentSpace = v.pathFromRoot[0];
    let id = currentSpace.id === '0' ? 0 : currentSpace.id;
    let switchSpace = space.id !== id;
    setSpace({ id, name: currentSpace.name, node: v, switch: switchSpace });
    if (switchSpace) {
      setModalType('tree');
    }
  };

  useEffect(() => {
    setKey(Math.random());
    if (modalType === 'team') {
      getList();
    }
    setCheckInfo({ count: 0, treeRef: null });
  }, [modalType]);

  return (
    <Modal
      visible={visible}
      onCancel={closeFileModal}
      title={
        <>
          {intl.t('从')}
          <span>Cooper</span>
          {intl.t('中导入文件到')}
          <span title={pageName || intl.t('知识库')}>
            {pageName || intl.t('知识库')}
          </span>
        </>
      }
      className={cx('file-tree-modal')}
      footer={null}
    >
      <p className={cx('import-tip')}>
        {intl.t('支持导入文件夹、协作文档和氢文档，导入后将保留原层级关系')}
      </p>
      <SearchFile onChange={onSearch} scope={space.id} value={key} />
      <div className={cx('file-modal-content')}>
        {modalType !== 'cooper' && (
          <div className={cx('file-modal-bread')}>
            <span onClick={() => handleSwitch('cooper')}>Cooper</span>
            <img src={image.arrow} />
            <span onClick={() => space.id && handleSwitch('team')}>
              {space.id === 0 ? intl.t('个人空间') : intl.t('团队空间')}
            </span>
            {!!space.id && (
              <>
                <img src={image.arrow} />
                <span className={cx('space-name')}>{space.name}</span>
              </>
            )}
          </div>
        )}
        {modalType === 'cooper' && (
          <ul className={cx('file-cooper-wrap')}>
            <li
              onClick={() => {
                setSpace({ id: 0, name: '' });
                setModalType('tree');
              }}
            >
              <img src={image.personal} alt="avatar" />
              <span>{intl.t('个人空间')}</span>
            </li>
            <li
              onClick={() => {
                setSpace({ id: '', name: '' });
                setModalType('team');
              }}
            >
              <img src={image.team} />
              <span>{intl.t('团队空间')}</span>
            </li>
          </ul>
        )}
        {modalType === 'team' && (
          <ul className={cx('file-space-wrap')}>
            {fileList.map((v) => (
              <li
                key={v.resourceId}
                onClick={() => {
                  setSpace({ id: v.resourceId, name: v.resourceName });
                  setModalType('tree');
                }}
              >
                <img src={v.tiny_image} />
                {v.resourceName?.length > 30 ? (
                  <Tooltip title={v.resourceName} placement="topLeft">
                    <span>{v.resourceName}</span>
                  </Tooltip>
                ) : (
                  <span>{v.resourceName}</span>
                )}
              </li>
            ))}
          </ul>
        )}
        {modalType === 'tree' && <Tree space={space} checked={setCheckInfo} />}
      </div>
      <div className={cx('import-modal-btns')}>
        <Button onClick={closeFileModal}>{intl.t('取消')}</Button>
        <Button
          type="primary"
          onClick={onSubmit}
          loading={loading}
          disabled={checkedInfo.count <= 0}
        >
          {intl.t('导入')}
        </Button>
      </div>
      <p className={cx('checked-number')}>
        {intl.t('已选：')}
        {checkedInfo.count}
        {intl.t('个文件')}
      </p>
    </Modal>
  );
};

const ComponentWrap = () => {
  const fileTree = useSelector((state) => state.fileTree);
  const { visible } = fileTree;
  const [modalVisible, setModalVisible] = useState(visible);
  useEffect(() => {
    if (!visible) {
      setTimeout(() => {
        setModalVisible(false);
      }, 200);
    } else {
      setModalVisible(true);
    }
  }, [visible]);
  return modalVisible && <Component {...fileTree} />;
};

export default ComponentWrap;
