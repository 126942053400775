/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-08-31 16:55:59
 * @LastEditTime: 2023-09-11 14:39:44
 * @Description: 收藏页面
 * @FilePath: /knowledgeforge/src/model/models/cooper/cooperStar.js
 *
 */
import * as CooperStarService from '@/service/cooper/cooperStar';

export const RECEIVE_STAR_CONTENTS = 'receive_star_content';


export default {
  state: {
    list: [],
  },
  reducers: {
    updteState(state, action) {
      switch (action.type) {
        case RECEIVE_STAR_CONTENTS: {
          const { data } = action;
          const { tempList, refresh } = data;
          const newList = tempList.map((item) => ({
            ...item,
            id: item.resource_id,
            permission: 1,
            markedStar: true,
          }));
          return { ...state, list: refresh ? newList : (state.list || []).concat(newList) };
        }
        default: {
          return state
        }
      }
    },
    updateGlobDir(state, currentList) {
      return {
        ...state,
        list: currentList,
      }
    },
  },
  effects: {
    async getStarFileList(params) {
      let { refresh } = params
      delete params[refresh]
      return new Promise((resolve) => {
        CooperStarService.getStarFile(params).then((data) => {
          const items = data.items || []
          items.forEach((item) => {
            item.name = item.display_name
          });
          this.updteState({
            type: RECEIVE_STAR_CONTENTS,
            data: {
              tempList: data.items || [],
              refresh,
            },
          })
          resolve(data)
        })
      })
    },
  },
};
