import { intl } from 'di18n-react';
import classBind from 'classnames/bind';
import { Tooltip } from 'antd';
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import styles from './style.module.less';

const cx = classBind.bind(styles);

export default function TransferState({
  // eslint-disable-next-line no-unused-vars
  percent,
  id,
  onClickClose,
  stateMsg,
  isFail,
  err,
  isFinish,
}) {
  return (
    <div className={cx('dk-upload-operation')}>
      {isFinish && (
        <Tooltip
          title={err || stateMsg}
          placement="top">
          {isFail ? (
            <ExclamationCircleOutlined
              className={cx('dk-upload-fail', 'dk-upload-result')}
            />
          ) : (
            <>
              <Tooltip title={intl.t('查看')}>
                <div className={cx('dk-upload-operation--icon-wrap')}>
                  <i
                    className={cx(
                      'dk-icon-yulan',
                      'dk-iconfont',
                      'dk-upload-jumpTo',
                    )}
                  />
                </div>
              </Tooltip>
              <div className={cx('dk-upload-operation--icon-wrap')}>
                <CheckCircleOutlined
                  className={cx('dk-upload-success', 'dk-upload-result')}
                />
              </div>
            </>
          )}
        </Tooltip>
      )}
      {!isFinish && (
        <div className={cx('dk-upload-operation-hint')}>{err || stateMsg}</div>
      )}

      {/* {
         err === '上传失败'
           ? <div className={cx('dk-upload-operation-failed')}></div>
           : <div className={cx('dk-upload-operation-hint')}>{percent}%</div>
        } */}
      {!isFinish && onClickClose && (
        <div
          alt="OPERATION"
          onClick={() => {
            onClickClose(id);
          }}
          className={cx('dk-upload-operation-cancel', 'dk-upload-card-icon')}
        />
      )}
    </div>
  );
}
