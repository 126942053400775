import { intl } from 'di18n-react';
/* eslint-disable */
import { Modal, Popover, Button } from 'antd';
import classBind from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  getUnreadList,
  setReadBatch,
  checkItemUnread,
} from '@/service/knowledge/guide';
import { getApolloConfig } from '@/service/knowledge/global';
import { getCookie } from '@/utils';
import { guideConfig } from './config';
import styles from './style.module.less';

const cx = classBind.bind(styles);
const useUpdate = (fn, dep) => {
  const [isMount, setMount] = useState(false);
  useEffect(() => {
    isMount && fn();
  }, dep);
  useEffect(() => setMount(true), []);
};

const Component = () => {
  const { editReady } = useSelector((state) => state.pageDetail);
  const dispatch = useDispatch();
  const { setOpenGuideFn } = dispatch.guide;
  const [visible, setVisible] = useState(false);
  const [list, setList] = useState([]);
  const [listCopy, setListCopy] = useState(list);
  const [currentDom, setCurrentDom] = useState({});
  const [welcomModal, setWelcomModal] = useState({});
  const [listFilter, setLisfilter] = useState(false);
  const [isOver, setOver] = useState(false);
  const lazyList = useRef([]);
  const [domStyle, setDomstyle] = useState({
    left: 0,
    top: 0,
    width: 10,
    height: 10,
  });
  const { pathname } = useLocation();

  const openGuideFn = async (position) => {
    if (Array.isArray(position)) {
      // 后续补充
    } else if (typeof position === 'string') {
      let { id } = (await checkItemUnread(position)) || {};
      if (id) {
        let currentPos = lazyList.current.find((v) => v.position === position);
        if (currentPos?.domId) {
          let dom = document.querySelector(currentPos.domId);
          let currentList = [{ ...currentPos, dom, id }];
          setOver(false);
          setWelcomModal({});
          setListCopy([...currentList]);
          setList(currentList);
        }
      }
    }
  };

  const clear = async () => {
    setVisible(false);
    setOver(true);
    let ids = [];
    list.map((v) => ids.push(v.id));
    welcomModal.id && ids.push(welcomModal.id);
    setReadBatch(ids);
    setWelcomModal({});
  };

  const getGuideDom = () => {
    listCopy.map((v) => {
      if (!v.dom) {
        v.dom = document.querySelector(v.domId);
      }
    });
  };

  const guideStart = (immediate) => {
    let currentDom = listCopy.shift();
    if (currentDom && !currentDom.dom) {
      currentDom.dom = document.querySelector(currentDom.domId);
    }
    if (currentDom && currentDom.dom && !isOver) {
      const { top, left, width, height } =
        currentDom.dom.getBoundingClientRect();
      const update = () => {
        setDomstyle({
          top: top + (currentDom?.offsetTop ?? 0),
          left: left + (currentDom?.offsetleft ?? 0),
          width: currentDom.width ?? width,
          height: currentDom.height ?? height,
        });
        setCurrentDom(currentDom);
        setVisible(true);
      };
      if (immediate) {
        update();
      } else {
        setTimeout(() => {
          update();
        }, 300);
      }
    }
  };

  useEffect(() => {
    // 修复控制台报错: 当组件卸载时，执行setState会导致控制台报错
    // Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it
    // indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a
    // useEffect cleanup function.
    let mounted = true;
    setOpenGuideFn(openGuideFn);

    (async () => {
      try {
        let [config, unreadList] = await Promise.all([
          getApolloConfig({ name: 'DK-guide' }),
          getUnreadList(),
        ]);
        let newList = [];
        unreadList.sort((v1, v2) => v1.order - v2.order);
        unreadList.map((v) => {
          let current = config[v.position];
          let currentLoalConfig = guideConfig[v.position] || {};
          if (current) {
            current = JSON.parse(current);
            if (current.lazyLoad) {
              lazyList.current.push({ ...currentLoalConfig, ...current, ...v });
            } else {
              newList.push({ ...currentLoalConfig, ...current, ...v });
            }
          }
        });
        mounted && setList(newList);
        setTimeout(() => {
          // 排除设置页面
          if (!window.msContainer || pathname.endsWith('/setUp')) {
            setLisfilter(true);
          }
        }, 400);
      } catch (e) {
        console.log('error', e);
      }
    })();

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      editReady && setLisfilter(true);
    }, 400);
  }, [editReady]);

  useUpdate(() => {
    !visible && guideStart();
  }, [visible]);

  useUpdate(() => {
    isOver &&
      setDomstyle({
        top: 0,
        left: 0,
        width: 0,
        height: 0,
      });
  }, [isOver]);

  useUpdate(() => {
    let basePath = location.hostname == 'localhost' ? '/knowledge' : '';
    // 分享和创建不做引导
    if (
      pathname === basePath + '/create' ||
      pathname.startsWith(basePath + '/share')
    ) {
      return;
    }
    let newList = [];
    list.map((v) => {
      let current = document.querySelector(v.domId);
      let parentNode = v.parentNode
        ? document.querySelector(v.parentNode)
        : true;
      if (parentNode && (current || v.necessary)) {
        newList.push({ ...v, dom: current });
      } else if (v.position === '1-4-1029-welcome') {
        setWelcomModal(v);
      }
    });
    setList(newList);
    setListCopy([...newList]);
  }, [listFilter]);

  useUpdate(() => {
    if (!listFilter) {
      return;
    }

    if (welcomModal.id) {
      Modal.info({
        icon: <></>,
        className: cx('guide-welcome-wrap'),
        centered: true,
        content: (
          <div>
            <img
              src={welcomModal.img}
              style={{
                maxWidth: 368,
                maxHeight: 240,
                margin: '0 auto',
                display: 'block',
              }}
            />

            <p className={cx('guide-welcome-desc')}>
              {welcomModal.desc.replace(
                '{userName}',
                decodeURI(getCookie('_cooper_username_zh') ?? '')
              )}
            </p>
          </div>
        ),

        okText: intl.t('开启探索之旅'),
        onOk() {
          guideStart(true);
        },
      });
    } else {
      guideStart(true);
    }
  }, [list]);

  const content = (current) => (
    <div className={`${cx('guide-pop-content')}`}>
      {current?.img && <img src={current?.img} />}
      <p className={cx('guide-pop-title')}>{current?.title}</p>
      <p className={cx('guide-pop-desc')}>{current?.desc}</p>
      <div className={cx('guide-pop-btns')}>
        <span className={cx('guide-pop-process')}>
          {list.length > 1 && (
            <>
              {list?.length - listCopy?.length}/{list?.length}
            </>
          )}
        </span>
        <div>
          {list.length > 1 && listCopy.length !== 0 && (
            <Button onClick={clear}>{intl.t('跳过')}</Button>
          )}

          <Button
            type="primary"
            onClick={() => {
              if (list.length == 1 || listCopy.length == 0) {
                clear();
              } else {
                setVisible(false);
              }
            }}
          >
            {list.length == 1
              ? intl.t('知道了')
              : listCopy.length > 0
              ? intl.t('下一步')
              : intl.t('完成')}
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={`${cx('dk-guide')} ${visible ? cx('gudie-active') : ''}`}
      id="dk-guide"
    >
      <Popover
        content={content(currentDom)}
        arrowPointAtCenter={currentDom.arrowPointAtCenter ?? true}
        onVisibleChange={(v) => {}}
        placement={currentDom?.placement}
        overlayClassName={currentDom?.img ? cx('guide-pop-content-img') : ''}
        getPopupContainer={() => document.querySelector('#dk-guide')}
        visible={visible}
        trigger="click"
      >
        <span className={cx('mask-btn')} style={{ ...domStyle }}>
          {currentDom.content || ''}
        </span>
      </Popover>
    </div>
  );
};

const Guide = () => {
  const location = useLocation();
  const [guideModal, setGuideModal] = useState(true);
  const dispatch = useDispatch();
  const { changeEditReady } = dispatch.pageDetail;

  useEffect(() => {
    if (location.pathname.endsWith('/edit')) {
      window.msContainer = true;
      setGuideModal(false);
      changeEditReady(false);
      setTimeout(() => {
        setGuideModal(true);
      }, 100);
    }
  }, [location.pathname]);
  return <>{guideModal && <Component />}</>;
};

export default Guide;
