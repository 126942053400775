/* eslint-disable */
import { get, post } from '@/utils/request';
import api from '@/utils/request/api/DkApi';

export async function getUnreadList() {
  try {
    const res = await get(api.GET_UNREAD_LIST, {params: {frontType: 'WEB', noticeType: 'NEWHAND'}});
    // 白名单
    if(window.localStorage?.useLocalGuide) {
      return [{"zone":"10000","position":"1-4-1029-createDk","id":"2199330973662","type":"NEWHAND","order":"2","group":"0"},{"zone":"10000","position":"1-4-1029-tagView","id":"2199330973883","type":"NEWHAND","order":"5","group":"0"},{"zone":"10000","position":"1-4-1029-welcome","id":"2199330980795","type":"NEWHAND","order":"1","group":"0"},{"zone":"10000","position":"1-4-1029-editTmpl","id":"2199330983900","type":"NEWHAND","order":"7","group":"0"},{"zone":"10000","position":"1-4-1029-editPublish","id":"2199330992989","type":"NEWHAND","order":"9","group":"0"},{"zone":"10000","position":"1-4-1029-addTag","id":"2199330993065","type":"NEWHAND","order":"10","group":"0"},{"zone":"10000","position":"1-4-1029-createPage","id":"2199330994842","type":"NEWHAND","order":"3","group":"0"},{"zone":"10000","position":"1-4-1029-fileImport","id":"2199330994950","type":"NEWHAND","order":"4","group":"0"},{"zone":"10000","position":"1-4-1029-helpCenter","id":"2199331009534","type":"NEWHAND","order":"6","group":"0"},{"zone":"10000","position":"1-4-1029-flowsheet","id":"2199331009635","type":"NEWHAND","order":"8","group":"0"}]
    }
    return res;
  } catch (e) {
    console.log(e);
  }
}

export async function checkItemUnread(position) {
  try {
    const res = await get(api.GET_UNREAD_ITEM.replace(':position', position), {params: {noticeType: 'NEWHAND'}});
    // 白名单
    if(window.localStorage?.useLocalGuide) {
      return {"zone":"10000","position":"1-4-1029-addTag","id":"2199330993065","type":"NEWHAND","order":"10","group":"0"}
    }
    return res;
  } catch (e) {
    console.log(e);
  }
}

export async function setReadBatch(data) {
  try {
    await post(api.SET_READ_BATCH, data);
  } catch (e) {
    console.log(e);
  }
}
