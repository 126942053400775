import CooperShareModal from '@/components/CooperShareModal';
import mountAnywhere from '@/utils/mountAnywhere';

function shareFile(id, name, teamName, relationTypeTags, doneCallback) {
  const Comp = (
    <CooperShareModal
      fileId={id}
      fileName={name}
      relationTypeTags={relationTypeTags}
      teamName={teamName}
      onClose={doneCallback}
    />
  );

  mountAnywhere(Comp);
}

export default shareFile;
