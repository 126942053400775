
import { get, post } from '@/utils/request';
import api from '@/utils/request/api/DkApi';
import * as service from '@/service/knowledge/pageTree';

export const formateTagTreeData = (list, tagId) => {
  const { tagList, tagPageList = [] } = list;
  return tagList.map((item) => {
    let isThis = item.id === tagId && tagPageList.length !== 0;
    return {
      ...item,
      key: `${item.id}-0`,
      title: item.name,
      isLeaf: false,
      children: isThis ? formatePageData(tagPageList, tagId) : [],
    };
  });
};

export const formatePageData = (pageList, tagId) => {
  return pageList.map((item) => {
    return {
      ...item,
      key: `${tagId}-${item.pageId}`,
      title: item.pageName,
      isLeaf: true,
      parentTagId: tagId,
      children: [],
    };
  });
};


export const getTagTreeData = async ({ knowledgeId, tagId }) => {
  const res = await get(api.TAG_GET_LIST.replace(':knowledgeId', knowledgeId), {
    params: {
      tagId,
    },
  });
  return res;
};


export const getTagPageData = async ({ knowledgeId, tagId }) => {
  const res = await get(api.TAG_GET_CHOOSE_PAGE_LIST.replace(':knowledgeId', knowledgeId).replace(':tagId', tagId));
  return res;
};

export const getCurrPageTag = async ({ pageId }) => {
  const res = await get(api.PAGE_TAG_LIST.replace(':pageId', pageId));
  return res;
};
