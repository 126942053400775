import * as service from '@/service/knowledge/tagTree';

export default {
  state: {
    pageSize: 1000,
    pageNum: 0,
    tagData: [],
    tagId: '0',
    choosePageMsg: {},
    isOpenCatalog: true,
  },
  reducers: {

    // 'otherModel/actionName': (state, payload) => state + payload,
    setState: (state, payload = {}) => ({ ...state, ...payload }),

    // 标签添加子节点页面
    addPageToTag: (state, { pageList, tagId }) => {
      let { tagData } = state;
      tagData.forEach((item) => {
        if (item.id === tagId) {
          item.children = [...pageList];
        }
      });

      return state;
    },
    // 标签目录时编辑页面更新节点上的name
    updateNameOnTag: (state, { name, pageId }) => {
      let { tagData } = state;
      // 该功能极少使用，暂不做优化
      for (let i = 0; i < tagData.length; i++) {
        const pageList = tagData[i]?.children || []
        for (let j = 0; j < pageList.length; j++) {
          if (pageId === pageList[j].pageId) {
            pageList[j].title = name
            pageList[j].pageName = name
          }
        }
      }
      return state;
    },

    // 添加一个标签时，重新更新该标签下的所有内容
    addTagToPage: (state, { tagPageData }) => {
      let { tagData } = state;

      let isExist = false;
      let data = [];
      tagData.forEach((item) => {
        if (item.id === tagPageData[0].id) {
          item.children = tagPageData[0].children;
          isExist = true;
        }
      });
      if (!isExist) {
        data = tagData.concat(tagPageData);
        state.tagData = data;
      }


      return state;
    },


    updateDataForDel(state, { tagId, pageId }) {
      let { tagData } = state;

      let temp = [];

      tagData.forEach((item, index) => {
        if (item.id === tagId) {
          temp = item.children.filter((i) => i.pageId !== pageId);

          item.children = [...temp];
          if (temp.length === 0) {
            tagData = tagData.splice(index, 1);
          }
        }
      });

      return state;
    },
  },
  effects: () => ({

    async updateTagTreeData(payload) {
      const { knowledgeId, tagId } = payload;
      const res = await service.getTagTreeData({ knowledgeId, tagId });
      const tagData = service.formateTagTreeData(res, tagId);
      return tagData;
    },

    async getSubTagPage(payload) {
      const { knowledgeId, tagId } = payload;
      const res = await service.getTagPageData({ knowledgeId, tagId });
      const pageList = service.formatePageData(res.tagPageList, tagId);
      return pageList;
    },

    async getNewTagPageData(payload) {
      const { tagId, tagName, knowledgeId } = payload;

      const { tagPageList: pageList } = await service.getTagPageData({ knowledgeId, tagId });

      const tagPageData = service.formateTagTreeData({
        tagList: [{
          sourceType: 'CUSTOM',
          name: tagName,
          id: tagId,
        }],
        tagPageList: pageList,
      }, tagId);

      return tagPageData;
    },

  }),
};
