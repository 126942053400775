import { COOPER_ASIDE_TYPE } from '@/constants';
import { getTopTeamList, getUnreadNotice } from '@/service/cooper/index';
import { setUserView } from '@/service/cooper/userView';
import { getLocalData } from '@/utils/localStorage';
import {
  getTenantList,
  getTenantConfig,
} from '@/pages/cooper/TenantLogin/service';

import { getApolloConfig } from '@/utils/ab';


// cooper的全局信息
export default {
  name: 'GlobalData',
  state: {
    asideTeamList: [],
    asideType: JSON.stringify(getLocalData('asideType')) !== '{}' ? getLocalData('asideType') : COOPER_ASIDE_TYPE.LARGE, // 空间默认是收起态，其他页面默认是展开态；进入空间后切换空间变成收起态；用户手动操作后以用户的为准
    // 用户先访问空间，再进另一个浏览器tab打开首页时，应该收起还是展开？
    toastPersonTrashTip: false,
    toastTeamTrashTip: false,
    userViewData: {},
    unreadCount: 0,
    renderCreateSpaceGuide: false,
    renderNewSpaceGuide: false,
    tenantId: 1, // 当前登录的租户id 默认滴滴租户
    tenantList: [{}], // 租户列表
    tenantConfig: [], // 当前租户配置
    IsExternalTenant: true,
    cooperLinkConf: {},
    // 用户先访问空间，再进另一个浏览器tab打开首页时，应该收起还是展开？
    needAsideSkeleton: true,
    needAccessSkeleton: true,
    needHomeTableSkeleton: true,
    needPersonSpaceSkeleton: true,
    needTeamSpaceSkeleton: true,
    needTeamDetailSkeleton: true,
    needShareFromMeSkeleton: true,
    needShareToMeSkeleton: true,
    needFavoriteSkeleton: true,
    needTrashSkeleton: true,
    needTeamFileSkeleton: true,
    showLiteNotice: false,
  },
  reducers: {
    setState(state, payload = {}) {
      return {
        ...state,
        ...payload,
      };
    },
    setRenderCreateSpaceGuide(state, renderCreateSpaceGuide) {
      return {
        ...state,
        renderCreateSpaceGuide,
      };
    },
    setRenderNewSpaceGuide(state, renderNewSpaceGuide) {
      return {
        ...state,
        renderNewSpaceGuide,
      };
    },
    setUserViewData(state, userViewData) {
      return {
        ...state,
        userViewData,
      };
    },
    setToastTeamTrashTip(state, toastTeamTrashTip) {
      return { ...state, toastTeamTrashTip };
    },

    setToastPersonTrashTip(state, toastPersonTrashTip) {
      return { ...state, toastPersonTrashTip };
    },

    setAsideTeamList(state, asideTeamList) {
      return { ...state, asideTeamList };
    },

    setAsideType(state, asideType) {
      return { ...state, asideType };
    },
    setNeedAsideSkeleton(state, needAsideSkeleton) {
      return { ...state, needAsideSkeleton }
    },
    setUnreadCount(state, totalCount) {
      return { ...state, unreadCount: totalCount };
    },

    setTenantId(state, tenantId) {
      return { ...state, tenantId };
    },

    setTenantList(state, tenantList) {
      return { ...state, tenantList };
    },

    setTenantConfig(state, tenantConfig) {
      return { ...state, tenantConfig };
    },

    setIsExternalTenant(state, IsExternalTenant) {
      return { ...state, IsExternalTenant };
    },

    setCooperLinkConf(state, cooperLinkConf) {
      return { ...state, cooperLinkConf }
    },

    setNeedAccessSkeleton(state, needAccessSkeleton) {
      return { ...state, needAccessSkeleton }
    },
    setNeedHomeTableSkeleton(state, needHomeTableSkeleton) {
      return { ...state, needHomeTableSkeleton }
    },
    setNeedPersonSpaceSkeleton(state, needPersonSpaceSkeleton) {
      return { ...state, needPersonSpaceSkeleton }
    },
    setNeedTeamSpaceSkeleton(state, needTeamSpaceSkeleton) {
      return { ...state, needTeamSpaceSkeleton }
    },
    setNeedTeamDetailSkeleton(state, needTeamDetailSkeleton) {
      return { ...state, needTeamDetailSkeleton }
    },
    setNeedShareFromMeSkeleton(state, needShareFromMeSkeleton) {
      return { ...state, needShareFromMeSkeleton }
    },
    setNeedShareToMeSkeleton(state, needShareToMeSkeleton) {
      return { ...state, needShareToMeSkeleton }
    },
    setNeedFavoriteSkeleton(state, needFavoriteSkeleton) {
      return { ...state, needFavoriteSkeleton }
    },
    setNeedTrashSkeleton(state, needTrashSkeleton) {
      return { ...state, needTrashSkeleton }
    },
    setNeedTeamFileSkeleton(state, needTeamFileSkeleton) {
      return { ...state, needTeamFileSkeleton }
    },
    setShowLiteNotice(state, showLiteNotice) {
      return { ...state, showLiteNotice }
    },
  },
  effects: () => ({
    async getAsideTeamList() {
      let res = await getTopTeamList();

      if (res) {
        this.setAsideTeamList(res);
      }
      return res;
    },

    async setUserViewDataRq(data) {
      await setUserView(data);
      this.setUserViewData(data);
    },

    async getUnreadList() {
      let res = await getUnreadNotice();

      if (res) {
        this.setUnreadCount(res.totalCount);
      }
      return res;
    },

    async getTenantDataRq() {
      let res = await getTenantList();
      // 适用于cooper的用户list
      const tenantListCooper = res?.tenantList?.filter((v) => v.useCooper);
      const tenantId = tenantListCooper.filter((v) => v.selected)[0]?.tenantId;
      // 请求配置信息
      if (tenantId) {
        this.setTenantId(tenantId)
        this.setIsExternalTenant(tenantId !== 1)
        this.setTenantList(tenantListCooper)
        const tenantConfig = await getTenantConfig()
        this.setTenantConfig(tenantConfig)
      }
    },
    async getCooperLinkConf() {
      let data = await getApolloConfig('cooper_link-conf')
      this.setCooperLinkConf(data)
    },
  }),
};
