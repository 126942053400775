import { Spin } from 'antd';
import { useState } from 'react';
import './style.module.less'

function ImageWithLoader(props) {
  const { src, alt, clickHandler } = props;
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div className='image-with-loader'>
      {loading && (
        <div className='image-with-loader__spinner'>
          <Spin />
        </div>
      )}
      <img
        className='image-with-loader__image'
        src={src}
        alt={alt}
        onLoad={handleImageLoad}
        onClick={clickHandler}
        style={loading ? { display: 'none' } : {}}
        />
    </div>
  );
};

export default ImageWithLoader
