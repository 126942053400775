module.exports = `
<style>
.file-skeleton-box  {
  margin: 0;
  padding: 0;
  min-width: 667px !important;
  margin: -8px auto 0 !important;
}

.file-skeleton-box .file-header-skeleton-box  {
  width: 100%;
  height: 36px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.file-skeleton-box .header-inner  {
  width: 100%;
  height: 22px;
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.file-skeleton-box .header-inner .file-header-skeleton-button  {
  width: 42px;
  height: 14px;
  // position: absolute;
  // top: 4px;
}

.file-skeleton-box .header-inner .file-header-skeleton-button *  {
  min-width: auto !important;
  width: 100% !important;
  height: 100% !important
}

.file-skeleton-box .header-inner .file-header-skeleton-name  {
  margin-right: 192px;
  flex: 1;
  min-width: 200px;
  // left: 0;
}

.file-skeleton-box .header-inner .file-header-skeleton-owner  {
  margin-right: 216px;
  min-width: 42px;
  // left: 544px
}

.file-skeleton-box .header-inner .file-header-skeleton-address  {
  margin-right: 216px;
  min-width: 42px;
  // left: 736px
}

.file-skeleton-box .header-inner .file-header-skeleton-time  {
  margin-right: 22px;
  min-width: 42px;
  // left: 928px
}

.file-skeleton-box .file-body-skeleton-box  {
  width: 100%;
  padding-top: 8px !important;
}

.file-skeleton-box .body-inner>li  {
  width: 100%;
  height: 46px;
  position: relative
}

.file-skeleton-box .body-inner .file-body-skeleton-button *  {
  min-width: auto !important;
  width: 100% !important;
  height: 100% !important
}

.file-skeleton-box .body-inner li  {
  display: flex;
  align-items: center;
}

.file-skeleton-box .body-inner .file-body-skeleton-name-logo  {
  width: 19.2px;
  height: 19.2px;
  min-width: 19.2px;
  // position: absolute;
  // top: 14.4px;
  // left: 2.4px
}

.file-skeleton-box .body-inner .file-body-skeleton-name-title  {
  // width: 280px;
  height: 14px;
  flex: 1;
  margin-left: 10px;
  // position: absolute;
  top: 17px;
  left: 32px;
  margin-right: 192px;
  min-width: 170px;
}

.file-skeleton-box .body-inner .file-body-skeleton-name-title .ant-skeleton  {
  max-width: 500px !important;
}

.file-skeleton-box .body-inner .file-body-skeleton-owner  {
  width: 66px;
  height: 14px;
  // position: absolute;
  top: 17px;
  left: 544px;
  margin-right: 192px;
  min-width: 66px;
}

.file-skeleton-box .body-inner .file-body-skeleton-address  {
  width: 66px;
  height: 14px;
  // position: absolute;
  top: 17px;
  left: 736px;
  margin-right: 192px;
  min-width: 66px;
}

.file-skeleton-box .body-inner .file-body-skeleton-time  {
  width: 66px;
  height: 14px;
  // position: absolute;
  top: 17px;
  left: 928px
  min-width: 66px;
}
</style>
<div class="file-skeleton-box">
  <div class="file-header-skeleton-box">
    <div class="header-inner">
      <div class="file-header-skeleton-name">
        <div class="file-header-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
      </div>
      <div class="file-header-skeleton-owner file-header-skeleton-button">
        <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
          <span class="ant-skeleton-button ant-skeleton-button-round"></span>
        </div>
      </div>
      <div class="file-header-skeleton-address file-header-skeleton-button">
        <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
          <span class="ant-skeleton-button ant-skeleton-button-round"></span>
        </div>
      </div>
      <div class="file-header-skeleton-time file-header-skeleton-button">
        <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
          <span class="ant-skeleton-button ant-skeleton-button-round"></span>
        </div>
      </div>
    </div>
  </div>
  <div class="file-body-skeleton-box">
    <div class="body-inner">
      <li>
        <div class="file-body-skeleton-name-logo file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element">
            <span class="ant-skeleton-button ant-skeleton-button-square"></span>
          </div>
        </div>
        <div class="file-body-skeleton-name-title file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
        <div class="file-body-skeleton-owner file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
        <div class="file-body-skeleton-address file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
        <div class="file-body-skeleton-time file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
      </li>
      <li>
        <div class="file-body-skeleton-name-logo file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element">
            <span class="ant-skeleton-button ant-skeleton-button-square"></span>
          </div>
        </div>
        <div class="file-body-skeleton-name-title file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
        <div class="file-body-skeleton-owner file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
        <div class="file-body-skeleton-address file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
        <div class="file-body-skeleton-time file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
      </li>
      <li>
        <div class="file-body-skeleton-name-logo file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element">
            <span class="ant-skeleton-button ant-skeleton-button-square"></span>
          </div>
        </div>
        <div class="file-body-skeleton-name-title file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
        <div class="file-body-skeleton-owner file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
        <div class="file-body-skeleton-address file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
        <div class="file-body-skeleton-time file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
      </li>
      <li>
        <div class="file-body-skeleton-name-logo file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element">
            <span class="ant-skeleton-button ant-skeleton-button-square"></span>
          </div>
        </div>
        <div class="file-body-skeleton-name-title file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
        <div class="file-body-skeleton-owner file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
        <div class="file-body-skeleton-address file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
        <div class="file-body-skeleton-time file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
      </li>
      <li>
        <div class="file-body-skeleton-name-logo file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element">
            <span class="ant-skeleton-button ant-skeleton-button-square"></span>
          </div>
        </div>
        <div class="file-body-skeleton-name-title file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
        <div class="file-body-skeleton-owner file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
        <div class="file-body-skeleton-address file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
        <div class="file-body-skeleton-time file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
      </li>
      <li>
        <div class="file-body-skeleton-name-logo file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element">
            <span class="ant-skeleton-button ant-skeleton-button-square"></span>
          </div>
        </div>
        <div class="file-body-skeleton-name-title file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
        <div class="file-body-skeleton-owner file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
        <div class="file-body-skeleton-address file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
        <div class="file-body-skeleton-time file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
      </li>
      <li>
        <div class="file-body-skeleton-name-logo file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element">
            <span class="ant-skeleton-button ant-skeleton-button-square"></span>
          </div>
        </div>
        <div class="file-body-skeleton-name-title file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
        <div class="file-body-skeleton-owner file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
        <div class="file-body-skeleton-address file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
        <div class="file-body-skeleton-time file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
      </li>
      <li>
        <div class="file-body-skeleton-name-logo file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element">
            <span class="ant-skeleton-button ant-skeleton-button-square"></span>
          </div>
        </div>
        <div class="file-body-skeleton-name-title file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
        <div class="file-body-skeleton-owner file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
        <div class="file-body-skeleton-address file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
        <div class="file-body-skeleton-time file-body-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
      </li>
    </div>
  </div>
</div>
`
