/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-06-02 17:07:37
 * @LastEditTime: 2023-11-27 15:54:45
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/CooperOperation/CopyFile/duplicate.js
 *
 */
import { doCopy } from '@/service/cooper/moveFile';
import showProgress from '@/components/CooperOperation/MoveFile/move-modal/m-progress/showProgress';
import { COPY } from '@/constants/cooper';
import { message } from 'antd';
import { intl } from 'di18n-react';

async function duplicate(ids, parentId, toTeamId, doneCallback) {
  doCopy(ids, parentId, toTeamId).then((res) => {
    let copyId = res.fileProgressId
    showProgress(COPY, copyId, 1, window.location.href, doneCallback);
  }).catch((err) => {
    if (err.errorCode === 1082) {
      message.error(intl.t('您没有权限! 请联系空间管理员添加目标位置的编辑/上传权限。'));
    } else {
      message.error(err.message);
    }
  });
}

export default duplicate;
