
import { intl } from 'di18n-react';
import React from 'react';

import { getCopyProgress } from '@/service/cooper/moveFile';
import { COPY, SAVE } from '@/constants/cooper';
import './index.less';
import { loadingPng, NotificationSuccessIcon } from '@/assets/icon/index';

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
} // 复制状态

class MProgress extends React.Component {
  static defaultProps = {
    type: COPY,
    notiKey: '',
    copyId: 0,
    copyNum: 1,
    link: '/',
    doneCallback: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      copyDone: false,
    };
  }

  componentDidMount() {
    if (this.props.type === COPY) {
      // 复制 是异步的
      this.updateProgress();
    } else {
      // 移动、保存 是同步的，调用即成功
      this.setState({
        copyDone: true,
      });
      this.props.doneCallback();
      setTimeout(this.props.onClose, 5000);
    }
  }

  updateProgress = async () => {
    const { doneCallback, copyId } = this.props;

    while (true) { // eslint-disable-line
      await sleep(500);
      const { totalSize, copySize } = await getCopyProgress(copyId);

      if (totalSize === copySize) {
        break;
      }
    }

    this.setState({
      copyDone: true,
    });
    doneCallback(); // 2秒后关闭

    await sleep(3000);
    this.props.onClose();
  };

  render() {
    const { type, copyNum, link } = this.props;
    const { copyDone } = this.state;
    let s1 = intl.t('正在移动:copyNum个项目');
    let s2 = intl.t('移动成功');

    if (type === COPY) {
      [s1, s2] = [intl.t('正在复制:copyNum个项目'), intl.t('复制成功')];
    }

    if (type === SAVE) {
      [s1, s2] = [intl.t('正在保存:copyNum个项目'), intl.t('保存成功')];
    }

    return (
      <div className='m-progress'>
        {copyDone ? (
          <span>
            <img
              src={NotificationSuccessIcon}
              alt='' />
            {s2} <a href={link}>{intl.t('查看')}</a>
          </span>
        ) : (
          <span>
            <i className="dk-iconfont dk-icon-shuaxin loading" />
            {s1.replace(':copyNum', copyNum)}
          </span>
        )}
      </div>
    );
  }
}

export default MProgress;
