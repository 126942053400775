import { intl } from 'di18n-react';

const getDefaultState = () => {
  return {
    visible: false, // modal状态
    modalTitle: '', // modal标题
    knowledgeId: 0, // 当前知识库id
    pageId: 0, // 当前页面id
    knowledgeName: intl.t('当前知识库'), // 当前知识库nanme
    checkable: true, // 迁入知识库 false为迁出知识库
    modalText: '',
    fullPath: '',
  }
};

export default {
  state: getDefaultState(),
  reducers: {
    openTransferModal(state, params) {
      return {
        ...state,
        ...params,
      };
    },
    closeTransferModal(state) {
      return {
        ...state,
        visible: false,
      };
    },
  },
};
