import USER_VIEW from '@/constants/userView';
import { post, get } from '@/utils/request/cooper';
import CooperApi from '@/utils/request/api/CooperApi';

// 递归函数，用于遍历并更新对象
function mergeObjects(target, source) {
  // 检查参数是否为 null 或 undefined
  if (target == null) return source;
  if (source == null) return target;

  const result = { ...target };
  for (const key of Object.keys(source)) {
    if (typeof source[key] === 'object' && !Array.isArray(source[key])) {
      // eslint-disable-next-line no-prototype-builtins
      if (!result.hasOwnProperty(key)) {
        result[key] = {};
      }
      result[key] = mergeObjects(result[key], source[key]);
    } else {
      // 只有当目标对象中不存在该键时才使用源对象的值
      // eslint-disable-next-line no-lonely-if, no-prototype-builtins
      if (!result.hasOwnProperty(key)) {
        result[key] = source[key];
      }
    }
  }
  return result;
}


/**
 * 迭代新加的key，需要初始化（localstorage已有userview的情况下）
 */
export const getUserView = async (key) => {
  try {
    const res = await get(CooperApi.OPERATE_USER_SETTINGS, {
      params: {
        key: key || 'userViewString',
      },
    });

    let cookieValue = localStorage.getItem('useLocal');
    if (cookieValue) {
      return USER_VIEW;
    }

    const parseRes = res.length > 0 ? JSON.parse(res) : {}; // 兼容接口返回是''的情况
    const apiResValue = mergeObjects(parseRes, USER_VIEW);

    // let existData = JSON.parse(localStorage.getItem('dk-always:USER_VIEW')) || {}
    // const { GUIDE_DIALOG = true } = existData.value ?? {};
    const defaultValue = Object.assign(USER_VIEW, {});
    // defaultValue.GUIDE_DIALOG = GUIDE_DIALOG;


    return res.length > 0 ? apiResValue : defaultValue;
  } catch (error) {
    return USER_VIEW;
  }
}

export async function setUserView(obj, key) {
  return post(CooperApi.OPERATE_USER_SETTINGS, {
    key: key || 'userViewString',
    value: JSON.stringify(obj),
  });
}
