import { intl } from 'di18n-react';
import { PAGE_MEMBER_NAME } from '@/constants/setup';
import { useEffect, useState, useRef, useMemo } from 'react';
import { Button, message } from 'antd';
import { countDown } from '@/utils';
import classBind from 'classnames/bind';
import styles from './style.module.less';

const cx = classBind.bind(styles);

const ApplyDetail = ({
  detail = {},
  id,
  urge,
  updateDetail,
  applyDetailClassName = '',
}) => {
  let { title, name, applyTime, role, approver, id: applyId, pageId } = detail;
  const showUrgeBtn = useMemo(() => {
    if (!applyTime) return true;
    // 86400000
    const res = new Date().getTime() - applyTime > 86400000;
    !res && countDown(+applyTime, '#applyCountDown', 86400000);
    return res;
  }, [detail]);

  const handleUrge = async () => {
    pageId
      ? window.__OmegaEvent('ep_dkpc_applypage_urge_ck')
      : window.__OmegaEvent('ep_dkpc_applyknowledge_urge_ck');
    await urge(id, { applyId });
    message.success(intl.t('待审批的提醒信息已推送给审批人'));
    updateDetail();
  };
  const handleHelp = () => {
    pageId
      ? window.__OmegaEvent('ep_dkpc_applypage_dc_ck')
      : window.__OmegaEvent('ep_dkpc_applyknowledge_dc_ck');
  };

  return (
    <div className={cx('apply-detail', applyDetailClassName)}>
      <div className={cx('apply-success')}>
        <span className={cx('apply-success-icon')} />
        <span>{intl.t('已发送申请')}</span>
      </div>
      <p className={cx('apply-tips')}>
        {intl.t('审批结果会以D-Chat消息推送给你')}
      </p>
      <div className={cx('apply-box')}>
        <p className={cx('apply-name')}>
          <span>
            {title}
            {intl.t('名称：')}
          </span>
          <span className={cx('apply-value')}>{name}</span>
        </p>
        <p>
          <span>
            {title}
            {intl.t('权限：')}
          </span>
          <span className={cx('apply-value')}>{PAGE_MEMBER_NAME()[role]}</span>
        </p>
        <p className={cx('apply-Approver')}>
          <span>{intl.t('当前审批人：')}</span>
          <a
            href={`dchat://im/start_conversation?name=${approver.ldap}`}
            onClick={handleHelp}
            className={cx('apply-value')}
          >
            {approver.cnName}
          </a>
        </p>
        <span className={cx('apply-line')} />
        <div className={cx('apply-text')}>
          {!showUrgeBtn && <span>{intl.t('如果')}</span>}
          <span id="applyCountDown" />
          {!showUrgeBtn && (
            <span>
              {intl.t('后审批人未审批，你可以重新进入此页面催促审批')}
            </span>
          )}
          {showUrgeBtn && (
            <span>{intl.t('审批人超过24小时未审批，你可以点击下方')}</span>
          )}
        </div>
        {showUrgeBtn && (
          <Button
            className={cx('button-urge')}
            onClick={handleUrge}
            type="primary"
          >
            {intl.t('催促')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ApplyDetail;
