const getDefaultState = () => ({
  trashFileList: [],
  trashTeamList: [],
});

export default {
  name: 'CooperTrash',
  state: getDefaultState(),
  reducers: {
    setTrashFileList(state, trashFileList) {
      return {
        ...state,
        trashFileList,
      };
    },
    setTrashTeamList(state, trashTeamList) {
      return {
        ...state,
        trashTeamList,
      };
    },
  },
  effects: {},
};
