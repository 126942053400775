import { NotificationErrorIcon, NotificationSuccessIcon, NotificationWarnIcon, downLoding } from '@/assets/icon';
import NotificationStatus from '@/constants/notification';
import { message } from 'antd';
import classBind from 'classnames/bind';
import styles from './style.module.less';

const cx = classBind.bind(styles);

export default function useNotification() {
  return (status, msg, onClose, duration = 2, key, noIcon, top = 56) => {
    let iconSrc = NotificationSuccessIcon;
    let colorClass = '';
    let iconClass = '';
    let closeIcon = (<i className={cx('dk-iconfont', 'dk-icon-guanbi', 'close-icon')} />);
    if (status === NotificationStatus.ERROR) {
      iconSrc = NotificationErrorIcon;
      colorClass = 'error';
    } else if (status === NotificationStatus.WARN) {
      iconSrc = NotificationWarnIcon;
      colorClass = 'warn';
    } else if (status === NotificationStatus.LOADING) {
      iconSrc = downLoding;
      colorClass = 'loading-msg';
      iconClass = 'anticon-loading anticon-spin'
    }
    message.open({
      content: msg,
      top,
      className: `${cx('notification')} ${cx(colorClass)} knowLedge_notification`,
      icon: <div>{!noIcon && <img
        className={cx('icon', iconClass)}
        src={iconSrc}/>}</div>,
      duration,
      onClose,
      key,
    });
  };
}

window.useNotification = useNotification;
