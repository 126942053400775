/**
 * 普通文件分享
 */
import { intl } from 'di18n-react';
import React from 'react';
import { Modal } from 'antd';
import DirectShare from './DirectShare';
import LinkShare from './LinkShare';
import './index.less';
import { CooperTabs, CooperTabsPane } from '@/components/common/CooperTabs';
import { EpClickShareBatchAddFns } from '@/constants/cooperConstants';

// export const EpClickShareBatchAddFns = {
//   clickBatchAdd: () => window.__OmegaEvent('ep_filesshare_sharespecific_batchadd_ck'),
//   notify: () => window.__OmegaEvent('ep_filesshare_sharespecific_batchadd_notify_ck'),
//   return: () => window.__OmegaEvent('ep_filesshare_sharespecific_batchadd_return_ck'),
//   modifyPermission: () => window.__OmegaEvent('ep_filesshare_sharespecific_batchadd_modifypermission_ck'),
//   modify: () => window.__OmegaEvent('ep_filesshare_sharespecific_batchadd_modify_ck'),
//   confirm: () => window.__OmegaEvent('ep_filesshare_sharespecific_batchadd_confirm_ck'),
//   copy: () => window.__OmegaEvent('ep_filesshare_sharespecific_batchadd_copy_ck'),
// };

class CooperShareModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '0',
      showBatchAdd: false,
    };
  }

  switchTab = (activeTab) => {
    this.setState({
      activeTab,
    });
  };

  setShowBatchAdd = (value) => {
    this.setState({
      showBatchAdd: value,
    });
  }

  backToShareModal = () => {
    this.setShowBatchAdd(false);
    EpClickShareBatchAddFns.return();
  }

  render() {
    const { fileName, fileId, teamName = '', onClose, relationTypeTags } = this.props;
    const { activeTab, showBatchAdd } = this.state;
    return (
      <Modal
        visible={true}
        maskClosable={false}
        wrapClassName={`${showBatchAdd ? 'show-batch-add' : ''} cooper-share-modal`}
        maskStyle={{
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
        }}
        width={640}
        footer={null}
        title={showBatchAdd
          ? <div className='batch-add-title'>
            <i
              onClick={this.backToShareModal}
              className='back'
            />
            {intl.t('批量分享')}
          </div>
          : <div>
            <span>{intl.t('分享')}</span>
            {fileName}
          </div>
        }
        onCancel={onClose}
      >
        {
          !showBatchAdd ? (
            <div className='addMember-tabs'>
              <CooperTabs
                defaultActiveKey={activeTab}
                activeKey={activeTab}
                destroyInactiveTabPane={true}
                onChange={this.switchTab}
                tabsize='small'
              >
                <CooperTabsPane
                  tab={intl.t('定向分享')}
                  key="0"
                >
                  <DirectShare
                    fileId={fileId}
                    teamName={teamName}
                    onClose={onClose}
                    showBatchAdd={showBatchAdd}
                    setShowBatchAdd={this.setShowBatchAdd}
                  />
                </CooperTabsPane>
                <CooperTabsPane
                  tab={intl.t('全员分享')}
                  key="1"
                >
                  <LinkShare
                    relationTypeTags={relationTypeTags}
                    fileId={fileId}
                    onClose={onClose}
                    fileName={fileName}
                  />
                </CooperTabsPane>
              </CooperTabs>
            </div>
          ) : (
            <DirectShare
              fileId={fileId}
              teamName={teamName}
              onClose={onClose}
              showBatchAdd={showBatchAdd}
              setShowBatchAdd={this.setShowBatchAdd}
            />
          )
        }
      </Modal>
    );
  }
}

export default CooperShareModal;
