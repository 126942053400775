import openDk from '@/components/CooperOperation/OpenDk';
import openDkPage from '@/components/CooperOperation/OpenDkPage';
import openFolder from '@/components/CooperOperation/OpenFolder';
import PreviewFile from '@/components/CooperOperation/PreviewFile';
import { FileType } from '@/constants/cooper';
import { getFlowChartUrl } from '@/service/cooper/index';
import { openNewWindow } from '@/utils/index';


export default async function handleFileClick({
  fileTypeStr,
  resourceId,
  resourceName,
  knowledgeId,
  dkShareType,
  pageId,
  shareId,
  shareLink,
  markedQuickVisit,
  teamId, // 打开文件夹时
  quickId,
  parentId,
  spaceId,
  filesList,
  fidx,
  relationTypeTags,
  doneCallback,
}) {
  switch (fileTypeStr) {
    // 普通文件
    case FileType.FILE:
      // eslint-disable-next-line no-case-declarations
      const shareType = dkShareType;

      PreviewFile({
        resourceId,
        resourceName,
        shareId,
        shareLink,
        shareType,
        markedQuickVisit,
        teamId,
        quickId,
        objectType: fileTypeStr,
        parentId,
        spaceId,
        filesList,
        fidx,
        relationTypeTags,
        doneCallback,
      });
      break;

    // 文件夹
    case FileType.DIR:
      openFolder({
        resourceId, teamId, shareId,
      });
      break;

    // 协作文档
    case FileType.COOPER_DOC:
      openNewWindow(`/docs/document/${resourceId}`);
      break;
    case FileType.SHIMO2_WORD:
      openNewWindow(`/docs2/document/${resourceId}`);
      break;
    case FileType.COOPER_SHEET:
      openNewWindow(`/docs/sheet/${resourceId}`);
      break;
    case FileType.SHIMO2_EXCEL:
      openNewWindow(`/docs2/sheet/${resourceId}`);
      break;
    case FileType.COOPER_SLICE:
      openNewWindow(`/docs/slide/${resourceId}`);
      break;
    case FileType.SHIMO2_PPT:
      openNewWindow(`/docs2/slide/${resourceId}`);
      break;
    case FileType.COOPER_ANONYMOUS_DOC:
      openNewWindow(`/docs/document/${resourceId}`);
      break;

    // 氢文档之后要下线啦
    case FileType.DI_DOC:
      openNewWindow(`/didoc/file/${resourceId}`);
      break;
    
    // process on 流程图
    case FileType.DIAGRAMING:
      openNewWindow(`/docs/diagraming/${resourceId}`);
      break;
    
    // process on 脑图
    case FileType.MINDMAP:
      openNewWindow(`/docs/mindmap/${resourceId}`);
      break;

    // 流程图
    case FileType.FLOWCHART:
      const url = await getFlowChartUrl(resourceId)
      openNewWindow(url);
      break;

    // 知识库页面
    case FileType.DK_PAGE:
      openDkPage({
        dkShareType,
        pageId,
        knowledgeId,
        shareId,
        shareLink,
      });
      break;
    case FileType.DK_FILE:
      openDkPage({
        dkShareType,
        pageId,
        knowledgeId,
        shareId,
        shareLink,
      });
      break;
    case FileType.KNOWLEDGE:
      openDk({
        knowledgeId,
      });
      break;

    case FileType.WIKI_PAGE:
      let wikiUrl = '';
      if (process.env.APP_ENV === 'prod') {
        wikiUrl = `http://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=${resourceId}`
      } else {
        wikiUrl = `http://wiki-qa.intra.xiaojukeji.com/pages/viewpage.action?pageId=${resourceId}`
      }
      openNewWindow(wikiUrl)

      break;


    default:
      break;
  }
}
