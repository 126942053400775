import { intl } from 'di18n-react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import classBind from 'classnames/bind';
import KnowledgeSearch from '@/components/serviceComponents/KnowledgeSearch';
import styles from '@/components/serviceComponents/Header/style.module.less';

const cx = classBind.bind(styles);
const ChangeKnowledge = ({ isShowCurrent }) => {
  const [switchVisible, setSwitchVisible] = useState(false);
  const { knowledgeDetail } = useSelector((state) => state.KnowledgeData);

  const handleSwitchKD = (v, isRecommend) => {
    if (isRecommend) {
      window.__OmegaEvent('ep_dkpc_changedk_recommend_ck');
    } else {
      window.__OmegaEvent('ep_dkpc_changedk_search_ck');
    }
    window.location.href = `/knowledge/${v}/home`;
  };

  return (
    <>
      <div className={cx('divider')} />
      <Tooltip
        overlayClassName={cx('switch-search-popover')}
        placement="bottomLeft"
        trigger="click"
        visible={switchVisible}
        destroyTooltipOnHide={true}
        onVisibleChange={(v) => {
          !v && setSwitchVisible(false);
        }}
        title={
          <KnowledgeSearch
            label={knowledgeDetail?.spaceName}
            value={knowledgeDetail?.metisSpaceId}
            relationTypeTags={knowledgeDetail?.relationTypeTags}
            onChange={(v, isRecommend) => handleSwitchKD(v.value, isRecommend)}
          />
        }
      >
        <div className={cx('knowledge-name')}>
          <span
            onClick={() => {
              setSwitchVisible(!switchVisible);
              window.__OmegaEvent('ep_dkpc_changedk_ck');
            }}
          >
            {isShowCurrent ? knowledgeDetail.spaceName : intl.t('切换知识库')}
          </span>
          <i
            className={`${cx('dk-iconfont', 'dk-icon-xiayitiaopinglun')} ${cx(
              'moreDk',
            )} ${switchVisible ? cx('isOpen') : ''}`}
            onClick={() => {
              setSwitchVisible(!switchVisible);
              window.__OmegaEvent('ep_dkpc_changedk_ck');
            }}
          />
        </div>
      </Tooltip>
    </>
  );
};

export default ChangeKnowledge;
