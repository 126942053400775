// 操作对应的key， value值 https://cooper.didichuxing.com/docs/sheet/2199239678253#dWsgO
export default {
  // 知识库相关
  READ_DK: {
    key: 'READ_DK',
    value: 1073741824,
  },
  // 管理知识库高级设置
  MANAGE_DK_ADVANCED_SETTING: {
    key: 'MANAGE_DK_ADVANCED_SETTING',
    value: 1048576,
  },
  DELETE_DK_RECYCLER_PAGE: {
    key: 'DELETE_DK_RECYCLER_PAGE',
    value: 8388608,
  },
  CONTROL_DK_SHARE: { // 知识库所有者&管理员
    KEY: 'MANAGE_DK_SETTING',
    value: 536870912,
  },
  // 添加、移除知识库成员，变更角色
  MANAGE_DK_MEMBER: {
    key: 'MANAGE_DK_MEMBER',
    value: 268435456,
  },
  MANAGE_DK_ADMIN: {
    key: 'MANAGE_DK_ADMIN',
    value: 134217728,
  },
  MANAGE_DK_SETTING: {
    key: 'MANAGE_DK_SETTING',
    value: 536870912,
  },
  MANAGE_DEPAERMENT: {
    key: 'MANAGE_DK_ADVANCED_SETTING',
    value: 1048576,
  },
  MANAGE_DK_PAGE: {
    key: 'MANAGE_DK_PAGE',
    value: 16777216,
  },

  // 页面相关
  READ_PAGE: {
    key: 'READ_PAGE',
    value: 1,
  },
  MANAGE_PAGE_CONTEXT: {
    key: 'MANAGE_PAGE_CONTEXT',
    value: 2,
  },
  MANAGE_PAGE_MEMBER: {
    key: 'MANAGE_PAGE_MEMBER',
    value: 8,
  },
  MANAGE_PAGE_CONTEXT_LIMIT: {
    key: 'MANAGE_PAGE_CONTEXT_LIMIT',
    value: 32,
  },
  MANAGE_PAGE_SETTING_LIMIT: {
    key: 'MANAGE_PAGE_SETTING_LIMIT',
    value: 64,
  },
};

