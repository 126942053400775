import { objMixin } from '@/utils/upload';

export default {
  state: {
    isShowTips: false,
    uploadFiles: [],
  },
  reducers: {
    addUploadFile(state, file) {
      const uploadFiles = Array.from(state.uploadFiles);
      uploadFiles.push(file);
      return { ...state, uploadFiles };
    },
    setShowTips(state, flag) {
      return { ...state, isShowTips: flag };
    },
    updateFile(state, id, partial) {
      const { taskId } = partial;
      const uploadFiles = Array.from(state.uploadFiles);
      const index = uploadFiles.findIndex((task) => task.id === id);
      if (index === -1) {
        return;
      }
      const updatedTask = objMixin(uploadFiles[index], { partial, taskId });

      uploadFiles.splice(index, 1, updatedTask);

      return { ...state, uploadFiles };
    },
    updateFileProgress(state, taskId, partial) {
      const uploadFiles = Array.from(state.uploadFiles);

      const index = uploadFiles.findIndex((task) => task.taskId === taskId);
      if (index === -1) {
        return;
      }

      const updatedTask = objMixin(uploadFiles[index], { ...partial });

      uploadFiles.splice(index, 1, updatedTask);
      return { ...state, uploadFiles };
    },
    deleteFile(state, id) {
      const uploadFiles = Array.from(state.uploadFiles);
      const index = uploadFiles.findIndex((task) => task.id === id);
      if (index === -1) {
        return;
      }
      uploadFiles.splice(index, 1);

      return { ...state, uploadFiles };
    },
    deleteAllFiles(state) {
      return { ...state, uploadFiles: [] };
    },
  },
  effects: () => ({
  }),
};
