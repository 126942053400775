export const RECENT_TABS = {
  VISIT: '0',
  EDIT: '1',
};

export const RECENT_VISIT_TYPE = {
  NORMAL: 1,
  PAGE_SHARE: 2,
  DK_SHARE: 3,
};

export const OWNER_TYPE = {
  ALL: 0,
  MINE: 1,
  SPECIFIC_USER: 2,
};
