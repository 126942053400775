import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import AdvanceSearch from '../../../AdvanceSearchDk';
import GlobalSearch from './GlobalSearch';

const SearchContent = () => {
  const { knowledgeDetail } = useSelector((state) => state.KnowledgeData);
  const { knowledgeId } = useContext(LayoutContext);
  const [searchKey, setSearchKey] = useState();
  const [searchModalVisible, setSearchModalVisible] = useState(false);
  const [isClear, setClear] = useState(false);


  const openSearchModal = (key) => {
    setSearchKey(key);
    setSearchModalVisible(true);
  };


  return <>
    <GlobalSearch
      knowledge={{ id: knowledgeId, name: knowledgeDetail?.spaceName }}
      openSearchModal={openSearchModal}
      setClear={setClear}
    />
    <AdvanceSearch
      visible={searchModalVisible}
      search={{ key: searchKey, id: knowledgeId, name: knowledgeDetail?.spaceName }}
      closeSearchModal={() => setSearchModalVisible(false)}
      clear={isClear}
      pathId={knowledgeId}
    />
  </>;
};

export default SearchContent;
