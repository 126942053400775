import { intl } from 'di18n-react';
/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-11-08 15:17:24
 * @LastEditTime: 2023-11-08 17:22:06
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/serviceComponents/Header/CreateKnowledge/index.js
 *
 */
import { useNavigate } from 'react-router-dom';
import classBind from 'classnames/bind';
import styles from '@/components/serviceComponents/Header/style.module.less';

const cx = classBind.bind(styles);

const CreateKnowledge = () => {
  const navigate = useNavigate();

  const createDk = () => {
    window.__OmegaEvent('ep_dkpc_dkhome_createdk_ck');
    navigate('/knowledge/create');
  };

  return (
    <button
      id="dk-create-btn" // 用于引导气泡位置
      className={cx('createDk')}
      onClick={createDk}
    >
      <i
        className={cx('dk-iconfont', 'dk-icon-ic_chuangjian', 'create-icon')}
      />
      <span className={cx('create-text')}>{intl.t('创建知识库')}</span>
    </button>
  );
};

export default CreateKnowledge;
