import { intl } from 'di18n-react';
import { useEffect, useMemo, useState, useRef } from 'react';
import { Tree, Spin } from 'antd';
import useTreeData from '@/components/serviceComponents/CatalogTree/UseTreeData';
import classBind from 'classnames/bind';
import styles from './style.module.less';

const cx = classBind.bind(styles);

// 切换知识库
// 搜索 -> 不切换知识库
// 搜索 -> 切换知识库

// 这里的树结构做了一些特殊处理，把知识库节点（root）当作和一级目录平级放在了第一个元素，主要是为了matchUI。。。。
const findCheakedParent = (root, arr) => {
  let result = [];
  let total = 0;
  const fn = (node) => {
    if (arr.includes(node.key)) {
      total += node.size;
      result.push({
        pageId: node.key,
        hasChild: !node.isLeaf,
        fullPath: node.fullPath,
      });
    } else {
      node.children.forEach((v) => fn(v));
    }
  };
  // 如果选中了知识库，返回所有的一级目录的id集合
  if (arr.includes('0')) {
    result = root.filter((v) => {
      if (v.key !== '0') {
        total += v.size;
        return { pageId: v.key, hasChild: !v.isLeaf };
      }
      return false;
    });
  } else {
    root.forEach((v) => fn(v));
  }
  return { list: result, total };
};

const PageTreeCheckable = (props) => {
  const {
    currentPage,
    onChecListUpdate = () => {},
    getData,
    formatNode,
    switcherIcon,
  } = props;
  const { pageId, path = [], knowledgeId, knowledgeName } = currentPage;
  const [size, setSize] = useState(0);
  const [focusPage, setFocusPage] = useState();
  const [renderTree, setRenderTree] = useState(true);
  const [checkedKeys, setCheckedKeys] = useState(pageId ? [pageId] : []);
  const {
    treeData,
    flattenData,
    setData,
    updateNode,
    getChildKeysById,
    getParentsKeysById,
    getNodeByKey,
  } = useTreeData(formatNode);
  const [expandedKeys, setExpandKeys] = useState(new Set('0'));
  const [loading, setLoading] = useState(true);
  const treeRef = useRef();
  const loadPage = useRef();
  const handleExpand = (key) => {
    loadPage.current = '9999';
    setExpandKeys(key);
  };

  const onChecked = ({ checkList, key, checked }) => {
    // 选中
    if (!checkList.length) {
      setCheckedKeys([]);
      return;
    }
    let newChecked = [];
    const childKeys = getChildKeysById(key);
    if (checked) {
      // 选中状态，把子项都选中
      if (key === '0') {
        newChecked = Object.keys(flattenData);
      } else {
        newChecked = [...new Set([...checkList, ...childKeys])];
      }
    } else if (key !== '0') {
      // 反选状态，1. 把子项都干掉 2. 把父级节点的选中状态都干掉
      const parentKeys = getParentsKeysById(key);
      newChecked = checkList.filter((v) => {
        return !childKeys.includes(v) && !parentKeys.includes(v);
      });
    }
    setCheckedKeys(newChecked);
  };

  const onSelect = (data, event) => {
    loadPage.current = '9999';
    if (event.node.isLeaf) {
      return;
    }
    const key = data[0];
    if (!expandedKeys.has(key)) {
      expandedKeys.add(key);
      setExpandKeys(new Set(expandedKeys));
    } else {
      expandedKeys.delete(key);
      setExpandKeys(new Set(expandedKeys));
    }
  };

  const onLoadData = async (node) => {
    let { key, children } = node;
    if (children.length) {
      return;
    }
    let res = await getData(key);
    updateNode(key, { children: res });
    // 父级选中的情况下，要把子级选中
    if (checkedKeys.includes(key)) {
      const childKeys = res.map((v) => v.pageId);
      setCheckedKeys([...checkedKeys, ...childKeys]);
    }
  };
  // 需要loading的时机 切换知识库的时候；搜索的时候
  useEffect(() => {
    (async () => {
      setLoading(true);
      setRenderTree(false);
      setExpandKeys(new Set());
      const res = await getData(pageId, 0);
      setData([
        {
          pageId: '0',
          pageName: knowledgeName,
          children: [],
          hasChild: false,
          parentId: '',
          size: 0,
        },
        ...res,
      ]);
      setRenderTree(true);
    })();
  }, [knowledgeId]);

  useEffect(() => {
    // 被加载的目录 === pageId的时候
    if (pageId === loadPage.current) {
      if (!pageId) {
        onChecked({
          checkList: [],
          key: '0',
          checked: false,
        });
        setLoading(false);
      } else if (getNodeByKey(pageId)) {
        onChecked({
          checkList: [...checkedKeys, pageId],
          key: pageId,
          checked: true,
        });
        setTimeout(() => {
          setLoading(false);
          const dom = document.querySelector(`#transfer-node-${pageId}`);
          if (dom) {
            // 没办法用antd的滚动方法只能粗暴的操作dom了
            const parentNode = dom?.parentNode?.parentNode?.parentNode;
            const rootDom = document.querySelector('.transefer-modal-tree');
            rootDom.scrollTo({
              top: parentNode.offsetTop,
              left: 0,
              behavior: 'smooth',
            });
          }
        }, 200);
      }
    }
  }, [treeData, focusPage]);

  useEffect(() => {
    loadPage.current = pageId;
    if (!pageId) return;
    setLoading(true);
    // 这里直接加入 展开节点即可： 展开节点 当children 为空且是目录时 会触发onloadData, 如果里面有值，则不会发起请求
    path.forEach((v) => {
      expandedKeys.add(v);
    });
    setExpandKeys(new Set(expandedKeys));
    // 当选中的节点不需要请求的时候强制触发滚动到该节点
    setFocusPage(Math.random());
  }, [currentPage]);

  useEffect(() => {
    let result = { list: [], total: 0 };
    if (checkedKeys.length) {
      result = findCheakedParent(treeData, checkedKeys);
    }
    onChecListUpdate(result.list);
    setSize(result.total);
  }, [checkedKeys]);
  return (
    <Spin spinning={loading}>
      <p className={cx('multi-checked-total')}>
        {intl.t('已选：')}
        {size}
        {intl.t('个页面')}
      </p>
      <>
        {' '}
        {renderTree && (
          <Tree
            ref={treeRef}
            rootClassName={`${cx('tree-content')} transefer-modal-tree`}
            checkable
            checkStrictly
            switcherIcon={switcherIcon}
            checkedKeys={checkedKeys}
            onCheck={(checkStatus, event) => {
              onChecked({
                checkList: checkStatus.checked,
                key: event.node.key,
                checked: event.checked,
              });
            }}
            selectedKeys={[]}
            treeData={treeData}
            onSelect={onSelect}
            expandedKeys={[...expandedKeys]}
            onExpand={(keys) => handleExpand(new Set(keys))}
            loadData={onLoadData}
            titleRender={(data) => {
              return (
                <div
                  className={cx('transfer-node-title')}
                  id={`transfer-node-${data.key}`}
                >
                  {data.key === '0' && (
                    <i className={cx('dk-iconfont', 'dk-icon-dafenqi')} />
                  )}
                  {data.title}
                </div>
              );
            }}
          />
        )}
      </>
    </Spin>
  );
};

export default PageTreeCheckable;
