import { Modal } from 'antd';
import React, { useState } from 'react';
import mountAnywhere from '@/utils/mountAnywhere';
import './index.less';

function Login({ url }) {
  const [visible, setVisible] = useState(true);
  return (
    <div id="login-modal">
      <Modal
        closable={false}
        maskClosable={false}
        zIndex={99999}
        wrapClassName={'login-setting'}
        className={'login-wrap'}
        visible={visible}
        footer={null}
        width={'440px'}
        onCancel={() => {
          setVisible(false);
        }}
        getContainer={() => document.getElementById('login-modal')}
      >
        <iframe
          className="login-iframe"
          allowFullScreen
          src={url} />
      </Modal>
    </div>
  );
}

function loginModal(url) {
  const Comp = <Login url={url} />;
  mountAnywhere(Comp);
}

export default loginModal;
