// 解析是否有权限X (int)，判断逻辑  X & Y == X
import OPERATION_PERMISSION from '@/constants/permission';


export default function usePermission() {
  /**
   * @param operation {string} 操作对应的字符串， 在constants/permission.js添加
   * 返回true or false 是否有操作对应的权限
   */
  const checkOperationPermission = (operation, perm) => {
    const { value } = OPERATION_PERMISSION[operation];

    return (value & perm) === value; /* eslint no-bitwise: ["error", { "allow": ["&"] }] */
  };

  return {
    checkOperationPermission,
  };
}
