import { intl } from 'di18n-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import classBind from 'classnames/bind';
import { isDesktopDC, computeTimeInterval } from '@/utils';
import { MODE_PREFIX } from '@/constants';
import FileContent from '@/baseComponents/FileContent';
import styles from './style.module.less';

const cx = classBind.bind(styles);

const jumpTo = ({ id, metisId, cb, origin, navigate, api, isShare }) => {
  // 判断当前所处页面是否存在知识库id和页面id，兼容有share的场景
  const path = window.location.pathname.replace('/knowledge', '').replace('/share/book', '')
  const knowledgeId = path.split('/')[1] // 获取知识库id
  const prefix = MODE_PREFIX[api.mode] || ''
  if (!isDesktopDC && id && (knowledgeId === `${metisId}` || isShare)) {
    if (isShare) {
      navigate(`/knowledge/share/book/${knowledgeId}/${id}`);
    } else {
      navigate(`/knowledge/${metisId}/${id}`);
    }
  } else {
    id ? window.open(`${prefix}/knowledge/${metisId}/${id}`, '_blank') : window.open(`${prefix}/knowledge/${id}/home`, '_blank');
  }
  cb && cb()
  origin && window.__OmegaEvent(origin);
};
const SearchItem = ({ data, callback, config = {}, api = {}, isShare, wrapClass = '' }) => {
  const { description = true, origin } = config
  const publishDesc = `${(!isShare && data.lastPublishUser) || ''} ${(data.lastPublishTime && `${computeTimeInterval(data.lastPublishTime || 0)}${intl.t('更新过去时')}`) || ''}`
  const navigate = useNavigate()
  return <li
    className={`${cx('search-item')} ${wrapClass}`}
    onClick={() => jumpTo({ id: data.resourceId, metisId: data.metisId, cb: callback, origin, navigate, api, isShare })}
  >
    <FileContent
      iconImage={data.iconImage}
      resourceName={data.resourceName}
      description={description ? data.description : null}
      metisName={!isShare ? data.metisName : null}
      resourceOwner={!isShare ? data.resourceOwner : null}
      publishDesc={publishDesc}
      metisId={data.metisId}
      tags={data.shareId ? [intl.t('访客链接')] : null}
    />
  </li>
};

export default SearchItem;
