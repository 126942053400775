import { intl } from 'di18n-react';
import { useSelector, useDispatch } from 'react-redux';
import classBind from 'classnames/bind';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import TagDirectoryTree from '@/components/serviceComponents/TagDirectoryTree';
import SwitchIcon from '../SwitchIcon';
import style from './style.module.less';
import stylesCommon from '../../catalogCommon.module.less';

const cx = classBind.bind(style);
const cm = classBind.bind(stylesCommon);

function ViewTag() {
  const dispatch = useDispatch();
  const { isOpenCatalog } = useSelector((state) => state.TagTree);
  const { setState } = dispatch.TagTree;

  const onClickMenuHeader = (e) => {
    e.stopPropagation();
    setState({
      isOpenCatalog: !isOpenCatalog,
    });
  };

  return (
    <div className={cx('view-tag-tree')}>
      <li className={`${cx('catalog-title-li')} ${cm('aside-menu-li-common')}`}>
        <div
          onClick={onClickMenuHeader}
          className={`${cx('catalog-li-item')} ${cm({
            'aside-li-item': true,
          })}`}
        >
          <i
            className={cm({
              'dk-iconfont': true,
              'dk-icon-biaoqian2': true,
              'menu-root-icon': true,
            })}
          />
          <div className={cm('aside-li-text')}>{intl.t('标签')}</div>

          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={cx('operate-wrap')}
          >
            <SwitchIcon />
          </div>
        </div>
      </li>

      <TagDirectoryTree isOpenCatalog={isOpenCatalog} />
    </div>
  );
}

export default ViewTag;
