/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-09-11 15:36:31
 * @LastEditTime: 2023-11-08 15:36:38
 * @Description: 收藏文件
 * @FilePath: /knowledgeforge/src/service/knowledge/star.js
 *
 */
import { get, post } from '@/utils/request';
import api from '@/utils/request/api/DkApi';

export async function getStarList(params) {
  const res = await get(api.STAR_ACTION, {
    params: {
      ...params,
    },
  });
  return res;
}

// starInfoList 这是一个数组 支持批量操作
export async function starAction(starType, starInfoList) {
  const res = await post(api.STAR_ACTION, {
    star: starType,
    list: starInfoList,
  })
  return res
}
