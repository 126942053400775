import { intl } from 'di18n-react';
/* eslint-disable import/prefer-default-export */

const knowledgePagePattern = /^\/knowledge\/\d+\/\d+$/;
const knowledgePageEditPattern = /^\/knowledge\/\d+\/\d+\/edit$/;
const knowledgeHomePattern = /^\/knowledge\/\d+\/home$/;

export const getName = (pathname) => {
  let name = pathname;

  if (pathname === '/knowledge' || pathname === '/knowledge/') {
    name = '/knowledge'
  } else if (knowledgePagePattern.test(pathname)) {
    name = '/knowledge/page'
  } else if (knowledgePageEditPattern.test(pathname)) {
    name = '/knowledge/page/edit';
  } else if (pathname.includes('/knowledge/share/page')) {
    name = '/knowledge/share/page';
  } else if (pathname.includes('/knowledge/share/book')) {
    name = '/knowledge/share/book';
  } else if (knowledgeHomePattern.test(pathname)) {
    name = '/knowledge/home';
  }

  return name;
}

export const reportMeasure = ({ startName, endName, measureName }) => {
  let { pathname } = window.location;
  const name = getName(pathname);

  try {
    window.performance.mark(endName);
    const newMeasureName = `${measureName} - ${Date.now()}`
    performance.measure(newMeasureName, startName, endName);
    let measures = performance.getEntriesByName(newMeasureName);
    let measure = measures?.[0];
    console.log(`*${measureName}*`, measure?.duration);
    window.__OmegaEvent('tech_performance_custom', intl.t('性能自定义埋点'), {
      timingCategory: measureName,
      timingName: `${name}`,
      timingValue: measure?.duration ?? 0,
    });
  } catch (error) {
    console.log(error)
  }
};
