
export function getDcVersion() {
  let version = '';
  const ua = window.navigator.userAgent;
  let matchReg = /(AppVersion\/).*?(?=\s)/;
  version = ua.match(matchReg)?.[0].split('/')?.[1];
  return version;
}

// 判断用户当前版本是否小于指定版本
export function isBeforeVersion(curr, origin) {
  if (!curr) return true;
  const currArray = curr.split('.');
  const originArray = origin.split('.');
  let isBefore = false;

  for (let guard = 0; guard < Math.min(currArray.length, originArray.length); guard++) {
    const currNumber = Number(currArray[guard]);
    const originNumber = Number(originArray[guard]);

    if (currNumber < originNumber) {
      isBefore = true;
      break;
    } else if (currNumber > originNumber) {
      break;
    }
    // If the components are equal, continue to the next component
  }

  return isBefore;
}
