import { intl } from 'di18n-react';
import dayjs from 'dayjs';
import { FlowChart } from '@/constants/cooperConstants';
import { isDesktopDC, isIpad } from '@/utils';


export const baseUrl = '/api/v1';


export function setCookie(name, value, seconds) {
  seconds = seconds || 0;
  let expires = '';

  if (seconds !== 0) {
    // 设置cookie生存时间
    let date = new Date();
    date.setTime(date.getTime() + seconds * 1000);
    expires = `; expires=${date.toGMTString()}`;
  }

  document.cookie = `${name}=${encodeURI(value)}${expires}; path=/`; // 转码并赋值
}


export function getUserNameFromCookie() {
  // 内网：_kylin_username
  // 外网：_cooper_username
  const m = document.cookie.match(/username=([^;]*)/);
  return decodeURIComponent(m ? m[1] : '');
}

export function getLcaAgentUuidFromCookie() {
  // 内网：_kylin_username
  // 外网：_cooper_username
  const m = document.cookie.match(/lca_agent_id=([^;]*)/);
  return decodeURIComponent(m ? m[1] : '');
}

export function getLocalLanguageFromCookie() {
  const m = document.cookie.match(/local_language=([^;]*)/);
  return decodeURIComponent(m ? m[1] : '');
}

export function shortenDep(dep) {
  // 只显示第一级和最后一级部门，以 / 分隔
  const spt = dep.split('/');
  return spt.length <= 2 ? dep : `${spt[0]}...${spt[spt.length - 1]}`;
}

export function deepCopy(obj) {
  // XXX: should be better
  return JSON.parse(JSON.stringify(obj));
}

export function formatSize(size) {
  if (size === -1) return '--';

  // 按 1000 换算
  if (size < 1024) return `${size}B`;
  if (size < 1024 * 1024) return `${(size / 1024).toFixed(1)}KB`;
  if (size < 1024 * 1024 * 1024) return `${(size / 1024 / 1024).toFixed(1)}MB`;
  return `${(size / 1024 / 1024 / 1024).toFixed(1)}GB`;
}

export function newFormatDate(time) {
  return dayjs(new Date(time)).format(intl.t('MM月DD日'));
}
export function newFormatTime(time) {
  return dayjs(new Date(time)).format(intl.t('HH时mm分'));
}

export function formatDatetime(time) {
  return dayjs(new Date(time)).format(intl.t('YYYY年MM月DD日 HH时mm分ss秒'));
}
export function isChrome() {
  return window.navigator.userAgent.indexOf('Chrome') !== -1;
}

export function isSafari() {
  return !isChrome() && window.navigator.userAgent.indexOf('Safari') !== -1;
}

export function supportWebkitdirectory() {
  // https://caniuse.com/#search=webkitdirectory
  //
  // XXX: 暂时仅处理 Safari < 11.1
  const ua = navigator.userAgent;
  if (/safari/i.test(ua)) {
    const m = ua.match(/version\/(\d+)\.(\d+)/i);
    if (m) {
      const [x, y] = [Number(m[1]), Number(m[2])];
      if (x < 11 || x === 11 && y < 1) {
        return false;
      }
    }
  }

  return true;
}

// 文件名比较大小
export function cmpName(key, subKey) {
  return (a, b) => {
    const x0 = subKey ? a[key][subKey] : a[key];
    const y0 = subKey ? b[key][subKey] : b[key];

    const x = parseInt(x0);
    const y = parseInt(y0);

    if (!Number.isNaN(x) && !Number.isNaN(y)) {
      // 优先按数字比较
      if (x > y) return 1;
      if (x < y) return -1;
      return 0;
    }
    if (x0 > y0) return 1;
    if (x0 < y0) return -1;
    return 0;
  };
}

function getFileListImg(imgUrl) {
  let myURL = new URL(imgUrl);
  const { protocol, host } = window.location;
  let hostCurr = `${protocol}//${host}`;
  return hostCurr + myURL.pathname;
}

function isFlow(type) {
  return type === FlowChart || type === 'FLOWCHART';
}

export const setImgUrl = (record, isSearch) => {
  let judgeType = isFlow(record.type || record.resource_type || record.resourceType);
  if (isSearch && judgeType) {
    return getFileListImg(record.tinyImage || record.tiny_image || record.iconImage);
  }
  if (!isSearch && judgeType) {
    return getFileListImg(record.tiny_image || record.tinyImage || record.iconImage);
  }
  return record.tiny_image || record.tinyImage || record.iconImage;
};


// 判断当前页面是团队空间(1)还是个人空间(2)
let pageType;
export const setPageType = (type) => {
  pageType = type;
};

export const checkPermis = (type, perm) => {
  if (pageType === 2) {
    return true;
  }
  /* eslint-disable no-bitwise */
  switch (type) {
    case 'read': {
      return Boolean(perm & 1); // 收藏
    }
    case 'write': {
      return Boolean(perm & 2); // 移动、重命名
    }
    case 'delete': {
      return Boolean(perm & 4); // 删除
    }
    case 'create': {
      return Boolean(perm & 8); // 上传、新建文件夹
    }
    case 'share': {
      return Boolean(perm & 16); // 分享
    }
    case 'download': {
      return Boolean(perm & 32); // 下载
    }
    default: {
      return false;
    }
    /* eslint-enable no-bitwise */
  }
};

export const getPermis = (perm, isTeam = false) => {
  /* eslint-disable no-bitwise */
  // 此处只校验是否具有查看、编辑、下载
  let res = [];
  if ((perm & 1)) {
    res.push(intl.t('查看'));
  }
  if ((perm & 2)) {
    res.push(intl.t('编辑'));
  }
  if ((perm & 32)) {
    res.push(intl.t('下载'));
  }
  if (isTeam) {
    if ((perm & 8)) {
      res.push(intl.t('上传'));
    }
    if ((perm & 16)) {
      res.push(intl.t('分享'));
    }
  }
  /* eslint-enable no-bitwise */

  return res.length === 0 ? [intl.t('无')] : res;
};

export const TEAM_MEMBER = 0; // 普通成员
export const TEAM_ADMIN = 1; // 管理员
export const TEAM_OWNER = 2; // 所有者
export const TEAM_GUEST = 3;
export const TEAM_STRANGER = 4; // 不在团队空间中

export function clog() {
}

export function fullScreen() {
  const el = document.documentElement;
  const rfs = el.requestFullScreen
    || el.webkitRequestFullScreen
    || el.mozRequestFullScreen
    || el.msRequestFullScreen;

  if (typeof rfs !== 'undefined' && rfs) {
    rfs.call(el);
  } else if (typeof window.ActiveXObject !== 'undefined') {
    const wscript = new window.ActiveXObject('WScript.Shell');
    if (wscript) {
      wscript.SendKeys('{F11}');
    }
  }
}
export function exitFullScreen() {
  const el = document;
  const cfs = el.cancelFullScreen
    || el.webkitCancelFullScreen
    || el.mozCancelFullScreen
    || el.exitFullScreen;

  if (typeof cfs !== 'undefined' && cfs) {
    cfs.call(el);
  } else if (typeof window.ActiveXObject !== 'undefined') {
    const wscript = new window.ActiveXObject('WScript.Shell');
    if (wscript) {
      wscript.SendKeys('{F11}');
    }
  }
}

export function blurTime(time, isNumTime) {
  const now = new Date();
  const _time = new Date(time);

  // 如果年月日相同，显示时分
  if (
    now.getFullYear() === _time.getFullYear()
    && now.getMonth() === _time.getMonth()
    && now.getDate() === _time.getDate()
  ) {
    return dayjs(new Date(time)).format(isNumTime ? intl.t('HH:mm') : intl.t('HH时mm分'));
  }

  // 如果年相同，显示月日
  if (now.getFullYear() === _time.getFullYear()) {
    return dayjs(new Date(time)).format('MM/DD');
  }

  // 年不同，显示年月日
  return dayjs(new Date(time)).format('YYYY/MM/DD');
}

export function numFormat(num) {
  if (num > 99 && num <= 110) {
    return '100+';
  }
  if (num > 110 && num <= 999) {
    return '110+';
  }
  if (num > 999 && num <= 9999) {
    return `${(num / 1000).toFixed(1)}K+`;
  }
  if (num > 9999) {
    return `${(num / 10000).toFixed(1)}W+`;
  }
  return num;
}

export function loadScript(src, props) {
  return new Promise((resolve) => {
    setTimeout(() => {
      const script = document.createElement('script');
      script.src = src;
      Object.assign(script, props);
      script.onload = function () {
        if (!this.readyState || this.readyState == 'complete') {
          resolve();
        }
      };
      script.onreadystatechange = script.onload;
      document.body.appendChild(script);
    }, 0);
  });
}

export function formatNumber(num) {
  return num < 100 ? num : '99+';
}

export function calcTextLength(text, fontSize, fontWeight = 400, fontFamily = 'inherit') {
  const span = document.createElement('span');

  span.innerText = text;
  Object.assign(span.style, {
    fontFamily,
    fontSize,
    fontWeight,
    display: 'inline-block',
    height: 0,
    overflow: 'hidden',
  });

  document.body.appendChild(span);
  const length = span.offsetWidth;
  document.body.removeChild(span);
  return length;
}
// 减法
export function Subtr(arg1, arg2) {
  let r1;
  let r2;
  let m;
  let n;
  try { r1 = arg1.toString().split('.')[1].length; } catch (e) { r1 = 0; }
  try { r2 = arg2.toString().split('.')[1].length; } catch (e) { r2 = 0; }
  m = 10 ** Math.max(r1, r2);
  n = (r1 >= r2) ? r1 : r2;
  return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

export function removePropertyOfNull(obj) {
  Object.keys(obj).forEach((item) => {
    if ((obj[item] === null || obj[item] === '') && item !== 'ownerIds') delete obj[item];
  });
  return obj;
}

export function parseUrlSearch() {
  const { search } = window.location;
  const query = {};

  search.slice(1).split('&').forEach((q) => {
    const [k, v] = q.split('=');
    query[k] = v;
  });

  return query;
}

export function noopenerOpen(url) {
  // 兼容新旧版safari
  if (isDesktopDC || isIpad()) {
    window.open(url);
  } else {
    let win = window.open('about:blank');
    win.location.replace(url);
  }
  // if (isSafari()) {
  //   // safari 使用 _blank 会打开新浏览器，因此不用 _blank
  //   window.open(url);
  // } else {
  //   // 不加 noopener 的 tab 会一个进程里，因此加上
  //   window.open(url, '_blank', 'noopener');
  // }
}

export function getIds() {
  const { pathname } = window.location;
  // 个人空间
  if (/^\/disk\/|^\/files\//.test(pathname)) {
    const m = pathname.match(/^\/files\/(\d+)$/);
    const parentId = m ? Number(m[1]) : 0;
    return {
      parentId,
      teamId: 0,
    };
  }

  // 团队空间
  if (/^\/team-file\//.test(pathname)) {
    const m1 = pathname.match(/^\/team-file\/\d+\/(\d+)$/);
    const parentId = m1 ? Number(m1[1]) : 0;
    const m2 = pathname.match(/^\/team-file\/(\d+)/);
    const newTeamId = m2 ? Number(m2[1]) : 0;
    return {
      parentId,
      teamId: newTeamId,
    };
  }

  const params = (new URL(document.location)).searchParams;
  const teamId = params.get('teamId');

  // 其他页面
  return {
    parentId: 0,
    teamId: teamId || 0,
  };
}

export function getDefaultPermis(teamInfo) {
  // XXX: teamInfo 中 teamInfo.permission(个人权限) 和 teamInfo.links[0].permission(团队权限)混淆，需要修改
  const p = teamInfo?.links?.[0]?.permission;
  const ps = [];
  [1, 2, 8, 16, 32].forEach((b, idx) => {
    if (b & p) ps.push(idx); // eslint-disable-line no-bitwise
  });
  return ps;
}

export function getGroupType(u) {
  switch (u.type) {
    case 1:
      return 'OE';

    case 2:
      return 'Cooper';

    case 3:
      return intl.t('邮件组');

    case 4:
      return 'D-Chat';

    default:
      return 'Unknown';
  }
}

export function string10to62(number) {
  let chars = '0123456789abcdefghigklmnopqrstuvwxyzABCDEFGHIGKLMNOPQRSTUVWXYZ'.split('');
  let radix = chars.length;
  let qutient = +number;
  let arr = [];
  let mod;
  do {
    mod = qutient % radix;
    qutient = (qutient - mod) / radix;
    arr.unshift(chars[mod]);
  } while (qutient);
  return arr.join('');
}


export const checkInputValue = (v, max) => {
  let s = '';
  if (!v.trim()) {
    s = intl.t('名称不能为空');
  } else if (v.length > (max || 200)) {
    s = intl.t('名称不能超过{count}个字符', {
      count: max || 200,
    });
  } else if (
    /\ud83c[\udf00-\udfff]|\ud83d[\udc00-\ude4f]|\ud83d[\ude80-\udeff]|[$'?*:;"%/|\\]/.test(v)
  ) {
    s = intl.t("名称不能包含$'?*:;\"%/|\\等特殊字符");
  }

  return s;
};


export const ellipsisStr = (str, len) => {
  if (str.length > len) { return `${str.slice(0, len)}...`; }
  return str;
};


export const addTime = (expire) => {
  if (expire === '1') return intl.t('永久有效');
  if (expire === '2') {
    return dayjs().add(1, 'M').format(intl.t('YYYY年MM月DD日 HH时mm分ss秒'));
  }
  if (expire === '3') {
    return dayjs().add(1, 'w').format(intl.t('YYYY年MM月DD日 HH时mm分ss秒'));
  }
  if (expire === '4') {
    return dayjs().add(1, 'd').format(intl.t('YYYY年MM月DD日 HH时mm分ss秒'));
  }
  return '';
};
