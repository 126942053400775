import { intl } from 'di18n-react';
import axios from 'axios';
import NotificationStatus from '@/constants/notification';
import useNotification from '@/hooks/useNotification';

const SocketHost = 'cooper_zas.ectest.intra.xiaojukeji.com';
const CooperTest = '//cooper-test.didichuxing.com/';

// 该实例用于访问其它不需要拦截器的请求
const ajax = axios.create({
  baseURL: window.location.host === SocketHost ? CooperTest : '/',
  keepSilent: false, // 公共错误提示
  withCredentials: true,
  validateStatus(status) {
    return (status >= 200 && status < 300) || status === 304; // 请求正常的状态码范围
  },
});

// 响应拦截器
ajax.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // 断网提示
    // if (!window.navigator.onLine) {
    //   useNotification()(
    //     NotificationStatus.ERROR,
    //     intl.t('当前网络已断开，请联网后重试'),
    //     null,
    //     2,
    //     'offline',
    //   );
    //   return Promise.reject(error);
    // }
    const { response, config } = error;
    const { errorMessage, errorType, errorCode } = response.data;
    let errorMsg = errorMessage || errorType || '';
    if (!config.keepSilent && errorMsg.length > 0 && errorCode !== 401) {
      useNotification()(NotificationStatus.ERROR, errorMsg);
    }
    return Promise.reject(error);
  },
);

const {
  get,
  post,
  patch,
  put,
  delete: del,
} = ajax;

export {
  get,
  post,
  patch,
  put,
  del,
};

export default ajax;
