import classBind from 'classnames/bind';
import { Link, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { getIconByFileName, formatSize } from '@/utils/upload';

import TransferState from '../transferState';
import styles from './style.module.less';

const cx = classBind.bind(styles);

export default function UploadItem(props) {
  const {
    id,
    fileName,
    size,
    percent,
    callback,
    knowledgeId,
    taskManager,
    file,
    type,
    deleteFile,
    parentId,
    stateMsg,
    isFail,
    err,
    taskId,
    isFinish,
    isImport,
    isImportFromDK,
    resourceType,
    currPageId,
    modeType,
  } = props;
  const { teamId } = useParams();

  function callbackAfterDone(id) {
    if (typeof callback === 'function') {
      callback(id);
    }
    // deleteFile(id);
  }


  useEffect(() => {
    let task = {
      id,
      fileName,
      file,
      size,
      percent,
      knowledgeId,
      parentId,
      currPageId,
      type,
      isImport,
      isImportFromDK,
      callback: callbackAfterDone,
      modeType,
    };
    taskManager.addTask(task);
  }, [taskId]);

  function cancelTask(id) {
    taskManager.pause(id);
    deleteFile(id);
  }

  return (
    <Link
      className={cx('item-container')}
      key={id}
      to={
        teamId
          ? `/team-file/${teamId}/knowledge/${knowledgeId}/${currPageId}/edit`
          : `/knowledge/${knowledgeId}/${currPageId}/edit`
      }
      onClick={(e) => {
        if (currPageId === '0') e.preventDefault();
        if (!isFinish || isFail) e.preventDefault();
      }}
    >
      <div className={cx('title-container')}>
        {getIconByFileName(fileName, resourceType)()}
        <div className={cx('text-container')}>
          <div className={cx('name')}>{fileName}</div>
          {size && <div className={cx('hint-container')}>
            <div className={cx('size')}>{formatSize(size)}</div>
          </div>}
        </div>
      </div>
      <TransferState
        percent={percent}
        id={id}
        onClickClose={(isImport || isImportFromDK) ? undefined : cancelTask}
        isFail={isFail}
        stateMsg={stateMsg}
        err={err}
        isFinish={isFinish}
      />
    </Link>
  );
}
