/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-06-16 10:46:42
 * @LastEditTime: 2023-07-03 16:19:35
 * @Description: cooper的模版数据
 * @FilePath: /knowledgeforge/src/model/models/cooper/template.js
 *
 */

import * as templateService from '@/service/cooper/template';
import { intl } from 'di18n-react';


const FILETYPE = {
  0: 'SHEET',
  1: 'DOC',
};

export const templateAction = {
  GET_TYPE_LIST: 'GET_TYPE_LIST',
  GET_ALL_LIST: 'GET_ALL_LIST',
  GET_SUB_LIST: 'GET_SUB_LIST',
  CLEAR_STATE: 'CLEAR_STATE',
  CHANGE_FILETYPE: 'CHANGE_FILETYPE',
  SET_TITLE: 'SET_TITLE',
  CHANGE_STEP: 'CHANGE_STEP',
  PREVIEW: 'PREVIEW',
  CHANGE_TYPE_INDEX: 'CHANGE_TYPE_INDEX',
};


const getDefaultState = () => ({
  fileType: 0,
  templateTypeList: [], // 模板分类列表
  allTemplateList: [], // 全部模板列表
  previewAllTemplate: [], // 全部模板拉平
  typeIndex: -1, // 第几个分类
  titleText: '选择模板',
  pageStep: 0,

  subTemplateList: [], // 当前选中的子分类模板，用于第一个页面展示

  currentTemplateList: [], // 当前预览的分类中的模板
  currentTemplateIndex: 0, // 当前分类的第几个模板，用于预览
});


export default {
  state: getDefaultState(),
  reducers: {
    updteState(state, action) {
      switch (action.type) {
        case templateAction.GET_TYPE_LIST:
          return { ...state, templateTypeList: action.templateTypeList };

        case templateAction.GET_ALL_LIST:
          return { ...state, allTemplateList: action.allTemplateList, previewAllTemplate: action.previewAllTemplate };

        case templateAction.GET_SUB_LIST:
          return { ...state, subTemplateList: action.subTemplateList };

        case templateAction.CLEAR_STATE:
          return { ...state, ...action.initState };

        case templateAction.CHANGE_FILETYPE:
          return { ...state, fileType: action.fileType };

        case templateAction.SET_TITLE:
          return { ...state, titleText: action.titleText };

        case templateAction.CHANGE_STEP:
          return { ...state, pageStep: action.pageStep, titleText: action.titleText };

        case templateAction.PREVIEW:
          return { ...state, ...action.data };

        case templateAction.CHANGE_TYPE_INDEX:
          return { ...state, typeIndex: action.typeIndex };

        default:
          return state;
      }
    },
  },
  effects: {
    async reqTemplateType(fileType) {
      templateService.getTemplateType(FILETYPE[fileType]).then((res) => {
        this.updteState({ type: templateAction.GET_TYPE_LIST, templateTypeList: res })
      })
    },
    async reqAllTemplateList({ fileType, cb = () => {} }) {
      templateService.getAllTypeTemplateList({
        fileType: FILETYPE[fileType],
        pageSize: 1000,
        itemSize: 1000,
        recentLyUseSize: 6,
      }).then((res) => {
        let previewAllTemplate = res.items.reduce((previewItem, item) => previewItem.concat(item.templateList), []);
        this.updteState({
          type: templateAction.GET_ALL_LIST,
          allTemplateList: res.items,
          previewAllTemplate,
        })
      })
      cb()
    },
    async reqTemplateList({ templateTypeId, fileType, cb = () => { } }) {
      templateService.getTemplateList({
        templateTypeId,
        fileType: FILETYPE[fileType],
        pageSize: 1000,
        itemSize: 1000,
      }).then((res) => {
        this.updteState({
          type: templateAction.GET_SUB_LIST,
          subTemplateList: res.items,
        })
      })
      cb()
    },
    async clearState() {
      let initState = {
        fileType: 0,
        currentTemplateIndex: -1, // 当前分类的第几个模板
        typeIndex: -1, // 第几个分类
        titleText: intl.t('选择模板'),
        pageStep: 0,
      };
      this.updteState({
        type: templateAction.CLEAR_STATE,
        initState,
      })
    },

    async changeFileType(fileType) {
      this.updteState({
        type: templateAction.CHANGE_FILETYPE,
        fileType,
      })
    },

    async setModelTitle(titleText) {
      this.updteState({
        type: templateAction.SET_TITLE,
        titleText,
      })
    },

    async goBackTemplateList() {
      this.updteState({
        type: templateAction.CHANGE_STEP,
        pageStep: 0,
        titleText: intl.t('选择模板'),
      })
    },

    async previewTemplate({ currentTemplateList, currentTemplateIndex, titleText }) {
      this.updteState({
        type: templateAction.PREVIEW,
        data: { pageStep: 1, currentTemplateList, currentTemplateIndex, titleText },
      })
    },

    async changeSubTemplate(typeIndex) {
      this.updteState({
        type: templateAction.CHANGE_TYPE_INDEX,
        typeIndex,
      })
    },
  },
};
