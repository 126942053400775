import { useSelector } from 'react-redux';
import { isDesktopDC, isDkPage } from '@/utils';
import classBind from 'classnames/bind';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import ProjectJump from './ProjectJump';
import ChangeKnowledge from './ChangeKnowledge';
import SearchContent from './SearchContent';
import CreateKnowledge from './CreateKnowledge';
import IconList from './IconList';
import UserInfo from './UserInfo';
import CooperTip from './CooperTip';
import styles from './style.module.less';
import { useContext, useEffect, useMemo } from 'react';

const cx = classBind.bind(styles);

// 根据configSet 决定渲染哪些组件
function Header({ configSet }) {
  const { knowledgeDetail } = useSelector((state) => state.KnowledgeData);
  const { tags } = useSelector((state) => state.pageDetail);
  const { knowledgeId, pageId } = useContext(LayoutContext);
  useEffect(() => {
    // 切换路由关闭面板
    if (window.CooperClientService) {
      try {
        window.CooperClientService.destroy();
      } catch (error) {}
    }
  }, [pageId]);

  const notSafe = useMemo(() => {
    const isHighSecurity = tags.some((item) => (item.sourceType === 'SYSTEM_SECURE' && item.name > 3));

    return isHighSecurity;
  }, [tags]);

  useEffect(() => {
    const isInGray = window.editorIframeABSwitch;

    window.showxiaodi = isInGray && !notSafe;
  }, [window.editorIframeABSwitch, notSafe]);

  if (!configSet || configSet.length === 0) return null;
  const {
    showProjectJump = true,
    showChangeKnowledge = true,
    showSearchContent = true,
    showCreateKnowledge = true,
    showIconList = true,
    showUserInfo = true,
    showSafeTip = false,
  } = configSet;

  return (
    <div
      className={cx('header-wrap', {
        'header-wrap-dc': isDesktopDC,
      })}
      id="dk-header">
      {(showProjectJump || showChangeKnowledge) && <div className={cx('left')}>
        {showProjectJump && <ProjectJump />}
        {showChangeKnowledge && <ChangeKnowledge isShowCurrent={knowledgeDetail?.metisSpaceId} />}
      </div>}
      <div
        className={cx('middle', {
          'header-middle-dc': isDesktopDC,
        })}>
        {showSearchContent && <SearchContent/>}
      </div>
      {(showCreateKnowledge || showIconList || showUserInfo) && <div className={cx('right')}>
        {showCreateKnowledge && <CreateKnowledge />}
        {showSafeTip && <CooperTip type={'large'} />}
        {showIconList && <IconList knowledgeId={knowledgeId} />}
        {showUserInfo && <UserInfo />}
      </div>}
    </div>
  );
}


export default Header;

