import { get, post, put, del } from '@/utils/request';
import api from '@/utils/request/api/DkApi';

// 最近使用的模板
export function getRecentTemplates(knowledgeId) {
  return get(api.RECENT_TEMPLATE_LIST.replace(':knowledgeId', knowledgeId), { keepSilent: true });
}

export function countRecentTemplate({ knowledgeId, templateId }) {
  return post(api.COUNT_RECENT_TEMPLATE.replace(':knowledgeId', knowledgeId).replace(':templateId', templateId));
}

export function getDefaultTemplates(knowledgeId) {
  const params = {
    pageNum: 0,
    pageSize: 1000,
  };
  return get(api.DEFAULT_TEMPLATE_LIST.replace(':knowledgeId', knowledgeId), { params });
}

export function getKnowledgeTemplates(knowledgeId) {
  const params = {
    pageNum: 0,
    pageSize: 1000,
  };
  return get(api.KNOWLEDGE_TEMPLATE_LIST.replace(':knowledgeId', knowledgeId), { params });
}

export function getTemplateDetail({ knowledgeId, templateId }) {
  return get(api.TEMPLATE_DETAIL.replace(':knowledgeId', knowledgeId).replace(':templateId', templateId));
}

export function createTemplate({ knowledgeId, formData }) {
  return post(api.SAVE_TEMPLATE.replace(':knowledgeId', knowledgeId), formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export function updateTemplate({ knowledgeId, formData }) {
  return put(api.SAVE_TEMPLATE.replace(':knowledgeId', knowledgeId), formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export function deleteTemplate({ knowledgeId, templateId }) {
  return del(api.DELETE_TEMPLATE.replace(':knowledgeId', knowledgeId).replace(':templateId', templateId));
}
