import { intl } from 'di18n-react';
import { useState, useMemo, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'antd';
import AddPopover from '@/components/serviceComponents/CatalogTree/AddPopover';
import classBind from 'classnames/bind';
import * as service from '@/service/knowledge/pageTree';
import CatalogTree from '@/components/serviceComponents/CatalogTree';
import usePermission from '@/hooks/usePermission';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import stylesCommon from '../../catalogCommon.module.less';
import SwitchIcon from '../SwitchIcon';
import style from './style.module.less';

const cx = classBind.bind(style);
const cm = classBind.bind(stylesCommon);

function ViewPage(props) {
  const { isResizing } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { knowledgeId } = useContext(LayoutContext);
  const [height, setHeight] = useState(document.body.clientHeight);
  const { checkOperationPermission } = usePermission();
  const { permission } = useSelector((state) => state.KnowledgeData);
  const { setState } = dispatch.PageTree;
  const { tree } = useSelector((state) => state.PageTree);
  const { isOpenCatalog } = useSelector((state) => state.PageTree);
  const { teamId } = useParams();

  const onClickMenuHeader = (e) => {
    setState({
      isOpenCatalog: !isOpenCatalog,
    });
  };

  // 创建子页面
  const doCreateSubPage = async (parentId = '0') => {
    const newPage = await service.createPage({ parentId, knowledgeId });
    if (newPage.pageId) {
      tree.addNode('0', newPage);

      navigate(
        teamId
          ? `/team-file/${teamId}/knowledge/${knowledgeId}/${newPage.pageId}/edit`
          : `/knowledge/${knowledgeId}/${newPage.pageId}/edit`,
      );
    }

    window.__OmegaEvent('ep_dkpc_createpage_ck');
  };

  // 创建子表格
  const doCreateSubExcel = async (parentId = '0') => {
    const newExcel = await service.createExcel({ parentId, knowledgeId });
    if (newExcel.pageId) {
      tree.addNode('0', newExcel);

      navigate(
        teamId
          ? `/team-file/${teamId}/knowledge/${knowledgeId}/${newExcel.pageId}/edit`
          : `/knowledge/${knowledgeId}/${newExcel.pageId}/edit`,
      );
    }

    window.__OmegaEvent('ep_dkpc_createexcel_ck');
  };

  useEffect(() => {
    const updateHeight = () => {
      setHeight(document.body.clientHeight);
    };
    window.addEventListener('resize', updateHeight);
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  return (
    <div className={cx('view-page-tree')}>
      <li className={`${cx('catalog-title-li')} ${cm('aside-menu-li-common')}`}>
        <div
          onClick={onClickMenuHeader}
          className={`${cx('catalog-li-item')} ${cm({
            'aside-li-item': true,
          })}`}
        >
          <i
            className={cm({
              'dk-iconfont': true,
              'dk-icon-a-mulu12x': true,
              'menu-root-icon': true,
            })}
          />
          <span className={cm('aside-li-text')}>{intl.t('目录')}</span>
          <span
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={cx('operate-wrap')}
          >
            {checkOperationPermission('MANAGE_DK_PAGE', permission.perm) && (
              <AddPopover
                pageName={intl.t('知识库')}
                isRoot={true}
                pageId={'0'}
                doCreateSubPage={doCreateSubPage}
                doCreateSubExcel={doCreateSubExcel}
              >
                <Tooltip
                  title={intl.t('添加页面、导入、上传本地文件')}
                  overlayClassName="dk-ant-tooltip__reset"
                >
                  <i
                    id="dk-create-page-btn" // 用于确定引导气泡的位置
                    className={`${cx('dk-icon-tianjia1', 'dk-iconfont')} ${cm(
                      'operation-common-icon',
                      'icon-left',
                    )}`}
                  />
                </Tooltip>
              </AddPopover>
            )}

            <SwitchIcon />
          </span>
        </div>
      </li>

      {knowledgeId && (
        <CatalogTree
          isOpenCatalog={isOpenCatalog}
          isResizing={isResizing}
          height={height - 196} />
      )}
    </div>
  );
}

export default ViewPage;
