import { intl } from 'di18n-react';
import { useEffect, useMemo, useContext, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getDkRoute } from '@/routes/route-dk';
import { useSelector, useDispatch } from 'react-redux';
import AsideDK from '@/components/serviceComponents/AsideDK';
import AsideDashboard from '@/components/serviceComponents/AsideDashboard';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import { inPhone } from '@/utils';
import NotificationStatus from '@/constants/notification';
import useNotification from '@/hooks/useNotification';
import { SHARE_TYPE, SHARE_SCOPE } from '@/constants/index';
import { getShareSetting } from '@/service/knowledge/share';

const isInPhone = inPhone();

const _AsideContentMap = {
  Share: <AsideDK asideContentType="Share" />,
  Normal: <AsideDK asideContentType="Normal" />,
  PersonalMenHu: <AsideDashboard />,
  None: <div />,
};

// 路由中将aside划分为4种
// 该组件中处理除路由外，其他因素导致的组件差异性，比如：权限【同一个路由权限不同asideType不同】、手机端等
const AsideWrap = () => {
  const { knowledgeId, shareId, pageId, templateId } = useContext(LayoutContext);
  const { search, pathname } = useLocation();
  const [isShowAsideAddPerm, setIsShowAsideAddPerm] = useState(null);
  const { hasCompleteLinkPerm } = useSelector((state) => state.KnowledgeData);
  const [isShareBatchPage, setIsShareBatchPage] = useState(); // 分享范围是否为当前页面
  const notification = useNotification();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getShareModalSetting } = dispatch.SharePage;
  const { teamId, snapshootId, version } = useParams();

  const asideTypeFromRoutes = useMemo(() => {
    // 由于知识库分享链接访问时会主动跳转到第一个页面，会闪动，因此直接写死
    if (pathname.indexOf('book') !== -1) {
      return 'Share';
    }
    const frameKey = pathname
      .replace(knowledgeId, ':knowledgeId')
      .replace(shareId, ':shareId')
      .replace(pageId, ':pageId')
      .replace(templateId, ':templateId')
      .replace(snapshootId, ':snapshootId')
      .replace(version, ':version');
    return getDkRoute().routesDK.find((route) => route.path === frameKey)?.meta?.asideType;
  }, [knowledgeId, templateId, shareId, snapshootId, version]); // 编辑模板/新建模板,templateId从无到时重新判断type

  // const isInSnapshot = useMemo(() => {
  //   return pathname.indexOf('snapshoot') !== -1
  // }, [pathname]);
  // 资源被移走时，知识库分享链接status为1；页面分享status为2
  const adjustShareAsideType = () => {
    // 获取分享范围，处理分享失效状态，存储在redux中，供以后使用
    let baseId = new URLSearchParams(search).get('base');

    let scopFromBase = null;
    // 有base时isShareBatchPage由Base分享范围决定，无base时由当前页面分享范围决定
    if (baseId) {
      getShareSetting({
        resourceType: SHARE_TYPE.SHARE,
        resourceId: baseId,
      }).then((res) => {
        if (res.status === 1) {
          setIsShareBatchPage(res.isOpenSubPage === SHARE_SCOPE.IncludeChild);
          if (res.isOpenSubPage === SHARE_SCOPE.OnlySelf) {
            window.history.pushState('', '', `${window.location.pathname}`);
          }
          scopFromBase = res.isOpenSubPage;
        } else if (res.status === 2) {
          window.location.href = window.location.pathname;
        }
      });
    }
    getShareModalSetting({
      resourceType: SHARE_TYPE.SHARE,
      resourceId: shareId,
    })
      .then((res) => {
        if (res.status === 0) {
          // 0:关闭；1:开启；2:删除
          if (pathname.indexOf('book') !== -1) {
            navigate(`/knowledge/expire?shareId=${shareId}&type=book`);
          } else {
            navigate(`/knowledge/expire?shareId=${shareId}`);
          }
        } else if (res.status === 2) {
          // base的情况下 跳转到base的页面
          if (baseId) {
            notification(
              NotificationStatus.ERROR,
              intl.t('当前页面已被删除，即将为您跳转到第一个页面'),
            );
            setTimeout(() => {
              window.location.href = `/knowledge/share/page/${baseId}`;
            }, 2000);
          } else {
            navigate('/knowledge/expire');
          }
        } else if (res.status === 1) {
          if (scopFromBase === SHARE_SCOPE.IncludeChild) return;

          setIsShareBatchPage(res.isOpenSubPage === SHARE_SCOPE.IncludeChild);
        }
      })
      .catch((error) => {
        notification(
          NotificationStatus.ERROR,
          error.errorMessage || error.errorType,
        );
      });
  };

  useEffect(() => {
    if (shareId) {
      adjustShareAsideType();
    }
  }, []);

  // 只需要在页面初始进入时判断是否有完整链路权限
  useEffect(() => {
    const routes = pathname
      .replace(knowledgeId, ':knowledgeId')
      .replace(shareId, ':shareId')
      .replace(pageId, ':pageId')
      .replace(teamId, ':teamId');

    /**
     * 没有knowledgeId或者没有pageId,不需要判断hasCompleteLinkPerm
     * 有pageId时，hasCompleteLinkPerm需要时真实数据，才走之后的逻辑
     */
    if (!knowledgeId || !pageId) {
      setIsShowAsideAddPerm(true);
      return;
    }
    if (!hasCompleteLinkPerm.pageId) return;
    if (hasCompleteLinkPerm.pageId !== pageId) return;
    if (
      routes === '/knowledge/:knowledgeId/:pageId'
      || routes === '/knowledge/:knowledgeId/:pageId/edit'
      || routes === '/team-file/:teamId/knowledge/:knowledgeId/:pageId'
      || routes === '/team-file/:teamId/knowledge/:knowledgeId/:pageId/edit'
    ) {
      if (hasCompleteLinkPerm.pageId === pageId) {
        setIsShowAsideAddPerm(hasCompleteLinkPerm.hasCompleteLinkPerm);
      }
    }
  }, [knowledgeId, hasCompleteLinkPerm]);

  const _AsideContent = (asideType = 'Normal') => {
    let resType = asideType;
    if (!isShowAsideAddPerm) resType = 'None';
    if (isShareBatchPage) resType = 'Share';
    if (isInPhone) resType = 'None';
    return _AsideContentMap[resType];
  };

  return _AsideContent(asideTypeFromRoutes);
};

export default AsideWrap;
