/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-05-26 17:01:30
 * @LastEditTime: 2023-08-07 15:36:01
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/common/PrimaryModal/index.js
 *
 */
import classBind from 'classnames/bind';
import { intl } from 'di18n-react';
import { Component } from 'react';
import { Modal } from 'antd';
import styles from './index.less';

const cx = classBind.bind(styles);

class PrimaryModal extends Component {
  render() {
    const {
      title,
      width,
      onCancel,
      onOk,
      okText,
      cancelText,
      confirmLoading,
      children,
      footer = undefined,
      selfClassName,
    } = this.props;
    return (
      <Modal
        centered
        visible={true}
        maskClosable={false}
        wrapClassName={`primary-modal ${selfClassName}`}
        maskStyle={{
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
        }}
        width={width || 480}
        confirmLoading={confirmLoading}
        okText={okText ? ` ${okText}` : intl.t(' 确定')}
        cancelText={cancelText ? ` ${cancelText}` : intl.t(' 取消')}
        title={title}
        onCancel={onCancel}
        onOk={onOk}
        footer={footer}
        closeIcon={
          <i
            className={cx('dk-iconfont', 'dk-icon-guanbi', 'modal-close')}
          />
        }
      >
        {children}
      </Modal>
    );
  }
}

export default PrimaryModal;
