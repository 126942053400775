import { intl } from 'di18n-react';
import { useState, useEffect, useRef, useMemo } from 'react';
import { Select, Tooltip, Avatar } from 'antd';
import classBind from 'classnames/bind';
import { getUserData } from '@/service/knowledge/global';
import SpinRender from '../SpinRender';
import useDebouncedCallback from './useDebouncedCallback';
import PeopleSelectEmpty from '@/components/serviceComponents/PeopleSelectEmpty';
import styles from './style.module.less';

const cx = classBind.bind(styles);

const { Option } = Select;

const SelectEmptyText = () => (
  <div style={{ textAlign: 'center' }}>
    <p>{intl.t('没有结果')}</p>
  </div>
);

const UserSelect = (props) => {
  const [peopleList, setPeopleList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);
  const istreeOpen = useRef(false);
  const currentSearchWord = useRef('');
  const selectFn = useRef(true);
  const {
    mode,
    valueSelectKey,
    getlabelKey,
    empClass,
    hrStatus,
    itemRender,
    itemRequest,
    styles = {},
    onPressEnter,
    deptCodeList,
    overlayClassName,
    onChange,
    onSearch: userSearch = () => { },
    onBlur,
    onFocus,
    isNoHoverTips,
    isImgEmpty,
    inputRef,
    ...other
  } = props;

  const searchFetch = async (keyword) => {
    if (!keyword) {
      setPeopleList([]);
      setLoading(false);
      currentSearchWord.current = '';
      setFirstLoading(true);
      return;
    }

    setPeopleList([]);
    currentSearchWord.current = keyword;
    setLoading(true);
    const res = itemRequest
      ? await itemRequest(keyword)
      : await getUserData({ keyword });
    setFirstLoading(false);
    setLoading(false);
    setPeopleList(res);
  };

  const debounceSearch = useDebouncedCallback(searchFetch, 500);

  const onSearch = (keyword) => {
    userSearch(keyword);
    debounceSearch(keyword, 1);
  };

  const onDropdownVisibleChange = (open) => {
    istreeOpen.current = open;
  };

  const selectThis = (value, valueObject) => {
    onChange && onChange(value, valueObject);
    selectFn.current = false;
  };

  const options = peopleList.map((i) => {
    let label = itemRender ? '' : `${i.title}(${i.dep})`;
    return (
      <Option
        key={i.id}
        value={i[valueSelectKey || 'id']}
        className={cx('dk-people-select-label-item-wrap')}
        label={
          getlabelKey
            ? getlabelKey(i)
            : mode === 'multiple'
              ? i.title
              : `${i.title}(${i[valueSelectKey] || i.id})`
        }
        data={i}
      >
        {itemRender ? (
          itemRender(i)
        ) : (
          <div className={cx('dk-people-select-label-item')}>
            <div className={cx('dk-people-select-label-item-left')}>
              <Avatar
                size={36}
                src={i.avatar} />
            </div>
            <div className={cx('dk-people-select-label-item-right')}>
              {label.length > 16 && !isNoHoverTips ? (
                <Tooltip title={label}>
                  <div
                    className={cx('dk-people-select-label-detail')}
                  >{`${i.title}(${i.dep})`}</div>
                </Tooltip>
              ) : (
                <div
                  className={cx('dk-people-select-label-detail')}
                >{`${i.title}(${i.dep})`}</div>
              )}

              <div className={cx('dk-people-select-deptPath')}>
                &lrm;{i.mail}&lrm;
              </div>
            </div>
          </div>
        )}
      </Option>
    );
  });

  return (
    <div className={cx('dk-people-select')}>
      <Select
        allowClear
        ref={inputRef}
        mode={mode}
        showArrow={false}
        placeholder={props.placeholder || intl.t('请输入所有者姓名')}
        showSearch
        dropdownMatchSelectWidth={false} // 虚拟滚动会出现空白，关闭
        onSearch={onSearch}
        onDropdownVisibleChange={onDropdownVisibleChange}
        dropdownClassName={cx('dk-people-select-dropdown')}
        style={styles}
        optionLabelProp="label"
        filterOption={false}
        maxTagCount={1}
        onBlur={onBlur}
        onFocus={onFocus}
        maxTagPlaceholder={(v) => {
          return <div>+{v.length}</div>;
        }}
        notFoundContent={
          loading ? (
            <SpinRender loading={true} />
          ) : firstLoading ? null : isImgEmpty ? (
            <PeopleSelectEmpty />
          ) : (
            <SelectEmptyText />
          )
        }
        {...other}
        getPopupContainer={
          props.getPopupContainer
            ? props.getPopupContainer
            : (trigger) => trigger.parentNode
        }
        onChange={selectThis}
      >
        {options}
      </Select>
    </div>
  );
};

export default UserSelect;
