import { get, post } from '@/utils/request/cooper';
import CooperApi from '@/utils/request/api/CooperApi';

export const RECEIVE_STAR_CONTENTS = 'receive_star_content';


export async function getStarFile(params) {
  const res = await get(CooperApi.STAR_LIST, { params });
  return res;
}

export function starResources(resourceIds, star) {
  return () => {
    return post(CooperApi.STAR_LIST, { resourceIds, star });
  };
}
