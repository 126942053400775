/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-11-08 15:17:24
 * @LastEditTime: 2023-11-15 11:23:23
 * @Description: 无权限页面
 * @FilePath: /knowledgeforge/src/components/serviceComponents/Layout/NoPermContent/index.js
 *
 */
import { intl } from 'di18n-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { inPhone } from '@/utils';
import ErrorTips from '@/components/ErrorTipsDk';
import {
  getDkApplyStatus,
  appplyJoinDk,
  applyJoinDkUrge,
  getPageApplyStatus,
  appplyJoinPage,
  applyJoinPageUrge,
} from '@/service/knowledge/permApply';
import classBind from 'classnames/bind';
import styles from './style.module.less';
import ApplyDetail from './applyDetail';
import ApplyPerm from './apply';

const cx = classBind.bind(styles);
const isInPhone = inPhone();

const requests = (type) => {
  const conf = {
    knowledge: {
      join: appplyJoinDk,
      urge: applyJoinDkUrge,
      getStatus: getDkApplyStatus,
      title: intl.t('知识库'),
    },
    page: {
      join: appplyJoinPage,
      urge: applyJoinPageUrge,
      getStatus: getPageApplyStatus,
      title: intl.t('页面'),
    },
  };
  return conf[type];
};

const NoPermContent = ({ pageId, setInitLoading, knowledgeId }) => {
  const id = pageId || knowledgeId;
  const type = pageId ? 'page' : 'knowledge';
  const { join, urge, getStatus, title } = requests(type);
  const [applyDetail, setApplyDetail] = useState({});
  // 10000 没有申请记录  10001 审批中  10002 审批同意  10003 审批拒绝  10004 未找到审批人
  const isApplied = applyDetail.state !== 10000 && applyDetail.state !== 10004;
  const handleHelp = () => {
    window.__OmegaEvent('ep_dkpc_noapprover_help_ck');
  };
  const getApplyStatus = async () => {
    try {
      const res = await getStatus(id);
      res.name = res.pageName || res.knowledgeName;
      res.title = title;
      setApplyDetail(res);
      setInitLoading(false);
    } catch (e) {
      setInitLoading(false);
      console.log('e', e);
    }
  };
  useEffect(() => {
    getApplyStatus();
  }, []);

  return (
    <>
      {applyDetail.state
        && (isApplied ? (
          <ApplyDetail
            id={id}
            applyDetailClassName={
              isInPhone ? cx('tip-inPhone-apply-detail') : ''
            }
            detail={applyDetail}
            urge={urge}
            updateDetail={getApplyStatus}
          />
        ) : (
          <ErrorTips
            overlayClassName={
              isInPhone
                ? `error-tip-inPhone ${cx('err-tip-inPhone-apply')}`
                : ''
            }
            imagestyle={{ width: '350px' }}
            title={
              applyDetail.state === 10000
                ? intl.t('抱歉，您无权访问{title}', { title })
                : intl.t('未找到审批人')
            }
            desc={
              <div className={cx('apply-title')}>
                {applyDetail.state === 10000 ? (
                  <div className={cx('apply-title-text')}>
                    {applyDetail.name}
                  </div>
                ) : (
                  <span>
                    {intl.t('当前找不到审批人处理你的权限申请，请根据')}
                    <a
                      style={{ color: '#047FFE' }}
                      href="https://cooper.didichuxing.com/knowledge/share/book/ezHH8cb4mbaP/2199724318215"
                      onClick={handleHelp}
                    >
                      {intl.t('帮助手册')}
                    </a>
                    {intl.t('的引导解决访问权限问题')}
                  </span>
                )}
              </div>
            }
          >
            {applyDetail.state === 10000 && (
              <ApplyPerm
                id={id}
                detail={applyDetail}
                type={type}
                join={join} />
            )}
          </ErrorTips>
        ))}
    </>
  );
};

export default NoPermContent;
