import { intl } from 'di18n-react';
import { useEffect, useState, useRef, useMemo } from 'react';
import { CaretDownOutlined } from '@ant-design/icons';
import { Input, Button, message, Popover } from 'antd';
import { APPLY_ROLE } from '@/constants';
import { PAGE_MEMBER_NAME } from '@/constants/setup';
import classBind from 'classnames/bind';
import styles from './style.module.less';
import { inPhone, openNewWindow } from '@/utils';

const cx = classBind.bind(styles);
const { TextArea } = Input;
const ApplyPerm = ({ join, id, type }) => {
  const [text, setText] = useState('');
  const [visible, setVisible] = useState(false);
  const [role, setRole] = useState(APPLY_ROLE[type].READ_MEMBER);
  const handleChangeRole = (value) => {
    setRole(value);
    setVisible(false);
    type === 'knowledge'
      && window.__OmegaEvent('ep_dkpc_applyknowledge_changerole_ck');
    type === 'page' && window.__OmegaEvent('ep_dkpc_applypage_changerole_ck');
  };

  const handleSubmit = async () => {
    type === 'knowledge'
      && window.__OmegaEvent('ep_dkpc_applyknowledge_confirm_ck');
    type === 'page' && window.__OmegaEvent('ep_dkpc_applypage_confirm_ck');
    await join(id, { role, message: text });
    message.success(intl.t('申请成功'));
    window.location.reload();
  };
  const handleChange = (e) => {
    setText(e.target.value);
  };

  const handlePermHelp = () => {
    let url = type === 'knowledge'
      ? 'https://cooper.didichuxing.com/knowledge/share/book/ezHH8cb4mbaP/2199334481809'
      : 'https://cooper.didichuxing.com/knowledge/share/book/ezHH8cb4mbaP/2199334508883';
    if (inPhone()) {
      window.dcH5Sdk.navigation.openWebview({
        url,
        onSuccess() {},
        onFail(err) {},
      });
    } else {
      openNewWindow(url);
    }
    type === 'knowledge'
      && window.__OmegaEvent('ep_dkpc_applyknowledge_roleillustration_ck');
    type === 'page'
      && window.__OmegaEvent('ep_dkpc_applypage_roleillustration_ck');
  };

  return (
    <div className={cx('apply-box')}>
      <div className={cx('apply-select')}>
        <span>{intl.t('你可以申请成为:')}</span>
        <Popover
          trigger="click"
          placement="bottom"
          visible={visible}
          onVisibleChange={(v) => {
            setVisible(v);
          }}
          content={
            <ul className={cx('apply-popover')}>
              <li
                onClick={() => handleChangeRole(APPLY_ROLE[type].READ_MEMBER)}
              >
                <span>{intl.t('只读成员')}</span>
                {role === APPLY_ROLE[type].READ_MEMBER && (
                  <i className={cx('dk-icon-xuanzhong', 'dk-iconfont')} />
                )}
              </li>
              <li onClick={() => handleChangeRole(APPLY_ROLE[type].MEMBER)}>
                <span>{intl.t('成员')}</span>
                {role === APPLY_ROLE[type].MEMBER && (
                  <i className={cx('dk-icon-xuanzhong', 'dk-iconfont')} />
                )}
              </li>
            </ul>
          }
          overlayClassName={cx('dk-ant-popover__reset')}
        >
          <div
            className={cx('role-name')}
            onClick={() => setVisible(true)}>
            <span className={cx('role')}>{PAGE_MEMBER_NAME()[role]}</span>
            <CaretDownOutlined
              className={`${cx('more-role')} ${visible ? cx('isOpen') : ''}`}
            />
          </div>
        </Popover>
      </div>
      <span
        onClick={handlePermHelp}
        className={cx('perm-help')}>
        {intl.t('权限说明')}
      </span>
      <TextArea
        onChange={handleChange}
        value={text}
        placeholder={intl.t('请填写申请原因（选填，最多200字）')}
        maxLength={200}
        style={{ height: 100 }}
      />

      <Button
        onClick={handleSubmit}
        type="primary">
        {intl.t('申请')}
      </Button>
    </div>
  );
};

export default ApplyPerm;
