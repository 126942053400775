import { intl } from 'di18n-react';
import { useState, useEffect, useMemo } from 'react';
import classBind from 'classnames/bind';
import NoMore from '@/components/NoMore';
import InfiniteScroll from 'react-infinite-scroller';
import ErrorTips from '@/components/ErrorTips';
import { PAGE_SIZE } from '@/constants';
import SpinRender from '@/components/SpinRender';
import ScopeItem from '@/components/common/ListOptions/ScopeItem';
import useDebounceFn from '@/hooks/useDebounceFn';
import {
  getSearchData,
} from '@/service/knowledge/search';
import styles from './style.module.less';


const cx = classBind.bind(styles);

const NormalOptions = (props) => {
  const { searchValue, onChooseCallback, itemRequest, recentRequest, itemRender } = props;

  const [optionList, setOptionList] = useState({
    data: [],
    keyWord: null,
  });
  const [loading, setLoading] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    setLoading(true);
    setOptionList({
      ...optionList,
      data: [],
    })
    getOptionList(true);
  }, [searchValue]);


  const getOptionListReq = async (refresh = false) => {
    const pageNum = refresh ? 0 : pageNumber;
    let currentData = refresh ? [] : [...optionList.data];

    let res = [];
    let arr = [];

    if (searchValue && searchValue !== '') {
      setLoading(true);
      let opts = {
        pageNum,
        pageSize: PAGE_SIZE,
        key: searchValue,
        keyword: searchValue,
      };
      res = itemRequest(opts) ? await itemRequest(opts) : await getSearchData(opts);
      arr = res.items ? res.items : res; // 各个接口格式不一样，做一个兼容
      setHasMore((arr ?? []).length === PAGE_SIZE);
      setOptionList({
        data: [...currentData, ...(arr ?? [])],
        keyWord: null,
      });
      setPageNumber(pageNum + 1);
      setLoading(false);
    } else if (recentRequest) {
      setLoading(true);
      res = await recentRequest({ maxSize: 100 });
      arr = res.items ? res.items : res;
      setHasMore(false); // 这个接口不能分页
      setOptionList({
        data: [...currentData, ...(arr ?? [])],
        keyWord: null,
      });
      setPageNumber(pageNum + 1);
      setLoading(false);
    }
  };

  const { run: getOptionList } = useDebounceFn(getOptionListReq);


  return (
    <div className={cx('normal-options-wrap')}>
      {
          (optionList.keyWord ? optionList.keyWord === searchValue : true)
          && optionList.data.length > 0
          && <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={() => getOptionList(false)}
            hasMore={!loading && hasMore}
            useWindow={false}
            getScrollParent={() => document.querySelector('.share-form-me-scroll')}
          >
            {
              optionList.data.map((v, index) => {
                return <li
                  key={index}
                  className={cx('option-item')}
                  onClick={() => onChooseCallback(v)}>
                  {
                    itemRender
                      ? itemRender(v)
                      : ScopeItem({
                        name: v.label,
                        isDepart: v.isDepart,
                        iconImage: v.iconImage,
                      })
                  }
                  {/* {currentId === v.value && (
                    <i
                      className={cx('dk-icon-gou', 'dk-iconfont', 'check-icon')}
                  />
                  )} */}
                </li>
              })
            }
            {
              !hasMore
              && !loading
              && searchValue && searchValue !== ''
              && <NoMore customClass={cx('noMore')} />
            }

          </InfiniteScroll>
        }
      <SpinRender loading={loading} />
      {
          !loading
          && !optionList.data.length
          && (optionList.keyWord ? optionList.keyWord === searchValue : true)
          && <ErrorTips
            title={intl.t((searchValue && searchValue.length > 0) ? '暂无搜索内容' : '暂无最近访问记录')}
            overlayClassName={cx('simple-empty')}
          />
        }
    </div>
  );
};

export default NormalOptions;
