import { getLocale } from 'di18n-react';
import cls from 'classnames';
import { isDesktopDC, getUserNameFromCookie } from '@/utils';
import IcZoomOut from '../icon/v2/icon_fangda.svg';
import IcZoomIn from '../icon/v2/icon_suoxiao.svg';
import IcRotate from '../icon/v2/icon_xuanzhuan.svg';
import IcFullPage from '../icon/v2/icon_yulan.svg';

import './index.less';

class OfficePlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rotate: 0,
      scale: 10,
    };
    this.iframeRef = null;
  }

  closeFromIframe = (e) => {
    // iframe 中的关闭事件
    if (['formcancel', 'formclose'].includes(e.data.event)) {
      this.props.onClose();
    }
  };

  fullScreen = () => {
    this.iframeRef.requestFullscreen();
  };


  render() {
    const {
      isExcel,
      url,
    } = this.props;
    const { rotate, scale } = this.state;
    let hash = `#ldap=${getUserNameFromCookie()}`; // d-chat 中隐藏全屏按钮

    if (!isExcel && isDesktopDC) hash += '&fs=0';
    let previewUrl = '';
    if (url.indexOf('?') > 0) {
      previewUrl = `${url}&language=${getLocale()}${hash}`;
    } else {
      previewUrl = `${url}?language=${getLocale()}${hash}`;
    }
    return (
      <div className='office-player'>
        <div
          className={cls('container', { 'is-dc': isDesktopDC })}
          style={{

          }}
        >
          <iframe
            allowFullScreen
            src={previewUrl}
            style={{
              transform: `scale(${scale / 10}) rotate(${rotate}deg)`,
              transformOrigin: 'center center',
            }}
            // eslint-disable-next-line no-return-assign
            ref={(node) => (this.iframeRef = node)}
          />
        </div>
        {isExcel && (
          <div className='tools'>
            <div>
              <button
                onClick={() => scale < 20
                  && this.setState({
                    scale: scale + 2,
                  })
                }
              >
                <img
                  src={IcZoomOut}
                  alt='ZoomOut' />
              </button>
              <button
                onClick={() => scale > 2
                  && this.setState({
                    scale: scale - 2,
                  })
                }
              >
                <img
                  src={IcZoomIn}
                  alt='ZoomIn' />
              </button>
              <button
                onClick={() => this.setState({
                  rotate: rotate + 90,
                })
                }
              >
                <img
                  src={IcRotate}
                  alt='Rotate' />
              </button>
              {!isDesktopDC && (
                <button onClick={this.fullScreen}>
                  <img
                    src={IcFullPage}
                    alt='FullPage' />
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default OfficePlayer;
