import { once } from 'lodash-es';
import { getUserNameFromCookie, loadScript } from '@/utils';
import { initSign } from '@didi/wsgsig'

function bindOmegaEvent() {
  const trackEvent = (eventId, eventLabel = '', attrs = {}) => {
    if (window.Omega.trackEvent) {
      window.Omega.trackEvent(eventId, eventLabel, attrs);
    }
  };
  window.__OmegaEvent = window.__OmegaEvent || trackEvent; // 自定义埋点
}

// 要在其他sdk之前
initSign({
  bizId: '73a4fb7df05932870128ab5b5a77037d',
  appVer: 'V1.0.1', // 业务方自定义，方便问题排查时定位版本tag
  os: '4', // H5、PC
});

export function initSdk() {
  bindOmegaEvent();
  // pv上报原则：第一次进来上报一次，pathname改变上报一次【layout】
  setTimeout(() => {
    if (window.Omega && window.Omega.sendPageView) {
      window.Omega.sendPageView();
    }
  }, 1000);

  // 水印
  loadScript('//sec-aegisfe.didistatic.com/static/aegisfe/water-mark1.0.js?v=001').then(() => {
    window.waterMark({
      systemId: process.env.NODE_ENV === 'production' ? 2176 : 1045,
      userId: getUserNameFromCookie(),
      textStyle: 'rgba(0,0,0,0.014)',
    });
  });
}


export default once(() => {
  initSdk();
});
