import { getQuickVisit } from '@/service/cooper/home';

const getDefaultState = () => ({
  visitList: [],
  recentList: [],
  editList: [],
});

export default {
  name: 'CooperHome',
  state: getDefaultState(),
  reducers: {
    updateVisitList(state, visitList) {
      return {
        ...state,
        visitList,
      };
    },
    setRecentList(state, recentList) {
      return {
        ...state,
        recentList,
      };
    },
    setEditList(state, editList) {
      return {
        ...state,
        editList,
      };
    },
  },
  effects: {
    async getVisitList() {
      const data = await getQuickVisit();
      this.updateVisitList(data);
      return data;
    },
  },
};
