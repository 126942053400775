import { useRef, useCallback } from 'react';
import { debounce } from 'lodash-es';

/**
 * 防抖事件 hook 封装
 * @param {function} fn 要防抖的事件
 * @param {object} options 防抖函数的配置项，同 debounce
 */
export default function useDebounceFn(fn, options) {
  const fnRef = useRef(fn);
  fnRef.current = fn;

  const wait = options?.wait ?? 300;

  const debounced = useCallback(
    debounce((...args) => fnRef.current(...args), wait, options),
    [],
  );

  return {
    run: debounced,
    cancel: debounced.cancel,
    flush: debounced.flush,
  };
}
