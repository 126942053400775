/*
 * @Date: 2024-04-07 10:03:52
 * @LastEditors: guanzhong guanzhong@didiglobal.com
 * @LastEditTime: 2024-04-10 15:55:10
 * @FilePath: /knowledgeforge/src/components/common/ListOptions/ScopeItem/index.js
 * @Description: 描述文件功能
 */
import { highlight } from '@/utils';
import { intl } from 'di18n-react';
import Tag from '@/components/Tag';
import { dkBook, spaceTeam } from '@/assets/icon/fileIcon';
import { IN_OUT } from '@/constants/space'
import './style.less'

function ScopeItem(v, iconType) {
  return (
    <span
      className={'item-title'}>
      {
        v.iconImage
          ? <img
              className={'item-icon'}
              src={v.iconImage} />
          : <>
            {iconType === 'space' && <img
              className={'item-icon'}
              src={spaceTeam} />}
            {iconType === 'metis' && <img
              className={'item-icon'}
              src={dkBook} />}
          </>
      }

      <span className={'item-text'}>{highlight(v.name)}</span>

      { v.isDepart && <Tag
        type="yellow-primary"
        text={intl.t('部门')}
      />}
      {
        v?.isShowOuter && <Tag
          type="out-yellow"
          text={intl.t('外部')}
      />
      }
    </span>
  );
}

export default ScopeItem;
