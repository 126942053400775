import { intl } from 'di18n-react';
import { useRef, useState, useEffect, useContext } from 'react';
import { Button, Modal, message, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import classBind from 'classnames/bind';
import styles from './style.module.less';
import TransferSearch from './TransferSearch';
// eslint-disable-next-line import/no-extraneous-dependencies
import Resizer from '@/components/Resizer';
import PageTree from './PageTree';
import {
  getMyDKList,
  moveKnowledgePage,
  removeKnowledgePage,
} from '@/service/knowledge/menhuIndex';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import useUpload from '@/hooks/uploadWiki';
// eslint-disable-next-line import/no-extraneous-dependencies
import { cloneDeep, throttle } from 'lodash-es';
import { TAB_TYPE } from '@/constants/dkList';
import { useParams } from 'react-router-dom';

const cx = classBind.bind(styles);

const minWidth = '230';
const TransferModal = (props) => {
  const dispatch = useDispatch();
  const { closeTransferModal } = dispatch.transfer;
  const {
    visible,
    modalTitle,
    knowledgeId,
    pageId,
    knowledgeName,
    checkable,
    isOwner,
    modalText = '',
    fullPath,
  } = props;
  const { addTask } = useUpload();
  const { tree } = useSelector((state) => state.PageTree);
  const [currentPageParams, setCurrentPageParams] = useState({}); // 搜索当前知识库page所需的参数
  const [checkPageList, setCheckPageList] = useState([]); // 选择的page list 提交的请求参数
  const [submitLoading, setSubmitLoading] = useState(false); // 存储按钮的loading
  const [dkListBelongToMeLoading, setDkListBelongToMeLoading] = useState(false); // 请求知识库的loadign
  const docInfo = useSelector((state) => state.pageDetail.docInfo);
  const { multiPath = [] } = docInfo;
  const checkTreeRef = useRef(null);
  const { pageId: hostPageId } = useContext(LayoutContext);
  // 控制拖拽
  const originalWidthRef = useRef(0);
  const asideContentRef = useRef();
  const [asideContentWidth, setAsideContentWidth] = useState(minWidth);
  const [dkListBelongToMe, setDkListBelongToMe] = useState([]);
  const { teamId } = useParams();

  useEffect(() => {
    if (knowledgeId) {
      setCurrentPageParams({
        knowledgeId,
        knowledgeName,
      });
    }
  }, [knowledgeId]);

  // 请求知识库list
  useEffect(() => {
    if (visible) {
      // let params = {
      //   type: checkable ? TAB_TYPE.OWN : '',
      //   orderType: 1,
      // };
      let params = checkable ? {
        owner: true,
        sort: 1,
      } : {
        sort: 1,
      }
      setDkListBelongToMeLoading(true);
      getMyDKList(params)
        .then((res) => {
          if (res) {
            // 深拷贝防止数据污染
            const newArray = cloneDeep(res);
            const findindex = newArray.findIndex(
              (item) => item?.id === knowledgeId,
            );

            let currentPage = '';

             // 当前的知识库放在最前面 先删除再添加
            if (findindex !== -1) {
              currentPage = newArray[findindex];
              newArray.splice(findindex, 1);

            } else {
              currentPage = {
                id: knowledgeId,
                name: knowledgeName,
              }
            }
           
            newArray.unshift(currentPage);
            setDkListBelongToMeLoading(false);
            setDkListBelongToMe(newArray);
          }
        })
        .catch(() => {
          setDkListBelongToMeLoading(false);
          setDkListBelongToMe([]);
        });
    }
  }, [visible]);

  // 切换知识库
  const handleChoiceKnowLedge = (params) => {
    if (checkable && checkPageList?.length) {
      modalRender(
        intl.t('确认切换知识库'),
        intl.t('切换后，选中的页面状态将清空，你将需要重新选择。'),

        () => {
          setCurrentPageParams({
            knowledgeId: params?.id,
            knowledgeName: params?.name,
          });
        },
      );
      return;
    }
    setCurrentPageParams({
      knowledgeId: params?.id,
      knowledgeName: params?.name,
    });
    window.__OmegaEvent(
      checkable ? 'ep_dkpc_immigrate_change_ck' : 'ep_dkpc_move_change_ck',
    );
  };

  // 搜索选择页面 修改传参
  const handleClickSearchPage = (params) => {
    const { pageId: newPageId, metisName, knowledgeId: newKnowledgeId, hasChild, path } = params;
    setCurrentPageParams({
      pageId: newPageId,
      knowledgeId: newKnowledgeId,
      knowledgeName: metisName,
      path,
      hasChild,
    });
  };

  // 页面拖拽
  const resizeBegin = () => {
    originalWidthRef.current = Number(asideContentRef.current.style.width.slice(0, -2)) || 230;
  };
  const treeDragWrapRef = document.getElementsByClassName('tree-drag-wrap')[0]?.clientWidth;
  const resizing = throttle((offsetX) => {
    const offset = Number(offsetX);
    const newWidth = originalWidthRef.current + offset;
    newWidth < 339 && newWidth > 102 && setAsideContentWidth(newWidth);
  }, 16);

  // Modal
  const modalRender = (title, content, handleOk) => {
    Modal.confirm({
      className: cx('cancel-edit-modal'),
      width: 480,
      title,
      content,
      icon: null,
      centered: true,
      okText: intl.t('确定'),
      cancelText: intl.t('取消'),
      onCancel() { },
      onOk: () => handleOk(),
    });
  };

  // 迁入
  const handleMoveKnowledgePage = () => {
    modalRender(
      intl.t('确认迁入'),
      intl.t(
        '确认迁入后，选中的页面及子页面将继承新父级页面的权限，“当前页面单独授权”的成员权限不做调整。迁入页面会从原来位置移除，出现在新的位置。',
      ),

      handleTransferMove,
    );
    window.__OmegaEvent('ep_dkpc_immigrate_confirm_ck');
  };
  // 确定迁入
  const handleTransferMove = () => {
    const params = {
      fromKnowledgeId: currentPageParams?.knowledgeId,
      toKnowledgeId: knowledgeId,
      toParentPageId: pageId,
      toParentFullPath: fullPath,
      relocationPageId: checkPageList,
    };
    setSubmitLoading(true);
    moveKnowledgePage(params)
      .then((res) => {
        if (res) {
          setSubmitLoading(false);
          res.map((v) => {
            addTask({
              fileName:
                v.childSize > 1
                  ? intl.t('{slot0}等{slot1}个页面', {
                    slot0: v.pageName,
                    slot1: v.childSize,
                  })
                  : v.pageName,
              knowledgeId,
              parentId: `${v.relocationPageId}`,
              currPageId: 0,
              taskId: `${v.relocationPageId}`,
              resourceType: v.childSize > 1 ? 'DIR' : 'COOPER_DOC',
              isImportFromDK: true,
            });
          });
          closeTransferModal();
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        errorMessage(error?.errorCode, error.errorMessage, error?.data);
      });
    window.__OmegaEvent('ep_dkpc_immigrate_confirmimmigrate_ck');
  };

  // 移出
  const handleRemoveKnowledgePage = () => {
    modalRender(
      intl.t('确认移动'),
      intl.t(
        '确认移动后，移动的页面及子页面将继承新父级页面的权限，“当前页面单独授权”的成员权限不做调整。移动页面会从原来位置移除，出现在新的位置。',
      ),

      handleTransferReMove,
    );
    window.__OmegaEvent('ep_dkpc_move_confirm_ck');
  };
  // 确定移出
  const handleTransferReMove = () => {
    const selectPageId = checkPageList[0];
    const params = {
      toKnowledgeId: currentPageParams?.knowledgeId,
      toParentPageId: selectPageId,
      pageId,
      toParentFullPath: checkTreeRef.current?.fullPath,
      pageFullPath: fullPath,
    };
    setSubmitLoading(true);
    removeKnowledgePage(knowledgeId, params)
      .then((res) => {
        if (res) {
          // 获取当前节点，改变节点的parentId
          const currentNode = {
            ...tree.getNodeByKey(pageId),
            parentId: selectPageId,
            path: res.path,
            fullPath: res.fullPath,
            title: res.pageName,
            pageName: res.pageName,
          };
          closeTransferModal();

          if (currentPageParams?.knowledgeId === knowledgeId) {
            tree.movePage(currentNode);
          } else {
            tree.deleteNode(pageId);
          }
          const pathList = multiPath.map((v) => v.id);
          if (pathList.includes(pageId) && hostPageId) {
            const url = `/knowledge/${currentPageParams?.knowledgeId}/${hostPageId}`;
            window.location.href = url;
            return;
          }
          // 跨库
          message.success({
            content: (
              <span className={cx('success-tip')}>
                {intl.t('移动完成，')}

                <span
                  className={cx('link')}
                  onClick={() => {
                    const url = `/knowledge/${currentPageParams?.knowledgeId}/${res?.pageId}`;
                    if (currentPageParams?.knowledgeId === knowledgeId) {
                      window.location.href = url;
                    } else {
                      window.open(url, '_blank');
                    }
                  }}
                >
                  {intl.t('前往查看')}
                </span>
              </span>
            ),
            duration: 5,
          });
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        errorMessage(error?.errorCode, error.errorMessage, error?.data);
      });
    window.__OmegaEvent('ep_dkpc_move_confirmmove_ck');
  };

  // 失败信息
  const errorMessage = (errorCode, errorMsg, errorData) => {
    if (errorCode === 600016) {
      Modal.info({
        className: cx('cancel-edit-modal'),
        width: 480,
        icon: null,
        centered: true,
        title: intl.t('超出知识库页面数量限制'),
        content: (
          <span>
            {intl.t('当前最多可以')}
            {checkable ? intl.t('迁入') : intl.t('移动')}
            <span style={{ color: '#1890ff' }}>{errorData}</span>
            {intl.t('个页面，所选页面量已超出知识库限制，请调整页面数量后重新')}
            {checkable ? intl.t('迁入') : intl.t('移动')}。
          </span>
        ),
        okText: intl.t('知道了'),
      });
    } else if (errorCode === 600017) {
      message.error(errorMsg);
    }
  };

  const handleChecListUpdate = (ids, node) => {
    if (checkable) {
      setCheckPageList(ids);
    } else {
      setCheckPageList(ids);
      checkTreeRef.current = node;
    }
  };

  return (
    <Modal
      title={
        <div className="modal-title">
          {checkable ? intl.t('迁入至') : intl.t('将新的')}&nbsp;
          <span className="modal-title__text">{modalTitle}</span>&nbsp;
          {checkable ? intl.t('下') : intl.t('移动到新的')}
        </div>
      }
      visible={visible}
      onCancel={closeTransferModal}
      className={cx('transfer-modal')}
      footer={
        <div className={cx('modal-footer')}>
          <div>
            {!checkable && (
              <span className={cx('modal-footer__text')}>{modalText}</span>
            )}
          </div>
          <div className={cx('modal-footer__btn')}>
            <Button onClick={closeTransferModal}>{intl.t('取消')}</Button>
            <Button
              type="primary"
              loading={submitLoading}
              onClick={
                checkable ? handleMoveKnowledgePage : handleRemoveKnowledgePage
              }
              disabled={!checkPageList?.length}
            >
              {intl.t('确定')}
            </Button>
          </div>
        </div>
      }
    >
      <div className={cx('modal-content')}>
        <TransferSearch
          dkList={dkListBelongToMe?.length > 0 && isOwner}
          currentPageParams={currentPageParams}
          hasChecked={checkPageList.length}
          modalRender={modalRender}
          checkable={checkable}
          handleClickSearchPage={handleClickSearchPage}
        />

        <div className={cx('tree-drag-wrap')}>
          {dkListBelongToMeLoading ? (
            <div className="dkListBelongToMe-loading">
              <Spin />
            </div>
          ) : (
            <>
              {dkListBelongToMe?.length > 0 && isOwner && (
                <div
                  className={cx('knowledge-list')}
                  ref={asideContentRef}
                  style={{
                    width: `${asideContentWidth}px`,
                  }}
                >
                  <p className={cx('sticky-top')}>{intl.t('知识库')}</p>
                  <div className={cx('knowledge-list__content')}>
                    {dkListBelongToMe.map((item, index) => {
                      return (
                        <div
                          className={cx(
                            'content-name',
                            item?.id === currentPageParams?.knowledgeId
                              ? 'checked'
                              : '',
                          )}
                          onClick={() => handleChoiceKnowLedge(item)}
                          key={index}
                        >
                          <i
                            className={cx(
                              'dk-iconfont',
                              `${item?.id === currentPageParams?.knowledgeId
                                ? 'dk-icon-dafenqi'
                                : 'dk-icon-dc-zhishiku-copy'
                              }`,
                            )}
                          />
                          <span className="text">{item?.name}</span>
                        </div>
                      );
                    })}
                  </div>
                  <Resizer
                    onBegin={resizeBegin}
                    onResize={resizing} />
                </div>
              )}
            </>
          )}

          <div className={cx('knowledge-page')}>
            <PageTree
              currentPage={currentPageParams}
              currentKnowledgeId={knowledgeId}
              currentPageId={pageId}
              checkable={checkable}
              isOwner={isOwner}
              onChecListUpdate={handleChecListUpdate}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const ComponentWrap = () => {
  const transfer = useSelector((state) => state.transfer);
  const { visible } = transfer;

  const [modalVisible, setModalVisible] = useState(visible);
  useEffect(() => {
    if (!visible) {
      setTimeout(() => {
        setModalVisible(false);
      }, 200);
    } else {
      setModalVisible(true);
    }
  }, [visible]);
  return modalVisible && <TransferModal {...transfer} />;
};

export default ComponentWrap;
