/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-09-14 14:09:10
 * @LastEditTime: 2023-09-15 19:26:42
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/SpinRender/index.js
 *
 */
import { Spin } from 'antd';
import { intl } from 'di18n-react';
import { LoadingOutlined } from '@ant-design/icons';

// 一个居中的loading
const antIcon = <LoadingOutlined
  style={{ fontSize: 14 }}
  spin />;
export default function ({ loading, style = {}}) {
  return loading
    ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '6px 0 5px 0', ...style }}>
      <Spin indicator={antIcon} /><span style={{ marginLeft: '8px' }}>{ intl.t('加载中...')}</span>
    </div>
    : <></>;
}
