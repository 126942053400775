import { intl } from 'di18n-react';
import { useEffect, useRef, useMemo, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, message } from 'antd';
import classNames from 'classnames/bind';
import useNotification from '@/hooks/useNotification';
import NotificationStatus from '@/constants/notification';
import usePermission from '@/hooks/usePermission';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import { convertWikiToDidoc } from '@/service/knowledge/page';
import styles from './style.module.less';
import { getIsWikiHTML } from '@/constants/wikihtmlrenderHelper';

const cls = classNames.bind(styles);

// 长链根据_chain_user_token标识，前端根据uniqueKey判断是否在当前页面上弹某条消息
function HaloNotification() {
  const dispatch = useDispatch();
  const revertRef = useRef(false);
  const publishRef = useRef(false);
  const deleteRef = useRef(false);
  const navigate = useNavigate();
  const { knowledgeId, shareId, pageId } = useContext(LayoutContext);
  const notification = useNotification();
  const { checkOperationPermission } = usePermission();
  const { permission = {}} = useSelector((state) => state.KnowledgeData);
  const { tree } = useSelector((state) => state.PageTree);
  const { treeComment } = useSelector((state) => state.CommentTree);
  const { changePreviewFileIdInEdit, changePreviewFileName, setAlertTip } = dispatch.pageDetail;
  const { docInfo } = useSelector((state) => state.pageDetail);
  const dataRef = useRef({});
  const { teamId } = useParams();

  useEffect(() => {
    dataRef.current = {
      pageId,
      docInfo,
    };
  }, [pageId, docInfo])

  const hasDKPerm = useMemo(() => {
    return checkOperationPermission('READ_DK', permission.perm);
  }, [permission]);

  const handleRevertPage = (params) => {
    const { uniqueKey = {}, data = {}} = params.bytesObj;
    if (isCurrentPage(uniqueKey) && !revertRef.current) {
      const content = (
        <div>
          {`${data.operatorCn} ${data.operatorEn || ''}`}
          {intl.t(
            '已将页面还原，页面刷新后将展示最新版本。你仍可以在历史记录中找回修改内容。',
          )}
        </div>
      );

      revertRef.current = true;
      Modal.info({
        className: cls('notification-modal'),
        width: 480,
        title: intl.t('当前页面已被还原'),
        icon: null,
        centered: true,
        content,
        okText: intl.t('刷新页面'),
        onOk() {
          revertRef.current = false;
          window.location.reload();
        },
      });
    }
  };

  const doDeletePage = async (key) => {
    tree.deleteNode(key);
  };

  const handleDeletePage = (params) => {
    const { uniqueKey = {}} = params.bytesObj;

    if (isCurrentPage(uniqueKey) && !deleteRef.current) {
      deleteRef.current = true;

      let onText = () => {
        if (shareId) {
          return intl.t('关闭弹窗');
        }
        return hasDKPerm ? intl.t('进入回收站') : intl.t('查看我的知识库');
      };

      Modal.confirm({
        className: cls('notification-modal'),
        width: 480,
        title: intl.t('当前页面已被删除'),
        icon: null,
        centered: true,
        content: hasDKPerm
          ? intl.t(
            '页面被他人删除，你可以在回收站进行恢复页面。页面内容已被自动保存。',
          )
          : intl.t('页面已经被删除，不可访问'),
        okText: onText(),
        cancelText: intl.t('好的'),
        onCancel() {
          deleteRef.current = false;
          if (!shareId) {
            if (hasDKPerm) {
              navigate(`/knowledge/${knowledgeId}/home`);
            }
            doDeletePage(uniqueKey.pageId);
          }
        },
        onOk() {
          deleteRef.current = false;
          let newUrl = '';
          if (!shareId) {
            newUrl = hasDKPerm ? `/knowledge/${knowledgeId}/recycleBin` : '/';
            navigate(newUrl);
            // 由于目录没有长链，当其他人删除时，页面目录该页面还在，从回收站恢复会导致有两个一样的页面，因此主动删除一下页面
            doDeletePage(uniqueKey.pageId);
          }
        },
      });
    }
  };

  const viewDetail = () => {
    publishRef.current = false;
    const isEditStatus = window.location.href.endsWith('/edit');
    if (isEditStatus) {
      window.open(`/knowledge/${knowledgeId}/${pageId}`, '_blank');
    } else {
      window.location.reload();
    }
  };

  const closeTip = () => {
    publishRef.current = false;
    message.destroy();
  };

  const handlePublishPage = (params) => {
    const { uniqueKey = {}, data = {}} = params.bytesObj;
    const isEdit = window.location.pathname.includes('edit');
    if (isCurrentPage(uniqueKey) && !publishRef.current) {
      publishRef.current = true;
      const content = (
        <div className={cls('publish-text')}>
          {`${data.operatorCn} ${data.operatorEn || ''}`}
          {intl.t('刚刚发布了版本')}
          {!isEdit && (
            <>
              ，
              <span
                className={cls('link')}
                onClick={viewDetail}>
                {intl.t('刷新页面查看')}
              </span>
            </>
          )}

          <i
            className={cls('dk-iconfont', 'dk-icon-guanbi', 'close-icon')}
            onClick={closeTip}
          />
        </div>
      );

      notification(
        NotificationStatus.SUCCESS,
        content,
        () => {
          publishRef.current = false;
        },
        !isEdit ? null : 3,
      );
    }
  };

  const isCurrentPage = (uniqueKey) => {
    const { pageId: MsgPageId } = uniqueKey;
    return MsgPageId === pageId;
  };

  const handlePageStyle = (params) => {
    const { pageId, docInfo } = dataRef.current;
    const { uniqueKey = {}, data = {}} = params.bytesObj;
    const isEdit = window.location.pathname.includes('edit');
    const isCurrentPage = uniqueKey.pageId === pageId;
    const isSelf = data.operateBy === docInfo?.operatorName;
    if (isCurrentPage && isEdit && !isSelf) {
      setAlertTip({
        type: 'warn',
        text: '页面所有者/管理员更改了页面设置，刷新后展示。',
        refresh: true,
      })
    }
  };

  const isCurrentKnowledge = (uniqueKey) => {
    const { knowledgeId: MsgKnowledgeId } = uniqueKey;
    return MsgKnowledgeId === knowledgeId;
  };

  const handlePageCopied = (params) => {
    const res = params.bytesObj?.data || {};
    const { data, code } = res;
    const { sourceFirstPageId, spaceId, pageId, guid } = data;
    let { uniqueKey } = params.bytesObj;
    if (!isCurrentKnowledge(uniqueKey)) return;
    if (code === 200) {
      message.success({
        content: (
          <span className={cls('success-tip')}>
            {intl.t('副本创建成功，')}

            <span
              className={cls('link')}
              onClick={async () => {
                if (getIsWikiHTML(data)) {
                  await convertWikiToDidoc({
                    pageId: data.pageId,
                    guid: data.guid,
                    exteralSystemType: 'html',
                    toExteralSystemType: 'didoc',
                  });
                }
                navigate(
                  teamId
                    ? `/team-file/${teamId}/knowledge/${knowledgeId}/${pageId}/edit`
                    : `/knowledge/${knowledgeId}/${pageId}/edit`,
                );
              }}
            >
              {intl.t('前往编辑')}
            </span>
          </span>
        ),
        key: sourceFirstPageId,
        duration: 2,
      });
      const position = tree
        .getNodeByKey(data.parentId)
        ?.children?.findIndex((v) => v.pageId === sourceFirstPageId) + 1;
      tree.addNode(data.parentId, data, position);
    } else {
      message.error({
        content: intl.t('副本创建失败'),
        key: sourceFirstPageId,
        duration: 2,
      });
    }
  };

  const handlePageImported = (params) => {
    const { data = {}, uniqueKey = {}} = params.bytesObj;
    const { page } = data;
    // 有人导入了页面，需要知识库所有人都更新目录，因此不做taskId判断
    if (page && isCurrentKnowledge(uniqueKey)) {
      tree.addNode(page.parentId, page);
    }
  };

  const handleMove = (params) => {
    const { data = {}, uniqueKey = {}} = params.bytesObj;
    const { page } = data;
    if (page && isCurrentKnowledge(uniqueKey)) {
      tree.movePage(page);
    }
  };

  const handleDownloadTask = (params) => {
    const res = params?.bytesObj?.data || {};
    const { taskId, code, downloadUrl } = res;
    const { downloadTask = {}} = window;
    if (downloadTask.taskId !== taskId) {
      return;
    }
    if (code === 100000) {
      if (downloadUrl) {
        const url = downloadUrl.charAt(4) !== 's'
          ? `${downloadUrl.slice(0, 4)}s${downloadUrl.slice(4)}`
          : downloadUrl;
        window.location.href = url;
      }
      notification(
        NotificationStatus.SUCCESS,
        intl.t('导出成功'),
        () => { },
        2,
        String(downloadTask.pageId),
      );
    } else {
      notification(
        NotificationStatus.ERROR,
        intl.t('导出失败'),
        () => { },
        2,
        String(downloadTask.pageId),
      );
    }
    window.downloadTask = {};
  };

  function handleParsingProgress(params) {
    const { updateFileProgress } = dispatch.uploadWiki;
    const { data = {}} = params.bytesObj;
    const { code, content, taskId, resourceId, pageId: currPageId } = data;
    // 200: 导入成功；
    // 20500:解析中；
    // 20501：解析了*个页面，创建*个，失败*个；
    // 20502: 正在重试；
    // 20601：压缩包内容无效，无法解析；
    // 20602: 页面数量超出知识库容量上限，请分多次导入wiki压缩包；
    // 20603：word损坏;
    // 20604: 导入失败，请重新选择文件
    updateFileProgress(resourceId || taskId, {
      stateMsg: content,
      isFinish: code !== 20500,
      currPageId,
      isFail: code !== 200 && code !== 6666 && code !== 20500,
    });
  }

  const handleCreatedComment = (params) => {
    const { data = {}, uniqueKey = {}, parsedMeta } = params.bytesObj;
    const { commentParentId } = data;
    if (isCurrentPage(uniqueKey)) {
      treeComment.addNode(commentParentId, data);
    }
  };

  const handleDeletedComment = (params) => {
    const { data = {}, uniqueKey = {}} = params.bytesObj;
    const { commentId } = data;
    if (isCurrentPage(uniqueKey)) {
      treeComment.deleteNode(commentId);
    }
  };

  const handleUpdatedComment = (params) => {
    const { data = {}, uniqueKey = {}} = params.bytesObj;
    const { commentId } = data;

    if (isCurrentPage(uniqueKey)) {
      treeComment.updateNode(commentId, data);
    }
  };

  const [haloClient, setHaloClient] = useState(null);

  useEffect(() => {
    import('@/service/knowledge/initHalo')
      .then((module) => {
        setHaloClient(module.default);
      })
      .catch((err) => {
        console.log('inithalo err', err);
      });
  }, []);

  useEffect(() => {
    if (haloClient) {
      haloClient.addEventListener('downloadTask', handleDownloadTask);
      haloClient.addEventListener('pagePublish', handlePublishPage);
      haloClient.addEventListener('pageStyleUpdate', handlePageStyle);
      haloClient.addEventListener('pageRevert', handleRevertPage);
      haloClient.addEventListener('parsingProgress', handleParsingProgress);
      haloClient.addEventListener('fileCompleted', handleFileCompleted);

      if (tree.initTree) {
        haloClient.addEventListener('pageDelete', handleDeletePage);
        haloClient.addEventListener('pageCreated', handlePageImported);
        haloClient.addEventListener('pageCopied', handlePageCopied);
        haloClient.addEventListener('pageImport', handleMove);
      }
    }
    return () => {
      if (haloClient) {
        haloClient.removeEventListener('downloadTask', handleDownloadTask);
        haloClient.removeEventListener('pagePublish', handlePublishPage);
        haloClient.removeEventListener('pageStyleUpdate', handlePageStyle);
        haloClient.removeEventListener('pageRevert', handleRevertPage);
        haloClient.removeEventListener('pageDelete', handleDeletePage);
        haloClient.addEventListener('pageCreated', handlePageImported);
        haloClient.removeEventListener('pageCopied', handlePageCopied);
        haloClient.removeEventListener(
          'parsingProgress',
          handleParsingProgress,
        );
        haloClient.removeEventListener('fileCompleted', handleFileCompleted);
        haloClient.removeEventListener('pageImport', handleMove);
      }
    };
  }, [tree, haloClient]);


  useEffect(() => {
    if (haloClient) {
      if (treeComment.initTree) {
        haloClient.addEventListener('commentCreated', handleCreatedComment);
        haloClient.addEventListener('commentDeleted', handleDeletedComment);
        haloClient.addEventListener('commentUpdated', handleUpdatedComment);
      }
    }
    return () => {
      if (haloClient) {
        haloClient.removeEventListener('commentCreated', handleCreatedComment);
        haloClient.removeEventListener('commentDeleted', handleDeletedComment);
        haloClient.removeEventListener('commentUpdated', handleUpdatedComment);
      }
    };
  }, [treeComment, haloClient]);

  const handleFileCompleted = (params) => {
    const res = params.bytesObj?.data || {};
    if (pageId === res.pageId) {
      changePreviewFileName(res.pageName);
      changePreviewFileIdInEdit(res.fileId);
    }
  };

  return <div />;
}

export default HaloNotification;
