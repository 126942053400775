/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-05-23 12:18:10
 * @LastEditTime: 2023-06-20 16:57:09
 * @Description: 打开流程图
 * @FilePath: /knowledgeforge/src/components/CooperOperation/OpenFlowChart/index.js
 *
 */
import { noopenerOpen } from '@/utils/cooperutils';
import { get } from '@/utils/request/cooper';
import api from '@/utils/request/api/CooperApi';

function openFlowChart(id) {
  let flowUrl = '';
  let flowPath = '/docs/flow/draw';
  get(api.RESOURCE_DOC.replace(':resourceId', id), {
    headers: {
      'X-App-Id': 2,
    },
  })
    .then((res) => {
      let docId = res.guid;
      const { fileName } = res;
      flowUrl = `${window.location.origin}${flowPath}?docId=${docId}&resourceId=${id}#L${fileName}`;
      noopenerOpen(flowUrl);
    });
}

export default openFlowChart;
