/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-06-20 10:21:42
 * @LastEditTime: 2023-11-27 17:39:07
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/service/cooper/upload.js
 *
 */
import { getUserNameFromCookie } from '@/utils';
import { getAjaxAbort, postAjaxAbort } from '@/utils/request/abort';
import { get, patch, post } from '@/utils/request/cooper';
import { message } from 'antd';
import { intl } from 'di18n-react';
// import { collaborativeDocumentTypes } from '@/constants/cooper';

import CooperApi from '@/utils/request/api/CooperApi';

export function checkTeamFoldPermission(teamId, resourceId) {
  return get(
    `${CooperApi.PERMISSIONS}?spaceId=${teamId}&parentResourceId=${resourceId}`,
    {
      headers: {
        'X-App-Id': 2,
      },
    },
  ).then((res) => {
    return res
  });
}

export function fileComplete(ticket) {
  return post(
    CooperApi.FILE_COMPLETE.replace(':token', ticket.token).replace(
      ':fileId',
      ticket.fileId,
    ),
    {
      md5: ticket.md5,
      totalMd5: ticket.totalMd5,
    },
    {
      headers: {
        'X-App-Id': 2,
      },
    },
  );
}

export function checkFileSeconds(ticket) {
  return getAjaxAbort(
    `${CooperApi.CHECK_FILE.replace(':token', ticket.token).replace(
      ':fileId',
      ticket.fileId,
    )}?md5=${ticket.md5}&size=${ticket.size}`,
  );
}

// 校验分片秒传
export function sliceSeconds(ticket) {
  // 开始上传第一片认为文件开始上传---用来计算文件上传总数
  if (ticket.seqId === 0) {
    window.__OmegaEvent('ep_upload_sum_count_ck');
    if (window.uploadCount) {
      window.uploadCount.sum++;
    }
    console.log(window.uploadCount?.sum, intl.t('总数+1'));
  }
  return getAjaxAbort(
    `${CooperApi.CHECK_SLICE.replace(':token', ticket.token)
      .replace(':fileId', ticket.fileId)
      .replace(':seqId', ticket.seqId)}?md5=${ticket.md5}&size=${ticket.size}`,
  );
}

// 分片上传
export function sliceUpload(buffer) {
  const formData = new FormData();
  formData.append('file', buffer.stream);
  return postAjaxAbort(
    `${CooperApi.SLICE_UPLOAD.replace(':token', buffer.token)
      .replace(':fileId', buffer.fileId)
      .replace(':seqId', buffer.seqId)}?md5=${buffer.md5}&size=${buffer.size}`,
    { body: formData },
  );
}

export function duplicateName(filePaths, teamId, parentId, isDir) {
  return post(
    `${CooperApi.DUPLICATE_NAME.replace(':teamId', teamId).replace(
      ':parentId',
      parentId,
    )}?resourceType=${isDir ? 1 : 0}`,
    filePaths,
    {
      headers: {
        'X-App-Id': 2,
      },
    },
  );
}

export function createTask({ resources, totalFiles, teamId, parentId }) {
  return post(
    (String(teamId) === '0'
      ? CooperApi.CREATE_TASK_P
      : CooperApi.CREATE_TASK
    ).replace(':teamId', teamId),
    {
      parentResourceId: parentId,
      totalFiles,
      resources,
    },
    {
      headers: {
        'X-App-Id': 2,
      },
    },
  );
}

export function addTask({ taskPatch, taskId, teamId, parentId }) {
  return patch(
    (String(teamId) === '0' ? CooperApi.ADD_TASK_P : CooperApi.ADD_TASK)
      .replace(':teamId', teamId)
      .replace(':taskId', taskId)
      .replace(':parentId', parentId),
    { resources: taskPatch },
    {
      headers: {
        'X-App-Id': 2,
      },
    },
  );
}

export function taskToken({ taskId, teamId, parentId }) {
  return get(
    (String(teamId) === '0' ? CooperApi.TASK_TOKEN_P : CooperApi.TASK_TOKEN)
      .replace(':teamId', teamId)
      .replace(':parentId', parentId)
      .replace(':taskId', taskId),
    {
      headers: {
        'X-App-Id': 2,
      },
    },
  );
}

export function getFileIdFn({ teamId, parentId, taskId, pageNum, pageSize }) {
  return get(`${(String(teamId) === '0' ? CooperApi.FILE_IDS_P : CooperApi.FILE_IDS).replace(':teamId', teamId).replace(':parentId', parentId).replace(':taskId', taskId)}?page=${pageNum}&pageSize=${pageSize}`);
}

export function fileIds({ teamId, parentId, taskId, pageNum = 0, pageSize, allData = [] }) {
  return getFileIdFn({ teamId, parentId, taskId, pageNum, pageSize }).then((res) => {
    const { currentPage, totalCount, items } = res;

    allData.push(...items);
    if ((currentPage + 1) * pageSize >= totalCount) {
      return allData;
    }
    return fileIds({ teamId, parentId, taskId, pageNum: pageNum + 1, pageSize, allData });
  });
}

export function addRay() {
  return post(
    `${CooperApi.ADD_RAY}?somebody=${getUserNameFromCookie()}`,
    {},
    {
      headers: {
        'X-App-Id': 2,
      },
    },
  );
}

export const getImportParams = (params) => {
  const { origin, spaceId, parentId, docType, fileName, resourceId } = params
  // 文件来源，0 本地文件 1 cooper平台的文件
  if (origin === 1) {
    return post(CooperApi.GET_IMPORT_PARMS, { origin, resourceId }, {
      keepSilent: true,
    }).then((res) => {
      return res
    }).catch((err) => {
      if (err.errorCode === 1082) {
        message.error(intl.t('您没有权限！请联系空间管理员添加上传权限。'))
      } else {
        message.error(err.message)
      }
      return Promise.reject(err);
    })
  }
  return post(CooperApi.GET_IMPORT_PARMS, { origin, spaceId, parentId, docType, fileName }, {
    keepSilent: true,
  }).then((res) => {
    return res
  }).catch((err) => {
    if (err.errorCode === 1082) {
      message.error(intl.t('您没有权限！请联系空间管理员添加上传权限。'))
    } else {
      message.error(err.message)
    }
    return Promise.reject(err);
  })
}


export const createImportTask = (params) => {
  const { origin, spaceId, parentId, docType, fileName, fileId, taskId, resourceId } = params
  if (origin === 1) {
    // eslint-disable-next-line max-len
    return post(CooperApi.CREATE_IMPORT_TASK, { origin, spaceId, parentId, resourceId, docType, fileName, fileId, taskId })
  }
  return post(CooperApi.CREATE_IMPORT_TASK, { origin, spaceId, parentId, docType, fileName, fileId, taskId })
}

export const getImportProgress = (taskId, fileId) => {
  return get(CooperApi.GET_IMPORT_PROGRESS, {
    params: {
      taskId,
      fileId,
    },
  })
}
