/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-11-08 15:17:24
 * @LastEditTime: 2023-11-08 16:18:28
 * @Description: 头文件
 * @FilePath: /knowledgeforge/src/components/serviceComponents/Layout/HeaderWrap/index.js
 *
 */
import { useEffect, useState, useMemo, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '@/components/serviceComponents/Header';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import { getDkRoute } from '@/routes/route-dk';
import { isDesktopDC, inPhone } from '@/utils';

const isInPhone = inPhone();
const _HeaderConfigFromType = {
  Normal: {
    showProjectJump: true,
    showChangeKnowledge: true,
    showSearchContent: true,
    showCreateKnowledge: false,
    showIconList: true,
    showUserInfo: true,
    showSafeTip: true,
  },
  PersonalMenHu: {
    showProjectJump: true,
    showChangeKnowledge: false,
    showSearchContent: true,
    showCreateKnowledge: true,
    showIconList: true,
    showUserInfo: true,
    showSafeTip: false,
  },
  None: null,
};

// 路由中将header划分为3种，该组件中处理除路由外的组件差异性
const HeaderWrap = ({ forHeaderType }) => {
  const [configSet, setConfigSet] = useState([]);
  const { knowledgeId, shareId, pageId, templateId } = useContext(LayoutContext);

  const { pathname } = useLocation();


  // const type = routes.find((route) => {
  //   // 通过字符串模板匹配路由路径
  //   const path = route.path.replace(':knowledgeId', '\\d+'); // 将参数替换成正则表达式
  //   const regex = new RegExp(`^${path}$`); // 构建正则表达式

  //   return regex.test(pathname); // 测试是否匹配
  // })?.meta.headerType;


  const _HeaderConfigFinally = useMemo(() => {
    if (isDesktopDC) {
      _HeaderConfigFromType.PersonalMenHu.showProjectJump = false;
      _HeaderConfigFromType.PersonalMenHu.showProjectJump = false;
      _HeaderConfigFromType.PersonalMenHu.showUserInfo = false;
    }
    return _HeaderConfigFromType;
  }, [isDesktopDC]);

  const type = useMemo(() => {
    const frameKey = pathname.replace(knowledgeId, ':knowledgeId')
      .replace(shareId, ':shareId')
      .replace(pageId, ':pageId')
      .replace(templateId, ':templateId');
    return getDkRoute().routesDK.find((route) => route.path === frameKey)?.meta?.headerType;
  }, [knowledgeId, templateId, shareId]); // 编辑模板/新建模板,templateId从无到时重新判断type

  useEffect(() => {
    let finallyType = type;
    if (isInPhone) finallyType = 'None';
    setConfigSet(_HeaderConfigFinally[finallyType]);
    forHeaderType(finallyType);
  }, [_HeaderConfigFinally, type]);

  return <Header configSet={configSet}/>;
};

export default HeaderWrap;
