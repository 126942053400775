import classBind from 'classnames/bind';
import SelectAnyOption from '@/components/common/SelectAnyOption/index'
import NormalOptions from '@/components/common/SelectAnyOption/NormalOptions';
import UserItem from '@/components/common/ListOptions/UserItem';
import styles from './style.module.less';
import { Checkbox, Divider, Tooltip } from 'antd';
import { intl } from 'di18n-react';
import { getUserData } from '@/service/knowledge/global';
import { getUserUidFromCookie, getTenantIdFromCookie } from '@/utils/index';
import { useState } from 'react';
import Tag from '@/components/Tag';
import ReactDOM from 'react-dom';

const cx = classBind.bind(styles);

const searchOwnerFilter = (data, callback) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [initValue, setInitValue] = useState('Initial value');

  const handleClick = () => {
    ReactDOM.flushSync(() => {
      setInitValue('Updated value');
    });
  };
  const changeDisObj = (vObj) => {
    if (!vObj.memberId) {
      return false;
    }
    window.__OmegaEvent('ep_ownerfilter_choose_ck');
    let selectValue = {
      value: vObj?.ldap,
      label: vObj?.title?.replace(/§§/g, ''),
      id: vObj?.memberId,
      checked: true,
      tenantId: `${vObj?.orgId}`,
      showOuter: vObj?.showOuter,
    };
    console.log(selectValue, data);
    let filterItem = data.filter((item) => item.id === selectValue?.id && item.tenantId === selectValue?.tenantId)
    if (filterItem.length === 0) {
      data.push(selectValue)
      callback(data);
    } else if (!filterItem[0].checked) {
      data.map((item) => {
        if (item.id === selectValue.id && item.tenantId === selectValue.tenantId) {
          item.checked = true
        }
      })
      callback(data);
    }
  }
  const selectListItemChange = (e) => {
    const { checked, value } = e.target
    data.map((item) => { item.id === value && (item.checked = checked) })
    callback(data)
  }
  const selectIsMe = (e) => {
    const { checked, value } = e.target
    if (checked) {
      const selectValue = {
        value: '',
        label: '',
        id: value,
        checked: true,
        tenantId: getTenantIdFromCookie(),
        showOuter: false,
      }
      data.push(selectValue)
      callback(data)
    } else {
      data.map((item) => {
        if (item.id === value && item.tenantId === getTenantIdFromCookie()) {
          item.checked = false
        }
      })
      callback(data)
    }
  }
  const resentFilter = () => {
    data.map((item) => { item.checked = false })
    callback(data)
  }
  const removeSelectItem = (handleItem) => {
    data.splice(data.findIndex((item) => item.id === handleItem.id && item.tenantId === handleItem.tenantId), 1)
    if (handleItem.checked) {
      callback(data)
    } else {
      handleClick()
      callback(data, false)
      setInitValue('Initial value')
    }
  }
  const filterToolitip = () => {
    return <div>
      <p>{intl.t('点击重置，筛选条件变为”不限归属“')}</p>
    </div>
  }
  return <div>
    <ul
      className={cx('custom-filter-dropdown')}
      key={initValue}>
      <li
        className={cx({
          'filter-resent': true,
          'filter-action': data.filter((item) => { return item.checked }).length !== 0,
        })}
        onClick={() => {
          window.__OmegaEvent('ep_ownerfilter_reset_ck');
          data.filter((item) => { return item.checked }).length !== 0 && resentFilter();
        }}
        >
        {intl.t('重置')}
      </li>
      <Divider className={cx('filter-divider')} />
      <li className={cx('filter-tip')}>
        {intl.t('所有者')}
        <Tooltip
          title={filterToolitip()}
          overlayClassName="filter-tooltip">
          <i className={cx('dk-iconfont', 'dk-icon-a-tishi2', 'icon-tip')} />
        </Tooltip>
      </li>
      <div className={cx('scroll-content')}>
        <li className={cx('is-flow-me')}>
          <Checkbox
            // eslint-disable-next-line max-len
            checked={data.filter((item) => item.id === getUserUidFromCookie() && item.checked && item.tenantId === getTenantIdFromCookie()).length > 0}
            value={getUserUidFromCookie()}
            onChange={(e) => {
              window.__OmegaEvent('ep_ownerfilter_byme_ck');
              selectIsMe(e);
            }}> {intl.t('归我所有')} </Checkbox>
        </li>
        {
        data && data.map((item) => {
          if (item.id !== getUserUidFromCookie()) {
            return <li
              key={`${item.value}-${item.tenantId}`}
              className={cx('filter-item')}
          >
              <Checkbox
                checked={item.checked}
                onChange={(e) => { selectListItemChange(e) }}
                value={item.id}>
                <span className={cx('filter-item-text')}>
                  <span className={cx('filter-item-text-name')}> {item.label}</span>
                  {
                    item?.showOuter && <Tag
                      type='out-yellow'
                      text={intl.t('外部')}
                    />
                  }
                </span>
              </Checkbox>
              <span className={cx('shanchu-btn')}><i
                className={cx('dk-iconfont dk-icon-shanchu4')}
                onClick={() => {
                  window.__OmegaEvent('ep_ownerfilter_delect_ck');
                  removeSelectItem(item)
                }}/></span>
            </li>
          }
        })
      }
      </div>
      <SelectAnyOption
        placeholder={intl.t('请输入所有者名称')}
        onChoose={(vObj) => {
          vObj && changeDisObj(vObj)
        }}
        showRecent={false}
        OptionRender={({ searchValue, onChooseCallback }) => {
          return (
            <NormalOptions
              itemRequest={getUserData}
              itemRender={(v) => <UserItem optionData={v} />}
              id={'owner-select'}
              searchValue={searchValue}
              onChooseCallback={onChooseCallback}
            />
          );
        }}
      />
    </ul>
  </div>
}

export default searchOwnerFilter;
