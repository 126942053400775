import CryptoJS from 'crypto-js';
import encHex from 'crypto-js/enc-hex';

export function createUploadQuery(hash, size) {
  return {
    extendHeaders: `Content-Hash:${hash}|Content-Size:${size}`,
  };
}

/**
  * 用于计算文件的hash值，包括sha256值和md5值
*/
export function getFileHash(file) {
  function hashFileInternal(file, alog) {
    const chunkSize = 1 * 1024 * 1024;
    let promise = Promise.resolve();
    // 保证update的顺序是文件正确的顺序
    for (let index = 0; index < file.size; index += chunkSize) {
      promise = promise.then(() => hashBlob(file.slice(index, index + chunkSize)));
    }

    function hashBlob(blob) { // 此处流式读取文件，增量计算md5，解决了大量占用内存的问题
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = ({ target }) => {
          const wordArray = CryptoJS.lib.WordArray.create(target.result);
          // 增量更新计算结果
          alog.update(wordArray);
          resolve();
        };
        reader.readAsArrayBuffer(blob);
      });
    }

    return promise.then(() => encHex.stringify(alog.finalize()));
  }

  return Promise.all([hashFileInternal(file, CryptoJS.algo.SHA256.create()),
    hashFileInternal(file, CryptoJS.algo.MD5.create())])
    .then(([sha256, md5]) => ({
      sha256,
      md5,
    }));
}


export function getChunkHash(chunk) {
  return CryptoJS.SHA256(chunk).toString();
}
