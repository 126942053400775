/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-06-01 14:46:22
 * @LastEditTime: 2023-06-01 14:47:03
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/CooperOperation/ShareCollab/index.js
 *
 */
import { intl } from 'di18n-react';
import React from 'react';

import PrimaryModal from '@/components/common/PrimaryModal';
import DocsShareGlobal from './docs-share-global';
import mountAnywhere from '@/utils/mountAnywhere';

class ShareCollab extends React.Component {
  static defaultProps = {
    id: 0,
    info: {},
    onClose: () => {},
  };

  render() {
    const { id, info, onClose } = this.props;
    return (
      <PrimaryModal
        title={intl.t('分享')}
        wrapClassName='share-collab'
        width={605}
        footer={null}
        onCancel={onClose}
      >
        <DocsShareGlobal
          id={id}
          info={info}
          onCancel={onClose}
        />
      </PrimaryModal>
    );
  }
}

export default function shareCollab(id, info) {
  const Comp = <ShareCollab
    id={id}
    info={info} />;
  mountAnywhere(Comp);
}
