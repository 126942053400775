import { intl } from 'di18n-react';
import React, { useState } from 'react';
import classBind from 'classnames/bind';
import { highlight } from '@/utils';
import Tag from '@/components/Tag';
import { gotoDk } from '@/utils/goto';
import styles from './style.module.less';
import { Tooltip, message } from 'antd';
import { starAction } from '@/service/knowledge/star';

const cx = classBind.bind(styles);

// TODO: 折行时hover弹tooltip;
const FileContent = ({
  iconImage = 'https://img-ys011.didistatic.com/static/cooper_cn/dkpage.png',
  resourceName,
  description,
  children,
  metisName,
  metisId,
  onClickDkCallBack,
  resourceOwner,
  publishDesc,
  tags,
  isLarge = false,
  isInTable = false,
  hasStar = false,
  record,
  callBack,
}) => {
  const [visible, setVisible] = useState(false);
  const jumpToDk = (e) => {
    e.stopPropagation();
    onClickDkCallBack && onClickDkCallBack();
    gotoDk(metisId);
  }
  const cancelStar = async (e) => {
    e.stopPropagation();
    // eslint-disable-next-line camelcase
    const { star_type, resource_id, star_info } = record
    const type = 0
    const listInfo = {
      resourceId: resource_id,
      starType: star_type, // 收藏类型  RESOURCE普通页面及资源; PAGE_SHARE页面分享; DK_SHARE知识库分享【必填】
      starInfo: star_info,
      appId: 4,
    }
    let data = await starAction(type, [listInfo])
    if (data) {
      message.success('取消收藏成功')
      if (callBack) callBack()
    }
  }
  return <div
    className={cx('file-content-wrap', {
      'file-content-wrap-large': isLarge,
      'no-padding-left': isInTable,
    })}
  >
    <img
      className={cx('file-icon')}
      src={iconImage}
    />
    <div className={cx('file-text-main')} >
      <div className={cx('title')}>
        {resourceName && <p
          className={cx('title-text')}
          title={resourceName?.replace(/§§/g, '')}>
          {highlight(resourceName || '')}
        </p>}

        {
          tags?.length > 0 && <div className={cx('tag-list')}>
            {
              tags.map((tag, index) => {
                return <React.Fragment key={tag}>
                  <Tooltip
                    overlayClassName={cx('dk-ant-tooltip__reset')}
                    visible={visible}
                    title={tag === intl.t('访客链接') ? intl.t('通过开启知识库/页面分享方式获得的链接') : null}
                  >
                    <Tag
                      type='blue-primary'
                      text={tag}
                      onMouseOver={() => {
                        setVisible(true);
                      }}
                      onMouseOut={() => {
                        setVisible(false);
                      }}
                    />
                  </Tooltip>
                </React.Fragment>
              })
            }
          </div>
        }
        {
          hasStar
          && <Tooltip
            title={intl.t('取消收藏')}
            placement='top'>
            <i
              className={
                cx('dk-iconfont', 'dk-icon-yishoucang1', 'icon-star')
              }
              onClick={(e) => cancelStar(e)}
            />
          </Tooltip>
        }
      </div>
      {description && <p
        className={cx('description')}
        title={description?.replace(/§§/g, '')}>{highlight(description || '')}</p>}
      {
        children
          ? <div className={cx('addition')}>
            {children}
          </div>
          : <div className={cx('addition')}>
            {metisName
              && <div className={cx('metis-name')}>
                <span
                  className={cx('metis-span')}
                  title={metisName}
                  onClick={jumpToDk}
                >
                  <i className={cx('dk-iconfont', 'dk-icon-zhishikuzuixin', 'dk-book')} />
                  {metisName}
                </span>
              </div>}
            {resourceOwner && <div
              title={resourceOwner}
              className={cx('owner')}>{intl.t('所有者')}:{resourceOwner}</div>}
            {publishDesc && <div
              className={cx('publish')}
              title={publishDesc}>{publishDesc}</div>}
          </div>
      }
    </div>
  </div>
};

export default FileContent;
