import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import mountAnywhere from '@/utils/mountAnywhere';
import { intl } from 'di18n-react';
import { getWithSuccess } from '@/utils/request';
import styles from './index.module.less';
import Loading from '@/components/SpinRender';
import api from '@/utils/request/api/DkApi';

const HelpConfirm = ({ type, onClose, onNetworkTest }) => {
  const titleText = {
    origin: intl.t('Cooper服务检测'),
    detecting: intl.t('Cooper服务检测中'),
    normal: intl.t('Cooper服务检测完成'),
    useVpn: intl.t('Cooper服务检测完成'),
    dataError: intl.t('Cooper服务检测完成'),
  };

  const contentText = {
    origin: intl.t('该检测主要应用于评估能否正常使用Cooper服务，检测结果将助力快速定位Cooper页面访问问题。'),
    detecting: '',
    normal: <div><span className={styles['fond-bond']}>{intl.t('检测结果：')}</span >{intl.t('当前网络及服务')}<span className={styles['green-light']}>{intl.t('正常')}</span>{intl.t('。')}</div>,
    useVpn: <div><span className={styles['fond-bond']}>{intl.t('检测结果：')}</span >{intl.t('你目前正在连接公司VPN，请检查VPN连接情况。若连接正常仍无法访问，建议断开VPN，Cooper支持在公司可信设备下进行访问。')}</div>,
    dataError: <div><span className={styles['fond-bond']}>{intl.t('检测结果：')}</span ><span className={styles['red-light']}>
      {intl.t('当前网络可能存在异常，')}</span>{intl.t('请切换至优质网络后重新刷新页面。若切换网络后页面仍无法加载内容，请点击加入')}
      <a href='https://im.xiaojukeji.com/channel?uid=179964&token=3bdb88d2caad6b9ef23b726c9c11a837&id=146847933529642240' target='_blank'>{intl.t('【Cooper反馈群】')}</a>
      {intl.t('进行咨询。')}</div>,
  };

  const threeCommonStatus = (
    <>
      <Button
        className={styles['lite-notice-btn-gray']}
        onClick={() => {
          window.__OmegaEvent(
            'ep_service_restart_ck',
            '检测服务可用性-重新检测',
            {
              source: 'cooper',
            },
          );
          onNetworkTest()
        }}>
        {intl.t('重新检测')}
      </Button>
      <Button className={styles['lite-notice-btn']} type="primary" onClick={() => onClose()}>
        {intl.t('我知道了')}
      </Button>
    </>
  );

  const footerButtons = {
    origin: (
      <>
        <Button className={styles['lite-notice-btn-gray']} onClick={() => onClose()}>
          {intl.t('取消')}
        </Button>
        <Button
          className={styles['lite-notice-btn']}
          type="primary"
          onClick={() => {
            window.__OmegaEvent(
              'ep_service_start_ck',
              '检测服务可用性-开始检测',
              {
                source: 'cooper',
              },
            );
            onNetworkTest()
          }
        }>
          {intl.t('开始检测')}
        </Button>
      </>
    ),
    detecting: (
      <Button
        className={styles['lite-notice-btn']}
        type="primary"
        onClick={
          () => {
            window.__OmegaEvent(
              'ep_service_stop_ck',
              '检测服务可用性-结束检测',
              {
                source: 'cooper',
              },
            );
            onClose('pause');
          }}>
        {intl.t('停止检测')}
      </Button>
    ),
    normal: threeCommonStatus,
    useVpn: threeCommonStatus,
    dataError: threeCommonStatus,
  };

  return (
    <Modal
      centered
      width={460}
      className={styles['help-network']}
      visible={true}
      maskClosable={false}
      footer={null}
      closable={true}
      onCancel={() => onClose()}
      zIndex={999999}
      transitionName=''
      maskTransitionName=''
    >
      <div className={styles['help-net-title']}>
        {titleText[type]}
      </div>

      <div className={styles['lite-notice-content']}>
        {type === 'detecting' ? (
          <Loading loading={true} />
        ) : (
          <div className={styles['lite-notice-title-content-text']}>
            {contentText[type]}
          </div>
        )}
      </div>

      <div className={styles['lite-notice-footer']}>
        {footerButtons[type]}
      </div>
    </Modal>
  );
};

let previousNotice = null;

const closePreviousNotice = () => {
  if (previousNotice) {
    previousNotice();
    previousNotice = null;
  }
};

async function network() {
  try {
    const res = await getWithSuccess(api.HELP_NETWORK);

    if (!res.success) return 'dataError';
    return res.useDiDiVPN ? 'useVpn' : 'normal';
  } catch (err) {
    return 'dataError'
  }
}

const helpConfirm = (type) => {
  closePreviousNotice();

  const handleNetworkTest = async () => {
    helpConfirm('detecting');
    try {
      const resultType = await network();
      helpConfirm(resultType);
    } catch (err) {
      return 'dataError'
    }
  };

  const handleClose = () => {
    closePreviousNotice();
  };

  const Comp = (
    <HelpConfirm
      onClose={handleClose}
      onNetworkTest={handleNetworkTest}
      type={type}
    />
  );

  const close = mountAnywhere(Comp);
  previousNotice = close;
};

export default helpConfirm;
