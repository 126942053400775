/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-05-26 17:01:40
 * @LastEditTime: 2024-04-25 10:28:52
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/CooperOperation/DownloadFile/index.js
 *
 */
import { dealDownloadRes } from '@/utils/file';
import handleHttpError from '@/utils/handleHttpError';
import api from '@/utils/request/api/CooperApi';
import { post } from '@/utils/request/cooper';
import { message } from 'antd';
import NoPermModal from '@/components/NoPermissionModel'
import { intl } from 'di18n-react';

/**
 *
 * @param {*} type 下载类型，有几种下载链接
 * @param {*} ids 文件的id
 * @param {*} shareId 分享给我的shareId
 * @param {*} spaceId 空间ID，规定个人空间的为0， 团队空间的为URL后面的
 */

export async function doDownload(type, ids, shareId, spaceId, region, parentLocationType) {
  let url = api.API_DOWNLOADS.replace(':teamId', spaceId);
  if (type === 'direct' || parentLocationType === 'ShareWithMe') url = api.API_DIRECT_DOWNLOAD.replace(':directId', shareId);
  else if (type === 'link') url = api.API_LINK_DOWNLOAD.replace(':linkId', shareId);
  url += `?region=${region}`;
  if (ids.length > 1) {
    message.info(intl.t('打包中...'), 0);
  }
  post(url, ids, { keepSilent: true }).then((res) => {
    message.loading(intl.t('下载中，内容如包含敏感信息请及时删除'), 3)
    dealDownloadRes(res);
    if (ids.length > 1) {
      message.destroy();
      message.loading(intl.t('打包成功并开始下载，内容如包含敏感信息请及时删除'), 3);
    }
  }).catch((e) => {
    message.destroy();
    if (e.errorCode === 301082) {
      NoPermModal('download')
    } else if (e.errorType === 'TargetDirOnlyCooperationErr') {
      NoPermModal('hasAllDocs')
    } else {
      message.destroy();
      message.error(e.errorMessage || intl.t('打包失败，请重新下载'))
    }
  });
}

function downloadFile(files, region = 0, shareId = 0, spaceId = 0) {
  try {
    let filesCoopCount = 0;
    for (let i = 0, l = files.length; i < l; ++i) {
      // console.log(checkPermis('download', files[i].permission));
      // if (!checkPermis('download', files[i].permission)) {
      //   NoPermModal('download')
      //   return;
      // }
      // 2 是 协作文档 3是氢文档 6是流程图
      if (files[i].type === 2 || files[i].type === 3 || files[i].type === 6) {
        filesCoopCount++;
      }
    }
    // TODO 补充弹窗样式及链接
    if (filesCoopCount === files.length) {
      NoPermModal('hasAllDocs')
      return;
    }
    if (filesCoopCount > 0) {
      NoPermModal('hasSomeDocs', () => download(files, region, shareId, spaceId))
      return;
    }
    download(files, region, shareId, spaceId);
  } catch (e) {
    handleHttpError(e);
  }
}

const download = (files, region, shareId, spaceId) => {
  const ids = files.map((f) => f.id);
  if (ids.length > 0) doDownload('file', ids, shareId, spaceId, region);
};

export default downloadFile;
