import api from '@/utils/request/api/DkApi';
import { get } from '@/utils/request';
import { encodeURIParamFn } from '@/utils';


export async function getKnowledgeDetail(knowledgeId) {
  let startTime = new Date().getTime();
  const res = await get(api.GET_DK_DETAIL.replace(':knowledgeId', knowledgeId), { keepSilent: true });
  let endTime = new Date().getTime();
  window.__OmegaEvent('tech_ep_network_load_time', '知识库核心接口加载时长', {
    source: 'metisdetail',
    time: endTime - startTime,
  })
  return res;
}


export async function getKnowledgeMemberList(knowledgeId, par) {
 
  const res = await get(`${api.GET_DK_MEMBERS.replace(':knowledgeId', knowledgeId)}${encodeURIParamFn(par)}`);
  return res;
}

export async function getKnowledgeGroupMemberList(knowledgeId, params) {
  const res = await get(api.GET_DK_GROUP_MEMBERS.replace(':knowledgeId', knowledgeId), { params });
  return res;
}

export async function getKnowledgeSingleMemberList(knowledgeId, params) {
  const res = await get(api.GET_DK_SINGLE_MEMBERS.replace(':knowledgeId', knowledgeId), { params });
  return res;
}

export async function getDkOwnerInfo(metisSpaceId) {
  const res = await get(api.DK_OWNER_INFO.replace(':metisSpaceId', metisSpaceId));
  return res;
}
