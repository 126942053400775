/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-05-26 17:01:30
 * @LastEditTime: 2023-05-29 18:36:13
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/CooperOperation/RenameFile/index.js
 *
 */
import { RENAME_FILE } from '../RenameTeam/constants';
import rename from '../RenameTeam/rename';

function renameTeam(fileType, id, oldName, doneCallback) {
  rename(RENAME_FILE, id, oldName, doneCallback, fileType);
}

export default renameTeam;
