import { intl } from 'di18n-react';
import classBind from 'classnames/bind';
import { useEffect, useRef, useState } from 'react';
import { Input } from 'antd';
import { DocType } from '@/constants/page';
import styles from './style.module.less';

const cx = classBind.bind(styles);
const { TextArea } = Input;

const RenameInput = ({ defaultValue, pressEnter, changePageName, type }) => {
  const [inputValue, setInputValue] = useState(
    type === DocType.DK_FILE
      ? defaultValue.split('.').slice(0, -1)
      : defaultValue,
  );
  const renameRef = useRef(null);
  useEffect(() => {
    // safari无法focus，需要用数据驱动
    renameRef.current?.focus({
      cursor: 'all',
    });
  }, []);

  const getPreviewName = (name) => {
    const arr = defaultValue.split('.');
    return `${name}.${arr[arr.length - 1]}`;
  };

  const onChangeName = (e) => {
    const { value } = e.target;
    setInputValue(value);
    let name = value;
    if (type === DocType.DK_FILE) {
      name = getPreviewName(name);
    }
    changePageName(name);
  };

  const onPressEnter = () => {
    let name = inputValue;
    if (type === DocType.DK_FILE) {
      name = getPreviewName(name);
    }
    pressEnter(name);
    setInputValue(name);
  };

  return (
    <div className={cx('name-input-content')}>
      <Input
        autoFocus
        allowClear
        maxLength={200}
        className={cx('name-input')}
        bordered={false}
        placeholder={intl.t('请输入页面名')}
        value={inputValue}
        onChange={onChangeName}
        onPressEnter={onPressEnter}
        ref={renameRef}
      />
    </div>
  );
};

export default RenameInput;
