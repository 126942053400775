import { get, post } from '@/utils/request';
import { PAGE_SIZE } from '@/constants';
import api from '@/utils/request/api/DkApi';

export async function getTreeById(spaceId, resourceId) {
  let res = await get(api.GET_FILE_TREE.replace(':spaceId', spaceId), {
    params: {
      resourceId,
    },
    headers: {
      'X-App-Id': 2,
    },
  });
  return res
}

export async function importFile(knowledgeId, params) {
  let res = await post(api.IMPORT_FROM_COOPER.replace(':knowledgeId', knowledgeId), params);
  return res
}

export async function getFileList(teamId, type, id) {
  let res = await get(api.GET_FILE_LIST.replace(':teamId', teamId), {
    params: {
      pageNum: 0,
      id,
      type,
      pageSize: 1000,
    },
    headers: {
      'X-App-Id': 2,
    },
  });
  return res
}

export async function getSpaceList() {
  let res = await get(api.GET_SPACE_LIST, {
    headers: {
      'X-App-Id': 2,
    },
  });
  // 洗数据
  res.map((v) => {
    v.resourceName = v.name
    v.resourceId = v.id
  })
  return res
}

export async function fileSearch({ key, scope }) {
  let res = await get(api.SEARCH_DIR_AND_FILE, {
    params: {
      pageSize: PAGE_SIZE,
      pageNum: 0,
      key,
      spaceId: scope,
    },
    headers: {
      'X-App-Id': 2,
    },
  });
  return res
}


