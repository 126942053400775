/* eslint-disable */
import { intl } from 'di18n-react';
import React, { useState, useRef, useEffect, Children } from 'react';
import { Select, Input, Tooltip, Radio, Space } from 'antd';
import UserSelect from '@/components/UserSelect';
import classBind from 'classnames/bind';
import useUpdate from '@/hooks/useUpdate';
import { searchTags } from '@/service/knowledge/search';
import KnowledgeSearch from '@/components/serviceComponents/KnowledgeSearch';
import { getUserNameFromCookie, highlight } from '@/utils';
import styles from './style.module.less';

const cx = classBind.bind(styles);
const ldap = getUserNameFromCookie();
const configMap = () => {
  return {
    '1d': '一天',
    '7d': '一周',
    '30d': '一月',
    '180d': '半年',
    '365d': '一年'
  }
};
const searchConfig = {
  scope: [
    {
      label: '全部',
      value: '',
    },
  ],
  time: [
    {
      label: '全部',
      value: '',
    },
    {
      label: '一天内',
      value: '1d',
    },
    {
      label: '一周内',
      value: '7d',
    },
    {
      label: '一月内',
      value: '30d',
    },
    {
      label: '半年内',
      value: '180d',
    },
    {
      label: '一年内',
      value: '365d',
    },
  ],
  owner: [
    {
      label: 'header-高级搜索-过滤-全部',
      value: '',
    },
    {
      label: 'header-高级搜索-过滤-归我所有',
      value: ldap,
    },
  ],
  fileType: [
    {
      label: 'header-高级搜索-过滤-全部',
      value: '0',
    },
    {
      label: 'header-高级搜索-过滤-pdf',
      value: '4',
    },
    {
      label: 'header-高级搜索-过滤-word',
      value: '1',
    },
    {
      label: 'header-高级搜索-过滤-excel',
      value: '2',
    },
    {
      label: 'header-高级搜索-过滤-ppt',
      value: '3',
    },
    {
      label: 'header-高级搜索-过滤-视频',
      value: '5',
    },
    {
      label: 'header-高级搜索-过滤-其他',
      value: '99',
    },
  ]
};

const Component = (props) => {
  const [visible, setVisible] = useState(false);
  const [vaule, setValue] = useState(props.value);
  const [label, setlabel] = useState(props.name || intl.t('header-高级搜索-过滤-选择知识库'));
  const handleDKChange = (v) => {
    window.__OmegaEvent('ep_dkpc_dk_advancedsearch_choosedk_ck');
    setValue(v.value);
    setlabel(v.label);
    setVisible(false);
    props.onChange(v);
  };

  useEffect(() => {
    if (props.visible) {
      setVisible(true)
    }
  }, [props.visible]);

  return (
    <Tooltip
      overlayClassName={`${cx('switch-search-popover')} ${cx('dk-switch-search-popver')}`}
      placement="bottomRight"
      trigger="click"
      visible={visible}
      destroyTooltipOnHide={true}
      getPopupContainer={() => document.querySelector('.search-modal-wrap')}
      onVisibleChange={(v) => { !v && setVisible(false); }}
      title={
        <KnowledgeSearch
          value={vaule}
          onChange={handleDKChange}
          height={200}
          defaultSelected={props.defaultSelected}
          api={props.api}
        />
      }
    >
      <div
        className={cx('knowledge-name')}
        onClick={() => setVisible(!visible)}
      >
        {label.length > 9 ? <Tooltip title={label}>
          <span>{label}</span>
        </Tooltip> : <span>{label}</span>}
        <i
          className={`${cx('dk-iconfont', 'dk-icon-shaixuan', 'shaixuanIcon')} ${cx(
            'moreDk'
          )} ${visible ? cx('isOpen') : ''}`

          }
        />
      </div>
    </Tooltip>
  );
};

const Filters = (props) => {
  const filter = props.value || {};
  const { scope = '', owner = '', time = '', name = '', tags, fileType = '0' } = filter
  const [value, setValue] = useState({ scope, owner, time, name, tags, fileType });
  const [customOwner, setCustomOwner] = useState();
  const [customScope, setCustomScope] = useState(scope);
  const [dkSelect, setDkSelect] = useState(false);

  const updateValue = (v, t) => {
    setValue({ ...value, [t]: v })
  }

  const updateScope = (v) => {
    if (v.target.value === '') {
      setValue({ ...value, scope: '' })
    } else {
      if (customScope) {
        setValue({ ...value, scope: customScope });
      } else {
        setDkSelect(true)
      }
    }
  }

  const handleRequest = async (key) => {
    let res
    if (props?.api?.tagList) {
      res = await props.api.tagList(key)
    } else {
      let data = await searchTags({ key })
      res = data.items
    }
    return res
  }

  const handleCustomScope = (v) => {
    if (v.value) {
      setCustomScope(v.value);
      setValue({ ...value, scope: v.value, name: v.label });
    }
  }

  useUpdate(() => {
    const newValue = {
      ...props.value,
      ...value,
      owner: value.owner === 'owner' ? customOwner : value.owner,
    }
    props.onChange(newValue);
  }, [value]);
  useUpdate(() => {
    window.__OmegaEvent('ep_dkpc_dk_advancedsearch_chooseowner_ck');
    if (!customOwner) {
      setValue({ ...value, owner: '' });
    } else {
      setValue({ ...value, owner: 'owner' });
    }
  }, [customOwner]);

  return (
    <div className={cx('search-page-filter')} >
      <div className={cx('filter-item')}>
        <p>{intl.t("header-高级搜索-过滤-范围")}</p>
        <Radio.Group className={cx('dk-ant-radio_reset')} onChange={updateScope} value={value.scope === '' ? '' : 'scope'}>
          <Radio value=''>{intl.t("header-高级搜索-过滤-全部")}</Radio>
          <Radio value='scope'>
            <Component
              value={customScope}
              name={name}
              visible={dkSelect}
              onChange={handleCustomScope}
              defaultSelected={value.scope !== ''}
              api={props.api}
            />
          </Radio>
        </Radio.Group>
      </div>

      <div className={cx('filter-item')}>
        <p>{intl.t("header-高级搜索-过滤-所有者")}</p>
        <Radio.Group
          className={cx('dk-ant-radio_reset')}
          onChange={(v) => {
            updateValue(v.target.value, 'owner');
            v.target.value && window.__OmegaEvent('ep_dkpc_dk_advancedsearch_chooseowner_ck');
          }} value={value.owner}>
          {searchConfig.owner.map((v, i) => <Radio key={i} value={v.value}>{intl.t(v.label)}</Radio>)}
          <UserSelect
            value={value.owner === 'owner' ? customOwner : undefined}
            onChange={(v) => setCustomOwner(v)}
            itemRequest={props?.api?.userList}
            allowClear
            valueSelectKey='ldap'
            styles={{ width: 190, margin: '0px 12px' }}
            getPopupContainer={() => document.querySelector('.search-modal-wrap')}
          />
        </Radio.Group>
      </div>
      <div className={cx('filter-item')}>
        <p>{intl.t('header-高级搜索-过滤-标签')}</p>
        <UserSelect
          value={value.tags && [value.tags]}
          mode="multiple"
          placeholder={intl.t("header-高级搜索-过滤-请输入标签")}
          itemRender={(data) => (<p title={data?.name?.replace(/§§/g, '')}>{highlight(data?.name || '')}</p>)}
          itemRequest={handleRequest}
          getlabelKey={(i) => i.name?.replace(/§§/g, '')}
          onChange={(v = []) => { updateValue(v[1] || v[0], 'tags') }}
          onSearch={() => {
            if (value.tags) {
              updateValue(undefined, 'tags')
            }
          }}
          styles={{ width: 190, margin: '0px 12px' }}
          getPopupContainer={() => document.querySelector('.search-modal-wrap')}
        />
      </div>
      <div className={cx('filter-item')} id="filter-fileType">
        <p>{intl.t("header-高级搜索-过滤-文件类型")}</p>
        <Radio.Group
          className={cx('dk-ant-radio_reset')}
          onChange={(v) => {
            updateValue(v.target.value, 'fileType');
          }} value={value.fileType}>
          {searchConfig.fileType.map((v, i) => <Radio key={i} value={v.value}>{intl.t(v.label)}</Radio>)}
        </Radio.Group>
      </div>
      <div className={cx('filter-item')}>
        <p>{intl.t('header-高级搜索-过滤-浏览时间')}</p>
        <Radio.Group
          className={cx('dk-ant-radio_reset')}
          onChange={(v) => {
            updateValue(v.target.value, 'time');
            v.target.value && window.__OmegaEvent('ep_dkpc_dk_advancedsearch_chooserecentview_ck', '', {
              recentview: configMap()[v.target.value]
            });
          }} value={value.time}>
          {searchConfig.time.map((v, i) => <Radio key={i} value={v.value}>{intl.t(v.label)}</Radio>)}
        </Radio.Group>
      </div>
    </div>
  );
};

export default Filters;
