/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-05-22 16:54:00
 * @LastEditTime: 2023-10-11 11:22:31
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/common/FileEllipsis/index.js
 *
 */
import { Component } from 'react';
import { Tooltip } from 'antd';
import Star from './star.js';
import classBind from 'classnames/bind';
import styles from './style.module.less';
import { intl } from 'di18n-react';
import Tag from '@/components/Tag';

const cx = classBind.bind(styles);

// 处理搜索关键词高亮
function handleSearchHit(value) {
  // 高亮关键词
  const isHit = value.match(/§§(.*?)§§/g);
  if (isHit === null) {
    return value;
  }
  const hitWords = isHit.map((item) => item.replace(/§/g, ''));
  const replacedKeyWord = value.split('§').map((item, i) => {
    if (hitWords.includes(item)) {
      return <span
        className='emphasis'
        key={i}>{item}</span>;
    }
    return item;
  });

  return replacedKeyWord;
}

class FileEllipsis extends Component {
  constructor(props) {
    super(props);
    this.state = { showTip: false };

    this.outerRef = null;
    this.innerRef = null;

    this.shouldCalc = false;
  }

  componentDidMount() {
    let addWidth = 0
    this.props.isShowStar ? addWidth += 32 : addWidth
    this.props.hasVisitorTag ? addWidth += 82 : addWidth
    const showTip = this.innerRef.clientWidth + addWidth + 14 >= this.outerRef.clientWidth;
    this.setState({ showTip });
  }

  componentWillReceiveProps() {
    this.shouldCalc = true;
  }

  componentDidUpdate() {
    if (this.shouldCalc) {
      this.shouldCalc = false;
      let addWidth = 0
      this.props.isShowStar ? addWidth += 32 : addWidth
      this.props.hasVisitorTag ? addWidth += 82 : addWidth
      const showTip = this.innerRef.clientWidth + addWidth + 14 >= this.outerRef.clientWidth;
      this.setState({ showTip });
    }
  }

  render() {
    // isSearch对应搜索， isShowStar对应文件名旁的收藏，record, doneCallback, originFileType为收藏API的传参
    const { value, isSearch = false, isShowStar = false, record, doneCallback, originFileType, department, hasVisitorTag = false, isOuter } = this.props;

    const { showTip } = this.state;
    // 处理搜索结果当中的特殊符号
    const tipValue = isSearch ? value.replace(/§/g, '') : value;
    const renderValue = isSearch ? handleSearchHit(value) : value;

    return (
      <div
        className={cx({ 'file-ellipsis': true, 'not-click': this?.props?.notClick })}
        ref={(element) => this.outerRef = element} // eslint-disable-line no-return-assign
      >
        <Tooltip
          overlayStyle={{ wordBreak: 'break-all' }}
          title={showTip ? tipValue : ''}
          placement='topLeft' >
          <span
            className={cx('file-ellipsis-inner-span')}
            ref={(element) => this.innerRef = element} // eslint-disable-line no-return-assign
          >
            {renderValue}
          </span>
        </Tooltip>

        {
          hasVisitorTag && <span className={cx('link-tag')}>
            {intl.t('访客链接')}
          </span>
        }
        {
          (!isOuter && record?.relationTypeTags?.includes('IN_OUT')) && <Tag
            type='out-yellow'
            // overlayClassName={'public-tag'}
            text={intl.t('外部')}
          />
        }
        {
          isShowStar && Star(record, doneCallback, originFileType)
        }
        {
          department && record.hasOwnProperty('tags') && record.tags.includes('SPACE_DEPART') && <img
            className={cx('department-src')}
            src='http://img-ys011.didistatic.com/static/cooper_cn/do1_kfJrUTPivjaHXd1QLgmh' />
        }
      </div>
    );
  }
}

export default FileEllipsis;
