/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-06-01 14:46:22
 * @LastEditTime: 2023-06-20 16:58:10
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/CooperOperation/ShareCollab/docs-share-global/actions.js
 *
 */
import { intl } from 'di18n-react';
import { post, put } from '@/utils/request/cooper';
import api from '@/utils/request/api/CooperApi';

export const getShareUrlRequest = (id, permission, password, expire) => new Promise((resolve, reject) => {
  post(api.COOPERATE_LINK, {
    resource_id: id,
    permission,
    password,
    expiration: expire,
  })
    .then((res) => {
      if (res) {
        const {
          /* eslint-disable camelcase, no-shadow */
          link_code,
          permission,
          password,
          expiration,
          invite_link_id,
          /* eslint-enable camelcase, no-shadow */
        } = res;
        resolve({
          code: link_code,
          inviteLinkId: invite_link_id,
          permission,
          password,
          expiration,
        });
      } else {
        reject(new Error(intl.t('生成链接失败')));
      }
    })
    .catch((err) => {
      if (err.response && err.response.data) {
        reject(new Error(err.response.data.message));
      } else {
        reject(err);
      }
    });
});
export const setShareUrlRequest = (
  inviteLinkId,
  permission,
  password,
  expiration,
) => new Promise((resolve, reject) => {
  put(api.COOPERATE_LINK, {
    invite_link_id: inviteLinkId,
    permission,
    password,
    expiration,
  })
    .then((res) => {
      if (res) {
        resolve(res.link_code);
      } else {
        reject(new Error(intl.t('生成链接失败')));
      }
    })
    .catch((err) => {
      if (err.response && err.response.data) {
        reject(new Error(err.response.data.message));
      } else {
        reject(err);
      }
    });
});
