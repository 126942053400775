import { intl } from 'di18n-react';
import { useEffect, useState, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Tree, Spin } from 'antd';
import classBind from 'classnames/bind';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import TreeNode from './TreeNode';
import stylesCommon from '@/components/serviceComponents/AsideDK/catalogCommon.module.less';
import OutlinedIcon from '@/components/serviceComponents/CatalogTree/OutlinedIcon';
import styles from './style.module.less';

const cx = classBind.bind(styles);
const cm = classBind.bind(stylesCommon);

function TagDirectoryTree() {
  const { tagData, tagId, choosePageMsg } = useSelector(
    (state) => state.TagTree,
  );
  const { isOpenCatalog } = useSelector((state) => state.TagTree);

  const { knowledgeId } = useContext(LayoutContext);

  const dispatch = useDispatch();
  const { setState, updateTagTreeData, getSubTagPage, addPageToTag } = dispatch.TagTree;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { teamId } = useParams();

  const treeRef = useRef(null);

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [selectedKey, setSelectedKey] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [initLoading, setInitLoading] = useState(true);

  useEffect(() => {
    updateTagTreeData({ knowledgeId, tagId }).then((res) => {
      setState({ tagData: res });

      setInitLoading(false);
    });
  }, [knowledgeId]);

  useEffect(() => {
    const { parentTagId, pageId } = choosePageMsg;

    if (!tagData.length) {
      return;
    }

    if (parentTagId && parentTagId !== '0') {
      setSelectedKey([`${parentTagId}-${pageId}`]);
      setExpandedKeys([...new Set([...expandedKeys, `${parentTagId}-0`])]);
    } else {
      // 未选中某个页面时的状态
      setSelectedKey([]);
    }
  }, [pathname, choosePageMsg, tagData]);

  const onSelect = (selectedKeys, info) => {
    // selectedKeys： 当前点击的页面
    const { id, pageId, parentTagId } = info.node;

    if (parentTagId) {
      // 选中页面
      setState({
        choosePageMsg: {
          parentTagId,
          pageId,
        },
      });
      navigate(
        teamId
          ? `/team-file/${teamId}/knowledge/${knowledgeId}/${pageId}`
          : `/knowledge/${knowledgeId}/${pageId}`,
      );
    } else {
      // 选中某个标签
      setState({
        choosePageMsg: {
          parentTagId: id,
          pageId: '0',
        },
      });
    }
  };

  const onExpand = (expandedKeysValue) => {
    // expandedKeysValue： 当前所有展开的节点
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  // isLeaf === false点击左侧箭头时触发，加载子页面
  const onLoadData = (info) => {
    const { id, children } = info;
    return new Promise((resolve) => {
      if (children.length > 0) {
        resolve();
        return;
      }

      getSubTagPage({ knowledgeId, tagId: id }).then((pageList) => {
        addPageToTag({ pageList, tagId: id });
        resolve();
      });
    });
  };

  return (
    <div
      className={`${cm({
        'catalog-page-wrap': true,
        'is-tree-hidden': !isOpenCatalog,
      })}`}
    >
      {initLoading && (
        <div className={cm('aside-catalog-loading')}>
          <Spin />
        </div>
      )}

      {!initLoading && tagData.length > 0 && (
        <Tree
          ref={treeRef}
          blockNode
          expandAction="click"
          className={cx('directory-tag-tree')}
          autoExpandParent={autoExpandParent}
          expandedKeys={expandedKeys}
          selectedKeys={selectedKey}
          treeData={tagData}
          switcherIcon={<OutlinedIcon />}
          titleRender={(nodeData) => <TreeNode nodeData={nodeData} />}
          onSelect={onSelect}
          onExpand={onExpand}
          loadData={onLoadData}
        />
      )}

      {!initLoading && tagData.length === 0 && (
        <div className={cm('empty-tree')}>
          {intl.t('没有标签哦，在页面中打标签试试吧~')}
        </div>
      )}
    </div>
  );
}

export default TagDirectoryTree;
