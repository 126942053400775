import { intl } from 'di18n-react';
import classBind from 'classnames/bind';
import { Modal } from 'antd';
import mountAnywhere from '@/utils/mountAnywhere';
import { RecycleBinEmptyIcon } from '@/assets/icon';
import styles from './style.module.less';
import { useCallback, useEffect, useState } from 'react';

const cx = classBind.bind(styles);

const DeleteModal = ({ onOk, pageName }) => {
  const [deleteVisible, setDeleteVisible] = useState(true);

  useEffect(() => {
    document.addEventListener('keyup', listenEnter);
  }, []);

  const listenEnter = useCallback((e) => {
    if (e.key === 'Enter' && !e.repeat && deleteVisible) {
      onOkDelete();
    }
  }, []);

  const onOkDelete = () => {
    window.__OmegaEvent('ep_dkpc_pagemore_delete_confirm_ck');
    setDeleteVisible(false);
    onOk();
  };

  const onCancelDelete = () => {
    window.__OmegaEvent('ep_dkpc_pagemore_delete_cancel_ck');
    setDeleteVisible(false);
  };

  return (
    <Modal
      centered
      keyboard={true}
      visible={deleteVisible}
      onOk={onOkDelete}
      okText={intl.t('删除')}
      cancelText={intl.t('取消')}
      width={580}
      closable={false}
      destroyOnClose
      wrapClassName={cx('delete-confirm-container', 'dk-ant-modal_reset')}
      onCancel={onCancelDelete}
      afterClose={() => document.removeEventListener('keyup', listenEnter)}
    >
      <div className={cx('delete-modal-content')}>
        <img
          src={RecycleBinEmptyIcon}
          className={cx('delete-confirm-icon')}
        />

        <div className={cx('delete-text')}>
          <h1 className={cx('text-h1')}>
            {intl.t('确认删除-确认删除')}
            <div className={cx('page-name')}>{pageName}</div>
            {intl.t('确认删除-吗？')}
          </h1>
          <p className={cx('text-p')}>
            {intl.t(
              '删除后，该页面及其子页面都将一并删除，30天内你仍可在「回收站」中恢复它',
            )}
          </p>
        </div>
      </div>
    </Modal>
  );
};

function deleteNode({ doDelete, pageName = '' }) {
  const Comp = <DeleteModal
    pageName={pageName}
    onOk={doDelete} />;

  mountAnywhere(Comp);
}

export default deleteNode;
