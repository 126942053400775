
/**
 * 页面激活时的轮询能力
 * 示例：
 *  const checker = new PageActivePollChecker( new Promise((resolve, reject){}),1000,10);
 *  checker.waitForResult().then((result) => {});
 *  checker.once(); //立即执行一次
 */
class PageActivePollChecker {
  /**
   * 创建一个PageActivePoll实例
   * @param {*} execFunc  执行函数, 需要返回一个Promise
   * @param {*} interval  轮询间隔时间（毫秒）
   * @param {*} maxAttempts 最大尝试次数
   * @param {*} instanceFlag 支持创建多个轮询实例
   */
  constructor(execFunc, interval = 1000, maxAttempts = 10, instanceFlag = 'default') {
    if (PageActivePollChecker.instance && PageActivePollChecker.instance[instanceFlag]) {
      return PageActivePollChecker.instance[instanceFlag];
    }

    this.execFunc = execFunc;
    this.interval = interval;
    this.maxAttempts = maxAttempts;
    this.controller = { stop: false };
    this._handlers = [];

    // 注册处理程序
    this._onResultChange = (handler) => {
      this._handlers.push(handler)
    }

    // 通知所有注册的处理程序
    this._notifyHandlers = (newResult) => {
      this._handlers.forEach((handler) => handler(newResult));
    }


    /**
     * 优雅的轮询函数，支持终止操作
     * @param {Function} fn - 执行的函数，应该返回一个Promise
     * @param {number} intervalTime - 轮询间隔时间（毫秒）s
     * @param {number} maxAttemptsTime - 最大尝试次数
     * @param {Object} controller - 控制对象，用于终止轮询, {stop:false}
     * @returns {Promise} - 如果成功则resolve，如果超过最大尝试次数或被终止则reject
     */
    // eslint-disable-next-line no-shadow
    this._poll = (fn, intervalTime, maxAttemptsTime, controller) => {
      let attempts = 0;
      // eslint-disable-next-line consistent-return
      const executePoll = async (resolve, reject) => {
        if (controller.stop) {
          return reject(new Error('Polling stopped by controller'));
        }
        try {
          const result = await fn();
          // 结果为null,undefined,false,{}等，则继续轮询
          if (!result || JSON.stringify(result) === '{}') {
            throw new Error('result is empty');
          } else {
            resolve(result);
          }
        } catch (error) {
          attempts++;
          if (attempts >= maxAttemptsTime) {
            reject(new Error('Exceeded maximum attempts', error));
          } else {
            setTimeout(executePoll, intervalTime, resolve, reject);
          }
        }
      };
      return new Promise(executePoll);
    }

    this._handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // 执行窗口激活时的逻辑，例如重新开始轮询或动画等
        this.controller.stop = false;
        this._poll(this.execFunc, this.interval, this.maxAttempts, this.controller)
          .then((result) => { this._notifyHandlers(result) })
          .catch((error) => console.log(error));
      } else {
        this.controller.stop = true;
        // 执行窗口不激活时的逻辑，例如暂停轮询或动画等
      }
    }

    // 暂时未做针对IE的兼容处理（window.onblur,window.onfocus）
    document.removeEventListener('visibilitychange', this._handleVisibilityChange);
    document.addEventListener('visibilitychange', this._handleVisibilityChange);

    // 缓存实例
    PageActivePollChecker.instance = PageActivePollChecker.instance || {};
    PageActivePollChecker.instance[instanceFlag] = this;

    return this;
  }

  start() {
    this.controller.stop = false;
  }

  stop() {
    this.controller.stop = true;
  }

  once() {
    this._handleVisibilityChange();
  }

  // 返回一个 Promise，当获取到最终的结果时，会调用 resolve 函数。
  waitForResult() {
    return new Promise((resolve) => {
      const handler = (result) => {
        resolve(result);
        // 解除注册的处理程序以防止多次调用
        this._handlers = this._handlers.filter((h) => h !== handler);
      };
      this._onResultChange(handler);
    });
  }
}

export default PageActivePollChecker;
