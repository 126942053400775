/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-07-11 15:00:37
 * @LastEditTime: 2023-08-07 15:23:57
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/common/CooperConfirm/index.js
 *
 */
import { intl } from 'di18n-react';
import React from 'react';
import { Modal, Checkbox } from 'antd';
import classBind from 'classnames/bind';
import styles from './style.module.less';

const cx = classBind.bind(styles);

function cooperConfirm({
  width,
  className = '',
  type = 'warn',
  title,
  content,
  onOk = () => {},
  onCancel = () => {},
  okText,
  zIndex,
  cancelText,
  onDestroy = () => {},
  isShowNoTip = false,
  onChangeToNoTip = () => {},
  isShowFooterCancel = true,
  icon,
  closable = true,
  getContainer = () => { return document.body },
}) {
  let selfRef = null;
  selfRef = Modal.confirm({
    className: cx({
      'cooper-confirm': true,
      'cooper-confirm-no-cancel': !isShowFooterCancel,
      [className]: Boolean(className),
    }),
    title,
    maskClosable: false,
    getContainer,
    // selfRef 保存了 Modal.confirm() 的引用，调用该实例的 destroy 方法销毁弹框
    content: (
      <div>
        {
          closable
          && <i
            className={cx('icon-close', 'modal-close-btn')}
            onClick={() => {
              onDestroy();
              selfRef.destroy();
            }}
        />
        }
        {content}
        <br />
        {isShowNoTip
          ? <Checkbox onChange={onChangeToNoTip}>{intl.t('不再提示')}</Checkbox>
          : null}
      </div>
    ),
    onOk,
    onCancel,
    width: width || 480,
    // antd button 在文字为2个时自动在文件间加空格，这与设计图不符，因此加入前置空格阻止 antd 默认行为
    okText: okText || intl.t(' 确定'),
    cancelText: cancelText || intl.t(' 取消'),
    icon,
    zIndex,
  });

  return selfRef;
}

export default cooperConfirm;
