
export default {
  state: {
    operatePerm: {},
  },
  reducers: {
    setState: (state, payload = {}) => ({ ...state, ...payload }),

    changeOperatePerm(state, operatePerm) {
      return {
        ...state,
        operatePerm,
      };
    },
  },
  effects: () => ({


  }),
};
