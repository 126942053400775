/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-05-23 12:18:05
 * @LastEditTime: 2023-05-23 14:00:18
 * @Description: 氢文档
 * @FilePath: /knowledgeforge/src/components/Cooper/CooperOperation/OpenDidoc/index.js
 *
 */
import { noopenerOpen } from '@/utils/cooperutils';

function openDidoc(id) {
  noopenerOpen(`/didoc/file/${id}`);
}

export default openDidoc;
