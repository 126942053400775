
import { getTeamDetail, getTeamFunc, getPersonTeamFunc, getSpaceKnowledgeDynamic, replaceTeamFunc } from '@/service/cooper/teamDetail';
import { KNOWLEDGE } from '@/constants/space';
// 空间头部内容放在这里
export default {
  name: 'TeamData',
  state: {
    permission: {},
    teamDetail: {}, // 放空间详情的所有信息
    spaceHeaderActFuns: {}, // 当前选中的tab
    personHeaderActFuns: {},
    features: [], // tab列表
    personFeatures: [],
    teamDynamic: [],
    currentFuns: {}, // 当前选中的tab
    currentSpaceType: '', // 所在空间的类型----空间url和知识库url
    currSpaceId: '', // 所在空间id----空间url和知识库url
    activeListTab: '0',
    personalSpaceId: null,
    personalSpaceName: null,
  },
  reducers: {
    setState(state, payload = {}) {
      return {
        ...state,
        ...payload,
      };
    },
    setPersonalSpaceId(state, personalSpaceId) {
      return { ...state, personalSpaceId };
    },
    setPersonalSpaceName(state, personalSpaceName) {
      return { ...state, personalSpaceName };
    },
    setTeamDetail(state, teamDetail) {
      return { ...state, teamDetail };
    },
    setActiveListTab(state, activeListTab) {
      return { ...state, activeListTab };
    },
    setSpaceHeaderActFuns(state, spaceHeaderActFuns) {
      return { ...state, spaceHeaderActFuns };
    },
    setFeatures(state, features) {
      return { ...state, features };
    },
    setPersonHeaderActFuns(state, personHeaderActFuns) {
      return { ...state, personHeaderActFuns };
    },
    setPersonFeatures(state, personFeatures) {
      return { ...state, personFeatures };
    },
    setCurrSpaceId(state, currSpaceId) {
      return { ...state, currSpaceId };
    },
    setKnowledgeDynamic(state, teamDynamic) {
      return { ...state, teamDynamic };
    },
    saveCurrentFuns(state, currentFuns) {
      return { ...state, currentFuns };
    },
    saveCurrentSpaceType(state, currentSpaceType) {
      return { ...state, currentSpaceType };
    },
  },
  effects: () => ({
    async getTeamData(id) {
      const data = await getTeamDetail(id);
      if (data) {
        this.setTeamDetail(data);
      }
      return data;
    },

    async getFeatures(teamId) {
      if (!teamId) return;
      const res = await getTeamFunc(teamId);
      // 做一次处理，生成唯一的key
      const arr = res.functionList
        // .filter((item) => item.enable)
        .filter((item) => item.function !== 'KNOWLEDGE_NEW')
        .map((item) => {
          let tabKey = item.function
          if (item.function === KNOWLEDGE) {
            if (item.subjectId) {
            // 此时知识库是已经绑定了的
              tabKey = `${item.function}-${item.subjectId}`
            } else if (item.functionId) {
            // 此时知识库无绑定
              tabKey = `${item.function}-${item.functionId}`
            }
          }
          return {
            ...item,
            tabKey,
          }
        });
      this.setFeatures(arr);
      this.setCurrSpaceId(res.spaceId);
      // eslint-disable-next-line consistent-return
      return arr;
    },

    async getPersonFeatures() {
      getPersonTeamFunc().then((res) => {
        // 做一次处理，生成唯一的key
        const arr = res.functionList.filter((item) => item.enable).map((item) => {
          let tabKey = item.function
          if (item.function === KNOWLEDGE) {
            if (item.subjectId) {
              // 此时知识库是已经绑定了的
              tabKey = `${item.function}-${item.subjectId}`
            } else if (item.functionId) {
              // 此时知识库无绑定
              tabKey = `${item.function}-${item.functionId}`
            }
          }
          return {
            ...item,
            tabKey,
          }
        })
        this.setPersonFeatures(arr);
        this.setCurrSpaceId(res.spaceId);
        return arr;
      });
    },

    async getKnowledgeDynamic({
      teamId,
      knowledgeIds,
    }) {
      const res = await Promise.all(knowledgeIds.map((item) => {
        return getSpaceKnowledgeDynamic(teamId, item);
      }));

      this.setKnowledgeDynamic(res);
      return res;
    },

    async resetKnowledgeDynamic() {
      await this.setKnowledgeDynamic([]);
      return [];
    },

    async saveFuncs(funcs) {
      const res = await replaceTeamFunc(funcs);
      this.setFeatures(funcs.functions);
      return res;
    },
  }),
};


