import { intl } from 'di18n-react';
import { Input, message } from 'antd';
import PrimaryModal from '@/components/common/PrimaryModal';
import { RENAME_TEAM } from '../constants';
import mountAnywhere from '@/utils/mountAnywhere';
import { renameTeam, renameFile } from '@/service/cooper/reame';
import { checkInputValue } from '@/utils/cooperutils';
import './index.less';

class Renames extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.oldName,
      errorText: '',
      confirmLoading: false,
    };
    this.inputRef = null;
  }

  componentDidMount() {
    const { fileType } = this.props;

    setTimeout(() => {
      if (this.inputRef) {
        // 选择输入框
        const { input } = this.inputRef;
        const name = input.value;
        input.focus();
        // 不选中文件后缀
        if (fileType === 'FILE' || fileType === 0) {
          input.setSelectionRange(0, name.indexOf('.'));
        } else {
          input.setSelectionRange(0, name.length);
        }
      }
    }, 0)
  }

  changeName = (e) => {
    const v = e.target.value;
    let s = checkInputValue(v);


    this.setState({
      name: v,
      errorText: s,
    });
  };

  doRename = async () => {
    const { type, id, doneCallback, onClose } = this.props;
    const renameFunc = type === RENAME_TEAM ? renameTeam : renameFile;

    try {
      const { name, errorText } = this.state;
      if (errorText) return;

      this.setState({
        confirmLoading: true,
      });

      await renameFunc(id, name.trim()).then(() => {
        message.success(intl.t('修改成功'));
        doneCallback && doneCallback();
        onClose();
      });
    } catch (e) {
      this.setState({
        confirmLoading: false,
      });
    }
  };

  render() {
    // onClose 是 mountAnywhere 自动注入的
    const { onClose } = this.props;


    const { name, errorText, confirmLoading } = this.state;
    return (
      <PrimaryModal
        title={intl.t('重命名')}
        onCancel={onClose}
        onOk={this.doRename}
        okText={intl.t(' 确定')}
        cancelText={intl.t(' 取消')}
        confirmLoading={confirmLoading}
        selfClassName='modal-rename-team'
      >
        <div className='rename'>
          <p>{intl.t('名称')}</p>
          <Input
            value={name}
            onChange={this.changeName}
            onPressEnter={this.doRename}
            ref={(node) => (this.inputRef = node)}
            bordered={true}
          />
          {errorText ? <div className='error-text'>{errorText}</div> : null}
        </div>
      </PrimaryModal>
    );
  }
}

// 这里的fileType是在文件列表中区分普通文件、协作文档之类的，打开focus时候是否选中后缀，团队空间逻辑和协作文档相同，所以给个默认值2
function rename(type, id, oldName, doneCallback, fileType = 2) {
  mountAnywhere(<Renames
    type={type}
    fileType={fileType}
    id={id}
    oldName={oldName}
    doneCallback={doneCallback}
  />);
}

export default rename;
