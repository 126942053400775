import { intl } from 'di18n-react';

const ASIDE_SHOW_TYPE = {
  TILE: 1,
  MODAL: 2,
};

const PAGE_SIZE = 33;

const TREE_VIEW_TYPE = {
  PAGE: 0,
  TAG: 1,
};

const SHARE_TYPE = {
  DK: 1,
  PAGE: 2,
  SHARE: 3,
};

const SHARE_SCOPE = {
  OnlySelf: 0,
  IncludeChild: 1,
  DkGlobal: 2,
};

const COOPER_ASIDE_TYPE = {
  LARGE: 1,
  SMALL: 2,
};

const SHARE_MODAL_TEXT = {
  1: {
    title: () => intl.t('知识库访客链接分享'),
    desc: () => intl.t(
      '知识库所有者和管理员可以开启知识库分享；分享开启后，获得分享链接的任何人只可查看知识库',
    ),
  },
  2: {
    title: () => intl.t('页面访客链接分享'),
    desc: () => intl.t(
      '知识库所有者、管理员、页面所有者可以开启页面分享；分享开启后，获得分享链接的任何人只可查看页面',
    ),
  },
};

const TAG_SOURCE_TYPE = {
  SYSTEM_SECURE: 'SYSTEM_SECURE',
  CUSTOM: 'CUSTOM',
};

const MODE_PREFIX = {
  development: 'http://localhost:3001',
  dev: 'http://localhost:3001',
  test: '//cooper-test.didichuxing.com',
  qa: '//cooper-qa.didichuxing.com',
  prod: '//cooper.didichuxing.com',
};

const LITE_URL = {
  development: 'https://cooper-lite-qa.didichuxing.com',
  dev: 'https://cooper-lite-qa.didichuxing.com',
  test: 'https://cooper-lite-qa.didichuxing.com',
  qa: 'https://cooper-lite-qa.didichuxing.com',
  prod: 'https://cooper-lite.didichuxing.com',
};

const APPLY_ROLE = {
  knowledge: {
    MEMBER: 'DK_MEMBER',
    READ_MEMBER: 'DK_READ_MEMBER',
  },
  page: {
    MEMBER: 'PAGE_MEMBER',
    READ_MEMBER: 'PAGE_READ_MEMBER',
  },
};

const PROCESS_ENV_MENHU_URL = {
  dev: 'http://localhost:3005/portal/',
  test: 'https://dk-test.didichuxing.com/portal/',
  qa: 'https://dk-qa.didichuxing.com/portal/',
  prod: 'https://k.didi.cn/portal/',
};

export const PROCESS_ON_SDK_URL = {
  // dev: 'https://api-v5.processon.com/sdkeditor/apitemp/template.html',
  dev: 'https://poapi.intra.xiaojukeji.com/sdkeditor/apitemp/template.html',
  test: 'https://poapi.intra.xiaojukeji.com/sdkeditor/apitemp/template.html',
  qa: 'https://poapi.intra.xiaojukeji.com/sdkeditor/apitemp/template.html',
  prod: 'https://cooper.didichuxing.com/sdkeditor/apitemp/template.html',
};

export const PROCESS_ON_APPID_CONFIG = {
  // TEST: 'cli_d9RICuj1i9mhO9dq',
  dev: 'cli_3I6QIo5ISKL6cICT',
  test: 'cli_3I6QIo5ISKL6cICT',
  qa: 'cli_3I6QIo5ISKL6cICT',
  prod: 'cli_cEr6GfFvn3oeoF8K',
}

const SYMOL_HTTP_URL = {
  dev: 'dk-test.didichuxing.com',
  test: 'dk-test.didichuxing.com',
  qa: 'dk-qa.didichuxing.com',
  prod: 'k.didi.cn',
};

export {
  ASIDE_SHOW_TYPE,
  TREE_VIEW_TYPE,
  SHARE_TYPE,
  SHARE_MODAL_TEXT,
  TAG_SOURCE_TYPE,
  MODE_PREFIX,
  APPLY_ROLE,
  PROCESS_ENV_MENHU_URL,
  SYMOL_HTTP_URL,
  SHARE_SCOPE,
  COOPER_ASIDE_TYPE,
  PAGE_SIZE,
  LITE_URL,
};
