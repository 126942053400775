/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-06-01 16:07:48
 * @LastEditTime: 2023-06-20 16:55:35
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/CooperOperation/DeleteFile/index.js
 *
 */
import { intl } from 'di18n-react';
import { message } from 'antd';
import { post, del } from '@/utils/request/cooper';
import CooperApi from '@/utils/request/api/CooperApi';
import cooperConfirm from '@/components/common/CooperConfirm';


const batchDeleteShare = (ids, runCallbacks) => {
  post(CooperApi.SHARES_SHARE, ids).then(() => {
    message.success(intl.t('取消成功'));
    runCallbacks();
  });
};

const deleteShare = (currItemShareId, currItemShareType, runCallbacks) => {
  if (!currItemShareId || !currItemShareType) {
    message.error(intl.t('取消失败'));
    return;
  }
  del(`${CooperApi.SHARE_SHAREID.replace(':shareId', currItemShareId)}?share_type=${currItemShareType}`).then(() => {
    message.success(intl.t('取消成功')); // 去掉被删的分享记录
    runCallbacks();
  });
};

function CancelShare({ shareId, shareType, ids, runCallbacks }) {
  cooperConfirm({
    width: 480,
    zIndex: 2001,
    type: 'warn',
    title: intl.t('确认取消分享吗？'),
    content: intl.t('取消分享后，该条分享记录将被删除，好友将无法再访问分享内容。'),
    onOk: shareId === -1
      ? () => batchDeleteShare(ids, runCallbacks)
      : () => deleteShare(shareId, shareType, runCallbacks),
    okText: intl.t('确定'),
    cancelText: intl.t('取消'),
  });
}

export default CancelShare;
