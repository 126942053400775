/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-05-11 19:29:47
 * @LastEditTime: 2023-10-23 17:32:17
 * @Description:  快速访问
 * @FilePath: /knowledgeforge/src/components/QuickAccess/index.js
 *
 */
import { intl } from 'di18n-react';
import { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';
import { setQuickOrder } from '@/service/cooper/home';
import classBind from 'classnames/bind';
import Card from './Card';
import { isDC } from '@/utils/index';
import QuickAccessSkeleton from '../SkeletonPage/common/QuickAccessSkeleton';
import styles from './style.module.less';
import GetHtml from '@/utils/DOMPurify';
import replaceIntl from '../SkeletonPage/utils/wrapWithIntl';

const cx = classBind.bind(styles);

const QuickAccessSkeletonIntl = replaceIntl(QuickAccessSkeleton);

// 防抖
let timer = null;

// eslint-disable-next-line import/no-mutable-exports
export let updateQuicks = () => { };

function QuickAccess() {
  // const [visits, setVisits] = useState([]);
  const dispatch = useDispatch();
  const { needAccessSkeleton } = useSelector((state) => state.GlobalData)
  const { setNeedAccessSkeleton } = dispatch.GlobalData;
  const { visitList } = useSelector((state) => state.CooperHome);
  const { getVisitList, updateVisitList } = dispatch.CooperHome;

  useEffect(() => {
    updateVisits();
  }, []);

  async function updateVisits() {
    await getVisitList();
    setNeedAccessSkeleton(false);
    // const visitsList = await getQuickVisit();
    // setVisits(visitsList);
  }

  updateQuicks = updateVisits;

  async function setVisitOrder(dragIndex, hoverIndex) {
    let data = visitList.slice(0);
    let temp = data[dragIndex];
    // 交换位置
    data[dragIndex] = data[hoverIndex];
    data[hoverIndex] = temp;
    updateVisitList(data);
    clearTimeout(timer);
    timer = setTimeout(async () => {
      const ids = data.map((file) => file.quickId);
      await setQuickOrder(ids);
    }, 1000);
  }

  return (
    needAccessSkeleton ? (
      <div dangerouslySetInnerHTML={{ __html: GetHtml(QuickAccessSkeletonIntl) }} />
    ) : (
      <>
        {
          visitList.length > 0 && <div className={cx({ 'v3-quick-access': true, 'is-dc': isDC() })}>
            <div className={cx('v3-quick-access-title')}>
              {intl.t('快速访问')}
            </div>
            <div className={cx('container')} >
              <DndProvider backend={HTML5Backend}>
                {visitList.map((file, idx) => (
                  <Card
                    key={file.quickId}
                    idx={idx}
                    file={file}
                    filesList={visitList}
                    switchOrder={(idx0, idx1) => setVisitOrder(idx0, idx1)}
                    refresh={updateVisits}
                  />))}
              </DndProvider>
            </div>
          </div>
        }
      </>
    )
  );
}

export default QuickAccess;
