import classBind from 'classnames/bind';
import { NoPermissionIcon } from '@/assets/icon';
import styles from './style.module.less';


const cx = classBind.bind(styles);

const ErrorTips = ({ title, desc, overlayClassName = '', imgClassName = '', descClassName= '', img, imagestyle = {}, children }) => {
  return (
    <div className={cx('error-wrap', overlayClassName)}>
      <img
        style={imagestyle}
        className={cx('icon', imgClassName)}
        src={img || NoPermissionIcon}/>
      {
        title && <div className={cx('title')}>{title}</div>
      }
      {
        desc && <div className={`${cx('desc')} ${descClassName} `}>{desc}</div>
      }
      {
        children
      }
    </div>


  );
};

export default ErrorTips;
