/*
 * @Date: 2024-02-28 17:03:05
 * @LastEditors: guanzhong guanzhong@didiglobal.com
 * @LastEditTime: 2024-04-10 15:48:39
 * @FilePath: /knowledgeforge/src/components/Tag/index.js
 * @Description: 描述文件功能
 */
import classBind from 'classnames/bind';
import { intl } from 'di18n-react';
import { useState, useMemo } from 'react';
import { Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styles from './style.module.less';

const cx = classBind.bind(styles);
// 安全标签 知识库和cooper共用
const SafeTag = ({
  tagkey,
  safeTag,
  onClick,
  hasBackground,
  overlayClassName,
}) => {
  const { globalOutsideChain } = useSelector((state) => state.CooperIndex);
  const { IsExternalTenant } = useSelector((state) => state.GlobalData);


  const safetyTooltip = useMemo(() => {
    return (
      <span>
        {intl.t('了解')}
        <a
          target="_blank"
          className={cx('data-safety-standard-link')}
          href={globalOutsideChain?.data_safety_standard}
        >
          {intl.t('数据安全分级标准')}
        </a>
      </span>
    );
  }, [globalOutsideChain?.data_safety_standard]);
  return (
    <Tooltip
      title={!IsExternalTenant && safetyTooltip}
      key={tagkey}
      placement="bottom"
      overlayClassName={cx('safety-level-tooltip')}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
    >
      <span
        className={cx('tag-item', 'safety-level', overlayClassName, {
          'safety-level-2': safeTag?.name?.indexOf('1') !== -1 || safeTag?.name?.indexOf('2') !== -1,
          'safety-level-3': safeTag?.name?.indexOf('3') !== -1,
          'safety-level-4': safeTag?.name?.indexOf('4') !== -1,
          'safety-level-5': safeTag?.name?.indexOf('5') !== -1,
          'none-background': !hasBackground,
        })}
        key={tagkey || safeTag.id}
        onClick={() => onClick && onClick(safeTag.id)}
        >
        <i
          className={cx(
            'dk-icon-a-bianzubeifen3',
            'dk-iconfont',
            'safety-level-icon',
          )}
        />
        <span>
          {intl.t('数据等级C')}
          {safeTag.name}
        </span>
      </span>
    </Tooltip>
  );
};

export default SafeTag;
