import { PAGE_SIZE } from '@/constants';
import { getFilesDetails, getShareToRootFiles } from '@/service/cooper/share';

export default {
  name: 'ShareToMe',
  state: {
    shareLists: [],
    hasMore: true,
  },
  reducers: {
    updateShareToMeList(state, shareLists) {
      return {
        ...state,
        shareLists,
      };
    },
    updateHasMore(state, hasMore) {
      return {
        ...state,
        hasMore,
      };
    },
  },
  effects: {
    async getShareDetailList(params, state) {
      const { refresh } = params;
      delete params.refresh;
      const result = await getFilesDetails(params);
      const { data } = result;
      // data.forEach((item) => {
      //   item.parent = {
      //     children: data,
      //   };
      // });
      this.updateHasMore(data.length === PAGE_SIZE);
      const shareLists = refresh ? data : [...state.ShareToMe.shareLists, ...data];
      this.updateShareToMeList(shareLists);
      return data;
    },
    async getShareTomeList(params, state) {
      const { refresh } = params;
      delete params.refresh;
      const result = await getShareToRootFiles(params);
      const { data } = result;
      // data.forEach((item) => {
      //   item.parent = {
      //     children: data,
      //   };
      // });

      this.updateHasMore(data.length === PAGE_SIZE);
      const shareLists = refresh ? data : [...state.ShareToMe.shareLists, ...data];
      this.updateShareToMeList(shareLists);
      return data;
    },
  },
};
