/*
 * @Date: 2023-11-10 11:38:46
 * @LastEditors: guanzhong
 * @LastEditTime: 2023-11-10 17:20:32
 * @FilePath: /knowledgeforge/src/components/serviceComponents/TagDirectoryTree/TreeNode/index.js
 * @Description: 描述文件功能
 */
import classBind from 'classnames/bind';
import { useParams } from 'react-router-dom';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import styles from '@/components/serviceComponents/CatalogTree/TreeNode/style.module.less';
import { useContext } from 'react';


const cx = classBind.bind(styles);


function TreeNode({
  nodeData,
}) {
  const { knowledgeId } = useContext(LayoutContext);
  const { teamId } = useParams();
  const draggable = nodeData.isLeaf;
  const onDragStart = (event) => {
    if (!draggable) {
      event.preventDefault();
      return false;
    }
    const originNode = event.target.closest('.ant-tree-treenode');
    const { dataTransfer } = event;
    console.log('*** node', nodeData, originNode);
    dataTransfer.setDragImage(originNode, 20, 40); // 拖拽中弹窗距离鼠标的位置
    dataTransfer.setData('livechatDocInfo', JSON.stringify({ resourceId: nodeData.pageId, title: nodeData.title }))
    dataTransfer.effectAllowed = 'move';
  }

  return (
    <span
      className={cx('tree-node-item-wrap')}
      draggable={draggable}
      onDragStart={onDragStart}>
      <a
        className={cx('item-name')}
        href={
          teamId
            ? `/team-file/${teamId}/knowledge/${knowledgeId}/${nodeData.pageId}`
            : `/knowledge/${knowledgeId}/${nodeData.pageId}`
        }
      >
        <span
          className={cx('item-name-link')}
          key={nodeData.key}
        >{nodeData.title}</span>
      </a>
    </span>

  );
}

export default TreeNode;
