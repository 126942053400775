/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-06-02 15:58:03
 * @LastEditTime: 2023-11-27 14:56:13
 * @Description: 移动资源入口文件
 * @FilePath: /knowledgeforge/src/components/CooperOperation/MoveFile/index.js
 *
 */

import { MOVE } from '@/constants/cooper';
import OperateFileModal from '@/components/OperateFileModal';
import mountAnywhere from '@/utils/mountAnywhere';
import store from '@/model';
import { Provider } from 'react-redux';

function MoveFile(files, teamId, doneCallback, autoLocate = true, isFromPerson) {
  const Comp = (
    <Provider store={store}>
      <OperateFileModal
        type={MOVE}
        files={files}
        teamId={teamId}
        doneCallback={doneCallback}
        autoLocate={autoLocate}
        onClose={() => close()}
        isFromPerson={isFromPerson}
    />
    </Provider>

  );
  let close = mountAnywhere(Comp);
}

export default MoveFile;
