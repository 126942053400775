
export default {
  name: 'UploadList',
  state: {
    showList: false,
    taskPool: {},
    uploadCase: null,
  },
  reducers: {
    switchUploadList(state, showList) {
      return { ...state, showList }
    },
    setTaskPool(state, taskPool) {
      return { ...state, taskPool }
    },
    setUploadCase(state, uploadCase) {
      return { ...state, uploadCase }
    },

  },
  effects: () => ({

  }),
}
