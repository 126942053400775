import { get } from '@/utils/request';
import api from '@/utils/request/api/DkApi';

export async function searchKnowledgePages(params) {
  try {
    const res = await get(api.SEARCH_KNOWLEDGE_PAGES, { params });
    return res;
  } catch (e) {
    console.log(e);
  }
}
export async function knowledgeMoveList(params) {
  try {
    const res = await get(api.KNOWLEDGE_MOVE_LIST.replace(':knowledgeId', params.knowledgeId), { params });
    return res;
  } catch (e) {
    console.log(e);
  }
}

