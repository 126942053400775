/*
 * @Date: 2024-02-28 17:03:05
 * @LastEditors: guanzhong guanzhong@didiglobal.com
 * @LastEditTime: 2024-04-10 15:48:39
 * @FilePath: /knowledgeforge/src/components/Tag/index.js
 * @Description: 描述文件功能
 */
import classBind from 'classnames/bind';
import { useState } from 'react';
import styles from './style.module.less';

const cx = classBind.bind(styles);
// TODO:待补充除蓝色标签之外的其他样式；可交互标签
const Tag = ({ type, overlayClassName = '', text, onMouseOver = () => { }, onMouseOut = () => { }, onClick = () => { }, canClose, iconSrc }) => {
  const [close, setClose] = useState(true);
  if (!close) return null;
  return (
    <span
      className={cx('cooper-tag', overlayClassName, {
        blue: type === 'blue-primary',
        gray: type === 'gray-primary',
        'gray-line': type === 'gray-line',
        'blue-line': type === 'blue-line',
        yellow: type === 'yellow-primary',
        'out-yellow': type === 'out-yellow',
        'safety-level-2': type === 'safety-level-2',
        'safety-level-3': type === 'safety-level-3',
        'safety-level-4': type === 'safety-level-4',
        'safety-level-5': type === 'safety-level-5',
      }, 'tag-global')}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onClick={onClick}
    >
      {
        iconSrc && <img
          src={iconSrc}
          className={cx('icon')} />
      }
      <span className={cx('word')}>{text}</span>
      {
        canClose && <i
          className={cx('dk-iconfont', 'dk-icon-chouti-guanbi', 'icon-close')}
          onClick={(e) => {
            e.stopPropagation();
            setClose(false);
          }}
        />
      }
    </span>
  );
};

export default Tag;
