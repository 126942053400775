import { intl } from 'di18n-react';

export const FOLDAUTH_MEMBERS_PAGESIZE = 8;
export const FULL_AUTH_PERM = 59; // 查看、编辑、上传、分享、下载

export function getPermissionOptions() {
  return [
    {
      label: intl.t('查看'),
      value: '1',
      id: 0,
      perm: 1,
      disabled: true,
      type: 'check',
    },
    {
      label: intl.t('编辑'),
      value: '2',
      id: 1,
      perm: 2,
      type: 'edit',
    },
    {
      label: intl.t('上传'),
      value: '8',
      id: 2,
      perm: 8,
      type: 'upload',
    },
    {
      label: intl.t('分享'),
      value: '16',
      id: 3,
      perm: 16,
      type: 'share',
    },
    {
      label: intl.t('下载'),
      value: '32',
      id: 4,
      perm: 32,
      type: 'download',
    },
  ]
}


export const PERM_DEFAULT_LIST = ['1', '2', '8', '16', '32'];
export const DEFAULT_PERM_ID = [0, 1, 2, 3, 4];
