
export const FILE = 'FILE'
export const HOME = 'HOME'
export const KNOWLEDGE = 'KNOWLEDGE'
export const NEWDK = 'KNOWLEDGE_NEW';
export const TRASH = 'TRASH';
export const SETTING = 'SETTING';
export const MEMBER = 'MEMBER';
export const DYNAMIC = 'DYNAMIC';
export const IN = 'IN'; // 内部空间
export const IN_OUT = 'IN_OUT'; // 内外互联空间
export const NEED_APPROVE = 501203; // 需要申请
export const NEED_FORBID = 501204; // 非内外协作不可访问
export const NEED_CHANGE_ACCOUNT = 501205; // 需要切换账号
