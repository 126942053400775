/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-05-26 17:01:40
 * @LastEditTime: 2023-06-20 15:32:02
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/service/cooper/quick.js
 *
 */

import { get, post } from '@/utils/request/cooper';
import api from '@/utils/request/api/CooperApi';

export async function getQuickStatus(params) {
  let res = await get(api.GET_QUICK_STATUS, {
    params,
    headers: {
      'X-App-Id': 2,
    },
  });

  let currPage = res.find((item) => item.objectId = params.objectId) || {};
  return currPage;
}


export async function AddQuick(params) {
  let quickId = await post(api.QUICK_VISIT, params, {
    needDD05: true,
  });
  return quickId
}
