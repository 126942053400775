/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-07-26 11:54:33
 * @LastEditTime: 2023-07-26 11:54:47
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/utils/miniBus.js
 *
 */
class EventDispatcher {
  constructor() {
    this._listeners = {};
  }

  addEventListener(type, listener) {
    this._listeners[type] = this._listeners[type]
      ? [...this._listeners[type], listener]
      : [listener];
  }

  removeEventListener(type, listener) {
    let listeners = this._listeners[type];
    if (!listeners || listeners.length === 0) {
      return;
    }

    const index = listeners.findIndex((item) => item === listener);
    if (index >= 0) {
      listeners.splice(index, 1);
    }
  }

  removeEventListeners() {
    this._listeners = [];
  }

  dispatchEvent(type) {
    if (!this._listeners[type]) {
      return;
    }

    this._listeners[type]
      .forEach((listener) => {
        if (typeof listener === 'function') listener();
      });
  }
}

export default new EventDispatcher();
