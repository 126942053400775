import api from '@/utils/request/api/DkApi';
import { get, post } from '@/utils/request';


export async function getMyDKList(params) { // 我参与的
  let par = {
    owner: params.owner,
    sortField: params.sort,
  }
  const res = await get(api.DK_LIST, {
    params: {
      ...par,
    },
    needDD05: true,
  });
  return res;
}

export async function getMyOwnDKList(params) { // 我拥有的
  let par = {
    ownType: params.ownerType ?? 0,
    sortField: params.sort ?? 1,
  }
  const res = await get(api.DK_LIST_OWN, {
    params: {
      ...par,
    },
    needDD05: true,
  });
  return res;
}

export async function getCountNumber(isMyKnowledge) {
  const res = await get(isMyKnowledge ? api.MY_KNOWLEDGE_COUNT_MEMBER : api.MHU_COUNT_MEMBER, {
    headers: {
      'X-App-Id': isMyKnowledge ? 4 : 7,
    },
  });
  return res;
}

export async function unboundDk(params) {
  const res = await get(api.UNBOUND_TEAM_DK, {
    params: {
      spaceId: params.spaceId,
      metisSpaceId: params.metisSpaceId,
    },
    needDD05: true,
  });
  return res;
}

export async function boundDk(params) {
  const res = await get(api.BOUND_TEAM_DK, {
    params: {
      metisSpaceId: params.metisSpaceId,
      spaceId: params.spaceId,
    },
    needDD05: true,
  });
  return res;
}


export async function getOnDepartmentProcessList(params) {
  const res = await post(api.ON_DEPARTMENT_PROCESS_LIST, params);
  return res;
}

// 门户列表获取
export async function getMyMHList(params) {
  const res = await get(api.MH_LIST,
    {
      params: {
        type: params.type,
        orderType: params.orderType,
      },
      headers: {
        'X-App-Id': 7,
      },
    });
  return res;
}

// 创建门户
export async function creactMH(params) {
  const res = await post(api.CREACT_MH, params, {
    headers: {
      'X-App-Id': 7,
    },
  });
  return res;
}

// 校验门户名称是否唯一
export async function checkMHName(params) {
  const res = await get(api.CHECK_MH_NAME, {
    params: { name: params.name },
    headers: {
      'X-App-Id': 7,
    },
  });
  return res;
}

// 校验门户域名是否唯一
export async function checkMHSymbol(params) {
  const res = await get(api.CHECK_MH_SYMBOL, {
    params: { symbol: params.symbol },
    headers: {
      'X-App-Id': 7,
    },
  });
  return res;
}

// 迁入知识库页面
export async function moveKnowledgePage(params) {
  const res = await post(api.MOVE_KNOWLEDGE_PAGES, params);
  return res;
}

// 移动页面
export async function removeKnowledgePage(knowledgeId, params) {
  const res = await post(`${api.REMOVE_KNOW_PAGE.replace(':knowledgeId', knowledgeId)}`, params);
  return res;
}
