import { get, post } from '@/utils/request/cooper';
import api from '@/utils/request/api/CooperApi';
import { N_TEAM, N_DIR, N_PERSONAL } from '@/constants/cooper';
import {
  iconShareFolder,
  iconPersonalSpace,
  iconTeamSpace,
} from '@/assets/icon/index';
import { message } from 'antd';
import { intl } from 'di18n-react';

const icFolderIcon = iconShareFolder;

function getItemIcon(item, isSearchItem) {
  switch (item.type) {
    case N_PERSONAL:
      return (isSearchItem
        ? 'https://img-ys011.didistatic.com/static/cooper_cn/icon_chengyuan_fill.svg?v=1'
        : iconPersonalSpace
      );
    case N_TEAM:
      return (isSearchItem
        ? 'https://img-ys011.didistatic.com/static/cooper_cn/spaceTeam.png'
        : iconTeamSpace
      );
    case N_DIR:
      return (isSearchItem
        ? iconShareFolder
        : icFolderIcon
      );
    default:
      return iconShareFolder;
  }
}

export async function searchFolder(keyword, spaceId) {
  const keywordNew = encodeURIComponent(keyword);
  let url = `${api.MOVE_SEARCH}?keyword=${keywordNew}&pageNum=0&pageSize=30`;

  // 移动仅支持当前空间下，因此需传一个 spaceId 过滤保留该空间下的文件夹
  if (spaceId) url += `&spaceId=${spaceId}`;


  const res = await get(url, {
    needDD05: true,
  });

  const { items } = res;
  items.forEach((item) => {
    item.icon = getItemIcon(item, true);
  });

  return res.items;
}

export async function getRecentFolds(spaceId, businessName) {
  let url = spaceId ? `${api.MOVE_RECORD}?spaceId=${spaceId}` : api.MOVE_RECORD;
  if (businessName) {
    url += `?businessName=${businessName}`;
  }
  const res = await get(url, {
    headers: {
      'X-App-Id': 2,
    },
  });

  res.forEach((item) => {
    item.icon = getItemIcon(item, false);
  });

  return res;
}

function addState(root, seletedId) {
  let teamId;
  let depth = 1;

  function _addState(root) {
    root.hasSelected = (root.id === seletedId);

    if (root.type === N_TEAM) {
      teamId = root.realId;
    } else if (root.type === N_DIR) {
      Object.assign(root, {
        id: root.id,
        teamId,
        name: root.name,
        icon: icFolderIcon,
        type: N_DIR,
        depth,
        hasChildren: true,
        hasOpened: root.children.length > 0,
        hasSelected: root.id === seletedId,
        hasPermis: checkMovePermis(root.permission),
      });
    }

    if (root.children.length > 0) {
      root.children.forEach((child) => {
        depth++;
        _addState(child);
        depth--;
      });
    }
  }

  _addState(root);
}

export async function getTree(id, type) {
  const res = await get(`${api.MOVE_UNFOLD}?id=${id}&type=${type}`, {
    needDD05: true,
  });

  const rootId = res.fullPath.split('/')[0];
  const root = res.roots.find((root) => root.id === Number(rootId));

  root.hasPermis = checkMovePermis(root.permission);

  if (type === N_TEAM || type === N_PERSONAL) {
    root.children = await getFolders(0, id);
  }

  addState(root, id);

  return root;
}

function checkMovePermis(permission) {
  if (permission >= 0) {
    return Boolean(permission & 2) && Boolean(permission & 8);
  }
  return true;
}

export async function getTeams() {
  const res = await get(api.TEAM_LIST, {
    needDD05: true,
  });
  return (res || []).map((team) => ({
    ...team,
    id: Number(team.id),
    name: team.name,
    icon: team.tiny_image,
    type: N_TEAM,
    children: [],
    hasChildren: true,
    hasPermis: checkMovePermis(team.perms),
    isShowOuter: (team?.relationTypeTags || []).includes('IN_OUT'),
  }));
}

export async function getFolders(id, teamId) {
  let res;

  if (teamId) {
    res = await get(api.TEAM_RESOURCE_FOLD_V2.replace(':resourceId', id).replace(':teamId', teamId), {
      headers: {
        'X-App-Id': 2,
      },
    });
    res = res.folds;
  } else {
    res = await get(api.PERSONAL_RESOURCE_FOLD.replace(':resourceId', id), {
      headers: {
        'X-App-Id': 2,
      },
    });
  }

  res = res.map((fold) => (Object.assign(fold, {
    id: fold.id,
    teamId,
    name: fold.display_name,
    icon: icFolderIcon,
    type: N_DIR,
    children: [],
    hasChildren: true,
    hasOpened: false,
    hasSelected: false,
    hasPermis: checkMovePermis(fold.permission),
  })));

  return res;
}

// 分享保存到云盘
export async function doSave(ids, shareId, shareType, parentId, teamId) {
  return post(api.SHARE_COPY, {
    resourceIds: ids,
    share_id: shareId,
    share_type: shareType,
    target_parent: parentId,
    target_space_id: teamId,
  }, {
    keepSilent: true,
  });
}

export async function doMove(ids, fromTeamId, parentId, toTeamId) {
  const res = post(api.DIRECTORY_MOVE, {
    resource_ids: ids,
    parent_id: parentId,
    from_team_id: fromTeamId,
    to_team_id: toTeamId,
  }, {
    keepSilent: true,
  });
  return res;
}

export async function doCopy(ids, parentId, toTeamId) {
  const res = post(api.COPY_FILE, {
    resourceIds: ids,
    toParentId: parentId,
    toSpaceId: toTeamId,
  }, {
    keepSilent: true,
  });
  return res;
}

export async function getCopyProgress(copyId) {
  const data = await post(api.COPY_PROGRESS, copyId);
  const totalSize = Number(data.totalSize || 1);
  const copySize = Number(data.copySize || 0);
  return {
    totalSize,
    copySize,
  };
}

export async function createFolder(name, parentId, teamId = 0) {
  // teamId=0: 个人空间
  // teamId>0: 团队
  return post(api.CREATE_DIRECTORY, {
    spaceId: teamId,
    name,
    parentId,
  }, {
    keepSilent: true,
  }).then((res) => {
    const fold = {
      id: res.id,
      teamId,
      name,
      icon: icFolderIcon,
      type: N_DIR,
      children: [],
      hasChildren: false,
      hasOpened: false,
      hasSelected: true,
      hasPermis: true,
    };
    return fold;
  }).catch((err) => {
    if (err.errorCode === 1082) {
      message.error(intl.t('您没有权限！请联系空间管理员添加上传权限。'));
    } else {
      message.error(err.message);
    }
    return Promise.reject(err);
  });
}

export const getBussinessIds = (parentLocationUrl) => {
  const pathname = parentLocationUrl || window.location.pathname;
  // 个人空间
  if (/^\/disk\/|^\/files\//.test(pathname)) {
    const m = pathname.match(/^\/files\/(\d+)$/);
    const parentId = m ? Number(m[1]) : 0;
    return { parentId, teamId: 0 };
  }

  // 团队空间
  if (/^\/team-file\//.test(pathname)) {
    const m1 = pathname.match(/^\/team-file\/\d+\/(\d+)$/);
    const parentId = m1 ? Number(m1[1]) : 0;
    const m2 = pathname.match(/^\/team-file\/(\d+)/);
    const teamId = m2 ? Number(m2[1]) : 0;
    return { parentId, teamId };
  }

  // 其他页面
  return { parentId: 0, teamId: 0 };
};
