/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-10-12 11:01:07
 * @LastEditTime: 2023-11-14 15:32:05
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/serviceComponents/AsideDashboard/index.js
 *
 */

import classBind from 'classnames/bind';
import { CATALOG_MENHU } from '@/constants/catalog';
import { NavLink, useLocation } from 'react-router-dom';
import { isDesktopDC } from '@/utils';
import stylesCommon from '@/components/serviceComponents/AsideDK/catalogCommon.module.less';
import styles from './style.module.less';
import { useEffect, useState } from 'react';

const cx = classBind.bind(styles);
const cm = classBind.bind(stylesCommon);


function AsideDashboard() {
  const [activeItem, setActiveItem] = useState(null);
  const [list, setList] = useState(CATALOG_MENHU);
  const { pathname } = useLocation();


  useEffect(() => {
    setActiveItem(pathname);
  }, [pathname]);

  const clickRecent = (item) => {
    const { link } = item;
    setActiveItem(link);
    if (link === '/recent') {
      window.__OmegaEvent('ep_dkpc_dkhome_recent_ck');
    }
  };

  const itemLis = (item) => {
    return <li
      key={item.key}
      onClick={() => clickRecent(item)}
      className={`${cx('aside-menu-li')} ${cm('aside-menu-li-common')} ${cm('aside-menu-li-common-out')}`}
    >
      <NavLink
        to={`${item.link}`}
        key={item.key}
        className={() => {
          return `${cm('aside-li-item', { 'aside-li-item-active': activeItem == item.link })} ${cx('aside-li-item', { 'aside-li-item-dc': isDesktopDC })}`
        }}>
        {
          item.key === 2 ? <i
            className={cm({
              'menu-root-icon': true,
              'dk-iconfont': true,
              [item.iconName]: true,
            })}
          /> : <i
            className={cm({
              'menu-root-icon': true,
              'dk-iconfont': true,
              [item.iconName]: !isDesktopDC,
              [item.iconNameDC]: isDesktopDC,
            })}
          />
        }
        <div className={cm('aside-li-text')} >{typeof item.title === 'function' ? item.title() : item.title}</div>

      </NavLink>
    </li>
  }

  return (
    <div
      className={
          `${cm('aside-wrap-common')} ${cx('menhu-aside-wrap', {
            'menhu-aside-wrap-dc': isDesktopDC,
          })}`
      }
      id="menhu-aside"
    >
      <ul className={`${cx('aside-container')} ${cm('aside-container-common')}`}>
        {
          list.map((item) => {
            return <div key={item.key}>
              {item.key === 3 && <div className={cx('mh-aside-div')} />}
              {itemLis(item)}
            </div>
          })
        }
      </ul>

    </div>
  );
}

export default AsideDashboard;
