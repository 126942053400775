import { intl } from 'di18n-react';
import { message } from 'antd';
/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-06-15 14:42:19
 * @LastEditTime: 2023-11-27 17:18:13
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/CooperOperation/CreateCollab/index.js
 *
 */
import { isDC } from '@/utils/index';
import {
  createExcelRequest,
  createAnonymousWordRequest,
  createWordRequest,
  createDiDocWordRequest,
  createSlideRequest,
  createWord2Request,
  createExcel2Request,
  createSlide2Request,
  createDiagramingRequest,
  createMindMapRequest,
} from '@/service/cooper/cooperFile';
import { CreateType } from '@/constants/cooper';
import showImportTip from './showImportTip';

const { DOCUMENT2, DOCUMENT, SHEET, SHEET2, SLIDE, SLIDE2, ANONYMOUS, DIDOC, DIAGRAMING, MINDMAP } = CreateType;
async function createCollab(type, parentId, teamId, templateId) {
  const createMap = {
    [DOCUMENT2]: createWord2Request,
    [DOCUMENT]: createWordRequest,
    [SHEET]: createExcelRequest,
    [SHEET2]: createExcel2Request,
    [SLIDE]: createSlideRequest,
    [SLIDE2]: createSlide2Request,
    [ANONYMOUS]: createAnonymousWordRequest,
    [DIDOC]: createDiDocWordRequest,
    [DIAGRAMING]: createDiagramingRequest,
    [MINDMAP]: createMindMapRequest
  };

  const docMap = {
    [DOCUMENT]: '/docs/document/:id',
    [DOCUMENT2]: '/docs2/document/:id',
    [SHEET]: '/docs/sheet/:id',
    [SLIDE]: '/docs/slide/:id',
    [SHEET2]: '/docs2/sheet/:id',
    [SLIDE2]: '/docs2/slide/:id',
    [ANONYMOUS]: '/docs/document/:id',
    [DIDOC]: '/didoc/file/:id',
    [DIAGRAMING]: '/docs/diagraming/:id',
    [MINDMAP]: '/docs/mindmap/:id'
  };

  const createFunc = createMap[type];

  const tips = intl.t('创建文档中...');
  const close = showImportTip(tips);

  let win = null;
  try {
    if (!isDC()) {
      win = window.open('about:blank');
      win.document.title = tips;
    }
    const id = await createFunc(parentId, teamId);
    let url = docMap[type].replace(':id', id);

    if (templateId) {
      url = docMap[type].replace(':id', id) + '?templateId=' + templateId
    }

    if (isDC()) {
      window.open(url);
      return;
    }
    if (process.env.NODE_ENV === 'development') {
      let locat = window.location.origin;
      if (type.indexOf('2') !== -1) {
        locat = 'http://localhost:3003';
      } else {
        locat = 'http://localhost:3002';
      }
      win.location.replace(locat + url);
      return;
    }
    win.location.replace(url);
  } catch (e) {
    if (win) win.close();
  } finally {
    close();
  }
}

export default createCollab;
