/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-05-16 14:42:13
 * @LastEditTime: 2023-05-16 14:59:35
 * @Description: 空白数据占位图
 * @FilePath: /knowledgeforge/src/components/cooper/CooperEmptyTip/index.js
 *
 */
import classBind from 'classnames/bind';
import styles from './style.module.less';
import { CooperEmptyImg } from '@/assets/icon/index';

const cx = classBind.bind(styles);

function CooperEmptyTip(props) {
  const { title, prefixText, callbackText, callback } = props;
  return (
    <div className={cx('cooper-empty-tip')}>
      <div>
        <img
          draggable='false'
          src={CooperEmptyImg} />
        <p>{title}</p>
        {!!prefixText && <p className='title'>
          {`${prefixText} `}
          {!!callbackText && <a
            href='#'
            onClick={callback}>{callbackText}</a>}
        </p>
        }
      </div>
    </div>
  )
}

export default CooperEmptyTip
