import { openNewWindow } from '@/utils/index';


function openDk({
  knowledgeId,
}) {
  openNewWindow(`/knowledge/${knowledgeId}/home`);
}

export default openDk;
