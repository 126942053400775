import { Popover } from 'antd';
import { intl } from 'di18n-react';
import './authTip.less'
/**
 * 
 * @param {*} props 
 * text 提示文案内容
 * tips 链接组 包含链接和文案
 * @returns 
 */
const AuthTip = (props) => {
  const { text, tips, isHovered, location } = props

  const replaceWithBold = (inputString) => {
    // 正则表达式用于匹配${}内的内容
    const regex = /\${(.*?)\}/g;
    if (typeof inputString == "string") {
      // 使用String.prototype.replace方法和一个替换函数来进行替换
      return intl.t(inputString).replace(regex, function(match, group1) {
        // group1包含了${}内的文本
        return `<span class="auth-type">${intl.t(group1)}</span>`;
      }); 
    } else {
      return inputString
    }
    
  }

  const openUrl = (e, urlParam) => {
    e.stopPropagation();
    window.open(urlParam)
  }

  const content = (
    <div className='global-auth-tip'>
      {typeof text == "string" ?
        <p dangerouslySetInnerHTML={{ __html: replaceWithBold(text) }} />
        : <p>{text}</p>}
      <div className='line'></div>
      {tips && tips.map(v => (
        <a onClick={(e) => openUrl(e, v.link)} target='_blank'>{intl.t(v.text)}</a>
      ))}
    </div>
  );

  return (
    <Popover
      content={content}
      title=""
      placement={'right'} 
      autoAdjustOverflow={true}
      getPopupContainer={(triggerNode) => triggerNode}
      arrowPointAtCenter={true}
      zIndex={99999999}
      overlayClassName='global-auth-tip-pop'
      // visible={isHovered}
    >
      {props.children}
    </Popover>)
}


export default AuthTip