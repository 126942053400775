/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-07-24 17:35:07
 * @LastEditTime: 2024-03-28 16:58:53
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/CooperOperation/PreviewFile/index.js
 *
 */
import { FilePreview, getPreviewType } from '@/components/FilePreview/main';
import store from '@/model';
import mountAnywhere from '@/utils/mountAnywhere';
import { Provider } from 'react-redux';

/* eslint-disable camelcase */
function PreviewFile({
  resourceId: id,
  resourceName: name,
  shareId,
  shareLink,
  markedQuickVisit: marked_quick_visit,
  teamId,
  objectType,
  sourceId,
  quickId,
  shareType,
  filesList,
  fidx,
  parentId,
  spaceId,
  doneCallback,
  relationTypeTags,
  hasOperation = true,
}) {
  let file = {
    id,
    name,
    shareId,
    shareLink,
    marked_quick_visit,
    teamId,
    objectType,
    sourceId,
    quickId,
    shareType,
    parentId,
    spaceId,
    relationTypeTags,
  };

  const type = getPreviewType(file);
  let close = () => { };

  function quickVisitCallback() {
    file.marked_quick_visit = !file.marked_quick_visit;
  }

  const isMain = true;
  //  filesList 当前需要预览的所有list
  //  fidx 当前点击的那一个
  //  当不需要图片预览的时候就用默认参数
  const Comp = (
    <Provider store={store}>
      <FilePreview
        files={filesList || [file]}
        fidx={fidx || 0}
        closePreview={() => close()}
        refreshFolder={doneCallback || quickVisitCallback}
        type={type}
        // 针对主页无法触发预览渲染做的折中
        isMain={isMain}
        spaceId={spaceId}
        teamId={teamId}
        hasOperation= {hasOperation}
      />
    </Provider>

  );

  close = mountAnywhere(Comp);
}
export default PreviewFile;
