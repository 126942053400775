
export default {
  name: 'CreateKnowledge',
  state: {
    choosenTemplate: {},
    chooseIcon: '',
  },
  reducers: {
    setChoosenTemplate(state, choosenTemplate) {
      return { ...state, choosenTemplate };
    },
    setChoosenIcon(state, chooseIcon) {
      return { ...state, chooseIcon };
    },
  },
  effects: () => ({
  }),
};
