/*
 * @Date: 2024-02-28 17:03:05
 * @LastEditors: guanzhong guanzhong@didiglobal.com
 * @LastEditTime: 2024-04-17 14:38:00
 * @FilePath: /knowledgeforge/src/service/cooper/teamDetail.js
 * @Description: 描述文件功能
 */
import api from '@/utils/request/api/CooperApi';
import { get, post, put } from '@/utils/request/cooper';
import { message } from 'antd';
import { history } from '@/model';

export const getTeamDetail = async (spaceId) => {
  let data = null
  await get(api.TEAM_DETAIL.replace(':teamId', spaceId)).then((res) => {
    data = res;
  }).catch((err) => {
    message.error(err.message);
    history.push('/team-file/team-invalid')
    data = {}
  })
  return data;
};

export const getTeamFunc = async (spaceId) => {
  let data = await get(api.TEAM_FUNCTION.replace(':teamId', spaceId));
  return data;
};

export const getPersonTeamFunc = async () => {
  let data = await get(api.TEAM_PERSON_FUNCTION);
  return data;
};

export const getTeamDynamic = async (params) => {
  let data = await get(api.TEAM_DYNAMIC, { params });
  return data;
}

export const saveSpaceLayout = async (spaceId, layout) => {
  let res = await post(api.TEAM_LAYTOUT.replace(':teamId', spaceId), layout);
  return res;
}

export const getSpaceKnowledgeList = async (spaceId) => {
  let res = await get(api.TEAM_KNOWLEDGE_LIST.replace(':teamId', spaceId));
  return res;
}

export const getSpaceKnowledgeDynamic = async (spaceId, knowledgeId) => {
  let res = await get(`${api.TEAM_KNOWLEDGE_DYNAMIC}?spaceId=${spaceId}&knowledgeId=${knowledgeId}&pageNum=0&pageSize=3`);
  return res;
}

export const replaceTeamFunc = async (funcs) => {
  let data = await put(api.UPDATE_TEAM_FUNCTION, funcs);
  return data;
};

