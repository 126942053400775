import { intl } from 'di18n-react';
import { useState } from 'react';
import { Popover } from 'antd';
import classBind from 'classnames/bind';
import { DKLogoIcon, BetaIcon } from '@/assets/icon';
import styles from '@/components/serviceComponents/Header/style.module.less';

const cx = classBind.bind(styles);

const ProjectJump = () => {
  const [cooperEnterVisible, setCooperEnterVisible] = useState(false);

  const handleCooperEnterVisible = (visible) => {
    setCooperEnterVisible(visible);
  };

  const gotoDkHomeFromIcon = () => {
    window.__OmegaEvent('ep_dkpc_icon_returndk_ck');
  };

  const gotoCooper = () => {
    window.__OmegaEvent('ep_dkpc_returncooper_ck');
    if (process.env.NODE_ENV === 'development') {
      window.location.href = 'http://localhost:4001/';
    } else {
      window.location.href = window.location.origin;
    }
  };

  const gotoDkHomeFromSwitch = () => {
    window.__OmegaEvent('ep_dkpc_returndk_ck');
    window.location.href = '/knowledge';
  };

  const gotoTeamCenter = () => {
    window.__OmegaEvent('ep_dkpc_returnteam_ck');
    // window.location.href = window.location.hostname === 'cooper.didichuxing.com' ? 'https://team.didichuxing.com' : 'https://team-test.didichuxing.com';
    switch (window.location.hostname) {
      case 'cooper.didichuxing.com':
        window.location.href = 'https://team.didichuxing.com';
        break;
      case 'cooper-qa.didichuxing.com':
        window.location.href = 'https://team-qa.intra.xiaojukeji.com/';
        break;
      default:
        window.location.href = 'https://team-test.didichuxing.com';
    }
  };

  const IconChoose = (
    <div className={cx('cooperEnterMenu')}>
      <div
        onClick={gotoCooper}
        className={cx('cooperEnterRow')}>
        <span
          className={`${cx('home-list-icon')} ${cx('home-list-icon-cooper')}`}
        />
        Cooper
      </div>
      <div
        onClick={gotoDkHomeFromSwitch}
        className={cx('cooperEnterRow')}>
        <span
          className={`${cx('home-list-icon')} ${cx('home-list-icon-dk')}`}
        />
        {intl.t('知识库')}
      </div>
      <div
        onClick={gotoTeamCenter}
        className={cx('cooperEnterRow')}>
        <span
          className={`${cx('home-list-icon')} ${cx('home-list-icon-team')}`}
        />
        {intl.t('团队中心')}
      </div>
    </div>
  );

  return (
    <>
      <Popover
        overlayClassName={`dk-ant-popover__reset ${cx(
          'cooperEnterChoose',
        )} knowLedge_cooperEnter`}
        trigger="click"
        content={IconChoose}
        visible={cooperEnterVisible}
        onVisibleChange={handleCooperEnterVisible}
        placement="bottomLeft"
      >
        <i
          className={cx(
            'dk-icon-qiehuanxitong1',
            'dk-iconfont',
            'cooperEnter',
            {
              'cooperEnter-active': cooperEnterVisible,
            },
          )}
        />
      </Popover>
      <a
        href="/knowledge"
        onClick={gotoDkHomeFromIcon}>
        <img
          src={DKLogoIcon}
          className={cx('dk-logo')}
          alt={intl.t('知识库')}
        />
      </a>
    </>
  );
};

export default ProjectJump;
