/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-09-15 15:55:53
 * @LastEditTime: 2023-09-18 15:37:30
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/common/CooperTabs/index.js
 *
 */
import React from 'react';
import { Tabs } from 'antd';
import classBind from 'classnames/bind';
import styles from './style.module.less';

const LARGE = 'large'
const SMALL = 'small'

const cx = classBind.bind(styles);

export const { TabPane: CooperTabsPane } = Tabs
// 提供 tabSize lage: 16px  & small 14px 两种
export function CooperTabs(props) {
  const { tabsize = LARGE, className } = props

  return (
    <Tabs
      {...props}
      className={`${className} ${cx({
        'tab-top-title': true,
        'tab-top-large': tabsize === LARGE,
        'tab-top-small': tabsize === SMALL,
      })}` }
    >
      {props.children}
    </Tabs>
  )
}
