import { intl } from 'di18n-react';
import SuiteList from '@/components/SuiteList';
import { suiteArrow } from '@/assets/icon';

export default function () {
  return [
    {
      icon: 'http://img-ys011.didistatic.com/static/cooper_cn/do1_xX4uKPjhKyXrW7jp4qee',
      tooltip: intl.t('最近'),
      size: 18,
      localType: 'recent',
      key: 'recent',
      children: [intl.t('最近浏览'), intl.t('最近编辑')],
    },
    {
      icon: 'http://img-ys011.didistatic.com/static/cooper_cn/do1_lgNfZQlAldHk13BExl3C',
      'icon-visible': suiteArrow,
      tooltip: intl.t('切换团队套件'),
      id: 'suits-list',
      size: 28,
      width: 34,
      key: 'suits',
      children: <SuiteList />,
      overlayClassName: 'suiteEnterChoose',
      needKnowledge: true,
      // hover: true,
      local: true,
    },
  ]
}
