import { intl } from 'di18n-react';
import { Switch } from 'antd';
import { useParams } from 'react-router-dom';
import { RECENT_TABS, OWNER_TYPE } from '@/constants/recent';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import RecentList from './RecentList';
import classBind from 'classnames/bind';
import useRecentData from '@/hooks/useRecentData';
import styles from './style.module.less';
import { openNewWindow } from '@/utils';
import { useContext } from 'react';
import { CooperTabs, CooperTabsPane } from '@/components/common/CooperTabs';

const cx = classBind.bind(styles);

const RecentKnowledge = () => {
  const {
    tabInfo,
    loading,
    hasMore,
    loadMore,
    handleTabChange,
    recentList,
    activeTab,
    setOnlyCurrentDk,
  } = useRecentData();
  const { knowledgeId } = useContext(LayoutContext);

  const onChange = (checked) => {
    checked && window.__OmegaEvent('ep_dkpc_dkhead_currentdk_ck');
    setOnlyCurrentDk(checked);
    loadMore(true, OWNER_TYPE.ALL);
  };
  const gotoRecent = () => {
    openNewWindow(
      `${window.location.origin}/knowledge/recent?key=${activeTab}`,
    );
  };
  const onTabChange = (activeKey) => {
    if (activeKey === RECENT_TABS.VISIT) {
      window.__OmegaEvent('ep_dkpc_dkhead_recentview_ck');
    } else {
      window.__OmegaEvent('ep_dkpc_dkhead_recentedit_visitpage_ck');
    }
    handleTabChange(activeKey);
  };
  const NotInMenhu = !!knowledgeId;

  return (
    <div className={cx('recentKnowledge')}>
      {NotInMenhu && (
        <div className={cx('switch')}>
          <Switch
            size="small"
            onChange={onChange} />
          <span className={cx('switch-text')}>{intl.t('当前知识库')}</span>
        </div>
      )}
      <CooperTabs
        destroyInactiveTabPane={true}
        className={cx('recentKnowledge-tab')}
        onChange={onTabChange}
      >
        {tabInfo.map((item) => {
          return (
            <CooperTabsPane
              tab={item.name}
              key={item.key}>
              <RecentList
                loading={loading}
                hasMore={hasMore}
                recentList={recentList}
                activeTab={activeTab}
                loadMore={loadMore}
              />
            </CooperTabsPane>
          );
        })}
      </CooperTabs>

      <div className={cx('more')}>
        <span
          className={cx('more-click-area')}
          onClick={gotoRecent}>
          {intl.t('查看更多')}

          <i
            className={cx('dk-iconfont', 'dk-icon-youjiantou1', 'more-icon')}
          />
        </span>
      </div>
    </div>
  );
};

export default RecentKnowledge;
