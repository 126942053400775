/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-06-16 10:46:42
 * @LastEditTime: 2024-04-10 11:30:25
 * @Description: cooper的模版数据
 * @FilePath: /knowledgeforge/src/model/models/cooper/teamSpace.js
 *
 */

// import * as templateService from '@/service/cooper/template';
import * as teamSpaceService from '@/service/cooper/teamSpace';

export const COOPER_TEAM_LIST_INIT_RECENT = 'COOPER_TEAM_LIST_INIT_RECENT';
export const COOPER_TEAM_LIST_INIT_MINE = 'COOPER_TEAM_LIST_INIT_MINE';
export const COOPER_TEAM_LIST_INIT_NOT_MINE = 'COOPER_TEAM_LIST_INIT_NOT_MINE';
export const COOPER_TEAM_LIST_IN_OUT_LIST = 'COOPER_TEAM_LIST_IN_OUT_LIST';

const getDefaultState = () => ({
  teams: [],
  quota: {},
  recent: [],
  owned: [],
  joined: [],
  out: [],
  activeOwnerType: null,
});


export default {
  state: getDefaultState(),
  reducers: {
    updteState(state, action) {
      switch (action.type) {
        case COOPER_TEAM_LIST_INIT_RECENT:
          return { ...state, recent: action.recent };
        case COOPER_TEAM_LIST_INIT_MINE:
          return { ...state, owned: action.owned };
        case COOPER_TEAM_LIST_INIT_NOT_MINE:
          return { ...state, joined: action.joined };
        case COOPER_TEAM_LIST_IN_OUT_LIST:
          return { ...state, out: action.out };
        default:
          return state;
      }
    },
    setActiveOwnerType(state, type) {
      return { ...state, activeOwnerType: type };
    }
  },
  effects: {
    async getTeamListInitRecent() {
      const data = await teamSpaceService.getTeamListInitRecent()
      this.updteState({
        type: COOPER_TEAM_LIST_INIT_RECENT,
        recent: data.slice(0, 8),
      })
      return data.slice(0, 8);
    },
    async getTeamListInitMine({ ownerType, sortField }) {
      const ownerTypeCurr = ownerType ?? '0';
      const sortFieldCurr = sortField ?? '1';
      const res = await teamSpaceService.getTeamListInitMine({ownerType: ownerTypeCurr, sortField: sortFieldCurr})
      this.updteState({
        type: COOPER_TEAM_LIST_INIT_MINE,
        owned: res,
      })
      return res;
    },
    async getTeamListInitNotMine() {
      const res = await teamSpaceService.getTeamListInitNotMine()
      this.updteState({
        type: COOPER_TEAM_LIST_INIT_NOT_MINE,
        joined: res,
      })
      return res;
    },
    async getTeamListInOut() {
      const res = await teamSpaceService.getTeamListInOut()
      this.updteState({
        type: COOPER_TEAM_LIST_IN_OUT_LIST,
        out: res,
      })
      return res;
    },
    async removeTeamListInOut(applyId) {
      return teamSpaceService.removeTeamListInOut(applyId).then(async () => {
        await teamSpaceService.getTeamListInOut().then((res) => {
          this.updteState({
            type: COOPER_TEAM_LIST_IN_OUT_LIST,
            out: res,
          })
        })
      })
    },


    setOwnerList({
      owned
    }) {
      this.updteState({ type: COOPER_TEAM_LIST_INIT_MINE, owned });
    },

    setRecentList({
      recent
    }) {
      this.updteState({ type: COOPER_TEAM_LIST_INIT_RECENT, recent });
    },

    setJoinedList({
      joined
    }) {
      this.updteState({ type: COOPER_TEAM_LIST_INIT_NOT_MINE, joined })
    },

    setOutList({
      out
    }) {
      this.updteState({ type: COOPER_TEAM_LIST_IN_OUT_LIST, out })
    },

    
    setTeamList({
      recent,
      owned,
      joined,
      out,
    }) {
      this.updteState({ type: COOPER_TEAM_LIST_INIT_RECENT, recent });
      this.updteState({ type: COOPER_TEAM_LIST_INIT_MINE, owned });
      this.updteState({ type: COOPER_TEAM_LIST_INIT_NOT_MINE, joined })
      this.updteState({ type: COOPER_TEAM_LIST_IN_OUT_LIST, out })
    },
  },
};
