import { intl } from 'di18n-react';
import { getSuiteList } from '@/service/knowledge/global';
import { useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash-es';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import {
  qunliaoIcon,
  quanziIcon,
  teamCenterIcon,
  cooperEntryIcon,
} from '@/assets/icon';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classBind from 'classnames/bind';
import { ADMIN_SUITE_TIP, MEMBER_SUITE_TIP } from '@/constants/suite';
import styles from './style.module.less';

const cx = classBind.bind(styles);
let prefix = '';
if (
  location.hostname === 'cooper-test.didichuxing.com'
  || location.hostname === 'localhost'
) {
  prefix = '-test';
}
const config = () => {
  return {
    IM: intl.t('群聊'),
    COOPER: intl.t('云空间'),
    JZD: intl.t('圈子'),
    TEAM: intl.t('套件管理'),
  }
};
const configIcon = {
  IM: qunliaoIcon,
  COOPER: cooperEntryIcon,
  JZD: quanziIcon,
  TEAM: teamCenterIcon,
};
export default function () {
  let { knowledgeId } = useContext(LayoutContext);
  const [list, setList] = useState([
    { title: intl.t('群聊'), suiteSourceType: 'IM' },
    { title: intl.t('云空间'), suiteSourceType: 'COOPER' },
    { title: intl.t('圈子'), suiteSourceType: 'JZD' },
  ]);
  const [teamId, setTeamId] = useState();
  const { roleKey } = useSelector((state) => state.KnowledgeData.permission);

  const suiteList = async () => {
    let res = await getSuiteList(knowledgeId);
    const tempList = cloneDeep(list);
    res.suiteList?.forEach((item) => {
      const index = list.findIndex((suite) => {
        return suite.suiteSourceType === item.suiteSourceType;
      });
      tempList[index] = { ...tempList[index], ...item };
    });

    setList(tempList);
    setTeamId(res.teamId);
  };
  useEffect(() => {
    suiteList();
  }, []);
  const gotoSuite = (suite) => {
    switch (suite.suiteSourceType) {
      case 'JZD':
        window.__OmegaEvent('ep_dkpc_suitecenter_circle_ck');
        break;
      case 'COOPER':
        window.__OmegaEvent('ep_dkpc_suitecenter_space_ck');
        break;
      case 'IM':
        window.__OmegaEvent('ep_dkpc_suitecenter_chat_ck');
        break;
      default:
    }
    if (suite.url) {
      window.open(suite.url);
    } else if (hasPower) {
      gotoSuitemanage();
    }
  };
  const gotoSuitemanage = () => {
    window.__OmegaEvent('ep_dkpc_suitecenter_suitemanage_ck');
    let host = '';
    if (window.location.hostname === 'cooper.didichuxing.com' || window.location.hostname === 'cooper-x.didichuxing.com') {
      host = 'https://team.didichuxing.com';
    } else if (window.location.hostname.includes('qa.didichuxing.com')) {
      host = 'https://team-qa.intra.xiaojukeji.com';
    } else host = 'https://team-test.didichuxing.com';
    window.open(`${host}/${teamId || ''}`);
  };
  const hasPower = roleKey === 'DK_ADMIN' || roleKey === 'DK_OWNER';

  return (
    <ul className={cx('suiteEnterMenu')}>
      {list.map((v) => (
        <li
          key={v.suiteSourceType}
          onClick={() => gotoSuite(v)}
          className={cx('suiteEnterRow', {
            withDesc: teamId,
            disabled: !v.url && !hasPower,
          })}
        >
          <img
            src={configIcon[v.suiteSourceType]}
            className={`${cx('suite-list-item-icon')}`}
          />
          <div className={cx('nameArea')}>
            <div className={cx('openArea')}>
              {config()[v.suiteSourceType]}
              {teamId
                && !v.url
                && (hasPower ? (
                  <span className={cx('open', 'btn')}>
                    {intl.t('去启用')}
                    <i
                      className={cx(
                        'dk-iconfont',
                        'dk-icon-youjiantou1',
                        'icon',
                      )}
                    />
                  </span>
                ) : (
                  <span className={cx('notOpen', 'btn')}>
                    {intl.t('未启用')}
                  </span>
                ))}
            </div>
            {teamId && !v.url && (
              <div className={cx('suite-tip')}>
                {hasPower
                  ? ADMIN_SUITE_TIP()[v.suiteSourceType]
                  : MEMBER_SUITE_TIP()}
              </div>
            )}
          </div>
        </li>
      ))}
      <li className={cx('divider')} />
      <li
        onClick={gotoSuitemanage}
        className={cx('suiteEnterRow', 'suiteManager', { withDesc: teamId })}
      >
        <img
          src={teamCenterIcon}
          className={`${cx('suite-list-item-icon')}`} />
        {intl.t('套件管理')}
      </li>
    </ul>
  );
}
