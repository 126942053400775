import { Tooltip } from 'antd';
import { beautySub } from '@/utils';
import classBind from 'classnames/bind';
import styles from './style.module.less';


const cx = classBind.bind(styles);

const RestrictLength = ({ word, maxLength, customeClassName }) => {
  if (!word) return '-';
  if (typeof (word) !== 'string') return word;
  return (
    <Tooltip
      title={word}
      placement="top"
      overlayClassName={cx('restrictLength-tooltip', { hide: word.length <= maxLength })}
    >
      <span className={cx(customeClassName, 'restrictLength')}>
        {beautySub(word, maxLength)}
      </span>
    </Tooltip>
  );
};

export default RestrictLength;
