/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-05-23 12:17:58
 * @LastEditTime: 2023-07-15 11:16:17
 * @Description: 文档
 * @FilePath: /knowledgeforge/src/components/CooperOperation/OpenCollab/index.js
 *
 */
import { FileType } from '@/constants/cooper';
import { noopenerOpen } from '@/utils/cooperutils';

const MIME_SHEET = 6;
const MIME_DOCUMENT = 7;
const MIME_SLIDE = 8;
const PO_DIAGRAMING = 10;
const PO_MINDMAP = 11;

function openCollob(id, mimeType, resourceType) {
  let url = '';
  switch (mimeType) {
    // 协作文档、匿名文档
    case MIME_DOCUMENT:
      url = `/docs/document/${id}`;
      break;
    case MIME_SHEET:
      url = `/docs/sheet/${id}`;
      break;
    case MIME_SLIDE:
      url = `/docs/slide/${id}`;
      break;
    case PO_DIAGRAMING:
      url = `/docs/diagraming/${id}`;
      break;
    case PO_MINDMAP:
      url = `/docs/mindmap/${id}`;
      break;
    default:
      break;
  }

  switch (resourceType) {
    case FileType.SHIMO2_WORD:
      url = `/docs2/document/${id}`;
      break;

    case FileType.SHIMO2_EXCEL:
      url = `/docs2/sheet/${id}`;
      break;
    case FileType.SHIMO2_PPT:
      url = `/docs2/slide/${id}`;
      break;
    default:
      break;
  }


  if (url) noopenerOpen(url);
}

export default openCollob;
