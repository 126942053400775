import { intl } from 'di18n-react';
/* eslint-disable */
import { useEffect, useState, useRef, useMemo } from 'react';
import { Select, Spin } from 'antd';
import { debounce } from 'lodash-es';
import { fileSearch } from '@/service/knowledge/fileTree';
import { highlight } from '@/utils';
import classBind from 'classnames/bind';
import styles from './style.module.less';

const cx = classBind.bind(styles);
const { Option } = Select;

const SearchFile = (props) => {
  const { onChange, value, scope } = props;
  const fetchRef = useRef(0);
  const [data, setData] = useState([]);
  const [key, setKey] = useState('');
  const [fetching, setFetching] = useState(false);

  const loadOptions = async (key, scope) => {
    fetchRef.current += 1;
    const fetchId = fetchRef.current;
    setData([]);
    if (!key) {
      setFetching(false);
      return;
    }
    setFetching(true);
    const res = await fileSearch({ scope, key });
    if (fetchId !== fetchRef.current) {
      return;
    }
    setData(res.items);
    setFetching(false);
  };
  useEffect(() => {
    setKey();
  }, [value]);

  const onInputChange = useMemo(() => debounce(loadOptions, 800), []);
  const handleChange = (item) => {
    setKey(item);
    onChange(data.find((v) => v.resourceId === item));
  };

  const options = data.map((v, i) => (
    <Option key={v.resourceId} label={v.resourceName.replace(/§/g, '')}>
      <div className={cx('file-search-item')}>
        <div className={cx('item-left')}>
          <img src={v.tinyImage}></img>
        </div>
        <div className={cx('item-right')}>
          <p>{highlight(v.resourceName)}</p>
          <p>{v.pathFromRoot.map((v) => v.name).join('>')}</p>
        </div>
      </div>
    </Option>
  ));

  return (
    <div style={{ position: 'relative' }}>
      <Select
        showSearch
        className={cx('file-search-input')}
        value={key}
        placeholder={intl.t('搜索文件夹或文件名称')}
        optionLabelProp={'label'}
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        loading={fetching}
        allowClear
        onSearch={(v) => {
          setKey(v);
          onInputChange(v, scope);
        }}
        onChange={handleChange}
        notFoundContent={
          <div className={cx('not-found-content')}>
            {fetching ? (
              <Spin size="small" />
            ) : (
              intl.t('没有找到匹配结果，尝试其他关键词进行搜索')
            )}
          </div>
        }
      >
        {options}
      </Select>
      <i
        className={`${cx('dk-icon-sousuo', 'dk-iconfont')} ${cx(
          'file-search-icon'
        )}`}
      />
    </div>
  );
};

export default SearchFile;
