import { intl } from 'di18n-react';
import React from 'react';
import classNames from 'classnames';
import { Radio, message, Tooltip } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';
import Loading from '@/components/Loading/index';
import { getShareUrlRequest, setShareUrlRequest } from './actions';
import permUtil from '@/utils/perm';
import pathUtil from '@/utils/path';
import DropdownCheckbox from '@/components/common/DropdownCheckbox';
import DropdownRadio from '@/components/common/DropdownRadio';
import './index.less';


const commonPerm = 3; // [1, 2] 查看和编辑
const regionPerm = 1;
const pwd = Math.floor(100000 + Math.random() * 900000);
const defaultPassword = `${pwd}`;
const defaultExpire = 2;
const regionExpire = 3;
const radioStyle = {
  display: 'block',
  height: '20px',
  lineHeight: '20px',
  marginBottom: '10px',
};
const RadioGroup = Radio.Group;

export default class DocsShare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      changed: false,
      perm: props.info.region === 2 ? regionPerm : commonPerm,
      shareType: 0,
      password: defaultPassword,
      expire: props.info.region === 2 ? regionExpire : defaultExpire,
      inviteLinkId: 0,
      isInit: true,
    };
  }

  componentDidMount() {
    const { id, info } = this.props;
    const { password, expire } = this.state;

    if (info.isFlowChart) {
      pathUtil.getFlowChart(info.resourceId || info.objectId || info.id)
        .then((url) => {
          this.setState({
            flowShareUrl: url,
          });
        });
    }

    const defaultPerm = (info.isFlowChart || info.region === 2) ? regionPerm : commonPerm;

    getShareUrlRequest(id, defaultPerm, password, expire)
      .then((data) => {
        this.setState({
          code: data.code,
          perm: data.permission,
          password: data.password,
          shareType: data.password === '' ? 1 : 2,
          expire: data.expiration,
          inviteLinkId: data.inviteLinkId,
        });
        this.setState({
          isInit: false,
        })
      })
      .catch((err) => message.error(err.message));
  }

  hideModal = () => {
    const { onClose = () => { } } = this.props;
    this.setState(
      {
        code: '',
      },
      () => onClose(),
    );
  };

  handlePermChange = (values) => {
    const newPerm = values.reduce((a, b) => a + b, 0);
    this.setState(
      {
        changed: true,
        perm: newPerm,
      },

      () => {
        this.regenerate();
      },
    );
  };

  regenerate = () => {
    const { perm, shareType, password, expire, inviteLinkId } = this.state;

    if (shareType === 2 && password.trim() === '') {
      message.error(intl.t('密码不能为空'));
      return;
    }

    setShareUrlRequest(inviteLinkId, perm, password, expire)
      .then((code) => {
        this.setState({
          code,
          changed: false,
        });

        message.success(intl.t('修改链接权限成功'));
      })
      .catch((err) => message.error(err.message));
  };

  handleExpireChange = (value) => {
    this.setState(
      {
        expire: value,
        changed: true,
      },

      () => {
        this.regenerate();
      },
    );
  };

  handleTypeChange = (e) => {
    let newPwd = Math.floor(100000 + Math.random() * 900000);

    if (e.target.value === 1) {
      newPwd = '';
    }

    const obj = {
      shareType: e.target.value,
      changed: true,
      password: `${newPwd}`,
    };

    this.setState(obj, () => {
      this.regenerate();
    });
  };

  render() {
    const { code, password, shareType, expire, perm, flowShareUrl } = this.state;
    const { info } = this.props;

    if (shareType === 0) {
      return <div style={{ minHeight: 378, overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Loading /></div>;
    }

    const parsedPerm = permUtil.parsePerm.call(this, perm);
    let sharePath = '';
    let copyPath = '';

    if (code) {
      const pathname = info.isFlowChart ? flowShareUrl
        : pathUtil.getCoopPath(info.resourceId || info.id || info.objectId, info.mime_type, info.type, info.objectType);

      sharePath = `${window.location.protocol}//${window.location.host}${pathname}`;
      copyPath = `${intl.t('文件名称')}：${info.fileName || info.resourceName}\n${intl.t('链接')}：${sharePath}`;

      if (password) {
        copyPath += `\n${intl.t('密码')}：${password}`;
      }
    }

    return (
      <div>
        <div className='share-modal'>
          <div className='share-modal__form'>
            <div className='modal-field modal-field__radio'>
              <span className='field-name field-sharename'>
                {intl.t('分享类型')}
              </span>
              <RadioGroup
                onChange={this.handleTypeChange}
                value={shareType}>
                <Radio
                  style={radioStyle}
                  value={2}
                >
                  {intl.t('私密')}
                  <small>{intl.t('需要密码验证才可以查看协作文档内容')}</small>
                </Radio>
                {
                  (info.region === 2) ? (
                    <Tooltip
                      placement='topLeft'
                      title={intl.t('外部空间内的文件不支持设为公开')}
                    >
                      <Radio
                        style={radioStyle}
                        value={1}
                        disabled={true}
                      >
                        {intl.t('公开')}
                        <small>{intl.t('任何人都可通过链接查看协作文档内容')}</small>
                      </Radio>
                    </Tooltip>

                  ) : (
                    <Radio
                      style={radioStyle}
                      value={1}
                    >
                      {intl.t('公开')}
                      <small>{intl.t('任何人都可通过链接查看协作文档内容')}</small>
                    </Radio>
                  )
                }
              </RadioGroup>
            </div>
            <div className='modal-field'>
              <span className='field-name'>{intl.t('有效期')}</span>
              <DropdownRadio
                options={[
                  {
                    id: 1,
                    label: intl.t('永久有效'),
                  },

                  {
                    id: 2,
                    label: `1${intl.t('个月')}`,
                  },

                  {
                    id: 3,
                    label: `1${intl.t('周')}`,
                  },

                  {
                    id: 4,
                    label: `1${intl.t('天')}`,
                  },
                ]}
                onChange={this.handleExpireChange}
                value={expire}
              />
            </div>
            <div className='modal-field share-perm'>
              <span className='field-name'>{intl.t('访问权限')}</span>
              <DropdownCheckbox
                options={permUtil.getPerms.call(this, info.isFlowChart).map((p) => ({
                  id: p.id,
                  label: p.label,
                  value: p.perm,
                  disabled: p.disabled,
                  desc: p.desc,
                }))}
                showDesc
                onChange={this.handlePermChange}
                value={parsedPerm.map((p) => p.perm)}
              />
            </div>
          </div>
          <div className='share-modal__form share-modal__shareform'>
            <div className='modal-field'>
              <span className='field-name'>{intl.t('链接')}</span>
              <div
                className={classNames('share-input__wrapper', {
                  active: password !== '',
                })}
              >
                <input
                  title={sharePath}
                  value={sharePath}
                  readOnly />
                {password !== '' && (
                  <span className='share-pwd'>
                    {intl.t('密码')}：{password}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className='share-modal__actions'>
            <button
              className='btn-share__cancel'
              onClick={() => {
                this.props.onCancel();
              }}
            >
              {intl.t(' 取消')}
            </button>
            <CopyToClipboard
              text={copyPath}
              onCopy={() => {
                if (!this.state.isInit) {
                  message.success(intl.t('复制成功'));
                }
              }}
            >
              <button
                className='btn-share__ok'
                id='btn-share'>
                {password === ''
                  ? intl.t('复制链接')
                  : intl.t('复制链接和密码')}
              </button>
            </CopyToClipboard>
          </div>
          <p className='share-modal__tip'>
            {intl.t('提示：邀请人要对添加的成员负责，请确认添加成员及访问权限')}
          </p>
        </div>
      </div>
    );
  }
}
