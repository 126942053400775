/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-05-19 11:18:45
 * @LastEditTime: 2023-09-20 16:37:58
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/RecentActivity/goToPath.js
 *
 */
import { intl } from 'di18n-react';
import { message } from 'antd';
// import pathUtil from '@/utils/path';
import { openNewWindow } from '@/utils/index';
import openDk from '@/components/CooperOperation/OpenDk';
import { FileType } from '@/constants/cooper';


function goToPath(f, cf, navigate, isNewWindow = false) {
  const { resourceTypeStr, teamId, shareLink, shareId, id, deleted = false } = f

  if (deleted) {
    message.error(intl.t('当前文件已被删除或移动，请进入回收站或搜索进行查找哦'));
    return;
  }

  // 知识库页面跳转到知识库
  if (resourceTypeStr === FileType.DK_FILE || resourceTypeStr === FileType.DK_PAGE) {
    openDk({
      // knowledgeId: f.teamId || f.team_id
      knowledgeId: f.teamId,
    })
    return
  }
  // wiki页面跳转到wiki空间
  if (resourceTypeStr === FileType.WIKI_PAGE) {
    let wikiUrl = ''
    if (process.env.APP_ENV === 'prod') {
      wikiUrl = `http://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=${teamId}`
    } else {
      wikiUrl = `http://wiki-qa.intra.xiaojukeji.com/pages/viewpage.action?pageId=${teamId}`
    }
    openNewWindow(wikiUrl)
    return
  }

  // 公开分享跳转
  if (shareLink) {
    isNewWindow ? openNewWindow(`/shares/${shareLink}`) : navigate(`/shares/${shareLink}`);
    return;
  }

  // 定向分享
  if (shareId) {
    isNewWindow ? openNewWindow('/tome') : navigate('/tome');
    return;
  }


  // if(SHIMO_ARR.includes(resourceTypeStr) || resourceTypeStr === FileType.DI_DOC) {
  //   if (f.is_deleted || f.isDeleted || f.deleted) {
  //     message.error(intl.t('当前文件已被删除或移动，请进入回收站或搜索进行查找哦'));
  //   } else {
  //     if (SHIMO_ARR.includes(resourceTypeStr)) {
  //       console.log(pathUtil.getCoopPath(f.id || f.resource_id || f.resourceId, f.mime_type || f.mimeType, resourceTypeStr));
  //       // openNewWindow(pathUtil.getCoopPath(f.id || f.resource_id || f.resourceId, f.mime_type || f.mimeType));
  //       // console.log(pathUtil.getCoopPath(f.id || f.resource_id || f.resourceId, f.mime_type || f.mimeType));
  //     } else {
  //       console.log(pathUtil.getDiDocPath(f.id || f.resource_id || f.resourceId));
  //       openNewWindow(pathUtil.getDiDocPath(f.id || f.resource_id || f.resourceId));
  //     }
  //   }
  //   return
  // }

  // 普通文件夹跳转
  // let id = f.filePathId || f.parentId || f.parent_id ; // 点击文件夹名称时，进入文件夹

  if (teamId) {
    if (id === 0) {
      // 团队根目录
      isNewWindow ? openNewWindow(`/team-file/${teamId}`) : navigate(`/team-file/${teamId}`);
    } else {
      isNewWindow ? openNewWindow(`/team-file/${teamId}/${id}`) : navigate(`/team-file/${teamId}/${id}`);
    }
  } else {
    // 个人文件
    if (id === 0) {
      isNewWindow ? openNewWindow('/disk') : navigate('/disk');
    } else {
      isNewWindow ? openNewWindow(`/files/${id}`) : navigate(`/files/${id}`);
    }
  }
}

export default goToPath;
