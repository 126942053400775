
export default {
  state: {
    openGuide: () => {},
  },
  reducers: {
    setOpenGuideFn(state, openGuide) {
      return { ...state, openGuide };
    },
  },
  effects: () => ({
  }),
};
