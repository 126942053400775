/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-06-19 14:08:04
 * @LastEditTime: 2023-06-20 16:59:09
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/CooperShareModal/service.js
 *
 */
import { get } from '@/utils/request/cooper';
import api from '@/utils/request/api/CooperApi';

export async function searchShareMember({ keyword, searchType, resourceId, itemSize, pageNum }) {
  const result = await get(`${api.SEARCH_DIRECT_SHARE}?keyword=${encodeURIComponent(keyword)}&searchType=${searchType}&resourceId=${resourceId}&itemSize=${itemSize}&pageNum=${pageNum || 0}`, {
    needDD05: true,
  });
  return result;
}
