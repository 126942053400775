/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-11-08 15:17:24
 * @LastEditTime: 2023-11-08 17:02:43
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/serviceComponents/Header/UserInfo/index.js
 *
 */
import { getLocale, intl, setLocale } from 'di18n-react';
import { useSelector } from 'react-redux';
import classBind from 'classnames/bind';
import logout from '@/service/knowledge/login';
import { Popover, Avatar } from 'antd';
import { AvatarIcon } from '@/assets/icon';

import styles from '@/components/serviceComponents/Header/style.module.less';

const cx = classBind.bind(styles);

const UserInfo = () => {
  const { profile } = useSelector((state) => state.CooperIndex);

  const switchLocaleAction = () => {
    let curLocale = getLocale();

    if (curLocale === 'en-US') {
      curLocale = 'zh-CN';
    } else {
      curLocale = 'en-US';
    }

    window.g_di18n_hard_reload = true;
    setLocale(curLocale, { cookieLocaleKey: 'local_language' });
  };

  const LoginOut = (
    <div className={cx('menu')}>
      <div
        className={cx('row')}
        onClick={switchLocaleAction}
      >
        {getLocale() === 'zh-CN' ? 'English' : intl.t('中文')}
      </div>
      <div
        className={cx('row')}
        onClick={logout}
      >
        {intl.t('注销')}
      </div>
    </div>
  );

  return (
    <Popover
      overlayClassName={`dk-ant-popover__reset ${cx(
        'loginOutChoose',
      )} knowLedge_loginOut`}
      trigger="click"
      content={LoginOut}
      placement="bottom"
    >
      <Avatar
        src={profile.avatar || AvatarIcon}
        className={cx('avatar')} />
    </Popover>
  );
};

export default UserInfo;
