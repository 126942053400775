/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-05-23 12:18:23
 * @LastEditTime: 2023-07-18 15:38:47
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/CooperOperation/OpenFolder/index.js
 *
 */
import { openNewWindow } from '@/utils/index';
import { history } from '@/model';
import { FileType } from '@/constants/cooper';


function openFolder({
  resourceId, teamId = 0, shareId, spaceType, isNewWindow = true, linkUrl = '',
}) {
  let url;
  if (linkUrl.indexOf('tome') !== -1) {
    isNewWindow ? openNewWindow(linkUrl) : history.push(linkUrl);
    return;
  }
  if (Number(shareId)) {
    if (resourceId === 0 || !shareId) {
      url = '/tome';
    } else {
      url = `/tome/${shareId}/${resourceId}`;
    }
    isNewWindow ? openNewWindow(url) : history.push(url);
    return;
  }


  if (spaceType) {
    if (
      spaceType === FileType.PERSONAL_SPACE
      || spaceType === FileType.PERSONAL_SPACE_V2
      || spaceType === FileType.PERSONAL_SPACE_V3) {
      if (Number(resourceId) === 0) {
        url = '/disk';
      } else {
        url = `/files/${resourceId}`;
      }
    } else if (spaceType === FileType.SPACE
      || spaceType === FileType.SPACE_V2
      || spaceType === FileType.SPACE_V3
    ) {
      if (Number(resourceId) === 0) {
        url = `/team-file/${teamId}`;
      } else {
        url = `/team-file/${teamId}/${resourceId}`;
      }
    }
    isNewWindow ? openNewWindow(url) : history.push(url);

    return;
  }

  if (Number(resourceId) === 0) {
    if (Number(teamId)) {
      url = `/team-file/${teamId}`;
    } else {
      url = '/disk';
    }
  } else if (Number(teamId)) {
    url = `/team-file/${teamId}/${resourceId}`;
  } else {
    url = `/files/${resourceId}`;
  }
  isNewWindow ? openNewWindow(url) : history.push(url);
}

function getFolderUrl({ resourceId, teamId = 0, shareId, spaceType }) {
  let url = window.location.origin;
  if (Number(shareId)) {
    if (resourceId === 0 || !shareId) {
      url += '/tome';
    } else {
      url += `/tome/${shareId}/${resourceId}`;
    }
    return url;
  }


  if (spaceType) {
    if (spaceType === 'person') {
      if (Number(resourceId) === 0) {
        url += '/disk';
      } else {
        url += `/files/${resourceId}`;
      }
    } else if (spaceType === 'team') {
      if (Number(resourceId) === 0) {
        url += `/team-file/${teamId}`;
      } else {
        url += `/team-file/${teamId}/${resourceId}`;
      }
    }

    return url;
  }

  if (Number(resourceId) === 0) {
    if (Number(teamId)) {
      url += `/team-file/${teamId}`;
    } else {
      url += '/disk';
    }
  } else if (Number(teamId)) {
    url += `/team-file/${teamId}/${resourceId}`;
  } else {
    url += `/files/${resourceId}`;
  }
  return url;
}

export default openFolder;

export { getFolderUrl }
