import { intl } from 'di18n-react';
import { useEffect, useRef, useState } from 'react';
import classBind from 'classnames/bind';
import styles from './style.module.less';
import { searchKnowledgePages } from '@/service/knowledge/transfer';
import SearchPageList from '../SearchPageList';
import useDebounceFn from '@/hooks/useDebounceFn';
import { PAGE_SIZE } from '@/constants';
import { Input, message } from 'antd';

const cx = classBind.bind(styles);
const TransferSearch = ({
  currentPageParams,
  dkList,
  handleClickSearchPage,
  hasChecked,
  checkable,
  modalRender,
}) => {
  const [value, setValue] = useState(); // input输入的内容
  const [searchPageList, setSearchPageList] = useState([]); // 搜索页面的list
  const [loading, setLoading] = useState(false); // loading
  const { knowledgeName, knowledgeId } = currentPageParams;
  const [currrentKnowledgeId, setCurrrentKnowledgeId] = useState();

  // 确保知识库id的更新
  useEffect(() => {
    setCurrrentKnowledgeId(knowledgeId);
  }, [knowledgeId]);
  // 搜索
  const handleSearch = async (key) => {
    const _value = key?.trim() || '';
    if (!_value) {
      setLoading(false);
      setSearchPageList([]);
      return;
    }
    try {
      let res = await searchKnowledgePages({
        key,
        pageNum: 0,
        pageSize: PAGE_SIZE,
        metisIds: currrentKnowledgeId,
        searchType: '4', //
      });
      const { pages } = res || {};
      pages?.items.forEach((item) => {
        item.path = item?.fullPathResourceId.map((i) => `${i}`);
        item.pageId = `${item?.resourceId}`;
        item.knowledgeId = `${item?.metisId}`;
      });
      setSearchPageList(res.pages);
      if (checkable) {
        window.__OmegaEvent('ep_dkpc_immigrate_search_ck');
      } else {
        window.__OmegaEvent('ep_dkpc_move_search_ck');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const { run: debounceDel } = useDebounceFn(handleSearch, { wait: 800 });
  const handleChangeValue = (e) => {
    setLoading(true);
    setValue(e.target.value);
    debounceDel(e.target.value);
  };

  useEffect(() => {
    // 清空的时候搜索
    if (currrentKnowledgeId === null) {
      setLoading(true);
      handleSearch(value);
    }
  }, [currrentKnowledgeId]);

  const searchOnScope = () => {
    return (
      <div className={cx('search-before')}>
        <i
          className={`${cx('dk-icon-sousuo', 'dk-iconfont')}`}
          style={{ color: '#bbb' }}
        />

        {dkList && currrentKnowledgeId && (
          <p className={cx('search-tag')}>
            <span className={cx('search-tag__text')}>{knowledgeName}</span>
            <span
              style={{ cursor: 'pointer', height: 24 }}
              onClick={() => setCurrrentKnowledgeId(null)}
            >
              <i
                className={`${cx(
                  'dk-icon-guanbi',
                  'dk-iconfont',
                  'search-close-icon',
                )}`}
                style={{ fontSize: 20 }}
              />
            </span>
          </p>
        )}
      </div>
    );
  };

  const onClickSearchPage = (params) => {
    const { isResourceSpaceOwner, permissionOfNode, fullPath } = params;
    const omgParams = checkable
      ? 'ep_dkpc_immigrate_search_visit_ck'
      : 'ep_dkpc_move_search_visit_ck';
    if (
      (checkable && !isResourceSpaceOwner)
      || (!checkable
        && (!['PAGE_MEMBER', 'PAGE_ADMIN', 'PAGE_OWNER'].includes(
          permissionOfNode,
        )
          || fullPath.length === 0))
    ) {
      message.error(intl.t('你没有该页面的移动权限'));
      window.__OmegaEvent(omgParams, '', { perm: false });
      return;
    }
    window.__OmegaEvent(omgParams);
    if (hasChecked && params.knowledgeId !== knowledgeId && checkable) {
      modalRender(
        intl.t('确认切换知识库'),
        intl.t('切换后，选中的页面状态将清空，你将需要重新选择。'),

        () => {
          handleClickSearchPage(params);
          setValue('');
        },
      );
      return;
    }
    handleClickSearchPage(params);
    setValue('');
  };

  return (
    <div className={cx('transfer-search-wrap')}>
      <Input
        size="large"
        id="searchInput"
        placeholder={
          dkList && currrentKnowledgeId
            ? intl.t('搜索当前知识库页面')
            : intl.t('搜索知识库页面')
        }
        className={cx('search-input')}
        bordered={false}
        value={value}
        onChange={handleChangeValue}
        addonBefore={searchOnScope()}
      />

      {value && (
        <SearchPageList
          loading={loading}
          searchPageList={searchPageList}
          onClickSearchPage={onClickSearchPage}
        />
      )}
    </div>
  );
};

export default TransferSearch;
