import { cloneElement } from 'react';
import ReactDOM from 'react-dom';

function mountAnywhere(Comp, root) {
  const div = document.createElement('div');
  (root || document.body).appendChild(div);

  function close() {
    ReactDOM.unmountComponentAtNode(div);
    div?.parentNode?.removeChild(div);
  }

  // 向组件注入 onClose 方法，以便组件能调用关闭
  const Clone = cloneElement(Comp, {
    onClose: () => {
      if (Comp.props.onClose) {
        Comp.props.onClose();
      }
      close();
    },

    onCancel: () => {
      if (Comp.props.onCancel) {
        Comp.props.onCancel();
      }
      close();
    },
  });

  ReactDOM.render(Clone, div);

  return close;
}

export default mountAnywhere;
