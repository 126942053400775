import { intl } from 'di18n-react';
import { useState, useContext, useRef, useEffect } from 'react';
import classBind from 'classnames/bind';
import { Popover, Menu } from 'antd';
import useUpload from '@/hooks/uploadWiki';
import { chooseFileAndUpload } from '@/utils/index';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import usePermission from '@/hooks/usePermission';
import styles from '@/components/serviceComponents/CatalogTree/OperatePopover/style.module.less';
import ImportChoose from '../ImportChoose';
import useDebounceFn from '@/hooks/useDebounceFn';
import { useDispatch, useSelector } from 'react-redux';

const cx = classBind.bind(styles);

function AddPopover({
  doCreateSubPage,
  doCreateSubExcel,
  children,
  pageId,
  pagePerm,
  pageName,
  isRoot,
  fullPath,
}) {
  const [visible, setVisible] = useState(false);
  const { addUploadTask } = useUpload();
  const { knowledgeId } = useContext(LayoutContext);
  const { knowledgeDetail, permission = {}} = useSelector(
    (state) => state.KnowledgeData,
  );
  const { checkOperationPermission } = usePermission();

  const dispatch = useDispatch();
  const { openTransferModal } = dispatch.transfer;

  const onVisibleChange = (value) => {
    setVisible(value);
  };

  const checPerm = (page) => {
    if (!pagePerm) return false;
    let res = false;
    page && !checkOperationPermission(page, pagePerm) && (res = true);
    return res;
  };

  const { run: onClickCreatePage } = useDebounceFn(doCreateSubPage);
  const { run: onClickCreateExcel } = useDebounceFn(doCreateSubExcel);
  const { run: onClickImportFile } = useDebounceFn(chooseFileAndUpload);

  const clickMenu = (e) => {
    if (e.key === 'create') {
      let currParentId = pageId ?? '0';
      window.performance.mark(`catalog-create-start${currParentId}`);
      onClickCreatePage(currParentId);
      setVisible(false);
    } else if (e.key === 'createExcel') {
      let currParentId = pageId ?? '0';
      onClickCreateExcel(currParentId);
      setVisible(false);
    } else if (e.key === 'transfer') {
      openTransferModal({
        knowledgeId,
        pageId,
        modalTitle:
          pageName === intl.t('知识库') ? knowledgeDetail?.spaceName : pageName,
        knowledgeName: knowledgeDetail?.spaceName,
        visible: true,
        checkable: true,
        fullPath,
        isOwner: checkOperationPermission(
          'MANAGE_DK_ADVANCED_SETTING',
          permission.perm,
        ),
      });
      setVisible(false);
      window.__OmegaEvent('ep_dkpc_immigrate_ck');
    } else if (e.key === 'file') {
      onClickImportFile('file', ({ file }) => {
        addUploadTask(file, {
          knowledgeId,
          currPageId: 0,
          parentId: pageId,
          type: 'dk_file',
        });
      });
      setVisible(false);
    }
  };

  const clickImport = () => {
    setVisible(false);
  };

  const AddPopoverContent = () => {
    return (
      <div className={cx('menu-wrap')}>
        <Menu
          className={cx('operate-ul', 'operate-ul-add')}
          selectable={false}
          onClick={clickMenu}
          mode="vertical"
          triggerSubMenuAction="hover"
        >
          <Menu.Item
            key="create"
            disabled={checPerm('MANAGE_PAGE_CONTEXT_LIMIT')}
            className={cx('operate-li')}
            icon={
              <i
                className={cx(
                  'dk-icon-xinjianziyemian',
                  'operate-icon-pop',
                  'dk-iconfont',
                )}
              />
            }
          >
            {intl.t('添加{slot0}页面', { slot0: isRoot ? '' : intl.t('子') })}
          </Menu.Item>

          {
            window.editorBlockABSwitch && (
              <Menu.Item
                key="createExcel"
                disabled={checPerm('MANAGE_PAGE_CONTEXT_LIMIT')}
                className={cx('operate-li')}
                icon={
                  <i
                    className={cx(
                      'dk-icon-xinjianziyemian',
                      'operate-icon-pop',
                      'dk-iconfont',
                    )}
                  />
                }
              >
                {intl.t('添加{slot0}表格', { slot0: isRoot ? '' : intl.t('子') })}
              </Menu.Item>
            )
          }

          <Menu.SubMenu
            title={intl.t('导入')}
            disabled={checPerm('MANAGE_PAGE_CONTEXT_LIMIT')}
            key="import"
            className={cx('operate-li', 'operate-li-sub')}
            popupClassName={cx('operate-li-sub-wrap')}
            icon={
              <i
                className={cx(
                  'dk-icon-daoru2',
                  'operate-icon-pop',
                  'dk-iconfont',
                )}
              />
            }
            popupOffset={[30, -84]}
            onClick={(e) => e.domEvent.stopPropagation()}
          >
            <ImportChoose
              pageId={pageId}
              pageName={pageName}
              clickImport={clickImport}
            />
          </Menu.SubMenu>
          <Menu.Item
            key="file"
            disabled={checPerm('MANAGE_PAGE_CONTEXT_LIMIT')}
            className={cx('operate-li')}
            icon={
              <i
                className={cx(
                  'dk-icon-shangchuan',
                  'operate-icon-pop',
                  'dk-iconfont',
                )}
              />
            }
          >
            {intl.t('上传本地文件')}
          </Menu.Item>
          {checkOperationPermission(
            'MANAGE_DK_ADVANCED_SETTING',
            permission.perm,
          ) && (
            <Menu.Item
              key="transfer"
              className={cx('operate-li')}
              icon={
                <i
                  className={cx(
                    'dk-icon-qianruyemian',
                    'operate-icon-pop',
                    'dk-iconfont',
                  )}
                />
              }
            >
              {intl.t('迁入知识库页面')}
            </Menu.Item>
          )}
        </Menu>
      </div>
    );
  };

  return (
    <Popover
      placement="bottom"
      trigger="click"
      visible={visible}
      onVisibleChange={onVisibleChange}
      overlayClassName={cx('catalog-add-container', 'dk-ant-popover__reset')}
      content={AddPopoverContent}
      destroyTooltipOnHide={{ keepParent: false }}
    >
      {children}
    </Popover>
  );
}

export default AddPopover;
