/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-05-09 15:08:26
 * @LastEditTime: 2023-07-22 14:12:32
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/CooperFilesList/SortIcon/index.js
 *
 */
import React from 'react';
import './style.less';

class Index extends React.Component {
  render() {
    const { iconUp, iconDown, sort } = this.props;
    return (
      <div className='ant-table-column-sorter'>
        <span
          className={ `ant-table-column-sorter-up ${iconUp ? 'on' : 'off'}` }
          onClick={ () => sort && sort(1) }
         />
        <span
          className={ `ant-table-column-sorter-up ${iconDown ? 'on' : 'off'}` }
          onClick={ () => sort && sort(0) }
         />
      </div>
    );
  }
}

export default Index;
