/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-07-07 10:53:40
 * @LastEditTime: 2023-07-17 10:56:28
 * @Description: 收藏
 * @FilePath: /knowledgeforge/src/components/common/FileEllipsis/star.js
 *
 */
import { Tooltip } from 'antd';
import { intl } from 'di18n-react';
import starFile from '@/components/CooperOperation/StarFile';
import {
  MY_COOPER,
  TEAM_COOPER,
  SHARE_FROM_ME,
  SHARE_WITH_ME,
  STAR,
} from '@/constants/cooper';
import classBind from 'classnames/bind';
import styles from './style.module.less';

const cx = classBind.bind(styles);

export default function star(record, doneCallback, originFileType) {
  function handleStar(e) {
    e.stopPropagation();
    starFile([record], doneCallback);

    // switch (originFileType) {
    //   case MY_COOPER:
    //     break;
    //   case TEAM_COOPER:
    //     break;
    //   case SHARE_FROM_ME:
    //     break;
    //   case SHARE_WITH_ME:
    //     break;
    //   case STAR:
    //     break;
    //   default:
    //     break;
    // }
  }

  const isShowStar = record.marked_star === false || record.marked_star === 0;

  return (
    <Tooltip title={isShowStar ? intl.t('收藏') : intl.t('取消收藏')} >
      <div
        className={cx('filename-side-star')}
        onClick={(e) => handleStar(e)}
        data-e2e='collect'
        style={{ display: isShowStar ? 'none' : 'block' }}
      >
        {isShowStar ? <i
          className={
            cx('dk-iconfont', 'dk-icon-shoucang4px', 'icon-selected-1')
          }
        /> : <i
          className={
            cx('dk-iconfont', 'dk-icon-yishoucang1', 'icon-selected')
          }
        />}
      </div>
    </Tooltip>
  );
}

