import React, { useState, useRef, useEffect } from 'react';
import { Popover, Button } from 'antd';
import classBind from 'classnames/bind';
import styles from './style.module.less';

const cx = classBind.bind(styles);

const DynamicPopover = ({ showOrNot, content, placement, overlayClassName, align, children }) => {
  const triggerRef = useRef(null);

  return (
    <>
      <Popover
        content={content}
        overlayClassName={overlayClassName || cx('dynamic-popover') }
        arrowPointAtCenter
        trigger="click"
        placement={placement}
        visible={showOrNot}
        align={ align || {offset: [25, 10] }}
        getPopupContainer={() => triggerRef.current}
      >
        <span ref={triggerRef}>{children}</span>
      </Popover>
    </>
  );
};

export default DynamicPopover;
