/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-06-05 19:58:59
 * @LastEditTime: 2024-05-08 14:05:12
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/model/models/cooper/index.js
 *
 */

// cooper和知识库共用的信息
import { login } from '@/service/knowledge/login';
import { getChainfromAb, setCookie } from '@/utils/index';

export default {
  name: 'CooperIndex',
  state: {
    profile: {},
    globalOutsideChain: {},
  },
  reducers: {
    setState(state, payload = {}) {
      return {
        ...state,
        ...payload,
      };
    },

    setProfile(state, profile) {
      return { ...state, profile };
    },

  },
  effects: () => ({
    async getGlobalOutsideChain() {
      let linkObj = {};
      try {
        linkObj = await getChainfromAb();

        this.setState({ globalOutsideChain: linkObj });
      } catch (error) {}
      return linkObj;
    },

    async reqProfile() {
      const data = await login();
      setCookie('_cooper_username', data.username, 24 * 60 * 60);
      setCookie('_cooper_user_uid', data.uid, 24 * 60 * 60);
      setCookie('_cooper_user_orgMemberId', data.orgMemberId, 24 * 60 * 60);
      window.__OmegaEvent(
        "ep_cooper_login_ck",
        "多租户",
        {
          orgid: data.tenantId,
          orgname: data.tenantName
        }
      );
      if (data) {
        this.setProfile(data);
      }
      return data;
    },

  }),
};


