/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-05-23 14:07:33
 * @LastEditTime: 2023-07-31 20:10:19
 * @Description: 添加快速访问
 * @FilePath: /knowledgeforge/src/components/CooperOperation/AddQuick/index.js
 *
 */
import cooperConfirm from '@/components/common/CooperConfirm';
import { AddQuick } from '@/service/cooper/quick';
import { message } from 'antd';
import { intl } from 'di18n-react';
import { Fragment } from 'react';
import './index.less';

const goHome = () => {
  window.location = '/';
};

async function addQuick(params) {
  const {
    objectType,
    objectId,
    sourceAppId,
    sourceId,
    shareId = '',
    shareType = '',
    doneCallback,
  } = params;

  const tip = () => {
    return (
      <Fragment>
        <span style={{ display: 'inline-block', position: 'relative' }}>
          {intl.t('已添加至')}
        </span>
        <span
          className="message-tip"
          onClick={() => goHome()}>
          {intl.t('首页-快速访问')}
        </span>
      </Fragment>
    );
  };
  let quickId;
  try {
    quickId = await AddQuick({
      objectType,
      objectId,
      sourceAppId,
      sourceId: sourceId || objectId,
      shareId,
      shareType,
      resourceId: objectId,
    });

    message.success({
      content: tip(),
    });
    if (doneCallback) doneCallback(quickId);
  } catch (e) {
    if (e.errorCode === 1601) {
      cooperConfirm({
        width: 480,
        zIndex: 2001,
        title: intl.t('快速访问数量超出上限'),
        content: intl.t('快速访问数量已达到上限20个，请清理后再进行添加'),
        okText: intl.t('去清理'),
        cancelText: intl.t('取消'),
        onOk: () => (window.location = '/'),
      });
    } else {
      message.error(e.errorMessage);
    }
  }
  return quickId;
}

export default addQuick;
