import { intl } from 'di18n-react';
import { useEffect, useMemo, useState, useRef } from 'react';
import { Tree, Spin, Tooltip } from 'antd';
import useTreeData from '@/components/serviceComponents/CatalogTree/UseTreeData';
import classBind from 'classnames/bind';
import styles from './style.module.less';

const cx = classBind.bind(styles);

const PageTreeSingle = (props) => {
  const {
    currentPage,
    onChecListUpdate = () => {},
    formatNode,
    getData,
    switcherIcon,
  } = props;
  const {
    pageId,
    path = [],
    hasChild = false,
    knowledgeId,
    knowledgeName,
  } = currentPage;
  const { treeData, setData, updateNode, getNodeByKey } = useTreeData(formatNode);
  const [expandedKeys, setExpandKeys] = useState(new Set());
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [focusPage, setFocusPage] = useState();
  const [renderTree, setRenderTree] = useState(true);
  const [loading, setLoading] = useState(true);
  const treeRef = useRef();
  const loadPage = useRef();
  const handleExpand = (key) => {
    loadPage.current = '9999';
    setExpandKeys(key);
  };
  // 需要loading的时机 切换知识库的时候；搜索的时候
  useEffect(() => {
    (async () => {
      setLoading(true);
      setRenderTree(false);
      setExpandKeys(new Set());
      const res = await getData(pageId, 0);
      setData([
        {
          pageId: '0',
          pageName: knowledgeName,
          children: [],
          hasChild: false,
          parentId: '',
          size: 0,
        },
        ...res,
      ]);
      setLoading(false);
      setRenderTree(true);
    })();
  }, [knowledgeId]);

  const onSelect = (data, event) => {
    loadPage.current = '9999';
    if (event.node.isPerm) {
      setSelectedKeys(data);
    }
    if (event.node.isLeaf) {
      return;
    }
    const key = data[0];
    if (!expandedKeys.has(key)) {
      setExpandKeys(new Set(expandedKeys.add(key)));
    }
  };

  const onLoadData = async (node) => {
    let { key, children } = node;
    if (children.length) {
      return;
    }
    let res = await getData(key);
    updateNode(key, { children: res });
  };

  useEffect(() => {
    if (pageId === loadPage.current) {
      if (!pageId) {
        setLoading(false);
        onSelect([], { node: { isPerm: true, isLeaf: true }});
      } else if (getNodeByKey(pageId)) {
        setLoading(false);
        // 直接触发选中，能走到这里选中的都有权限
        onSelect([pageId], { node: { isPerm: true, isLeaf: !hasChild }});
        setTimeout(() => {
          const dom = document.querySelector(`#transfer-node-${pageId}`);
          if (dom) {
            // 没办法用antd的滚动方法只能粗暴的操作dom了
            const parentNode = dom?.parentNode?.parentNode?.parentNode;
            const rootDom = document.querySelector('.transefer-modal-tree');
            rootDom.scrollTo({
              top: parentNode.offsetTop,
              left: 0,
              behavior: 'smooth',
            });
          }
        }, 200);
      }
    }
  }, [treeData, focusPage]);

  useEffect(() => {
    loadPage.current = pageId;
    if (!pageId) return;
    setLoading(true);
    // 这里直接加入 展开节点即可： 展开节点 当children 为空且是目录时 会触发onloadData, 如果里面有值，则不会发起请求
    path.forEach((v) => {
      expandedKeys.add(v);
    });
    setExpandKeys(new Set(expandedKeys));
    // 当选中的节点不需要请求的时候强制触发滚动到该节点
    setFocusPage(Math.random());
  }, [currentPage]);

  useEffect(() => {
    let res = [];
    let parentNode;
    if (selectedKeys?.length) {
      res = selectedKeys;
      parentNode = getNodeByKey(res[0]);
    }
    onChecListUpdate(res, parentNode);
  }, [selectedKeys]);

  return (
    <Spin spinning={loading}>
      {renderTree && (
        <Tree
          ref={treeRef}
          rootClassName={`${cx('tree-content')} ${cx(
            'tree-content-single',
          )} transefer-modal-tree`}
          switcherIcon={switcherIcon}
          treeData={treeData}
          selectedKeys={selectedKeys}
          onSelect={onSelect}
          expandedKeys={[...expandedKeys]}
          onExpand={(keys) => handleExpand(new Set(keys))}
          loadData={onLoadData}
          titleRender={(data) => {
            const content = (
              <>
                {data.key === '0' && (
                  <i className={cx('dk-iconfont', 'dk-icon-dafenqi')} />
                )}
                {data.title}
              </>
            );
            return (
              <div
                className={cx('transfer-node-title')}
                id={`transfer-node-${data.key}`}
              >
                {data.isPerm ? (
                  content
                ) : (
                  <Tooltip title={intl.t('你无权限移动到该页面')}>
                    <span className={cx('no-perm')}>{content}</span>
                  </Tooltip>
                )}
              </div>
            );
          }}
        />
      )}
    </Spin>
  );
};

export default PageTreeSingle;
