import * as PageService from '@/service/knowledge/page';
import { getPageIdFormShare, getShareSetting, changeShareSetting } from '@/service/knowledge/share';

const getDefaultState = () => ({
  docInfo: {},
  initLoading: true,
  writerVisible: true,
  editReady: false,
  editorKey: Date.now(), // 编辑器组件key，用于刷新编辑器
  normalIds: {},
  shareModalSetting: {},
  tags: [],
});
export default {
  state: getDefaultState(),
  reducers: {
    changeInitLoading(state, initLoading) {
      return {
        ...state,
        initLoading,
      };
    },
    changeDocInfo(state, docInfo) {
      return {
        ...state,
        docInfo,
        editorKey: Date.now(), // 每次查询详情都刷新编辑器
      };
    },
    changeEditReady(state, editReady) {
      return {
        ...state,
        editReady,
      };
    },
    refreshEditorKey(state) {
      return {
        ...state,
        editorKey: Date.now(),
      };
    },
    changeSharStar(state, isStar) {
      return {
        ...state,
        docInfo: {
          ...state.docInfo,
          markedStar: isStar,
        },
      }
    },
    changeNormalIds(state, normalIds) {
      return {
        ...state,
        normalIds,
      };
    },
    changeShareModalSetting(state, shareModalSetting) {
      return {
        ...state,
        shareModalSetting,
      };
    },
    setPageStyle(state, pageStyle) {
      return {
        ...state,
        docInfo: {
          ...state.docInfo,
          pageStyle: {
            ...state.docInfo.pageStyle,
            ...pageStyle,
          },
        },
      }
    },
    changeTags(state, tags) {
      return {
        ...state,
        tags: [...tags],
      };
    },
  },
  effects: {
    async getSharePageDetail(payload) {
      const data = await PageService.getSharePageDetail(payload);
      if (data) {
        this.changeDocInfo(data);
      }
      return data;
    },
    async getSnapshootDetail({ pageId, version, pageName, mapId }) {
      let data = await PageService.getSnapshotDetail({ pageId, version, mapId })
      data = { ...data, latestVersion: version, pageName, mapId }
      if (data) {
        this.changeDocInfo(data);
      }
      return data
    },
    async getIdsFormShare({ shareId }) {
      const data = await getPageIdFormShare({ shareId })
      if (data) {
        this.changeNormalIds(data);
      }
      return data
    },
    async getShareModalSetting({ resourceType, resourceId }) {
      const data = await getShareSetting({ resourceType, resourceId })
      if (data) {
        this.changeShareModalSetting(data);
      }
      return data
    },

    async editShareModalSetting(params) {
      const data = await changeShareSetting(params);
      if (data) {
        this.changeShareModalSetting(data);
      }
      return data
    },
    async getPageTags(payload) {
      const data = await PageService.getPageTags(payload);
      if (data) {
        this.changeTags(data);
      }
    },
  },
};
