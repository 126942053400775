/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-10-27 16:53:09
 * @LastEditTime: 2023-11-23 16:43:01
 * @Description: 视频播放预览
 * @FilePath: /knowledgeforge/src/components/FilePreview/VideoPreview/index.js
 *
 */

import { getUserNameFromCookie } from '@/utils';
import './index.less'


export default class VideoPreview extends React.Component {
  componentDidMount() {
    if (this.props.videoKind === 'new') {
      this.props.onSetEndLoading && this.props.onSetEndLoading();
    }
  }

  render() {
    const { videoKind, url } = this.props;
    return (
      <React.Fragment>
        {
          videoKind === 'new' && <iframe
            className='video-iframe'
            allowFullScreen
            src={url.includes('ldap') ? url : `${url}#ldap=${getUserNameFromCookie()}`}
          />
        }
      </React.Fragment>
    );
  }
}
