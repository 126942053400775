import * as PageService from '@/service/knowledge/page';

const getDefaultState = () => ({
  initLoading: false,
  view: {},
  versionList: [],
  currentVersionIndex: 0,
  historyVersionVisible: false,
  comparisonVersionIndex: '',
});
export default {
  state: getDefaultState(),
  reducers: {
    changeInitLoading(state, initLoading) {
      return {
        ...state,
        initLoading,
      };
    },
    changeView(state, view) {
      return {
        ...state,
        view,
      };
    },
    changeCurrentVersionIndex(state, currentVersionIndex) {
      return {
        ...state,
        currentVersionIndex,
      };
    },
    changeVersionList(state, versionList) {
      return {
        ...state,
        versionList,
      };
    },
    toggleHistoryVersionVisible(state) {
      return {
        ...state,
        currentVersionIndex: 0,
        historyVersionVisible: !state.historyVersionVisible,
      };
    },
    changeComparisonVersion(state, comparisonVersionIndex) {
      return {
        ...state,
        comparisonVersionIndex,
      }
    },
  },
  effects: {
    async getVersionList({ pageId, owner }) {
      const data = await PageService.getVersionList(pageId, owner);
      if (data) {
        this.changeVersionList(data);
      }
      return data;
    },
    async revertVersion(payload) {
      return PageService.revertPage(payload);
    },
  },
};
