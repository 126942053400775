/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-10-30 10:14:20
 * @LastEditTime: 2024-05-23 17:28:33
 * @Description: 预览服务主文件
 * @FilePath: /knowledgeforge/src/components/FilePreview/main.js
 *
 */
import { doDownload } from '@/components/CooperOperation/DownloadFile';
import {
  checkPermis,
  exitFullScreen,
  fullScreen,
  getIds,
  isNewVideo,
  isVideo,
} from '@/utils/file';
import api from '@/utils/request/api/CooperApi';
import { get } from '@/utils/request/index';
import { getLocale, intl } from 'di18n-react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import Footer from './Footer/index';
import Header from './Header/index';
import Viewer from './ImgPreview';
import OfficePlayer from './OfficePreview';
import VideoPreview from './VideoPreview';
import './index.less';
import React from 'react'

// 判断是不是Office
function isOffice(fname) {
  return /\.pdf$|\.docx?$|pptx?$|\.xlsx?$/i.test(fname);
}

// 判断是不是Excel
function isExcel(fname) {
  return /\.xlsx?$/i.test(fname);
}

// 判断是不是文本
function isText(fname) {
  return /\.txt$|\.md$|\.json$|\.html?$|\.jsx?$|\.tsx?$|\.css$|\.sass$|\.less$|\.sh$|\.py$|\.java$|\.go$|\.csv$/i.test(
    fname,
  );
}

// 判断是不是图片
function isImage(fname) {
  return /\.png$|\.jpe?g$|\.gif$|\.heic$|\.bmp$/i.test(fname);
}

// 获取预览类型
export function getPreviewType(f) {
  const shareId = f.shareId || f.share_id
  const shareLink = f.shareLink || f.share_link
  const shareType = f.shareType || f.share_type
  if (!shareId) return 'file';
  if (shareLink || (shareType && (shareType === 'link' || shareType === 'Link'))) {
    return 'link'
  }
  return 'direct'
}

let fidx;
let files;
let spaceId;
let urlPreviewImg;
let parentLocationType;
let teamId;
let imgFiles;
let container;
let imgViewerDefaultUrl = []

const imgViewerConfig = {
  inline: true,
  backdrop: false,
  button: true,
  focus: true,
  fullScreen: false,
  fullScreenAble: true,
  loop: true,
  loading: true,
  movable: true,
  keyboard: true,
  rotatable: true,
  scalable: false,
  tooltip: true,
  zoomOnWheel: true,
  slideOnTouch: true,
  zoomable: true,
  navbar: true,
  transition: true,
  downloadable: true,
  originalImgAble: true,
  container: document.getElementById('cooper-preview-body'),
  toolbar: {
    zoomIn: true,
    zoomOut: true,
    oneToOne: true,
    prev: true,
    play: false,
    next: true,
    rotateLeft: true,
    movable: false,
    flipHorizontal: true,
    flipVertical: true,
  },
};

class FilePreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasOperation: props.hasOperation,
      hasFooter: false, // 标识是不是需要footer
      fname: '', // 保存文件名称
      type: '', // 类型
      url: '', // 真实的url
      text: '', // 解析出来的文本
      hasDownloadIcon: false,
      hasDeleteIcon: false,
      videoKind: 'old',
      isFullScreen: false,
      file: {},
    };
    this.type = props.type;
    files = props.files;
    fidx = props.fidx;
    spaceId = props.spaceId;
    parentLocationType = props.parentLocationType;
    teamId = getIds().teamId;
    imgFiles = files.filter((item) => isImage(item.name)) || [];
  }

  componentDidMount() {
    imgViewerDefaultUrl = []
    this.loadPreview();
    document.addEventListener('fullscreenchange', this.exitFullscreenHandler);
    document.addEventListener(
      'webkitfullscreenchange',
      this.exitFullscreenHandler,
    );
    document.addEventListener(
      'mozfullscreenchange',
      this.exitFullscreenHandler,
    );
    document.addEventListener('MSFullscreenChange', this.exitFullscreenHandler);
    document.addEventListener('click', (event) => {
      let cDom = document.querySelector('.viewer-canvas')?.firstChild;
      let pDom = document.querySelector('.viewer-canvas');
      let tDom = event.target;
      if (cDom && tDom && pDom && !cDom.contains(tDom) && pDom.contains(tDom) && !this.state.isFullScreen) {
        this.props.closePreview();
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener(
      'fullscreenchange',
      this.exitFullscreenHandler,
    );
    document.removeEventListener(
      'webkitfullscreenchange',
      this.exitFullscreenHandler,
    );
    document.removeEventListener(
      'mozfullscreenchange',
      this.exitFullscreenHandler,
    );
    document.removeEventListener(
      'MSFullscreenChange',
      this.exitFullscreenHandler,
    );
  }

  exitFullscreenHandler = () => {
    if (
      !document.fullscreenElement
      && !document.webkitFullscreenElement
      && !document.mozFullScreenElement
      && !document.msFullscreenElement
    ) {
      const PreviewDom = document.querySelector('.cooper-preview');
      PreviewDom.classList.remove('viewer-container-fullscreen');
      const cancelFullScreen = document.querySelector(
        '.viewer-container-fullscreen-cancel',
      );
      cancelFullScreen && PreviewDom.removeChild(cancelFullScreen);
      exitFullScreen(PreviewDom);
      this.setState({
        isFullScreen: false,
      }, () => {
        if (this.type === 'image') {
          document.querySelector('.viewer-list').style.display = 'block'
        }
      });
    }
  };

  getImgIdx = (src, fileItems) => {
    let idx = 0;
    fileItems.forEach((f, index) => {
      if (f.tiny_image === src || f.name === src) {
        idx = index;
      }
    });
    return idx;
  };

  appendCancelFullScreen = (PreviewDom) => {
    const cancelFullScreen = document.createElement('div');
    cancelFullScreen.classList.add('viewer-container-fullscreen-cancel');
    cancelFullScreen.setAttribute('title', intl.get('退出全屏'));
    PreviewDom.appendChild(cancelFullScreen);
    cancelFullScreen.addEventListener('click', () => {
      cancelFullScreen.parentNode.removeChild(cancelFullScreen);
      exitFullScreen(PreviewDom);
      this.setState({
        isFullScreen: false,
      });
    });
  };

  updateImgFiles = (file, quickId) => {
    imgFiles = imgFiles.map((f) => {
      if (f.id === file.id) {
        return {
          ...f,
          marked_quick_visit: !!quickId,
          markedQuickVisit: !!quickId,
          quickId,
          quick_id: quickId,
        };
      }
      return f
    })
  }

  // eslint-disable-next-line consistent-return
  getImgPreviewUrl = async (data) => {
    const f = imgFiles[data.index]
    const resourceId = f.id;
    const shareId = f.share_id || f.shareId;
    let urlPreview = api.RESOURCE_PREVIEW.replace(':resourceId', resourceId);
    if (f?.search_link_v2) {
      urlPreview = f.search_link_v2;
    } else {
      if (
        getPreviewType(f) === 'direct'
      ) {
        urlPreview = api.DIRECT_RESOURCE_PREVIEW.replace(
          ':shareId',
          shareId,
        ).replace(':resourceId', resourceId);
      }
      if (
        getPreviewType(f) === 'link'
      ) {
        urlPreview = api.LINK_RESOURCE_PREVIEW.replace(
          ':shareId',
          shareId,
        ).replace(':resourceId', resourceId);
      }
    }
    try {
      const res = await get(`${urlPreview}?original=true`, {
        headers: {
          'X-App-Id': 2,
        },
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  renderImgPreview = () => {
    let _this = this;
    let viewer = new Viewer(container, {
      ...imgViewerConfig,
      localeLanguage: getLocale(),
      hide() {
        _this.props.closePreview();
      },
      view(image) {
        if (image.detail && image.detail.image && image.detail.image.alt) {
          const imgOriginalDom = document.querySelector('.viewer-original-img');
          if (image.detail.image.alt.endsWith('.heic') || image.detail.image.alt.endsWith('.HEIC')) {
            imgOriginalDom.style.display = 'none';
          } else {
            imgOriginalDom.style.display = 'block';
          }
        }
      },
      viewed(image) {
        const data = image.target.viewer
        let imgOriginalDom = document.querySelector('.viewer-original-img');
        let imgStatusDom = document.querySelector('.viewer-one-to-one')
        let imgDom = document.querySelector('.viewer-transition')
        if (imgStatusDom.classList.contains('viewer-one-to-one-auto')) imgStatusDom.classList.remove('viewer-one-to-one-auto');
        if (imgViewerDefaultUrl[data.index]) {
          !imgOriginalDom.classList.contains('viewer-original-img-has') && imgOriginalDom.classList.add('viewer-original-img-has')
          imgOriginalDom.innerHTML = intl.get('已是原图')
          imgDom.src = imgViewerDefaultUrl[data.index]
          viewer.resize()
        } else {
          imgOriginalDom.classList.contains('viewer-original-img-has') && imgOriginalDom.classList.remove('viewer-original-img-has')
          imgOriginalDom.innerHTML = intl.get('查看原图')
        }
        // 存储当前查看图片的名字
        _this.setState({
          fname: image?.detail?.image?.alt || '暂无',
          file: imgFiles[_this.getImgIdx(image?.detail?.image?.alt, imgFiles)],
        });
      },
      initStatus() {
        let imgStatusDom = document.querySelector('.viewer-one-to-one')
        if (imgStatusDom && imgStatusDom.classList?.contains('viewer-one-to-one-auto')) {
          imgStatusDom.classList.remove('viewer-one-to-one-auto');
        }
      },
      title(data) {
        return `${data.index + 1}/${data.length}`;
      },
      async downloadAble(data) {
        const f = imgFiles[data.index];
        const resourceId = f.id;
        const shareId = f.share_id || f.shareId;
        let url = api.RESOURCE_META.replace(':resourceId', resourceId);
        if (shareId) url += `?shareId=${shareId}`;
        try {
          const res = await get(url, {
            headers: {
              'X-App-Id': 2,
            },
            keepSilent: true,
          });
          return checkPermis('download', res.permission);
        } catch (err) {
          console.log(err);
        }
      },
      download() {
        if (!_this.state.hasDownloadIcon) return;
        const file = imgFiles[_this.getImgIdx(_this.state.fname, imgFiles)];
        const type = getPreviewType(file);
        const region = _this.props.connectSiteId || window._connectSiteId || 0;
        // eslint-disable-next-line max-len
        doDownload(
          type,
          [file.id],
          file.share_id || file.shareId,
          file.teamId || file.spaceId || file.sourceId || spaceId || teamId,
          region,
          parentLocationType,
        );
        window.__OmegaEvent('ep_filespreview_download_ck');
      },
      fullScreen() {
        const PreviewDom = document.querySelector('.cooper-preview');
        if (PreviewDom) {
          if (!_this.state.isFullScreen) {
            _this.appendCancelFullScreen(PreviewDom);
            fullScreen(PreviewDom);
            PreviewDom.classList.add('viewer-container-fullscreen');
            _this.setState({
              isFullScreen: true,
            });
          }
        }
      },
      showOriginalImg(data) {
        const { index } = data;
        if (document.querySelector('.viewer-original-img').classList.contains('viewer-original-img-has')) return
        let imgStateDom = document.querySelector('.viewer-original-img')
        imgStateDom.innerHTML = intl.get('加载中...');
        _this.getImgPreviewUrl(data).then((res) => {
          const { url } = res;
          let imgDom = document.querySelector('.viewer-transition')
          imgDom.src = url
          imgDom.onload = () => {
            imgViewerDefaultUrl[index] = url
            // 需要校准加载原图切换图片状态不准确的问题
            if (index === data.index) {
              imgStateDom.innerHTML = intl.get('已是原图')
              imgStateDom.classList.add('viewer-original-img-has')
              viewer.resize()
            }
          }
        });
      },
    });
    window.viewer = viewer;
    viewer.view(_this.getImgIdx(urlPreviewImg, imgFiles));
  };

  loadPreview = async () => {
    const f = files[fidx];
    const resourceId = f.id;
    const shareId = f.share_id || f.shareId;
    this.getPermissionAction(resourceId, shareId);
    // 根据名字区分类型、做不同的处理
    if (isOffice(f.name) || isImage(f.name) || isText(f.name)) {
      let urlPreview = api.RESOURCE_PREVIEW.replace(':resourceId', resourceId);
      if (f?.search_link_v2) {
        urlPreview = f.search_link_v2;
      } else {
        if (
          this.type === 'direct'
          || getPreviewType(f) === 'direct'
        ) {
          urlPreview = api.DIRECT_RESOURCE_PREVIEW.replace(
            ':shareId',
            shareId,
          ).replace(':resourceId', resourceId);
        }
        if (
          this.type === 'link'
          || getPreviewType(f) === 'link'
        ) {
          urlPreview = api.LINK_RESOURCE_PREVIEW.replace(
            ':shareId',
            shareId,
          ).replace(':resourceId', resourceId);
        }
      }
      get(urlPreview, {
        headers: {
          'X-App-Id': 2,
        },
      })
        .then((res) => {
          const { url, uri } = res;
          let previewUrl = url || `/${uri}`;
          let type = 'text';
          if (isOffice(f.name)) {
            type = 'newoffice';
            return this.setState({
              file: f,
              type,
              url: previewUrl,
              fname: f.name,
            });
          }
          if (isImage(f.name)) {
            type = 'image';
            urlPreviewImg = f.tiny_image;
            container = document.getElementById('cooper-preview-body');
            imgFiles.forEach((fItem) => {
              const image = new Image();
              image.src = `${fItem.tiny_image || fItem.tinyImage}`;
              image.alt = fItem.name;
              container.appendChild(image);
            });
            imgViewerDefaultUrl.length = imgFiles.length
            return this.setState({
              file: f,
              type,
              url: previewUrl,
              fname: f.name,
            });
          }
          return window.fetch(previewUrl).then((data) => data.text());
        })
        .then((data) => {
          if (data) {
            if (typeof data !== 'string') {
              // eslint-disable-next-line no-param-reassign
              data = JSON.stringify(data, null, 2);
            }
            this.setState({
              file: f,
              type: 'text',
              text: data,
              fname: f.name,
              hasFooter: true,
            });
          }
        }).catch((e) => {
          if (!e.success) {
            this.setState({
              hasOperation: false,
            })
          }
        })
    } else if (isVideo(f.name) || isNewVideo(f.name)) {
      let urlVideo = api.VIDEO_PREVIEW.replace(':resourceId', resourceId);
      // 当分享类型是 direct 的时候
      if (
        this.type === 'direct'
        || getPreviewType(f) === 'direct'
      ) {
        urlVideo = api.DIRECT_VIDEO_PREVIEW.replace(
          ':shareId',
          shareId,
        ).replace(':resourceId', resourceId);
      }
      // 当分享类型是 link 的时候
      if (
        this.type === 'link'
        || getPreviewType(f) === 'link'
      ) {
        urlVideo = api.LINK_VIDEO_PREVIEW.replace(':shareId', shareId).replace(
          ':resourceId',
          resourceId,
        );
      }
      get(urlVideo, {
        headers: {
          'X-App-Id': 2,
        },
      }).then((res) => {
        const { url, uri } = res;
        let previewUrl = url || `/${uri}`;
        this.setState({
          file: f,
          type: res.isSupport ? 'video' : 'unknown',
          url: previewUrl,
          videoKind: res.kind || 'old',
          fname: f.name,
          hasFooter: true,
        })
      }).catch((err) => {
        if (!err.success) {
          this.setState({
            hasOperation: false,
          })
        }
      });
    } else {
      this.setState({
        file: f,
        type: 'unknown',
        fname: f.name,
        hasFooter: true,
      });
    }
  };

  getPermissionAction = (resourceId, shareId) => {
    let url = api.RESOURCE_META.replace(':resourceId', resourceId);
    if (shareId) url += `?shareId=${shareId}`;
    get(url, {
      headers: {
        'X-App-Id': 2,
      },
      keepSilent: true,
    })
      .then((res) => {
        this.setState({
          hasDownloadIcon: checkPermis('download', res.permission),
          hasDeleteIcon: checkPermis('delete', res.permission),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { connectSiteId = window.__connectSiteId || 0, isMain } = this.props;
    const { type, url, text, videoKind, fname, hasFooter, file } = this.state;
    const fid = file.id;

    const rmStyle = {
      overlay: {
        zIndex: 1080,
        backgroundColor: 'transparent',
      },
      content: {
        border: 0,
        backgroundColor: 'transparent',
      },
    };

    return (
      <ReactModal
        isOpen={true}
        appElement={document.body}
        style={rmStyle}>
        <div className="cooper-preview">
          <div className="cooper-preview-header">
            <Header
              file={file}
              fname={fname}
              fid={fid}
              onClose={() => {
                this.props.closePreview();
              }}
              refreshFolder={this.props.refreshFolder}
              state={this.state}
              updateImgFiles={(fileItem, quickId) => this.updateImgFiles(fileItem, quickId)}
              isMain={isMain}
              hasDeleteIcon={this.state.hasDeleteIcon}
              hasDownloadIcon={this.state.hasDownloadIcon}
              updateShareFiles={this.props.updateShareFiles}
              // eslint-disable-next-line max-len
              onDownload={() => doDownload(
                this.type,
                [file.id],
                file.share_id || file.shareId,
                file.teamId || spaceId || teamId,
                connectSiteId,
              )
              }
            />
          </div>
          <div
            className="cooper-preview-body"
            id="cooper-preview-body">
            {type === 'text' && <pre className="cp-pre">{text}</pre>}
            {type === 'video' && (
              <div className="cp-video-content">
                <VideoPreview
                  url={url}
                  fname={fname}
                  videoKind={videoKind}
                  onSetEndLoading={this.setEndLoading}
                />
              </div>
            )}
            {type === 'image' && this.renderImgPreview()}
            {type === 'newoffice' && (
              <OfficePlayer
                file={files[fidx]}
                fid={fid}
                fname={fname}
                url={url}
                isExcel={isExcel(fname)}
                onSetEndLoading={this.setEndLoading}
              />
            )}
            {type === 'unknown' && (
              <span className="cp-unknown">{intl.t('暂不支持此文件预览')}</span>
            )}
          </div>
          {hasFooter && (
            <div className="cooper-preview-footer">
              <Footer
                file={file}
                fid={fid}
                fname={fname}
                type={this.type}
                state={this.state}
                onClose={() => {
                  this.props.closePreview();
                }}
                connectSiteId={connectSiteId}
                spaceId={this.props.spaceId}
                teamId={this.props.teamId}
                hasDownloadIcon={this.state.hasDownloadIcon}
                parentLocationType={this.props.parentLocationType}
              />
            </div>
          )}
        </div>
      </ReactModal>
    );
  }
}

function mapStateToProps(state) {
  return {
    // 这个是文件标识文件存储地方的
    connectSiteId: state?.user?.connectSiteId || window?.__connectSiteId || 0,
  };
}

export { FilePreview };

export default connect(mapStateToProps)(FilePreview);
