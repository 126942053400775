import * as PageService from '@/service/knowledge/page';

const getDefaultState = () => ({
  initLoading: true,
  view: {},
  currentRecord: {},
  historyRecordVisible: false,
});
export default {
  state: getDefaultState(),
  reducers: {
    changeInitLoading(state, initLoading) {
      return {
        ...state,
        initLoading,
      };
    },
    changeView(state, view) {
      return {
        ...state,
        view,
      };
    },
    changeCurrentRecord(state, currentRecord) {
      return {
        ...state,
        currentRecord,
      };
    },
    toggleHistoryRecordVisible(state) {
      return {
        ...state,
        historyRecordVisible: !state.historyRecordVisible,
      };
    },
  },
};
