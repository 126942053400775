import { intl } from 'di18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import classBind from 'classnames/bind';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import { useMemo, useContext } from 'react';
import { TREE_VIEW_TYPE } from '@/constants';
import useDebounceFn from '@/hooks/useDebounceFn';
import styles from './style.module.less';
import { useParams } from 'react-router-dom';

const cx = classBind.bind(styles);

const SwitchIcon = React.memo(() => {
  const dispatch = useDispatch();
  const { setTreeViewType } = dispatch.KnowledgeData;
  const { pageId } = useContext(LayoutContext);
  const { treeViewType } = useSelector((state) => state.KnowledgeData);
  const { setState } = dispatch.TagTree;

  const switchCatalog = () => {
    let currViewType = Math.abs(treeViewType - 1);
    setTreeViewType(currViewType);
    setState({
      choosePageMsg: {
        parentTagId: null,
        pageId,
      },
    });

    if (currViewType === 1) {
      window.__OmegaEvent('ep_dkpc_change_tagview_ck');
    } else {
      window.__OmegaEvent('ep_dkpc_change_treeview_ck');
    }
  };

  const { run: onClickSwitchCatalog } = useDebounceFn(switchCatalog);

  const viewText = useMemo(() => {
    return treeViewType === TREE_VIEW_TYPE.PAGE
      ? intl.t('切换为-标签')
      : intl.t('切换为-目录');
  });

  return (
    <Tooltip
      title={intl.t('切换为{viewText}视图', { viewText })}
      mouseLeaveDelay={0.01}
      overlayClassName={cx('dk-ant-tooltip__reset', 'catalog-operate-tooltip')}
    >
      <i
        id="dk-switch-tag-btn"
        onClick={onClickSwitchCatalog}
        className={cx(
          'dk-icon-biaoqianqiehuan',
          'dk-iconfont',
          'switch-view-icon',
        )}
      />
    </Tooltip>
  );
});

export default SwitchIcon;
