

/**
 * ！！弃用！！
 */

const EXPIRE_TIME = 7 * 24 * 60 * 60;

/**
 * 清理Localstorage。（只会清理知识库项目中设置的缓存）
 * 设置always前缀，表示永远不主动清理
 */
const clearExpireData = () => {
  let now = new Date().getTime();

  let isDelete = false;
  try {
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i); // 获取本地存储的Key
      if (!key) continue;
      if (isOutOfClearRange(key)) continue;
      let { createTime } = JSON.parse(localStorage.getItem(key) || '{}');
      if (now - (createTime ?? 0) > EXPIRE_TIME) {
        localStorage.removeItem(key);
        isDelete = true;
      }
    }
    // 如果都没有过期，就清理知识库的
    if (!isDelete) {
      for (let i = localStorage.length - 1; i >= 0; i--) {
        let keyLast = localStorage.key(i);
        if (isOutOfClearRange(keyLast)) continue;
        localStorage.removeItem(keyLast);
      }
    }
  } catch (error) {
    console.log(error);
  }
};

// 判断是否在清理范围
const isOutOfClearRange = (key) => {
  return key.indexOf('dk-') === -1 || key.indexOf('persist') === -1 || key.indexOf('always') !== -1;
}

// 判断是否缓存满了
const isLocalSpaceUp = () => {
  let size = 0;
  let localStorageUsed = 0;
  for (let i = 0; i < localStorage.length; i++) {
    let key = localStorage.key(i);
    if (!key) return;
    size += localStorage.getItem(key)?.length || 0;
  }
  // '当前localStorage已使用容量为' + (size / 1024).toFixed(2) + 'KB'
  localStorageUsed = (size / 1024).toFixed(2);
  return (localStorageUsed / (1024 * 1024 * 5)) > 0.48; // 亲测chrome 为 5120KB,safari 2540KB
};

/**
 * 设置创建时间（用户过期判断）、localstorage满时处理
 * @param {*} key
 * @param {*} value
 * @param {*} expire
 */
export const setLocalData = (key, value) => {
  try {
    if (isLocalSpaceUp()) {
      clearExpireData();
    }

    let itemString = JSON.stringify({
      value,
      createTime: new Date().getTime(),
    });
    localStorage.setItem(`dk-${key}`, itemString);
  } catch (error) {
    console.log(error);
  }
};

export const getLocalData = (key) => {
  try {
    if (key.indexOf('persist:') !== -1) { // 兼容已经存在的写法
      return JSON.parse(localStorage.getItem(`dk-${key}`) || '{}');
    }
    return JSON.parse(localStorage.getItem(`dk-${key}`) || '{}')?.value || {}; // 主动设置的缓存都会存储在这里
  } catch (error) {
    console.log(error);
  }
};

const maxLocalStorageSize = 4 * 1024 * 1024;

const getUsedLocalStorageSize = () => {
  const data = Object.keys(localStorage).map((key) => localStorage[key]);
  const usedSpace = data.reduce((total, value) => total + value.length, 0);
  return usedSpace;
}

export const cleanLocalStorageIfNeeded = () => {
  let usedSpace = getUsedLocalStorageSize();

  if (usedSpace >= maxLocalStorageSize) {
    console.log('LocalStorage容量超过4MB，正在进行清理操作...');

    // 获取localStorage的所有键
    const keys = Object.keys(localStorage);

    keys.sort((a, b) => localStorage[b].length - localStorage[a].length);

    let clearedSpace = 0;

    for (const key of keys) {
      const data = localStorage[key];
      localStorage.removeItem(key);
      console.log(`清理的key是:${key}`);
      clearedSpace += data.length;
      usedSpace -= data.length;

      if (usedSpace < maxLocalStorageSize) {
        break;
      }
    }

    console.log(`清理完成，已释放 ${clearedSpace} 字节。`);
  }
}
