/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-05-12 10:37:24
 * @LastEditTime: 2024-04-30 10:36:35
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/RecentActivity/index.js
 *
 */
import { intl } from 'di18n-react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fromJS } from 'immutable';
import { cloneDeep } from 'lodash-es';
import {
  getFileTableData,
  getGlobalFileDir,
} from '@/service/cooper/home';
import api from '@/utils/request/api/CooperApi';
import classBind from 'classnames/bind';
import { FileType, HOME_FILE_TYPE_RECENT, HOME_FILE_TYPE_EDIT } from '@/constants/cooper';
import FilesTables from './file-table/index';
import { isDC } from '@/utils/index';
import { CooperTabs, CooperTabsPane } from '@/components/common/CooperTabs';
import FileTableSkeleton from '../SkeletonPage/common/FileTableSkeleton';
import GetHtml from '@/utils/DOMPurify';
import styles from './style.module.less';

export let handleScrollTable = null;
export let updateCurrentList = null;

const cx = classBind.bind(styles)

const LIST_HEADER_DATA = [
  {
    key: '1',
    tip: () => intl.t('最近30天访问过的文件'),
    val: () => intl.t('最近访问'),
  },
  {
    key: '2',
    tip: () => intl.t('最近30天编辑过的文件'),
    val: () => intl.t('最近编辑'),
  },
];

// 加载完成标识
const loadEndIfon = () => {
  const switchMap = {
    1: 'loadEndRecent',
    2: 'loadEndEdite',
  };
  const loadEnd = {
    loadEndRecent: false,
    loadEndEdite: false,
  };

  return {
    setLoadEnd: (listType, loadEndV) => {
      loadEnd[switchMap[listType]] = loadEndV;
    },
    getLoadEndByType: (listType) => {
      return loadEnd[switchMap[listType]];
    },
  };
}

// 分页标识
const pageNum = () => {
  const switchMap = {
    1: 'pageNumRecent',
    2: 'pageNumEdite',
  };
  const pageNumMap = {
    pageNumRecent: 0,
    pageNumEdite: 0,
  };
  return {
    setPageNumCount: (listType, newPageNum) => {
      pageNumMap[switchMap[listType]] = newPageNum;
    },
    getPageNum: (listType) => {
      return pageNumMap[switchMap[listType]];
    },
  };
}
// MyRecentFilter 最近访问  MyEditorFilter 最近编辑

// 筛选项标识

let loadEndTag = loadEndIfon();
let pageNumTag = pageNum();

// 防止频繁进行加载
let loadMoreFlag = true;
const pageSize = 33;

function RecentActivity(props) {
  const [chooseListType, setListType] = useState('1');
  const [loading, setLoading] = useState(false);
  const [init, setInit] = useState(true);

  const [filterRecent, setFilterRecent] = useState('');
  const [filterEditor, setFilterEditor] = useState('');
  const [ownerRecent, setOwnerRecent] = useState([]);
  const [ownerEditor, setOwnerEditor] = useState([]);
  const [orderTimeRecent, setOrderTimeRecent] = useState('1')
  const [orderTimeEditor, setOrderTimeEditor] = useState('1')
  const { recentList, editList } = useSelector((state) => state.CooperHome);
  const { userViewData, needHomeTableSkeleton } = useSelector((state) => state.GlobalData);
  const { setUserViewDataRq, setNeedHomeTableSkeleton } = useDispatch().GlobalData;
  const { setRecentList, setEditList } = useDispatch().CooperHome;

  useEffect(() => {
    // eslint-disable-next-line max-len
    const { MyRecentFilter, MyEditorFilter } = userViewData;
    if (MyRecentFilter && MyEditorFilter) {
      setFilterRecent(MyRecentFilter ?? HOME_FILE_TYPE_RECENT);
      setFilterEditor(MyEditorFilter ?? HOME_FILE_TYPE_EDIT);
    }
  }, [userViewData]);

  const getOwnerIds = (arr) => {
    return arr
      .filter((item) => item?.checked)
      .map((item) => item?.id)
      .join(',') || '';
  }

  const _filterInfoUtil = {
    1: {
      filterTypeValue: filterRecent,
      funSetType: setFilterRecent,
      ownerTypeValue: ownerRecent,
      funSetOwner: setOwnerRecent,
      timeOrder: orderTimeRecent,
      funSetTimeOrder: setOrderTimeRecent,
      funLocalFilterType: (value) => {
        let data = { ...userViewData };
        data.MyRecentFilter = value;
        if (JSON.stringify(userViewData) === '{}') return;
        setUserViewDataRq(data);
      },
    },
    2: {
      filterTypeValue: filterEditor,
      funSetType: setFilterEditor,
      ownerTypeValue: ownerEditor,
      funSetOwner: setOwnerEditor,
      timeOrder: orderTimeEditor,
      funSetTimeOrder: setOrderTimeEditor,
      funLocalFilterType: async (value) => {
        let data = { ...userViewData };
        data.MyEditorFilter = value;
        if (JSON.stringify(userViewData) === '{}') return;
        setUserViewDataRq(data);
      },
    },
  };

  useEffect(() => {
    let recentParams = {
      queryType: 1,
      type: filterRecent,
      ownerIds: getOwnerIds(ownerRecent),
      pageNum: 0,
      timeOrder: orderTimeRecent,
    };
    if (ownerRecent && init) {
      // pageNum
      reqFilesData(recentParams, api.RECENT_FILE_LIST)
        .then(setListKey)
        .then(
          setCurrentList(1),
          setListType('1'),
          setInit(false),
        )
        .then(() => { setLoading(false) });

      pageNumTag = pageNum()
      loadEndTag = loadEndIfon()
      // 每次进来的时候保证最近编辑的加载都是从0开始的
      pageNumTag.setPageNumCount(2, 0)
      loadEndTag.setLoadEnd(2, false)
      return () => {
        loadEndTag,
        pageNumTag
      }
    }
  }, [filterRecent, ownerRecent])

  // 给数据添加一个key值
  const setListKey = (list) => {
    const newList = cloneDeep(list);
    newList?.forEach((item, index) => {
      if (item.timeFlag) {
        // 最新动态的时间行
        item.key = item.resourceId;
      } else {
        item.key = `${item.resourceId}${index}`;
      }
    });

    return newList;
  }

  const setCurrentList = (listType) => {
    // const setListMap = {
    //   1: setRecentList,
    //   2: setEditList,
    // };

    return (list) => {
      if (listType === 1) {
        setRecentList(list);
      } else {
        setEditList(list);
      }
      // setListMap[listType](list);
    };
  }

  // 获取当前的数据源
  const getCurrentList = (listType) => {
    if (listType == 1) {
      return recentList;
    }
    return editList;
  }

  // 获取数据
  const reqFilesData = async (params, url, type) => {
    if (type !== 'update') {
      setLoading(true)
    }
    const files = await getFileTableData(
      { ...params, pageSize, latestTs: new Date().getTime() },
      url.replace(':queryType', params.queryType),
    );
    if (files.items?.length === 0 || files?.totalCount < files?.pageSize) {
      loadEndTag.setLoadEnd(params.queryType, true);
    } else if (type === 'update') {
      loadEndTag.setLoadEnd(params.queryType, false);
    }
    setNeedHomeTableSkeleton(false);
    return files.items
  }

  // 查看文件位置的时候获取完整链接
  const getWholePath = (record, idx) => {
    const {
      parentId,
      teamId,
      shareType,
      teamName,
      resourceTypeStr,
      fileDir,
    } = record;

    let currentList = fromJS(getCurrentList(Number(chooseListType)));

    if (currentList.getIn([idx, 'globalDir'])) {
      return;
    }

    if (fileDir === '--') {
      setCurrentList(Number(chooseListType))(currentList.setIn([idx, 'globalDir'], intl.t('--')).toJS());
      return
    }
    // 分享给我
    if (shareType === 'Direct') {
      setCurrentList(Number(chooseListType))(currentList.setIn([idx, 'globalDir'], intl.t('分享给我')).toJS());
      return;
    }

    // 链接分享
    if (shareType === 'Link') {
      setCurrentList(Number(chooseListType))(currentList.setIn([idx, 'globalDir'], intl.t('链接分享')).toJS());
      return;
    }

    // 个人空间
    if (teamId === 0 && parentId === 0 && !record.shareType) {
      setCurrentList(Number(chooseListType))(currentList.setIn([idx, 'globalDir'], intl.t('个人空间')).toJS());
      return;
    }

    // 知识库页面
    if (resourceTypeStr === FileType.DK_FILE || resourceTypeStr === FileType.DK_PAGE) {
      setCurrentList(Number(chooseListType))(currentList.setIn([idx, 'globalDir'], intl.t(`${intl.t('知识库')}/${teamName}`)).toJS());
      return;
    }

    if (resourceTypeStr === FileType.WIKI_PAGE) {
      setCurrentList(Number(chooseListType))(currentList.setIn([idx, 'globalDir'], intl.t(`Wiki/${teamName}`)).toJS());
      return;
    }

    getGlobalFileDir(parentId, teamId).then((res) => {
      setCurrentList(Number(chooseListType))(currentList.setIn([idx, 'globalDir'], res).toJS());
    });
  }

  // 设置请求参数
  const getDefaultReqParams = (listType) => {
    const params = {
      pageNum: pageNumTag.getPageNum(listType),
      queryType: listType,
      type: _filterInfoUtil[listType].filterTypeValue,
      ownerIds: getOwnerIds(_filterInfoUtil[listType].ownerTypeValue),
      timeOrder: _filterInfoUtil[listType].timeOrder,
    }
    return {
      url: api.RECENT_FILE_LIST,
      params,
    }
  }

  // 筛选后去重新查询接口
  const doFilters = async (filterOpera, type, isUpdate = true) => {
    let numListType = Number(chooseListType)
    if (type === 'type') {
      _filterInfoUtil[numListType].funSetType(filterOpera.type);
      await _filterInfoUtil[numListType].funLocalFilterType(filterOpera.type);
    } else if (type === 'owner') {
      _filterInfoUtil[numListType].funSetOwner(filterOpera.ownerType);
    } else {
      _filterInfoUtil[numListType].funSetTimeOrder(filterOpera.timeOrder);
    }
    if (isUpdate) {
      // 筛选后页数和加载完成的状态要清空
      pageNumTag.setPageNumCount(numListType, 0);
      loadEndTag.setLoadEnd(numListType, false);
      setCurrentList(numListType)([])
      reqFilesData({
        pageNum: pageNumTag.getPageNum(numListType),
        queryType: numListType,
        type: filterOpera.type,
        ownerIds: getOwnerIds(filterOpera.ownerType),
        timeOrder: filterOpera.timeOrder,
      }, api.RECENT_FILE_LIST)
        .then(setListKey)
        .then(setCurrentList(numListType))
        .then(() => { setLoading(false); });
    }
  }

  // 更新数据
  updateCurrentList = () => {
    let numListType = Number(chooseListType)
    let query = getDefaultReqParams(numListType);
    let queryData = {
      ...query.params,
      pageNum: 0,
    }
    pageNumTag.setPageNumCount(numListType, 0);
    reqFilesData(queryData, query.url, 'update')
      .then(setListKey)
      .then(setCurrentList(numListType))
      .then(() => { setLoading(false) });
  }

  const checkLoadMore = () => {
    let isLoadEnd = loadEndTag.getLoadEndByType(chooseListType);
    return loading || isLoadEnd;
  }

  // 滚动加载
  handleScrollTable = (e) => {
    e.persist();
    setTimeout(() => {
      if (checkLoadMore()) return;
      const st = e.target.scrollTop;
      const sh = e.target.scrollHeight;
      const ch = e.target.clientHeight;
      if (st + ch + 50 > sh) {
        doLoadMoreTableData();
      }
    }, 500);
  }

  // 获取更多数据
  const doLoadMoreTableData = () => {
    let numListType = Number(chooseListType)
    let isLoadEnd = loadEndTag.getLoadEndByType(numListType);
    if (!isLoadEnd && loadMoreFlag) {
      let query = getDefaultReqParams(numListType);
      let currPageNum = pageNumTag.getPageNum(numListType) + 1;
      pageNumTag.setPageNumCount(numListType, currPageNum);
      let params = { ...query.params, pageNum: currPageNum };
      loadMoreFlag = false;
      reqFilesData(params, query.url)
        .then((fmtFiles) => {
          if (fmtFiles.length > 0) {
            setCurrentList(numListType)(setListKey(getCurrentList(numListType).concat(fmtFiles)));
          }
          setLoading(false);
          loadMoreFlag = true;
        });
    }
  }

  // 切换tab
  const handleChooseTab = (listType) => {
    setListType(listType);
    let numListType = Number(listType)
    if (chooseListType == 1) {
      window.__OmegaEvent('ep_home_recent_ck');
    } else if (chooseListType == 2) {
      window.__OmegaEvent('ep_home_edited_ck');
    }

    // 切换tab后状态要清空
    pageNumTag.setPageNumCount(numListType, 0);
    loadEndTag.setLoadEnd(numListType, false);
    let query = getDefaultReqParams(numListType);
    reqFilesData(query.params, query.url, 'update')
      .then(setListKey)
      .then(setCurrentList(numListType))
      .then(() => { setLoading(false); });
  }

  const loadEnd = loadEndTag.getLoadEndByType(Number(chooseListType));
  const currentFilterData = _filterInfoUtil[Number(chooseListType)].filterTypeValue;
  const currentOwnerData = _filterInfoUtil[Number(chooseListType)].ownerTypeValue;
  const currentTimeOrder = _filterInfoUtil[Number(chooseListType)].timeOrder;
  return (
    <div className={cx({ 'recent-activity': true, 'is-dc': isDC() })}>
      <CooperTabs
        defaultActiveKey={chooseListType}
        activeKey={chooseListType}
        onChange={handleChooseTab}
        tabsize='large'
      >
        {
          LIST_HEADER_DATA.map((item) => {
            return (
              <CooperTabsPane
                tab={
                  <span>
                    <span>{item.val()}</span>
                  </span>
                }
                key={item.key}
              >
                {
                  needHomeTableSkeleton ? (
                    <div
                      style={{ padding: '0 28px 0 27px' }}
                      dangerouslySetInnerHTML={{ __html: GetHtml(FileTableSkeleton) }} />
                  ) : (
                    <FilesTables
                      rowKey={item.key}
                      filteredInfo={{
                        type: currentFilterData,
                        ownerType: currentOwnerData,
                        timeOrder: currentTimeOrder,
                      }}
                      getWholePath={getWholePath}
                      updateCurrentList={updateCurrentList}
                      doFilters={doFilters}
                      dataSource={getCurrentList(item.key)}
                      loading={loading}
                      loadEnd={loadEnd}
                    />
                  )
                }
              </CooperTabsPane>
            )
          })
        }
      </CooperTabs>
    </div>
  )
}

export default RecentActivity
