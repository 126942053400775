import classBind from 'classnames/bind';

import React from 'react';
import styles from './style.module.less';

const cx = classBind.bind(styles);

class Resizer extends React.Component {
  static defaultProps = { onBegin: () => {}, onResize: () => {}, onEnd: () => {} };

  constructor(props) {
    super(props);
    this.lastOffsetX = 0;
    this.mouseX = 0;
    this.resizing = false;
  }

  handleDown = (e) => {
    this.resizing = true;
    this.mouseX = e.clientX;
    document.addEventListener('mousemove', this.handleMove, false);
    document.addEventListener('mouseup', this.handleUp, false);

    this.props.onBegin();
  }

  handleMove = (e) => {
    if (this.resizing) {
      const offsetX = e.clientX - this.mouseX;
      this.props.onResize(offsetX);
    }
  }

  handleUp = () => {
    this.resizing = false;
    document.removeEventListener('mousemove', this.handleMove, false);
    document.removeEventListener('mouseup', this.handleUp, false);
    this.props.onEnd();
  }

  render() {
    return (
      <div
        className={cx('resizer')}
        onMouseDown={this.handleDown}
      >
        <div className={cx('resizer-container')}> </div>

      </div>
    );
  }
}

export default Resizer;
