/*
 * @Author: guanzhong
 * @Date: 2023-11-09 16:00:00
 * @LastEditors: guanzhong
 * @LastEditTime: 2023-11-10 11:15:30
 * @FilePath: /knowledgeforge/src/pages/knowledge/ShareBook/CatalogTree/TreeNode/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Link } from 'react-router-dom';
import classBind from 'classnames/bind';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import styles from '@/components/serviceComponents/CatalogTree/TreeNode/style.module.less';
import { useContext } from 'react';

const cx = classBind.bind(styles);


function TreeNode({ nodeData, isSubPageShare, fromShareId }) {
  const {
    pageId, key, title, shareId: currShareId,
  } = nodeData;
  const { shareId } = useContext(LayoutContext);

  return (
    <span
      className={cx('tree-node-item-wrap')}
    >
      <Link
        to={isSubPageShare ? `/knowledge/share/page/${currShareId}?base=${fromShareId}` : `/knowledge/share/book/${shareId}/${pageId}`}
        className={cx('item-name')}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <span
          className={cx('item-name-link')}
          key={key}
        >{title}</span>
      </Link>
    </span>

  );
}

export default TreeNode;
