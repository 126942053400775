/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-11-01 12:03:14
 * @LastEditTime: 2023-11-08 15:13:47
 * @Description: 知识库跳转代码
 * @FilePath: /knowledgeforge/src/utils/goto.js
 *
 */

import { openNewWindow } from './index';
import { RECENT_TABS, RECENT_VISIT_TYPE } from '@/constants/recent';


export const gotoDk = (knowledgeId) => {
  openNewWindow(`${window.location.origin}/knowledge/${knowledgeId}/home`);
};

const gotoPage = (knowledgeId, pageId) => {
  openNewWindow(`${window.location.origin}/knowledge/${knowledgeId}/${pageId}`);
};

const gotoEditPage = (knowledgeId, pageId) => {
  openNewWindow(`${window.location.origin}/knowledge/${knowledgeId}/${pageId}/edit`);
};

const gotoSharePage = (shareId) => {
  openNewWindow(`${window.location.origin}/knowledge/share/page/${shareId}`);
};

const gotoDkSharePage = (shareId, pageId) => {
  openNewWindow(`${window.location.origin}/knowledge/share/book/${shareId}/${pageId}`);
};

export const gotoRecentPage = ({ knowledgeId, pageId, sourceType, shareId, activeTab }) => {
  if (activeTab === RECENT_TABS.EDIT) {
    gotoEditPage(knowledgeId, pageId);
    return;
  }
  if (sourceType === RECENT_VISIT_TYPE.PAGE_SHARE) {
    gotoSharePage(shareId);
  } else if (sourceType === RECENT_VISIT_TYPE.DK_SHARE) {
    gotoDkSharePage(shareId, pageId);
  } else {
    gotoPage(knowledgeId, pageId);
  }
};

export const goStarPage = ({ knowledgeId, pageId, sourceType, shareId, starInfo }) => {
  // console.log(knowledgeId, pageId, sourceType, shareId, starInfo);
  if (sourceType === 'PAGE_SHARE') {
    gotoSharePage(starInfo);
  } else if (sourceType === 'DK_SHARE') {
    gotoDkSharePage(starInfo, pageId);
  } else {
    gotoPage(knowledgeId, pageId);
  }
}
