/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-06-15 14:45:00
 * @LastEditTime: 2023-10-24 13:56:44
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/common/ImportTip/index.js
 *
 */
import React from 'react';
import { Modal } from 'antd';
import styles from './style.module.less';
import classBind from 'classnames/bind';

const cx = classBind.bind(styles);

class ImportTip extends React.Component {
  render() {
    return (
      <Modal
        className={cx('import-tip')}
        style={{ top: 65 }}
        width={320}
        height={200}
        visible={true}
        title={null}
        footer={null}
        zIndex={9999}
        wrapClassName={'import-tip-modal'}
        >
        {this.props.text}
      </Modal>
    );
  }
}

export default ImportTip;
