import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import mountAnywhere from '@/utils/mountAnywhere';
import styles from  './index.module.less';
import { openNewWindow } from '@/utils';
import { intl } from 'di18n-react';

const ErrorNoticeCom = (props) => {
  const { type } = props;
  const [visible, setVisible] = useState(true);

  const titleText = {
    'error': intl.t('故障通知'),
    'switch': intl.t('Cooper切换通知'),
    'switch_knowledge': intl.t('Cooper提示'),
  }

  const contentText = {
    'error': intl.t('相关服务出现故障，Cooper平台相关功能受到影响，我们正在全力处理以确保尽快恢复，给您带来不便，敬请谅解。'),
    'switch': intl.t('服务故障短时间无法恢复，有工作需求的同学可前往使用Cooper容灾版。'),
    'switch_knowledge': intl.t('服务故障暂未恢复，移动端不支持访问，Cooper在网页端提供了容灾通道，请前往网页端访问。'),
  }

  return (
    <Modal
      centered
      width={350}
      className={styles['lite-error-notice']}
      visible={visible}
      maskClosable={false}
      footer={null}
      title={null}
      closable={false}
      zIndex={999999}
    >
      <div className={styles['lite-notice-logo']}>
        <img src='http://img-ys011.didistatic.com/static/cooper_cn/do1_IdgHKEBYk8gvDIAQMp8C' alt='logo' />
      </div>
      <div className={styles['lite-notice-title']}>
        { titleText[type] }
      </div>
      <div className={styles['lite-notice-content']}>
        { contentText[type] }
      </div>
      <div className={styles['lite-notice-footer']}>
        {
          (type === 'error' || type === 'switch_knowledge') ? (
            <Button
              className={styles['lite-notice-btn']}
              type='primary'
              onClick={() => {
                setVisible(false);
              }}
            >
              {intl.t('我知道了')}
            </Button>
          ) : (
            <Button
              className={styles['lite-notice-btn']}
              type='primary'
              onClick={() => {
                if (process.env.APP_ENV === 'prod') {
                  openNewWindow('https://cooper-lite.didichuxing.com' + location.pathname + location.search);
                } else {
                  openNewWindow('https://cooper-lite-qa.didichuxing.com' + location.pathname + location.search);
                }
              }}
            >
              {intl.t('使用容灾版')}
            </Button>
          )
        }
      </div>
    </Modal>
  )
}

let previousErrorNotice = null;

const closePreviousErrorNotice = () => {
  if (previousErrorNotice) {
    previousErrorNotice();
    previousErrorNotice = null;
  }
};

const errorNotice = (type) => {
  closePreviousErrorNotice();

  const Comp = (
    <ErrorNoticeCom 
      onClose={() => close()}
      type={type}
    />
  );

  const close = mountAnywhere(Comp);
  previousErrorNotice = close;

}

export default errorNotice;
