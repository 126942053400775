import { intl, getLocale } from 'di18n-react';
import classBind from 'classnames/bind';
import React, { Fragment, useState } from 'react';
import { openNewWindow } from '@/utils/index';
import { Popover, Tag } from 'antd';
import { isDiDiTenant } from '@/utils/entryEnhance';
import { useSelector } from 'react-redux';
import styles from './style.module.less';
import ImageWithLoader from '../ImageWithLoader';
import NetworkHelp from '../Network'

const cx = classBind.bind(styles);

function HelpCenter(props) {
  const isZH = getLocale() === 'zh-CN';
  const { disablePopover = false, showNewFeature = false, config = {}} = props;
  const [visible, setVisible] = useState(false);
  const {
    helpBannerImageUrl,
    helpBannerImageUrl_en,
    helpBannerClickUrl,
    helpBannerClickUrl_en,
    helpBannerDesc,
    helpBannerDesc_en,
    helpBannerDesc_color,
    helpBannerDesc_bg_color
  } = config;
  const { cooperLinkConf } = useSelector((state) => state.GlobalData);
  // eslint-disable-next-line camelcase, max-len
  const { 
    feedBack_dc, 
    help_link_zh, 
    help_link_en, 
    update_log_zh, 
    faq_en,
    faq_zh, 
    update_log_en, 
    out_help_link_zh, 
    out_help_link_en, 
    out_update_log_zh, 
    out_update_log_en
  } = cooperLinkConf;

  const HELP_LIST = isDiDiTenant() ? [
    // {
    //   val: intl.t('Cooper 客服'),
    //   open: (helpChain) => {
    //     openNewWindow(helpChain.support)
    //   }
    // },
    {
      icon: 'dk-icon-gongnengshangxin',
      val: intl.t('功能上新'),
      needShowNew: true,
      open: () => {
        window.__OmegaEvent(
          'ep_help_updatelog_ck',
          '查看新功能',
          {
            platform: 'knowledgeforge',
          },
        );
        if (getLocale() === 'zh-CN') {
          openNewWindow(update_log_zh);
        } else {
          openNewWindow(update_log_en);
        }
      },
    },
    {
      icon: 'dk-icon-changjianwenti-01',
      val: intl.t('常见问题'),
      open: () => {
        window.__OmegaEvent(
          'ep_help_commonquestion_ck',
          '查看常见问题',
          {
            platform: 'knowledgeforge',
          },
        );
        if (getLocale() === 'zh-CN') {
          openNewWindow(faq_zh);
        } else {
          openNewWindow(faq_en);
        }
      },
    },
    // {
    //   val: intl.t('问题反馈'),
    //   open: (helpChain) => {
    //     openNewWindow(helpChain.feedBack)
    //   }
    // },
    {
      icon: 'dk-icon-bangzhushouce-01',
      val: intl.t('帮助文档'),
      open: () => {
        window.__OmegaEvent(
          'ep_help_doc_ck',
          '查看帮助文档',
          {
            platform: 'knowledgeforge',
            tenant: isDiDiTenant() ? 'internal' : 'external',
          },
        );
        if (getLocale() === 'zh-CN') {
          openNewWindow(help_link_zh);
        } else {
          openNewWindow(help_link_en);
        }
      },
    },
    {
      icon: 'dk-icon-wangluojiance',
      val: intl.t('检查服务可用性'),
      open: () => {
        window.__OmegaEvent(
          'ep_service_ck',
          '检测服务可用性',
          {
            source: 'cooper',
          },
        );
        NetworkHelp()
      }
    },
  ] : [
    {
      icon: 'dk-icon-changjianwenti-01',
      val: intl.t('常见问题'),
      open: () => {
        if (getLocale() === 'zh-CN') {
          openNewWindow(out_help_link_zh);
        } else {
          openNewWindow(out_help_link_en);
        }
      },
    },
    {
      icon: 'dk-icon-bangzhushouce-01',
      val: intl.t('帮助文档'),
      open: () => {
        if (getLocale() === 'zh-CN') {
          openNewWindow(out_update_log_zh);
        } else {
          openNewWindow(out_update_log_en);
        }
      },
    },
  ]

  const bannerClickHandler = () => {
    openNewWindow(isZH ? helpBannerClickUrl : helpBannerClickUrl_en);
  }

  const _renderContent = () => {
    return (
      <div onMouseLeave={() => setVisible(false)}>
        {
          HELP_LIST.map((item, index) => {
            return <li
              className={'help-center-list-item'}
              key={index}
              onClick={() => {
                setVisible(false)
                item.open()
              }}>
              <i className={`dk-iconfont ${item.icon}`}/>
              <span>{item.val}</span>
              {
                item.needShowNew && showNewFeature ? <Tag color="#f50" className='dk-new'>new</Tag> : null
              }
            </li>
          })
        }
        {
          helpBannerImageUrl ? <>
            <div className='help-conter-split'/>
            <div className={cx('help-center-list-banner')}>
              <div
                className={'banner-title'}
                style={{
                  backgroundColor:helpBannerDesc_bg_color,
                  color:helpBannerDesc_color
                }}>
                {isZH ? helpBannerDesc : helpBannerDesc_en}
              </div>
              <div className={'banner-img'}>
                <ImageWithLoader
                  // eslint-disable-next-line camelcase
                  src={isZH ? helpBannerImageUrl : helpBannerImageUrl_en}
                  clickHandler={bannerClickHandler} />
              </div>
            </div>
          </> : null
        }
      </div>
    )
  }
  return (
    <div className={cx('cooper-helper-wrap')}>
      {
        disablePopover
          ? <div className={cx('cooper-helper-disable')}>
            <i className="dk-iconfont dk-icon-gongnengshangxin" />
          </div>
          : <Popover
              content={_renderContent()}
              overlayClassName = {cx('cooper-helper-popover')}
              align={{ offset: [10, 10] }}
              placement='bottomRight'
              zIndex={9999999}
              mouseEnterDelay="0.1"
              visible={visible}
            >
            <div className={cx('cooper-helper')} onClick={() => setVisible(true)}>
              <i className="dk-iconfont dk-icon-gongnengshangxin"/>
            </div>
          </Popover>
      }
    </div>
  );
}

export default HelpCenter;
