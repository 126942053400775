import { intl } from 'di18n-react';
import styles from './style.module.less';
import classBind from 'classnames/bind';
import { NoSearchResultIcon } from '@/assets/icon';

const cx = classBind.bind(styles);

const PeopleSelectEmpty = () => (
  <div className={cx('emptySearch')}>
    <img
      className={cx('emptySearch-icon')}
      src={NoSearchResultIcon} />
    <p className={cx('emptySearch-tip')}>{intl.t('暂无搜索结果')}</p>
  </div>
);

export default PeopleSelectEmpty;
