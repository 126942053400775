const getDefaultState = () => ({
  rootCache: {
    teamId: 0,
    id: 0,
    isRoot: true,
    level: 0,
    checkStatus: 0,
    children: [],
  },
});

export default {
  name: 'CooperDisk',
  state: getDefaultState(),
  reducers: {
    setRoot(state, root) {
      return {
        ...state,
        rootCache: root,
      };
    },
  },
  effects: {},
};
