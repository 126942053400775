import { useSelector } from 'react-redux';
// 接受redux的用户信息：普通用户 or 租户用户
// 装饰器，接收组件本身，判断是否返回null 还是 渲染组件
// 组件调用时执行，而不是直接装饰原组件，因为此逻辑是业务“状态”

// 怎么处理接口数据 及 for循环的逻辑 文案逻辑

import store from '@/model';

// import React from 'react';
// 模拟的配置入口开关JSON ，简单配置决定渲染
const entryJSON = {
  UPLOAD: false,
  IMPORT: false,
  SHOWOFF: false,
}


const logicJson = {
  TEAM_OUTER() {
    const { teamDetail } = useSelector((state) => state.TeamData);
    if (teamDetail?.cooperate_type === 'IN_OUT') {
      return false
    }
    return true
  },
}

const entryMap = new Map(Object.entries(entryJSON));
// const logicMap = new Map(Object.entries(logicJson));

/**
 *
 * @param {*} WrappedComponent 渲染组件
 * @param {*} key 渲染组件映射的配置开关
 * @returns
 */
export function entryEnhance(WrappedComponent, key, logicFunction) {
  function EntryEnhanceHOC() {
    const { IsExternalTenant } = useSelector((state) => state.GlobalData);

    if (IsExternalTenant) {
      // 1、入口json中是否有这个配置，根据配置决定是否渲染入口
      if (entryMap.has(key)) {
        return entryMap.get(key) ? WrappedComponent : null
      }

      // 3、外部租户隐藏
      return null
    }
    // 2、是否有特殊逻辑处理，例如外部空间
    if (logicJson[logicFunction]) {
      return logicJson[logicFunction]() ? WrappedComponent : null
    }
    return WrappedComponent;
  }
  return EntryEnhanceHOC(WrappedComponent)
}

export const isDiDiTenant = () => {
  const { IsExternalTenant, tenantConfig } = store.getState().GlobalData
  if (tenantConfig.length !== 0) {
    const agentItem = !IsExternalTenant;
    return agentItem;
  }
  return true;
}

