import { intl } from 'di18n-react';
import classBind from 'classnames/bind';
import { List } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import RestrictLength from '@/components/RestrictLength';
import { RECENT_TABS, OWNER_TYPE } from '@/constants/recent';
import NoMore from '@/components/serviceComponents/NoMore';
import { PageNotPublishIcon, RecentListDkIcon } from '@/assets/icon';
import { gotoDk, gotoRecentPage } from '@/utils/goto';
import FileContent from '@/baseComponents/FileContent';
import styles from './style.module.less';

const cx = classBind.bind(styles);

const RecentList = ({ activeTab, recentList, loading, loadMore, hasMore }) => {
  const clickRow = (item) => {
    if (activeTab === RECENT_TABS.EDIT) {
      window.__OmegaEvent('ep_dkpc_dkhead_recentedit_visitdk_ck');
    } else {
      window.__OmegaEvent('ep_dkpc_dkhead_recentview_visitpage_ck');
    }
    gotoRecentPage({ ...item, activeTab });
  };

  return (
    <div className={cx('recentList', 'knowledge_recentList_wrap')}>
      <InfiniteScroll
        initialLoad={false}
        pageStart={0}
        loadMore={() => loadMore(false, OWNER_TYPE.ALL)}
        hasMore={!loading && hasMore}
        useWindow={false}
      >
        {recentList.length === 0 && !loading && (
          <div className={cx('empty')}>
            <img
              className={cx('empty-pic')}
              src={PageNotPublishIcon} />
            <p className={cx('empty-text')}>
              {intl.t('暂无{slot0}记录', {
                slot0: activeTab === RECENT_TABS.EDIT ? intl.t('编辑') : intl.t('浏览'),
              })}
            </p>
          </div>
        )}

        {recentList.length > 0
          && recentList?.map((item, index) => (
            <div
              className={cx('recent-item')}
              onClick={() => clickRow({ ...item, activeTab })}
              key={item.key}
            >
              <FileContent
                resourceName={item.pageName}
                metisName={item.knowledgeName}
                tags={item.shareId ? [intl.t('访客链接')] : null}
                metisId={item.knowledgeId}
              />
            </div>
          ))}

        {!loading && !hasMore && recentList.length !== 0 && (
          <NoMore customClass={cx('noMore')} />
        )}
      </InfiniteScroll>
    </div>
  );
};

export default RecentList;
