/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-05-29 17:38:12
 * @LastEditTime: 2023-06-20 15:32:10
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/service/cooper/reame.js
 *
 */

import { put, patch } from '@/utils/request/cooper';
import api from '@/utils/request/api/CooperApi';

export async function renameTeam(id, name) {
  const res = await put(api.ADAPTER_TEAMS, {
    id,
    name,
  });

  return res;
}

export async function renameFile(id, name) {
  const res = await patch(api.RESOURCES_RENAME.replace(':resourceId', id), {
    name,
  }, {
    headers: {
      'X-App-Id': 2,
    },
  });

  return res;
}
