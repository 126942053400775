
const getDefaultState = () => ({
  visible: false,
  resourceId: '0',
  knowledgeId: '',
  pageName: '',
});

export default {
  state: getDefaultState(),
  reducers: {
    openFileModal(state, { pageId, knowledgeId, pageName }) {
      return {
        ...state,
        visible: true,
        resourceId: pageId,
        knowledgeId,
        pageName,
      };
    },
    closeFileModal(state) {
      return {
        ...state,
        visible: false,
      };
    },
  },
};
