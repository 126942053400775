import { get, post } from '@/utils/request';
import { getCookie, isDesktopDC, isKnowledge, encodeURIParamFn } from '@/utils';
import api from '@/utils/request/api/DkApi';

export async function getUserData(par) {
  let url = isKnowledge() ? api.GET_USER_DATA_DK : api.GET_USER_DATA_COOPER;
  try {
    const res = await get(`${url}${encodeURIParamFn(par)}`);
    return res;
  } catch (e) {
    console.log(e);
  }
}
export async function getApolloConfig({ ns = 'cooper_announce', name }) {
  try {
    const res = await fetch(`https://${api.GET_APOLLO_CONFIG}?ns=${ns}&name=${name}&__caller=apollo_sdk_react`).then((v) => v.json());
    return res?.data[0]?.params;
  } catch (e) {
    console.log(e);
  }
}

export async function getSuiteList(suiteSourceId) {
  let host = '';
  if (window.location.hostname === 'cooper.didichuxing.com') {
    host = 'https://team.didichuxing.com';
  } else if (window.location.hostname === 'cooper-qa.didichuxing.com') {
    host = 'http://team-qa.intra.xiaojukeji.com/zhuronghao';
  } else host = 'https://team-test.didichuxing.com';
  const res = await post(`${host}${api.GET_SUITE_LIST}`, {
    suiteSourceId,
    clientTag: isDesktopDC ? 'PC' : 'WEB',
    suiteSourceType: 'KNOWLEDGE',
  }, {
    headers: {
      'X-App-Id': 6,
      _user_token: getCookie('_chain_user_token') || getCookie('_user_token'),
    },
  });
  return res;
}


