/*
 * @Date: 2024-04-17 14:32:12
 * @LastEditors: guanzhong guanzhong@didiglobal.com
 * @LastEditTime: 2024-04-22 19:59:08
 * @FilePath: /knowledgeforge/src/components/common/ListOptions/UserItem/index.js
 * @Description: 描述文件功能
 */
import { highlight } from '@/utils';
import { Avatar, Tooltip } from 'antd';
import classBind from 'classnames/bind';
import styles from './style.module.less';
import Tag from '@/components/Tag/index';
import { intl } from 'di18n-react';

const cls = classBind.bind(styles);

function UserItem({ optionData, isNoHoverTips = false }) {
  let label = `${optionData.title}(${optionData.dep})`;
  return (
    <div className={cls('dk-people-select-label-item')}>
      <div className={cls('dk-people-select-label-item-left')}>
        <Avatar
          size={36}
          src={optionData.avatar} />
      </div>
      <div className={cls('dk-people-select-label-item-right')}>
        <div className={cls('dk-people-select-label-item-right-top')}>
          {label.length > 16 && !isNoHoverTips ? (
            <Tooltip title={label}>
              <div
                className={cls('dk-people-select-label-detail')}
          >
                {`${highlight(optionData.title)}(${optionData.showOuter ? optionData.orgName : optionData.dep})`}
              </div>
            </Tooltip>
          ) : (
            <div
              className={cls('dk-people-select-label-detail')}
        >
              {`${highlight(optionData.title)}(${optionData.showOuter ? optionData.orgName : optionData.dep})`}
            </div>
          )}
          {optionData.showOuter && <Tag
            type='out-yellow'
            text={intl.t('外部')}/>}
        </div>


        <div className={cls('dk-people-select-deptPath')}>
          &lrm;{highlight(optionData.mail)}&lrm;
        </div>
      </div>
    </div>
  );
}

export default UserItem;
