import { isDesktopDC, openNewWindow } from '@/utils/index';
import classBind from 'classnames/bind';
import { useSelector } from 'react-redux';
import { isDiDiTenant } from '@/utils/entryEnhance';
import helperFeedback from './FeedbackModal';
import { Tooltip } from 'antd';
import { intl } from 'di18n-react';
import styles from './style.module.less';
import { toggleModal } from '@didi/ai-customer-service';
import { useEffect } from 'react';

const cx = classBind.bind(styles);


function Feedback({ icon, placement }) {
  const { cooperLinkConf } = useSelector((state) => state.GlobalData);
  useEffect(() => {
    // 智能客服
    function getUserNameFromCookie() {
      const m = document.cookie?.match(/username=([^;]*)/);
      return decodeURIComponent(m ? m[1] : "");
    }
    fetch(
      `https://as.xiaojukeji.com/ep/as/feature?name=ai-customer-service&key=${getUserNameFromCookie()}&_cooper_username=${getUserNameFromCookie()}&__caller=di-knowledge`
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.code === 0 && res && res.data) {
          let result = res.data[0] || {};
          window.aiCustomerServiceABSwitch = result.allow;
        }
      })
      .catch((e) => {
        console.log("获取灰度名单失败", e);
      });
  }, [])
  // eslint-disable-next-line camelcase, max-len
  const { feedBack_dc } = cooperLinkConf;
  // TODO：判断是否DC
  const open = (e) => {
    window.__OmegaEvent(
      'ep_help_ck',
      '点击进入反馈群',
      {
        platform: 'knowledgeforge',
        tenant: isDiDiTenant() ? 'internal' : 'external',
      },
    );
    if (isDiDiTenant()) {
      window.aiCustomerServiceABSwitch !== false
        ? toggleModal({
          initialWidth: 380,
          initialHeight: isDesktopDC ? 512 :  600,
          initialZIndex: 100000000
        }, { config: { feedBack_dc } }, e)
        : openNewWindow(feedBack_dc);
    } else {
      helperFeedback();
    }
  }

  return (
    <div className={cx('feedback')}>
      <Tooltip
        placement={placement ?? 'bottomRight'}
        title={isDiDiTenant() ? intl.t('Cooper相关问题，请点击咨询') : intl.t('点击反馈Cooper问题')}
      >
        <div
          onClick={open}
          key={'feedback'}
          className={cx('content')}
        >
          {
            icon || <i className='dk-iconfont dk-icon-lianxikefu'/>
          }

        </div>
      </Tooltip>
    </div>
  );
}

export default Feedback;
