import Icon from '@ant-design/icons';

const ArrowRightIcon = () => <svg
  width="14px"
  height="14px"
  fill="currentColor"
  viewBox="0 0 1024 1024"
  version="1.1"
  p-id="6812"
  xmlns="http://www.w3.org/2000/svg"
    >
  <path
    d="M276.187429 378.587429a43.885714 43.885714 0 0 1 55.954285-5.12l6.070857 5.12L512 552.228571l173.860571-173.714285a43.885714 43.885714 0 0 1 55.954286-5.12l6.070857 5.12a43.885714 43.885714 0 0 1 5.12 55.954285l-5.12 6.070858-178.907428 178.980571a80.457143 80.457143 0 0 1-106.788572 6.217143l-7.021714-6.217143-178.907429-178.980571a43.885714 43.885714 0 0 1 0-62.025143z"
    p-id="6511" />
</svg>

const OutlinedIcon = (props) => <Icon
  component={ArrowRightIcon}
  {...props} />;

export default OutlinedIcon;
