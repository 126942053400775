import { intl } from 'di18n-react';
import { useState, useRef, useEffect } from 'react';
import { Tooltip } from 'antd';
import classBind from 'classnames/bind';
import { useSelector, useDispatch } from 'react-redux';
import { ASIDE_SHOW_TYPE } from '@/constants/index';
import Resizer from '@/components/Resizer';
import { throttle } from 'lodash-es';
import { useParams, useLocation } from 'react-router-dom';
import AsideShareContent from './AsideShareContent';
import AsideContent from './AsideContent';
import stylesCommon from './catalogCommon.module.less';
import styles from './style.module.less';

const minWidth = '280';

const cx = classBind.bind(styles);
const cm = classBind.bind(stylesCommon);

export default function AsideDK({ asideContentType }) {
  const dispatch = useDispatch();
  const [asideContentWidth, setAsideContentWidth] = useState(minWidth);
  const [isResizing, setIsResizing] = useState(false);
  const { pathname } = useLocation();
  const asideContentRef = useRef();
  const asideWrapRef = useRef();
  const originalWidthRef = useRef(0);
  const { asideType } = useSelector((state) => state.KnowledgeData);
  const { setAsideType } = dispatch.KnowledgeData;
  const { changeIsResizingAside } = dispatch.asideDK;
  const { teamId, knowledgeId } = useParams();

  const resizeBegin = () => {
    originalWidthRef.current = Number(asideContentRef.current.style.width.slice(0, -2)) || 280;
    setIsResizing(true);
  };

  const resizeEnd = () => {
    setIsResizing(false);
  };

  useEffect(() => {
    changeIsResizingAside(isResizing);
  }, [isResizing]);

  useEffect(() => {
    if (asideType === ASIDE_SHOW_TYPE.MODAL) {
      asideWrapRef.current.style.width = '0px';
      let left = asideContentWidth;
      if (teamId) {
        left += 40;
      }
      asideContentRef.current.style.left = `-${left}px`;
    }
  }, [knowledgeId, asideType])

  const changeAsideView = (type) => {
    if (type === ASIDE_SHOW_TYPE.MODAL) {
      asideWrapRef.current.style.width = '0px';
      let left = asideContentWidth;
      if (teamId) {
        left += 40;
      }
      asideContentRef.current.style.left = `-${left}px`;
    } else if (type === ASIDE_SHOW_TYPE.TILE) {
      asideWrapRef.current.style.width = `${asideContentWidth}px`;
      asideContentRef.current.style.left = 0;
    }

    if (asideType !== type) {
      setAsideType(type);
    }
  };

  const hoverChangeLeft = (left) => {
    if (asideType !== ASIDE_SHOW_TYPE.TILE) {
      const popoverDom = document.getElementsByClassName('catalog-operate-container ')[0]
        || document.getElementsByClassName('catalog-add-container')[0];
      if (asideContentRef.current.style.left !== left && !popoverDom) {
        asideContentRef.current.style.left = left;
      }
    }
  };

  const resizing = throttle((offsetX) => {
    const offset = Number(offsetX);
    const newWidth = originalWidthRef.current + offset;
    newWidth < 680 && newWidth > 280 && setAsideContentWidth(newWidth);
  }, 16);

  return (
    <div
      className={`${cm('aside-wrap-common')} ${cx({
        'aside-wrap': true,
        'aside-wrap-modal': asideType !== ASIDE_SHOW_TYPE.TILE,
      })}`}
      id="dk-aside"
      ref={asideWrapRef}
      style={{
        flexBasis:
          asideType === ASIDE_SHOW_TYPE.TILE ? `${asideContentWidth}px` : '0px',
      }}
    >
      <div
        id={asideType === ASIDE_SHOW_TYPE.TILE ? 'dk-view-wrap' : ''}
        className={cx({
          'aside-handle': true,
          'aside-handle-hover': asideType !== ASIDE_SHOW_TYPE.TILE,
        })}
        onMouseLeave={() => hoverChangeLeft(`-${teamId ? (asideContentWidth + 40) : asideContentWidth}px`)}
      >
        <Tooltip
          title={intl.t('展开目录树')}
          placement="right"
          overlayClassName="dk-ant-tooltip__reset"
        >
          <i
            className={cx({
              'dk-iconfont': true,
              'dk-icon-liebiaomulushu': true,
              'icon-zhankai': true,
              'icon-unfold': true,
            })}
            onClick={() => {
              if (pathname.includes('edit')) {
                window.__OmegaEvent('ep_dkpc_opentree_edit_ck');
              } else {
                window.__OmegaEvent('ep_dkpc_opentree_view_ck');
              }
              changeAsideView(ASIDE_SHOW_TYPE.TILE);
            }}
            onMouseEnter={() => hoverChangeLeft('0px')}
          />
        </Tooltip>
        <div
          className={cx('aside-content')}
          id={'aside-content-id'}
          ref={asideContentRef}
          style={{
            width: `${asideContentWidth}px`,
            backgroundColor: `${teamId && '#fff'}`,
            borderRadius: `${teamId && '8px 0 0 0'}`,
          }}
        >
          {asideContentType === 'Normal' && <AsideContent isResizing={isResizing} />}
          {asideContentType === 'Share' && <AsideShareContent />}
          {asideType === ASIDE_SHOW_TYPE.TILE && (
            <Tooltip
              title={intl.t('收起目录树')}
              placement="right"
              overlayClassName="dk-ant-tooltip__reset"
            >
              <i
                className={cx({
                  'dk-iconfont': true,
                  'dk-icon-shouqijiantou': true,
                  'icon-shouqi': true,
                  'icon-unfold': true,
                })}
                onClick={() => {
                  if (pathname.includes('edit')) {
                    window.__OmegaEvent('ep_dkpc_closetree_edit_ck');
                  } else {
                    window.__OmegaEvent('ep_dkpc_closetree_view_ck');
                  }
                  changeAsideView(ASIDE_SHOW_TYPE.MODAL);
                }}
              />
            </Tooltip>
          )}

          {asideType === ASIDE_SHOW_TYPE.TILE && (
            <Resizer
              onBegin={resizeBegin}
              onResize={resizing}
              onEnd={resizeEnd}
            />
          )}
        </div>
      </div>
    </div>
  );
}
