import { intl } from 'di18n-react';

export const SETUP_TABS = {
  BASIC_SETUP: '0',
  POWER_MANAGERMENT: '1',
  TEMPLATE_MANAGERMENT: '2',
  ADVANCED_SETTING: '3',
};

export const POWER_MANAGERMENT_TABS = [
  { key: '0', value: () => intl.t('全部') },
  { key: '1', value: () => intl.t('成员') },
  { key: '2', value: () => intl.t('成员组') },
];

export const POWER_MANAGERMENT_TABS_TYPE = {
  ALL: '0',
  MEMBER: '1',
  GROUP: '2',
};

export const DK_MEMBER_TYPE = {
  DK_MEMBER: 'DK_MEMBER',
  DK_READ_MEMBER: 'DK_READ_MEMBER',
  DK_ADMIN: 'DK_ADMIN',
};

export const DK_MEMBER_OMEGA_TYPE = {
  DK_MEMBER: 'editor',
  DK_READ_MEMBER: 'readonly',
  DK_ADMIN: 'administrators',
};

export const PAGE_MEMBER_TYPE = {
  PAGE_MEMBER: 'PAGE_MEMBER',
  PAGE_READ_MEMBER: 'PAGE_READ_MEMBER',
};

export const SEARCH_GROUP_TYPE = {
  CUSTOM_GROUP: 1,
  ORGANIZATION_GROUP: 2,
  COOPER_GROUP: 3,
};

export const PAGE_MEMBER_NAME = function () {
  return {
    DK_MEMBER: intl.t('成员'),
    DK_READ_MEMBER: intl.t('只读成员'),
    PAGE_OWNER: intl.t('所有者'),
    DK_OWNER: intl.t('所有者'),
    DK_ADMIN: intl.t('管理员'),
    PAGE_MEMBER: intl.t('成员'),
    PAGE_READ_MEMBER: intl.t('只读成员'),
  }
};

export const ADD_MEMBER_TYPE = {
  PERSON: 0,
  OE_GROUP: 1,
  COOPER_GROUP: 2,
  EMAIL_GROUP: 3,
  DC_GROUP: 4,
  DEPARTMENT: 5,
  CUSTOM_GROUP: 6,
};

export const ADD_GROUP_TYPE = {
  PERSON: 'PERSON',
  OE_GROUP: 'OE_GROUP',
  COOPER_GROUP: 'COOPER_GROUP',
  EMAIL_GROUP: 'EMAIL_GROUP',
  DC_GROUP: 'DC_GROUP',
  CUSTOM_GROUP: 'METIS_CUSTOM',
  DEPARTMENT_GROUP: 'DEPARTMENT',
};

export const ADD_MEMBER_FROM_TYPE = {
  DK: 'Dk',
  DK_PAGE: 'DkPage',
  Dk_Group: 'DkGroup',
};

export const SAFE_SETTING_VALUE = {
  OPEN: 1,
  CLOSE: 2,
};

export const SHARE_STATUS = {
  CLOSE: 0,
  OPEN: 1,
  EDIT_SETTING: 2,
};

export const GROUP_OMEGA_TYPE = {
  CREATE: 'create',
  EDIT: 'edit',
};

export const ADD_SEARCH_MEMBER_TYPE = ['all', 'user', 'group'];
export const CHANGE_SEARCH_MEMBER_TYPE = [
  'owner',
  'administrators',
  'edit',
  'readonly',
];

export const EpClickAddDkMemberBatchAddFns = {
  clickBatchAdd: () => window.__OmegaEvent('ep_dkpc_member_invite_batchinvite_ck'),
  notify: () => window.__OmegaEvent('ep_dkpc_member_invite_batchinvite_notify_ck'),
  return: () => window.__OmegaEvent('ep_dkpc_member_invite_batchinvite_return_ck'),
  modifyPermission: () => window.__OmegaEvent(
    'ep_dkpc_member_invite_batchinvite_modifypermission_ck',
  ),
  modify: () => window.__OmegaEvent('ep_dkpc_member_invite_batchinvite_modify_ck'),
  confirm: () => window.__OmegaEvent('ep_dkpc_member_invite_batchinvite_confirm_ck'),
  copy: () => window.__OmegaEvent('ep_dkpc_member_invite_batchinvite_copy_ck'),
};

export const EpClickAddPageMemberBatchAddFns = {
  clickBatchAdd: () => window.__OmegaEvent('ep_dkpc_pagedetail_invite_batchinvite_ck'),
  notify: () => window.__OmegaEvent('ep_dkpc_pagedetail_invite_batchinvite_notify_ck'),
  return: () => window.__OmegaEvent('ep_dkpc_pagedetail_invite_batchinvite_return_ck'),
  modifyPermission: () => window.__OmegaEvent(
    'ep_dkpc_pagedetail_invite_batchinvite_modifypermission_ck',
  ),
  modify: () => window.__OmegaEvent('ep_dkpc_pagedetail_invite_batchinvite_modify_ck'),
  confirm: () => window.__OmegaEvent('ep_dkpc_pagedetail_invite_batchinvite_confirm_ck'),
  copy: () => window.__OmegaEvent('ep_dkpc_pagedetail_invite_batchinvite_copy_ck'),
};
