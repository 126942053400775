import { intl,getLocale } from 'di18n-react';
import { useMemo } from 'react';
import classBind from 'classnames/bind';
import { highlight, openNewWindow, isDesktopDC } from '@/utils';
import { dkItemDepartmentTagIcon } from '@/assets/icon';
import styles from './style.module.less';

const cx = classBind.bind(styles);

const DKCard = ({ dkInfo, isOwn, prefix }) => {
  const gotoDk = (metisSpaceId) => {
    openNewWindow(
      `${prefix || window.location.origin}/knowledge/${metisSpaceId}/home`,
    );

    if (isOwn) {
      window.__OmegaEvent('ep_dkpc_dkhome_owned_viewdk_ck');
    } else window.__OmegaEvent('ep_dkpc_dkhome_joined_viewdk_ck');
  };

  return (
    <li
      className={cx('team-dk', {
        'team-dk-dc': isDesktopDC,
      })}
      onClick={() => gotoDk(dkInfo.id)}
    >
      <div className={cx('team-dk-pic-wrap')}>
        <div
          className={cx('team-dk-pic')}
          style={{ backgroundImage: `url(${dkInfo.picture})` }}
        />
      </div>
      <div className={cx('team-dk-info')}>
        {(dkInfo.relationTypeTags || []).indexOf('SPACE_DEPART') > -1 && (
          // <img
          //   className={cx('team-dk-departmentTag')}
          //   src={dkItemDepartmentTagIcon}
          // />
          <span className={cx('department')}>
            {intl.t('部门')}
          </span>
        )}
        <div>
          <div className={cx('team-dk-title')}>{highlight(dkInfo.name)}</div>
          <div className={cx('team-dk-groupTag-wrap')}>
            {(dkInfo.relationTypeTags || []).indexOf('SPACE_COOPER') > -1 && (
              <span className={cx('team-dk-groupTag')}>
                {intl.t('已关联团队空间')}
              </span>
            )}
          </div>
        </div>
        
        <div className={cx('team-dk-desc')}>
          {highlight(dkInfo.desc) || intl.t('暂无描述')}
          {
            dkInfo.originalOwner && <div className={cx('team-dk-desc')}>
            <p>{
              intl.t('原所有者:{slot}', {
                slot: getLocale() === 'zh-CN' ?  dkInfo.originalOwner : dkInfo.originalOwnerEn,
              })
            }</p>
          </div>
          }
        </div>
        
        </div>
    </li>
  );
};

export default DKCard;
