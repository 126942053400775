

// 其他 *****
export { default as AvatarIcon } from './avatar.jpeg';
export const NoSearchResultIcon = 'http://img-ys011.didistatic.com/static/cooper_cn/noSearchResult.png';
export { default as fileChecked } from './file-checked.png';
export { default as CommentFullIcon } from './comment-full.png';

// 创建弹窗
export { default as CreateKnowledgeIcon } from './createKnowledge.png';
export { default as CreateKnowledgeBackIcon } from './createKnowledgeBack.png';
// 首页
export { default as HomeWelcomeIcon } from './homeWelcome.png';
export { default as HomeIcon } from './homeIcon.png';
export { default as ModalCloseIcon } from './modal_close.png';
export { default as NoPermissionIcon } from './noPermission.png';
export { default as NotSupportPreviewIcon } from './notSupportPreview.png';
export { default as GroupIcon } from './groupIcon.png';
export { default as HomeBackgroundIcon } from './homeBackground.png';
export { default as ShareBackgroundIcon } from './shareBackground.png';
export { default as ImportCooper } from './import-cooper.png';
export { default as ImportWord } from './import-word.png';
export { default as ImportWiki } from './import-wiki.png';
export { default as ImportWikiHtml } from './import-wiki-html.png';
export { default as NoMyDkIcon } from './empty7.png';
export { default as NoMyJoinDkIcon } from './empty1.png';
export { default as SpaceInvalid } from './empty14.png';
export { default as SpaceNoPermission } from './empty15.png'
export { default as PhoneNoAccessIcon } from './phoneNoAccess.png';
export { default as TrendEmptyTagIcon } from './trendEmpty.png';
export { default as BetaIcon } from './beta.png';
// 门户
export { default as MenhuIcon } from './menhuIcon.png';
// 版本对比 - 历史版本空页面
export { default as HistoryVersionEmptypage } from './historyVersionEmptypage.png'

// 回收站
export { default as RecoverIcon } from './recover.png';
export { default as RecycleBinIcon } from './recycleBin.png';
export { default as RecycleBinEmptyIcon } from './recycleBinEmpty.png';
export { default as CompleteDeleteIcon } from './completeDelete.png';
export { default as ModelDetel } from './modelDetel.png'
// 设置页
export { default as SetupIcon } from './setup.png';
export { default as powerManagermentSearchIcon } from './powerManagerment-search.png';
export { default as powerManagermentAddMemberIcon } from './powerManagerment-addMember.png';
export { default as powerManagermentCheckIcon } from './powerManagerment-check.png';
export { default as hoverTipIcon } from './hoverTipIcon.png';
export { default as departmentTagIcon } from './departmentTag.png';
export { default as departmentTagIcon2 } from './departmentTag2.png';
export const departmentMemberIcon = 'http://img-ys011.didistatic.com/static/cooper_cn/cooper_icon_organization.png';
export const customMemberIcon = 'https://img-ys011.didistatic.com/static/cooper_cn/cooper_icon_custom_mumber_group.png';

// 头部
export { default as MoreDkIcon } from './moreDk.png';
export { default as SwitchLocaleActiveIcon } from './switchLocaleActive.png';
export { default as SwitchLocaleIcon } from './switchLocale.png';
export { default as CooperLogoIcon } from './cooperLogo.png';
export { default as DCLogoIcon } from './dcLogo.png';
export { default as DKLogoIcon } from './dkLogo.png';
export { default as DCIcon } from './dcIcon.js';
export const DKDefaultIcon = 'http://img-ys011.didistatic.com/static/cooper_yx/do1_jWXD884PuZvJlY6TYLTD';
// 通知
export { default as NotificationSuccessIcon } from './notification_success.png';
export { default as NotificationErrorIcon } from './notification_error.png';
export { default as NotificationWarnIcon } from './notification_warn.png';


// 新人引导
export { default as CreatePageIcon } from './createPage.gif';
export { default as CreateDKIcon } from './createDK.gif';
export { default as TagViewIcon } from './tagView.gif';
export { default as AddTagIcon } from './addTag.gif';
export { default as ImportFileIcon } from './importFile.gif';

// 页面
export { default as PageNotPublishIcon } from './pageNotPublish.png';
export { default as OwnerPageNotPublishIcon } from './ownerPageNotPublish.png';
export { default as gotoDkhomeIcon } from './gotoDkhome.png';

// 最近访问
export { default as RecentDkIcon } from './recentDk.png';
export { default as RecentListDkIcon } from './recentListDk.png';


// 套件入口
export { default as qunliaoIcon } from './qunliao.png';
export { default as quanziIcon } from './quanzi.png';
export { default as cooperEntryIcon } from './cooper-entry.png';
export { default as teamCenterIcon } from './teamcenter.png';
export { default as DiDoc } from './di_doc.png';
export { default as CooperDoc } from './cooper_doc.png';
export { default as Dir } from './dir.svg';
export const suiteArrow = 'http://img-ys011.didistatic.com/static/cooper_cn/do1_1ukHUwyLjnFXXuqRC5dX';

// 下载 word文档 icon
export { default as downLoding } from './downLoding.png';
export { default as downError } from './downError.png';

// 搜索无结果 数据库迁移
export { default as noSearchDataImg } from './noSearchDataImg.png';

// 复选框icon
export { default as checkableEmpty } from './checkable-empty.png';
export { default as checkableChecked } from './checkable-checked.png';

// 空白页面图片
export { default as CooperEmptyImg } from './empty3.png'

// 文件夹icon
export { default as iconShareFolder } from './share_folder.png'

// 个人空间icon
export { default as iconPersonalSpace } from './personal_space.png'

// 团队空间icon
export { default as iconTeamSpace } from './team_space.png'

// loading
export { default as loadingPng } from './loading.png'

// cooper-tip 安全提示
export { default as CooperTipImg } from './cooper-tip.png'

// 新建文件夹
export { default as CreateFolder } from './create-folder.png'

// 新建匿名表格
export { default as CreateCollabNoname } from './collab-noname.png'

// 导入为在线文档
export { default as CreateImportWord } from './import.png'

// 从模版库新建
export { default as CreateTemplate } from './create-template.png'

export { default as iconUploadFolder } from './iconUploadFolder.png'

export { default as iconUploadFile } from './iconUploadFile.png'

export { default as CreateTeamSpace } from './create_team-space.png'
export { default as CreateTeamSpaceEn } from './create_team-space-en.png'


export { default as TeamSpaceInit } from './team_space_init.png'

export { default as TeamSpaceFolder } from './team_space_folder.png'


export const LargeLogo = 'https://img0.didistatic.com/static/cooper_cn/cooper/images/logo_cooper-97326ac6428fcd3fb235680bd6b849f4.svg';

export { default as SmallLogo } from './logo.png'

export { default as RecentSortDkIcon } from './di-sort.png';

export { default as StarTableIcon } from './star_table.png'

export { default as dkItemDepartmentTagIcon } from './dkItem-departmentTag.png';

export { default as addModalHomeEn } from './add-tab-home-en.png';
export { default as addModalKnowledgeEn } from './add-tab-knowledge-en.png';
export { default as addModalHome } from './add-tab-home.png';
export { default as addModalKnowledge } from './add-tab-knowledge.png';

// 预览图片
export { default as previewFileFull } from './full.png';
export { default as previewFileDownload } from './download.png';

export { default as feedbackNormal } from './feedback-normal.svg';

export const CreateCommentIcon = 'https://img-ys011.didistatic.com/static/cooper_cn/do1_iJTt5GBl7HCzlkhyynwu';
export const DotIcon = 'https://img-ys011.didistatic.com/static/cooper_cn/do1_76xHLPwikkPtlp81nvyP';
export const EditIcon = 'https://img-ys011.didistatic.com/static/cooper_cn/do1_U8pgxPHGlDiCCmJgHSTe';
export const ReplyIcon = 'https://img-ys011.didistatic.com/static/cooper_cn/do1_dX4HS5AAiNKjUflVzNvY';
export const DeleteIcon = 'https://img-ys011.didistatic.com/static/cooper_cn/do1_nTYvgYHlETEQKUwPT1Xc';
