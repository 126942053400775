import { PAGE_SIZE } from '@/constants';
import { getShareFromData } from '@/service/cooper/share';

const getDefaultState = () => ({
  shareLists: [],
  hasMore: true,
});

export default {
  name: 'ShareFromMe',
  state: getDefaultState(),
  reducers: {
    updateShareFromeMeList(state, shareLists) {
      return {
        ...state,
        shareLists,
      };
    },
    updateHasMore(state, hasMore) {
      return {
        ...state,
        hasMore,
      };
    },
  },
  effects: {
    async getShareFromMeList(params, state) {
      const { refresh } = params;
      delete params.refresh;
      const data = await getShareFromData({ params });
      const { items } = data;
      this.updateHasMore(items.length === PAGE_SIZE);
      const shareLists = refresh ? items : [...state.ShareFromMe.shareLists, ...items];
      this.updateShareFromeMeList(shareLists);
      return data;
    },
  },
};
