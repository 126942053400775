/**
 * rematch默认使用文件名作为model的name
 */
import { init } from '@rematch/core';
import immerPlugin from '@rematch/immer';
import loadingPlugin from '@rematch/loading'; // 提供异步加载的状态
import selectPlugin from '@rematch/select';
import { createBrowserHistory } from 'history';
import * as models from './models';

export const history = createBrowserHistory({ basename: process.env.NODE_ENV === 'development' ? '/' : '/knowledge' });

const store = init({
  models,
  plugins: [loadingPlugin(), immerPlugin(), selectPlugin()],
});

export const { select } = store;
export default store;
