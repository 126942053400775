/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-06-02 17:07:38
 * @LastEditTime: 2023-11-27 15:00:07
 * @Description: 复制文件
 * @FilePath: /knowledgeforge/src/components/CooperOperation/CopyFile/index.js
 *
 */
import OperateFileModal from '@/components/OperateFileModal';
import { COPY } from '@/constants/cooper';
import mountAnywhere from '@/utils/mountAnywhere';
import store from '@/model';
import { Provider } from 'react-redux';

function copyFile(files, doneCallback, cancelCallback, isFromPerson, isBatch) {
  const Comp = (
    <Provider store={store}>
      <OperateFileModal
        type={COPY}
        files={files}
        doneCallback={doneCallback}
        cancelCallback={cancelCallback}
        onClose={() => close()}
        isFromPerson={isFromPerson}
        isBatch={isBatch}
      />
    </Provider>

  );
  let close = mountAnywhere(Comp);
}

export default copyFile;
