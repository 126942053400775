
import { post, get } from '@/utils/request/cooper';
import qs from 'qs';
import CooperApi from '@/utils/request/api/CooperApi';
import { intl, getLocale } from 'di18n-react';
import { getUserNameFromCookie } from '@/utils';
import { getPermis, formatSize } from '@/utils/cooperutils';
import { Coop, DiDoc } from '@/constants/cooperConstants';
import { formatRecentTime } from '@/utils/index';
import { message } from 'antd'
import { history } from '@/model';

function formatMeta(item, params) {
  let owner = null;
  if (getUserNameFromCookie() === item.create_by_en) {
    owner = intl.t('我');
  } else {
    owner = getLocale() === 'en-US' ? (item.create_by_en) : (item.create_by_cn);
  }

  return ({
    // XXX: 兼容老的操作
    ...item,

    id: item.resource_id ?? item.id,
    name: item.display_name,
    image: item.tiny_image,
    owner: owner || '--',
    updateTime: formatRecentTime(item.modify_on),
    createTime: formatRecentTime(Date.parse(item.create_time)),
    type: item.type,
    mimeType: item.mime_type,
    markedStar: item.marked_star,
    shareId: Number(params.shareId) || item.share_id,

    // 默认文件夹有子节点
    hasChild: item.type === 1,

    // 勾选状态，0-未勾选，1-已勾选，2-半勾选
    checkStatus: 0,

    fileTypeStr: item.resourceType,
    resourceTypeStr: item.resourceType,
    resourceId: item.resource_id,
    resourceName: item.display_name,
    pageId: item.resource_id,
    markedQuickVisit: item.marked_quick_visit,
    quickId: item.quickId, // 现在接口还没有返回
    shareType: (item.type === Coop) || (item.type === DiDoc) ? item.share_type : 'Direct', // 除协作文档外都是定性分享类型，兼容分享的文件夹下的文件没有返回share_type的情况
  });
}

export const getShareToRootFiles = async (params) => {
  let res = await get(CooperApi.SHARES_TO_ME_LIST, { params }, {
    headers: {
      'X-App-Id': 2,
    },
  });

  return {
    data: res?.items.map((item) => formatMeta(item, params)),
    currentPage: res.currentPage,
    pageSize: res.pageSize,
    totalCount: res.totalCount,
  }
}

export const getFilesDetails = async (params) => {
  let res = []
  await get(CooperApi.SHARES_DIRECTORY.replace(':shareId', params.shareId).replace(':resourceId', params.foldId), { params, keepSilent: true }, {
    headers: {
      'X-App-Id': 2,
    },
  }).then((data) => {
    res = data
  }).catch(() => {
    message.error({
      content: <p
        style={{ cursor: 'pointer', display: 'inline-block' }}>
        {intl.t('你没有权限，3s后自动跳转到“分享给我”')}
      </p>,
      duration: 3,
      onClose: () => {
        let { pathname } = history.location
        pathname.includes('/tome/') && history.push('/tome');
        pathname.includes('/fromme/') && history.push('/fromme');
      },
    })
  });
  return {
    data: res.map((item) => formatMeta(item, params)),
  };
}

const formateData = (data) => {
  const sl = [];

  for (const f of data || []) {
    const s = {
      ...f,
      id: f.resource_id,
      image: f.tiny_image,
      name: f.display_name,
      size: formatSize(f.size),
      shareCount: f.share_count,
      downCount: f.downloads,
      createTime: formatRecentTime(Date.parse(f.create_time)),
    };
    sl.push({
      checked: false,
      open: false,
      file: s,
      shares: [],
      type: f.type,
    });
  }
  return sl;
};

export const getShareFromData = async ({ params }) => {
  try {
    const res = await get(`${CooperApi.SHARES_FROM_ME_LIST}?${qs.stringify(params)}`, {
      headers: {
        'X-App-Id': 2,
      },
    });
    return {
      ...res,
      items: formateData(res.items || []),
    };
  } catch (e) {
    console.log(e);
  }
}


export function getDownloadUrl(shareId, ids, region) {
  return post(`${CooperApi.API_DIRECT_DOWNLOAD.replace(':directId', shareId)}?region=${region}`, ids);
}


export function getPermisValue(record, index, scrollTop) {
  let cache = {};
  let permis;
  if (!cache[record.resource_id]) {
    let body = {
      resourceId: record.resource_id,
    };
    if (record.share_id !== 0) {
      body.shareId = record.share_id;
    }
    get(CooperApi.RESOURCES_PERMISSION, body).then((res) => {
      cache[record.resource_id] = getPermis(res.permission).join('/');
      permis(cache);
    });
  }

  return permis;
}
