import { previewFileDownload } from '@/assets/icon/index';
import { doDownload } from '@/components/CooperOperation/DownloadFile';
import { Tooltip } from 'antd';
import { intl } from 'di18n-react';
import { Fragment } from 'react';
import './index.less';


const Footer = (props) => {
  const { file, connectSiteId = 0, parentLocationType, spaceId, teamId, type, hasDownloadIcon } = props
  const goToDownload = () => {
    // eslint-disable-next-line max-len
    doDownload(type, [file.id], file.share_id || file.shareId, file.teamId || spaceId || teamId, connectSiteId, parentLocationType);
    window.__OmegaEvent('ep_filespreview_download_ck');
  }
  return (
    <Fragment>
      {hasDownloadIcon && <div className='footer-content'>
        <Tooltip title={intl.t('下载')}>
          <div
            className='footer-content-item'
            onClick={() => goToDownload()}>
            <img
              src={previewFileDownload}
              alt='下载' />
          </div>
        </Tooltip>
      </div>}
    </Fragment>

  )
}

export default Footer
