import { knowledgeMoveList } from '@/service/knowledge/transfer';
import OutlinedIcon from '@/components/serviceComponents/CatalogTree/OutlinedIcon.js'
import PageTreeMulti from './PageTreeMulti';
import PageTreeSingle from './PageTreeSingle';
import classBind from 'classnames/bind';
import styles from './style.module.less';

const cx = classBind.bind(styles);

const formatNode = ({ pageId, pageName, hasChild, parentId, size = 0, isPerm = true, fullPath }) => {
  return {
    key: pageId,
    isLeaf: !hasChild,
    title: pageName,
    parentId,
    pageId,
    isPerm,
    pageName,
    size: size + 1,
    fullPath,
  };
};

const getData = ({ key, type, knowledgeId, currentKnowledgeId, checkable, currentPageId }) => {
  return knowledgeMoveList({
    pageId: key,
    type,
    knowledgeId,
    currentKnowledgeId,
    currentPageId,
    operation: +(checkable),
  })
};

const PageTree = (props) => {
  const { currentPage = {}, currentPageId, checkable, currentKnowledgeId } = props
  const PageTreeComponent = checkable ? PageTreeMulti : PageTreeSingle

  if (!currentPage || !currentPage.knowledgeId) {
    return <></>
  }
  return <div className={cx('paget-tree-wrap')}><PageTreeComponent
    formatNode={formatNode}
    switcherIcon={<OutlinedIcon size={16}/>}
    getData={(key, type = 1) => {
      return getData({ key, type, knowledgeId: currentPage.knowledgeId, currentKnowledgeId, checkable, currentPageId })
    }}
    {...props}
    currentPage={currentPage}
  /></div>
}

export default PageTree;
