import { Modal, Divider } from 'antd';
import styles from './style.module.less';
import { intl, getLocale } from 'di18n-react';
import classBind from 'classnames/bind';

const cx = classBind.bind(styles);

const contentCof = {
  download: {
    title: '存在文件你无权限下载',
    text: '批量操作时，每个文件都需具备下载权限，请逐个检查是否都有下载权限，若无可咨询管理员授权',
    linkConf: [
      {
        text: '如何找管理员？',
        linkZh: 'https://cooper.didichuxing.com/knowledge/share/book/V2fuj0ncesoZ/2202804682367',
        linkEn: 'https://cooper.didichuxing.com/knowledge/share/book/V2fuj0ncesoZ/2202804682367',
      },
      {
        text: '管理员不会授予权限怎么办？',
        linkZh: 'https://cooper.didichuxing.com/knowledge/share/book/V2fuj0ncesoZ/2203083987400?anchor=54c114cb-49fd-4890-8e1f-fd36ff317f9e',
        linkEn: 'https://cooper.didichuxing.com/knowledge/share/book/V2fuj0ncesoZ/2203083987400?anchor=54c114cb-49fd-4890-8e1f-fd36ff317f9e',
      },
    ],
  },
  delete: {
    title: '存在文件你无权限删除',
    text: '仅文件所有者/管理员可以删除文件，请逐个检查是否都为文档管理员，若无可咨询空间所有者授予管理员权限，或联系管理员删除',
    linkConf: [
      {
        text: '如何找管理员？',
        linkZh: 'https://cooper.didichuxing.com/knowledge/share/book/V2fuj0ncesoZ/2202804682367',
        linkEn: 'https://cooper.didichuxing.com/knowledge/share/book/V2fuj0ncesoZ/2202804682367',
      },
      {
        text: '管理员不会授予权限怎么办？',
        linkZh: 'https://cooper.didichuxing.com/knowledge/share/book/V2fuj0ncesoZ/2203083987400?anchor=54c114cb-49fd-4890-8e1f-fd36ff317f9e',
        linkEn: 'https://cooper.didichuxing.com/knowledge/share/book/V2fuj0ncesoZ/2203083987400?anchor=54c114cb-49fd-4890-8e1f-fd36ff317f9e',
      },
    ],
  },
  hasAllDocs: {
    title: '文档暂不支持批量下载',
    text: '协作文档/表格/幻灯片/流程图/脑图暂不支持批量下载，请在空间列表更多操作处进行逐个下载',
    imgUrlZh: require('./hasAllDocZh.png'),
    imgUrlEn: require('./hasAllDocEn.png'),
  },
  hasSomeDocs: {
    title: '存在文档不支持批量下载',
    text: '协作文档/表格/幻灯片/流程图/脑图暂不支持批量下载，下载过程会自动过滤',
  },
  uploadLimitPerson: {
    title: '上传失败',
    text: '已超过最大容量，请在个人空间申请扩容后重试，若无申请入口可通过BPM选择“个人空间扩容”',
    imgUrlZh: require('./gerenkongjianZh.png'),
    imgUrlEn: require('./gerenkongjianEn.png'),
  },
  uploadLimitTeam: {
    title: '上传失败',
    text: '已超过最大容量，请联系空间所有者申请扩容后重试，若空间所有者无申请入口可通过BPM选择“团队空间扩容”',
    imgUrlZh: require('./tuanduikongjianZh.png'),
    imgUrlEn: require('./tuanduikongjianEn.png'),
  },
}

const getModalContent = (data) => {
  return <div className={cx('no-perm-modal-content')}>
    <div className={cx('no-perm-modal-text')}>{ intl.t(data.text)}</div>
    {/* {
      data.linkConf && <Divider style={{ marginTop: '12px', marginBottom: '12px' }}/>
    } */}
    {
      data.linkConf && data.linkConf.map((item, index) => (
        <div
          key={index}
          className={cx('no-perm-modal-link')}>
          <a
            href={ getLocale() === 'zh-CN' ? item.linkZh : item.linkEn }
            target='_blank'
            rel='noopener noreferrer'>{intl.t(item.text)}</a>
        </div>
      ))
    }
    {
      (data.imgUrlZh || data.imgUrlEn) && <img
        className={cx('no-perm-modal-img')}
        src={getLocale() === 'zh-CN' ? data.imgUrlZh : data.imgUrlEn } />
    }
  </div>
}
const NoPermModal = (type, callBack) => (
  Modal.warning({
    width: 460,
    title: () => { intl.t(contentCof[type].title) },
    content: getModalContent(contentCof[type]),
    okText: intl.t('我知道了'),
    className: cx('no-perm-modal'),
    onOk() {
      callBack && callBack()
    },
  })
);

// NoPermModal('uploadLimitPerson')

export default NoPermModal;
