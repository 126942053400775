import { getLocale, intl } from 'di18n-react';
import { useState, useRef, useEffect, useMemo } from 'react';
import { debounce } from 'lodash-es';
import ReactDOM from 'react-dom';
import classBind from 'classnames/bind';
import { Modal, Input } from 'antd';
import { singleSpaReact } from '@didi/mf-tenon';
import Result from './Result';
import Filter from './Filter';
import styles from './style.module.less';

const cx = classBind.bind(styles);

/*
微前端引用参数配置
api参数：
search： 搜索接口
recent： 最近访问
dkList： 知识库list
userList： 搜索用户接口
tagList： 搜索tag接口
mode: 环境 |prod|test|development
*/
const Search = (wrapProps) => {
  const props = wrapProps.appProps ?? wrapProps;
  const { visible, closeSearchModal, search, clear, api = {}} = props;
  const [filter, setFilter] = useState({});
  const currentFilter = useRef(filter);
  const loadOptions = (key) => {
    setFilter({ ...currentFilter.current, key });
  };
  const onInputChange = useMemo(() => {
    return debounce(loadOptions, 800);
  }, []);

  useEffect(() => {
    currentFilter.current = filter;
  }, [filter]);

  useEffect(() => {
    let scope = clear ? {} : {
      scope: search.id ?? undefined,
      name: search.name,
    };
    setFilter({ key: search.key, ...scope });
  }, [search]);

  const headerRender = (<div className={cx('search-modal-header')}>
    <div
      className={cx({
        'search-header-left': true,
        'search-header-left-en': getLocale() === 'en-US',
      })}>
      <i
        className={`${cx('dk-icon-sousuo', 'dk-iconfont')}`}
        style={{ color: '#bbb', fontSize: 20 }}
      />
      <Input
        placeholder={filter.scope ? intl.t('搜索“{slot0}”页面', { slot0: filter.name }) : intl.t('header-高级搜索-搜索知识库和页面') }
        bordered={false}
        defaultValue={filter.key}
        onPressEnter={() => loadOptions(filter.key) }
        onChange={(v) => onInputChange(v.target.value)}
        allowClear
        size="large"
      />
    </div>
    <div
      className={cx('search-header-right')}
      onClick={() => closeSearchModal()}><i className={`${cx('ant-input-clear-icon', 'dk-iconfont')}`} /></div>
  </div>);

  return (
    <Modal
      visible={visible}
      wrapClassName={`${cx('search-modal-wrap')} search-modal-wrap`}
      className={cx('search-page-wrap')}
      title={headerRender}
      width='90%'
      closeIcon={<></>}
      footer={null}
      destroyOnClose={true}
      onCancel={() => closeSearchModal()}
    >
      <Result
        value={filter}
        close={() => closeSearchModal()}
        pathId={props.pathId}
        api={api} />
      <Filter
        value={filter}
        onChange={setFilter}
        api={api} />
    </Modal>
  );
};

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Search,
});

export const { bootstrap } = reactLifecycles;
export const { mount } = reactLifecycles;
export const { unmount } = reactLifecycles;

export default Search;
