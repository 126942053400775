import { intl } from 'di18n-react';

/* 定义常量 */
// 查看
const ReadPerm = 1;
// 编辑
const EditPerm = 2;
// 上传
const UploadPerm = 8;
// 分享
const SharePerm = 16;
// 下载
const DownloadPerm = 32;


const permissionOptions = function () {
  return [
    {
      label: intl.t('查看'),
      id: 1,
      value: '1',
      perm: 1,
      desc: intl.t('用户可以查看在线协作文档'),
      disabled: true,
    },

    {
      label: intl.t('编辑'),
      id: 2,
      value: '2',
      perm: 2,
      desc: intl.t('用户可以对协作文档进行修改和评论'),
    },
    // { label: formatMessage({id: 'common.canUpload'}), value: '8', perm: 8, desc: '用户可以邀请协作者一起协作' },
    // { label: formatMessage({id: 'common.canShare'}), value: '16', perm: 16, desc: '拥有此权限的用户允许分享文件' },
    {
      label: intl.t('下载'),
      id: 32,
      value: '32',
      perm: 32,
      desc: intl.t('用户可以将协作文档导出'),
    },
  ];
};

const permissionFlow = function () {
  return [
    {
      label: intl.t('查看'),
      id: 1,
      value: '1',
      perm: 1,
      desc: intl.t('用户可以查看在线协作文档'),
      disabled: true,
    },
  ];
};

const utils = {
  parsePerm(perm) {
    const perms = [];
    permissionOptions.call(this).forEach((item) => {
      if ((item.perm & perm) === item.perm) { // eslint-disable-line no-bitwise
        perms.push(item);
      }
    });
    return perms;
  },

  /* eslint-disable no-bitwise */
  canRead: (perm) => {
    return (perm & 1) === 1;
  },
  canWrite: (perm) => {
    return (perm & 2) === 2;
  },
  canUpload: (perm) => {
    return (perm & 8) === 8;
  },
  canShare: (perm) => {
    return (perm & 16) === 16;
  },
  canDownload: (perm) => {
    return (perm & 32) === 32;
  },
  getPerms(isFlow) {
    return isFlow ? permissionFlow.call(this).slice() : permissionOptions.call(this).slice();
  },
  /* eslint-enable no-bitwise */
};

export default utils;

export {
  ReadPerm,
  EditPerm,
  UploadPerm,
  SharePerm,
  DownloadPerm,
};
