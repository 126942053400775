import { get } from '@/utils/request';
import api from '@/utils/request/api/DkApi';

export default function logout() {
  const url = api.LOGOUT;
  // 登出退出cookie
  window.localStorage.setItem('x-tenant-id', null);
  window.location.href = `${url}?jumpto=${window.location.href}`;
}

export async function login() {
  const res = await get(api.LOGIN);
  return res;
}
