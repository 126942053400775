import { intl } from 'di18n-react';
import { Modal, message } from 'antd';
import { initIntranetDetector } from './ws.check';
import { apolloSwitch, cooperIntranetDetector } from '@/utils/ab';
import { goModalLogin } from '@/utils/request/index';

window.__request_block = false;

function createAjax() {
  let request = null;

  // window对象中有XMLHttpRequest存在就是非IE，有可能是（IE7，IE8）
  if (window.XMLHttpRequest) {
    request = new XMLHttpRequest();
    if (request.overrideMimeType) {
      request.overrideMimeType('text/xml');
    }
    // window对象中有ActiveXObject属性存在就是IE中的Microsoft.XMLHTTP代表不同IE版本的参数
  } else if (window.ActiveXObject) {
    // ActiveXOject('Microsoft.XMLHTTP')
    let versions = [
      'Microsoft.XMLHTTP',
      'MSXML.XMLHTTP',
      'Msxml2.XMLHTTP.7.0',
      'Msxml2.XMLHTTP.6.0',
      'Msxml2.XMLHTTP.5.0',
      'Msxml2.XMLHTTP.4.0',
      'MSXML2.XMLHTTP.3.0',
      'MSXML2.XMLHTTP',
    ];
    for (let i = 0; i < versions.length; i++) {
      try {
        request = new ActiveXObject(versions[i]);

        if (request) {
          return request;
        }
      } catch (e) {
        request = null;
      }
    }
  }
  return request;
}

function onError(callback) {
  return (response) => {
    let data = '';
    try {
      data = JSON.parse(response.response);
    } catch (e) {
      data = '';
    }
    callback({
      data,
      status: response.status,
      statusText: response.statusText,
    });
  };
}

function cusError(callback) {
  return (response) => {
    let data = '';
    try {
      data = JSON.parse(response.response);
    } catch (e) {
      data = '';
    }
    callback({
      data,
      status: response.status,
      statusText: response.statusText,
    });
  };
}

function ajax({ url, type, data, headers, success, error }) {
  let xhr = createAjax();
  let aborted = false;
  let ajx = () => {
    xhr.open(type, url, true);
    // 请求成功回调函数
    xhr.onload = () => {
      if (aborted) return;
      if ((xhr.status >= 200 && xhr.status < 300) || xhr.status === 304) {
        if (xhr.response) {
          // 注意:状态码200表示请求发送/接受成功,不表示业务处理成功
          success({
            data: JSON.parse(xhr.response),
            status: xhr.status,
          });
        }
      } else {
        cusError(error)(xhr);
      }
    };
    // 请求出错
    xhr.onerror = () => onError(error)(xhr);
    // 设置超时时间,0表示永不超时
    xhr.timeout = 0;
    // 设置请求头
    if (headers) for (let header in headers) xhr.setRequestHeader(header, headers[header]);
    xhr.send(data || null);
  };
  ajx();
  ajx.abort = () => {
    if (!aborted) {
      xhr.abort();
      aborted = true;
    }

    // trigger error callback to let callee known
    error('AbortError');
  };
  return ajx;
}


const checkStatus = (response) => {
  if (response.status === 204 || response.status === 304) {
    return null;
  }

  if (
    (response.status >= 200 && response.status < 300)
    || response.status === 400
  ) {
    return response.json();
  }

  if (response.status === 401) {
    if (!window.__request_block) {
      window.__request_block = true;
      response.json().then((res) => {
        const { pathname, search, hash } = window.location;
        if (res.data.redirect) {
          const jumpto = encodeURIComponent(pathname + search + hash);
          window.location = `${decodeURI(res.data.redirect)}&jumpto=${jumpto}`;
          // goModalLogin(res.data.redirect)
        }
        message.error(intl.t('登录信息失效，请重新登录'));
      });
    }

    return null;
  }

  // 用户属于多个租户，并且不属于滴滴租户：需要用户选择，返回租户列表
  if (response.status === 403) {
    if (window.location.href.indexOf('tenant') === -1) {
      window.location.href = '/tenant'
    }
  } else {
    message.error(response.statusText, 2);
    throw new Error(response.statusText);
  }
};

const errCodeFilter = (response, keepSilent) => (request) => {
  if (typeof response.success === 'boolean' && !response.success) {
    if (response.errorCode === 129999) {
      if (window.__IntranetDetector == null) {
        return new Promise((resolve, reject) => {
          apolloSwitch(cooperIntranetDetector, () => {
            window.__IntranetDetector = true;
            initIntranetDetector(request.options.headers['X-Net-Token']).then((token) => {
              if (token) {
                request.options.headers['X-Net-Token'] = token;
                request.f(request.url, request.options, ...request.params).then((res) => {
                  resolve(res);
                });
              } else {
                reject(response.errorMessage);
              }
            });
          }, () => {
            window.__IntranetDetector = false;
            throw new Error(response.errorMessage);
          });
        });
      } if (window.__IntranetDetector) {
        // 灰度结束后，仅保留此段逻辑即可
        return initIntranetDetector(request.options.headers['X-Net-Token']).then((token) => {
          if (token) {
            request.options.headers['X-Net-Token'] = token;
            return request.f(request.url, request.options, ...request.params);
          }
          throw new Error(response.errorMessage);
        });
      }
      throw new Error(response.errorMessage);
    } else if (response.errorCode === 130000) {
      // 后端调用安全部接口识别可信设备（携带当前页面url，作为rederictUrl，和failUrl）
      // 当发现认证失败 返回130000状态码，同时返回安全认证页面url
      // 前端识别状态码&跳转安全认证页面url （用户输入D盾）
      // 认证成功页面重定向到rederictUrl（认证失败则代开failurl）
      window.location.href = response.data.safeCheckUrl;
      throw response;
    } else if (response.errorCode === 20030) {
      // 敏感操作设备认证
      // 当触发敏感操作（下载、导出）后端调用安全部接口认证设备
      // 当发现认证失败 返回20030状态码
      // 前端弹框提示
      Modal.warning({
        className: 'all-warn-tip',
        title: intl.t('提示'),
        content: response.errorMessage,
        okText: intl.t(' 好的 '),
      });
      throw response;
    } else {
      if (!keepSilent) message.error(response.errorMessage, 2);

      // XXX: 其他调用可能受影响，待整体排查
      throw response;
    }
  } else {
    return response.data;
  }
};

const checkAjaxStatus = (response, keepSilent) => (resolve, reject) => (request) => {
  if (response.status === 204 || response.status === 304) {
    resolve(null);
  } else if (response.status >= 200 && response.status < 300) {
    resolve(response.data.data || null);
  } else if (response.status === 400) {
    reject(errCodeFilter(response, keepSilent)(request));
  } else if (response.status === 401) {
    if (!window.__request_block) {
      window.__request_block = true;
      const { pathname, search, hash } = window.location;
      if (response.data.redirect) {
        const jumpto = encodeURIComponent(pathname + search + hash);
        window.location = `${decodeURI(response.data.redirect)}&jumpto=${jumpto}`;
        // goModalLogin(response.data.redirect)
      }
      message.error(intl.t('登录信息失效，请重新登录'));
    }

    resolve(null);
  } // 用户属于多个租户，并且不属于滴滴租户：需要用户选择，返回租户列表
  else if (response.status === 403) {
    if (window.location.href.indexOf('tenant') === -1) {
      window.location.href = '/tenant'
    }
  } else if (response === 'AbortError') {
    reject({
      name: 'AbortError',
    });
  } else {
    message.error(response.statusText, 2);
    reject(response.statusText);
  }
};
/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */

const _fetch = (url, options) => {
  if (window.__request_block) {
    return new Promise((resolve) => {
      resolve(null);
    });
  }
  return window
    .fetch(url, options)
    .then(checkStatus)
    .then((response) => response || {})
    .then((response) => errCodeFilter(response, options.keepSilent)({ f: _fetch, options, url, params: [] }));
};

const _ajax = (url, options, type) => {
  if (window.__request_block) {
    return new Promise((resolve) => {
      resolve(null);
    });
  }

  let ajx = {
    abort: () => {},
  };
  let promise = new Promise((resolve, reject) => {
    const _ajx = ajax({
      url,
      type,
      headers: options.headers,
      data: options.body,
      success: (data) => {
        checkAjaxStatus(data, options.keepSilent)(resolve, reject)({ f: _ajax, options, url, params: [type] });
      },
      error: (err) => {
        checkAjaxStatus(err, options.keepSilent)(resolve, reject)({ f: _ajax, options, url, params: [type] });
      },
    });

    ajx.abort = _ajx.abort;
  });
  promise.abort = ajx.abort;
  return promise;
};

const postAjaxAbort = (url, options = {}) => {
  options.headers = {
    'X-App-Id': 2,
    ...options.headers,
  };
  return _ajax(url, options, 'POST');
};

const getAjaxAbort = (url, options = {}) => {
  options.headers = {
    'X-App-Id': 2,
    ...options.headers,
  };
  return _ajax(url, options, 'GET');
};


const postAbort = (url, body = {}, opt = {}) => {
  let controller = new AbortController();
  const options = {
    credentials: 'include',
    method: 'POST',
    signal: controller.signal,
    headers: {
      'Content-Type': 'application/json',
      'X-App-Id': 2,
    },
    body: JSON.stringify(body),
    ...opt,
  };

  let promise = _fetch(url, options);

  promise.abort = () => {
    controller.abort();
  };

  return promise;
};

const d = (url, body = {}, opt = {}) => {
  const options = {
    credentials: 'include',
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-App-Id': 2,
    },
    body: JSON.stringify(body),
    ...opt,
  };
  return _fetch(url, options);
};

export {
  postAbort,
  postAjaxAbort,
  getAjaxAbort,
  d,
};
