/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-06-15 14:42:19
 * @LastEditTime: 2023-06-15 14:45:54
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/CooperOperation/CreateCollab/showImportTip.js
 *
 */
import React from 'react';
import ImportTip from '@/components/common/ImportTip';
import mountAnywhere from '@/utils/mountAnywhere';

function showImportTip(text = '') {
  const Comp = <ImportTip text={text}/>;
  return mountAnywhere(Comp);
}

export default showImportTip;
