import cls from 'classnames';
import './style.less';


const ErrorTips = ({ title, desc, overlayClassName = '', imgClassName = '', img, imagestyle = {}, children }) => {
  return (
    <div className={cls('error-tips-wrap', overlayClassName)}>
      {
        img ? <img
          style={imagestyle}
          className={cls('icon', imgClassName)}
          src={img} /> : <div className='sub-div' />
      }
      {
        title && <div className={cls('title')}>{title}</div>
      }
      {
        desc && <div className={cls('desc')}>{desc}</div>
      }
      {
        children
      }
    </div>


  );
};

export default ErrorTips;
