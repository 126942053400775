import { connect } from 'shimo-js-sdk';
import { get } from '@/utils/request/cooper';
import { getLocale } from 'di18n-react';

// GET 获取协作文档摘要，即打开协作文档
const RESOURCE_DOC = '/cooper_gateway/adapter/v1/resource/:id/doc';

const fetchExcelInfo = (id) => {
  return get(RESOURCE_DOC.replace(':id', id))
};

// GET 获取signature和token
const GET_SIGNATURE_TOKE = '/cooper_gateway/cooperation/v1/resource/token';

const fetchSignatureAndToken = () => {
  return get(GET_SIGNATURE_TOKE);
}

const initShimoExcel = ({ fileId, signature, token, id, container }) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const smParam = urlSearchParams.get('smParams');
  const sheetId = urlSearchParams.get('sheetId');
  const paramsList = [];

  smParam && paramsList.push(smParam);
  sheetId && paramsList.push({ sheetId });

  return connect({
    appId: 'e068a071e473421296a8d4f8161f8259',
    container, // iframe 挂载的目标容器元素
    fileId,
    signature,
    token,
    smParams: paramsList,
    endpoint: process.env.APP_ENV === 'prod' ? `${window.location.origin}/sdk/v2/` : 'https://sdk312.intra.xiaojukeji.com/sdk/v2/',
    plugins: { Lock: false },
    generateUrl() {
      return window.location.origin + window.location.pathname;
    },
    lang: getLocale() === 'zh-CN' ? 'zh-CN' : 'en',
  });
}

const getSheetVersion = (guid) => {
  return new Promise((resolve, reject) => {
    const iframe = document.querySelector('#shimo-excel-container iframe');
    if (!iframe) return reject('no iframe');
    setTimeout(() => {
      iframe.contentWindow.postMessage({
        type: 'getVersion',
        guid,
      }, '*');
    }, 500)
    const onMessage = (e) => {
      const { data } = e;
      if (data.type === 'getVersion') {
        window.removeEventListener('message', onMessage);
        resolve(data.version);
      }
    }
    window.addEventListener('message', onMessage)
  })
}

export {
  fetchExcelInfo,
  fetchSignatureAndToken,
  initShimoExcel,
  getSheetVersion,
};
