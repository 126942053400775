export const formatTree = (node, isSubPageShare) => {
  return node.map((v, i) => {
    return {
      ...v,
      key: isSubPageShare ? v.shareId : v.pageId,
      isLeaf: !v.hasChild,
      title: v.pageName,
      index: i,
      children: v.children?.length ? formatTree(v.children, isSubPageShare) : [],
    };
  });
};

export const formatNode = (v, isSubPageShare) => {
  return {
    ...v,
    key: isSubPageShare ? v.shareId : v.pageId,
    isLeaf: !v.hasChild,
    title: v.pageName,
    children: v.children?.length ? formatTree(v.children) : [],
  };
};

export const updateTreeData = (list, key, children) => {
  return list.map((node) => {
    if (node.key === key) {
      return {
        ...node,
        children,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      };
    }
    return node;
  });
};

export const updateNode = (list, key, newState) => {
  return list.map((node) => {
    if (node.key === key) {
      return {
        ...node,
        ...newState,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateNode(node.children, key, newState),
      };
    }
    return node;
  });
};

export const getNodeById = (id, root) => {
  if (Array.isArray(root)) {
    for (let i = 0; i < root.length; i++) {
      let res = getNodeById(id, root[i]);
      if (res) {
        return res;
      }
    }
  } else {
    if (root.key === id) {
      return root;
    } if (root.children.length) {
      for (let i = 0; i < root.children.length; i++) {
        let res = getNodeById(id, root.children[i]);
        if (res) {
          return res;
        }
      }
    }
  }

  return null;
};

export const addNode = (list, parentKey, node, position) => {
  node.parentId = parentKey;
  if (parentKey === '0') {
    if (position !== undefined) {
      list.splice(position, 0, node);
    } else {
      list.push(node);
    }
    list = [...new Set(list)];
  } else {
    let parent = getNodeById(parentKey, list);

    if (position !== undefined) {
      parent.children.splice(position, 0, node);
    } else {
      parent.children.push(node);
    }
    parent.children = [...new Set(parent.children)];
    parent.hasChild = true;
    parent.isLeaf = false;
  }

  return list;
};

export const deleteNode = (list, key) => {
  let current = getNodeById(key, list);
  if (current.parentId === '0') {
    list = list.filter((v) => v.key !== key);
  } else {
    let parent = getNodeById(current.parentId, list);
    parent.children = parent.children.filter((v) => v.key !== key);
    if (parent.children.length < 1) {
      parent.hasChild = false;
      parent.isLeaf = true;
    }
  }
  return list;
};
