import Dompurify from 'dompurify';

const config = {
  SAFE_FOR_TEMPLATES: false,
  ALLOWED_TAGS: [
    'div', 'span', 'a', 'p', 'b', 'i', 'u', 'svg', 'path', 'img', 'br', 'strong',
    'em', 'ul', 'ol', 'li', 'table', 'tr', 'td', 'th', 'tbody', 'thead', 'tfoot',
    'colgroup', 'col', 'caption', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'pre',
    'code', 'blockquote', 'style',
  ],
  ALLOWED_ATTR: [
    'class',
  ],
}

const GetHtml = (html) => {
  // eslint-disable-next-line no-unused-vars
  const clean = Dompurify.sanitize(html, config);
  // console.log(clean);
  return html
};

export default GetHtml;
