import { intl } from 'di18n-react';
import classBind from 'classnames/bind';
import styles from './style.module.less';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { highlight } from '@/utils';
import { noSearchDataImg } from '@/assets/icon';

const cx = classBind.bind(styles);
const SearchPageList = ({ searchPageList, onClickSearchPage, loading }) => {
  const handleSerachList = () => {
    if (!searchPageList?.items?.length) {
      return (
        <div className={`${cx('search-loading')}`}>
          <div className={`${cx('search-list-noData')}`}>
            <img
              src={noSearchDataImg}
              className={`${cx('img')}`} />
            <span className={`${cx('text')}`}>{intl.t('搜索无结果')}</span>
          </div>
        </div>
      );
    }
    return (
      <div style={{ overflow: 'auto', height: '100%' }}>
        {searchPageList?.items.map((item, index) => {
          let fullPathLength = item.fullPath?.length || 0;
          return (
            <li
              className={`${cx('search-item')}`}
              key={index}
              onClick={() => onClickSearchPage(item)}
            >
              <img src={item.iconImage} />
              <div className={cx('right')}>
                <p className={cx('title')}>
                  {highlight(item.resourceName || '')}
                </p>
                <p className={cx('desc')}>
                  {highlight(item.description || '')}
                </p>
                <p className={cx('other')}>
                  {/* 分享场景需要所有者等字段 */}
                  <i
                    className={cx('dk-iconfont', 'dk-icon-zhishiku_liebiao2')}
                  />
                  &nbsp;
                  <span className={cx('knowledge-name')}>
                    {' '}
                    {item.metisName}
                  </span>
                  {fullPathLength > 2
                    ? '/.../'
                    : fullPathLength > 1
                      ? ' / '
                      : ''}
                  <span className={cx('knowledge-name')}>
                    {item.fullPath[fullPathLength - 2] || ''}
                  </span>
                </p>
              </div>
            </li>
          );
        })}

        <span className={cx('show-allData')}>{intl.t('已显示所有结果')}</span>
      </div>
    );
  };

  return (
    <div className={cx('search-page-list')}>
      {loading ? (
        <div className={`${cx('search-loading')}`}>
          <Spin />
        </div>
      ) : (
        handleSerachList()
      )}
    </div>
  );
};

export default SearchPageList;
