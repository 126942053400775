import { getUserNameFromCookie, getLcaAgentUuidFromCookie } from '@/utils/cooperutils';

export const getWsConnect = (url) => {
  return (onmessage = () => {}, onclose = () => {}, onerror = () => {}) => new Promise((resolve) => {
    const ws = new WebSocket(url);
    ws.onopen = () => {
      resolve(ws);
    };
    ws.onmessage = (event) => onmessage(event.data);
    ws.onerror = onerror;
    ws.onclose = onclose;
  });
};

const getHost = () => {
  let host = process.env.APP_ENV === 'production' ? 'wss://cooper-vpn.didiglobal.com' : 'wss://cooper-qa.didichuxing.com';
  window.location.search.substring(1).split('&').map((v) => {
    if (v.split('=')[0].toLowerCase() === 'ip') {
      host = v.split('=')[1];
    }
  });
  return host;
};

export const initIntranetDetector = (xNetToken) => {
  return new Promise((resolve) => {
    // eslint-disable-next-line vars-on-top
    let hiddenProperty = 'hidden' in document ? 'hidden'
      : 'webkitHidden' in document ? 'webkitHidden'
        : 'mozHidden' in document ? 'mozHidden'
          : null;
    if (!(window.WebSocket && window.WebSocket.prototype.send) || !hiddenProperty) {
      resolve(false);
      return;
    }
    if (!xNetToken && window.__IntranetDetectorToken) {
      resolve(window.__IntranetDetectorToken);
      return;
    }
    const url = `${getHost()}/beat?ldap=${getUserNameFromCookie()}&clientId=${getLcaAgentUuidFromCookie()}`;
    const timer = 5000;
    const timeOut = 11000;
    const wsInstance = IntranetDetector.getInstance({ url, timer, timeOut });
    wsInstance.init((data) => {
      window.__IntranetDetectorToken = data;
      resolve(data);
    });
  });
};

const TimeOutTimer = (timeOut) => {
  let timeOutTimer = 0;
  return {
    init: () => { timeOutTimer = +new Date(); return timeOutTimer; },
    getTimer: () => timeOutTimer,
    setTimer: (timer) => { timeOutTimer = timer; },
    isTimeOut: () => (+new Date() - timeOutTimer > timeOut),
  };
};

export class IntranetDetector {
  constructor(props) {
    this.pending = false;
    this.timeOutTimer = new TimeOutTimer(props.config.timeOut);
    this.config = props.config;
    this.token = '';
    this.instance = null;
    this.ws = null;
    this.execute = this.execute.bind(this);
    this.awaitPending = this.awaitPending.bind(this);
  }

  static getInstance(config) {
    if (!this.instance) {
      this.instance = new IntranetDetector({ config });
      // eslint-disable-next-line vars-on-top
      let hiddenProperty = 'hidden' in document ? 'hidden'
        : 'webkitHidden' in document ? 'webkitHidden'
          : 'mozHidden' in document ? 'mozHidden'
            : null;
      let visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange');
      document.addEventListener(visibilityChangeEvent, () => {
        if (!document[hiddenProperty]) {
          this.instance.refresh();
        } else {
          this.instance.close();
        }
      });
    }
    return this.instance;
  }

  awaitPending() {
    return new Promise((resolve) => {
      let timer = 0;
      const stv = setInterval(() => {
        timer += 5;
        if (!this.pending || timer > 5000) {
          resolve(this.pending || false);
          clearInterval(stv);
        }
      }, 5);
    });
  }

  async init(onmessage) {
    if (this.pending) {
      let success = await this.awaitPending();
      if (!success) {
        onmessage(false);
        return;
      }
    }
    if (!this.ws || this.ws.readyState > 1) {
      this.pending = true;
      this.timeOutTimer.init();
      this.ws = await getWsConnect(this.config.url)(
        (data) => {
          this.timeOutTimer.init();
          this.token = data;
          onmessage(data);
        },
        () => { this.ws = null; },
        () => { onmessage(false); },
      );
      this.pending = false;
      this.execute();
    } else if (this.token) {
      onmessage(this.token);
    } else {
      let timer = 0;
      const stv = setInterval(() => {
        timer += 5;
        if (this.token || timer > 5000) {
          onmessage(this.token || false);
          clearInterval(stv);
        }
      }, 5);
    }
  }

  async refresh() {
    if (this.pending) {
      await this.awaitPending();
    }
    if (!this.ws || this.ws.readyState > 1) {
      this.pending = true;
      this.timeOutTimer.init();
      this.ws = await getWsConnect(this.config.url)(
        (data) => {
          this.timeOutTimer.init();
          this.token = data;
        },
        () => { this.ws = null; },
        () => { this.refresh(); },
      );
      this.pending = false;
      this.execute();
    }
  }

  execute() {
    if (this.ws && this.ws.readyState === 1) {
      if (this.timeOutTimer.isTimeOut()) {
        this.close();
        return;
      }
      this.ws.send('beat');
      setTimeout(() => {
        this.execute();
      }, this.config.timer);
    }
  }

  close() {
    if (this.ws && this.ws.readyState < 2) {
      this.ws.close();
    }
  }
}
