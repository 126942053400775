import { get, post } from '@/utils/request';
import api from '@/utils/request/api/DkApi';


export async function getRecentVisitList(params) {
  const res = await get(api.RECENT_VISIT, { params, needDD05: true });
  return res;
}


export async function getRecentEditList(params) {
  const res = await get(api.RECENT_EDIT, { params });
  return res;
}

export async function addRecentVisit(params) {
  const res = await post(api.ADD_RECENT_VISIT, params, {
    needDD05: true,
  });
  return res;
}


export async function addRecentEdit(params) {
  const res = await post(api.ADD_RECENT_EDIT, params);
  return res;
}
