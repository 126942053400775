export const DocType = {
  DK_FILE: 'DK_FILE',
  DK_PAGE: 'DK_PAGE',
  DK_SHEET: 'DK_SHEET',
};

export const Share_Type = {
  Dk_Share: 0,
  Page_Share: 1,
}

