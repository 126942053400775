/**
 * 客服服务组件，包含帮助、反馈、新功能弹窗
 * @author dumingtang@didiglobal.com
 */
import classBind from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import styles from './style.module.less';
import FeedbackWithPopup from './FeedbackWithPopup';
import NewFeaturePopup from './NewFeaturePopup';
import PageActivePollChecker from '@/utils/checkPageActive';
import { getApolloConfig } from '@/utils/ab';
import { getUserView, setUserView } from '@/service/cooper/userView';
import { openNewWindow } from '@/utils';
// import { useLocation } from 'react-router-dom';
import HelpCenter from './HelpCenter';
import { isDiDiTenant } from '@/utils/entryEnhance';
import { getLocale } from 'di18n-react';

const cx = classBind.bind(styles);

function CustomerService(props) {
  // hideSpliteLine: 隐藏作左侧的分割线，默认不隐藏
  const { hideSpliteLine = false, hideShowNewFeature = false } = props;
  // startNewFeature: 开始推送新功能，显示‘new’
  const [startNewFeature, setStartNewFeature] = useState(false);
  // showNewFeature: 是否显示新功能弹窗，默认不显示
  const [showNewFeature, setShowNewFeature] = useState(false);
  const [newFeatureVersion, setNewFeatureVersion] = useState();
  // 显示反馈入口的弹窗提示
  const [showFeedbackGuide, setShowFeedbackGuide] = useState(false);
  // customerServiceConfig: 客服配置信息，从apollo读取
  const [config, setConfig] = useState([]);

  // const location = useLocation();

  const isZH = getLocale() === 'zh-CN';

  // 获取客服配置信息
  const getCustomerServiceConfig = () => {
    return new Promise((resolve, reject) => {
      getApolloConfig('customer-service').then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  /**
  * 获取个人是否需要显示新功能弹窗
  * @returns
  */
  const getUserViewNewFeature = async () => {
    return new Promise((resolve) => {
      (async function () {
        const { showOrNot: personalNewFeatureShowOrNot, version: personalNewFeatureVersion } = await getUserView('customer_service_show_new_feature');
        resolve({ personalNewFeatureShowOrNot, personalNewFeatureVersion });
      }());
    });
  };

  /**
   * 获取个人是否需要显示反馈入口弹窗
   * @returns
   */
  const getUserViewFeedbabckGuide = async () => {
    return new Promise((resolve) => {
      (async function () {
        const { feedbackGuide } = await getUserView('feedback_guide');
        const personalFeedbackGuideShowOrNot = feedbackGuide === undefined ? true : feedbackGuide;
        resolve({ personalFeedbackGuideShowOrNot });
      }());
    })
  };

  /**
   *  保存个人新功能弹窗状态
   * @param {*} showOrNot
   * @param {*} version
   */
  const saveUserViewNewFeature = (showOrNot, version) => {
    (async function () {
      const res = await setUserView({ showOrNot, version }, 'customer_service_show_new_feature');
      if (res) {
        setShowNewFeature(showOrNot);
      }
    }());
  };

  /**
   *  检测新功能和反馈入口指引状态
   * @returns
   */
  const checkShowOrNot = async () => {
    try {
      const [result1, result2] = await Promise.all([getUserViewNewFeature(), getUserViewFeedbabckGuide()]);
      const { personalNewFeatureShowOrNot, personalNewFeatureVersion } = result1;
      const { personalFeedbackGuideShowOrNot } = result2;
      return { personalNewFeatureShowOrNot, personalFeedbackGuideShowOrNot, personalNewFeatureVersion };
    } catch (error) {
      console.error('发生错误:', error);
    }
  }

  useEffect(() => {
    if (isDiDiTenant()) {
      // 仅didi租户启动页面激活检查
      const pageActivePoll = new PageActivePollChecker(getCustomerServiceConfig);
      pageActivePoll.waitForResult().then((result) => {
        const { newFeature, customerServiceInfo: { version }} = result;
        setConfig(result);
        setStartNewFeature(newFeature);
        setNewFeatureVersion(version);

        checkShowOrNot().then((status) => {
          const { personalFeedbackGuideShowOrNot, personalNewFeatureVersion } = status;
          setShowFeedbackGuide(personalFeedbackGuideShowOrNot);
          if (!personalFeedbackGuideShowOrNot) {
            if (newFeature && version !== personalNewFeatureVersion) {
              // 需要展示
              setShowNewFeature(true);
            } else {
              // 不需要展示
              setShowNewFeature(false);
            }
          }
        });
      });
      // 默认执行一次
      pageActivePoll.once();
    }
  }, [location.pathname]);


  const iknowHandler = () => {
    saveUserViewNewFeature(false, newFeatureVersion);
  };
  const ilookupHandler = () => {
    saveUserViewNewFeature(false, newFeatureVersion);

    const { customerServiceInfo: { newFeatureClickUrl, newFeatureClickUrl_en }} = config;
    openNewWindow(isZH ? newFeatureClickUrl : newFeatureClickUrl_en);
  };

  return (
    <div className={cx('cooper-customer-service')}>
      { hideSpliteLine || !isDiDiTenant() ? null : <span className={cx('customer-service-bar')} /> }

      {
        isDiDiTenant() && (
          <NewFeaturePopup
            config={config?.customerServiceInfo}
            iknowCallback={iknowHandler}
            ilookupCallback={ilookupHandler}
            showOrNot={showNewFeature && !hideShowNewFeature}
          />
        )
      }

      {
        isDiDiTenant() && (
          <HelpCenter
            disablePopover={showNewFeature && !hideShowNewFeature}
            showNewFeature={startNewFeature}
            config={config?.customerServiceInfo}
          />
        )
      }
      <FeedbackWithPopup
        placement={'bottomRight'}
        // showGuide={showFeedbackGuide}
        showGuide={false}
      />
    </div>
  );
}

export default CustomerService;
