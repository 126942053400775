import { intl } from 'di18n-react';
import { useSelector, useDispatch } from 'react-redux';

let id = 0;

export default function useUpload() {
  const { isShowTips, uploadFiles } = useSelector((state) => state.uploadWiki);
  const { addUploadFile, setShowTips, updateFile, deleteFile, deleteAllFiles } = useDispatch().uploadWiki;

  function addUploadTask(
    file,
    { knowledgeId, parentId = '0', currPageId = '0', type = 'confluence', modeType },
    callback = () => {},
  ) {
    const { name: fileName, size } = file;
    addUploadFile({
      fileName,
      size,
      percent: 0,
      id: id++,
      file,
      err: null,
      knowledgeId,
      callback,
      type,
      parentId,
      taskId: null,
      isFinish: false,
      stateMsg: intl.t('上传中'),
      isFail: false,
      currPageId,
      modeType,
    });
    setShowTips(true);
  }
  // 不需要上传，直接创建导入任务
  function addTask({
    fileName,
    knowledgeId,
    parentId = '0',
    currPageId = '0',
    type = 'cooper',
    resourceType,
    taskId,
    isImport = true,
    isImportFromDK = false,
    modeType,
  }) {
    addUploadFile({
      fileName,
      percent: 0,
      id: taskId,
      err: null,
      knowledgeId,
      type,
      parentId,
      isFinish: false,
      stateMsg: intl.t('导入中'),
      isFail: false,
      isImport,
      isImportFromDK,
      resourceType,
      taskId,
      currPageId,
      modeType,
    });
    setShowTips(true);
  }

  function closeTip() {
    setShowTips(false);
    deleteAllFiles();
  }

  function isUploading(pageId) {
    return uploadFiles.some((item) => {
      return item.currPageId === pageId && !item.isFinish;
    });
  }

  return {
    isShowTips,
    uploadFiles,
    closeTip,
    addTask,
    addUploadTask,
    updateFile,
    deleteFile,
    deleteAllFiles,
    isUploading,
  };
}
