import { intl } from 'di18n-react';
import {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
  useContext,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Tree, Spin } from 'antd';
import classBind from 'classnames/bind';
import * as service from '@/service/knowledge/pageTree';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import { formatTree, updateTreeData } from './util';
import TreeNode from './TreeNode';
import stylesCommon from '@/components/serviceComponents/AsideDK/catalogCommon.module.less';
import OutlinedIcon from '@/components/serviceComponents/CatalogTree/OutlinedIcon';
import NotificationStatus from '@/constants/notification';
import useNotification from '@/hooks/useNotification';

const cm = classBind.bind(stylesCommon);

function CatalogTree({ height }) {
  const { pageId: currPageId, shareId } = useContext(LayoutContext);
  const { docInfo } = useSelector((state) => state.SharePage);
  const { multiPath = [] } = docInfo;
  const [selectedKey, setSelectedKey] = useState('');
  const [treeData, setTreeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const notification = useNotification();
  const { pathname } = useLocation();
  useEffect(() => {
    // if (catalogContainer.current.offsetHeight) {
    //   setHeight(catalogContainer.current.offsetHeight);
    // }
    (async () => {
      try {
        const res = await service.getSharePageTreeData({
          pageId: currPageId,
          shareId,
          type: 0,
        });
        setTreeData(formatTree(res));
      } catch (error) {
        const errorCodeList = [300002, 501082, 301052]; // 资源不存在 // 无权限 // 移动后资源位置不对
        if (errorCodeList.includes(error.errorCode)) {
          notification(
            NotificationStatus.ERROR,
            intl.t('请稍后，即将为您跳转到第一个页面'),
          );
          let shareBookUrl = pathname.replace(`/${currPageId}`, '');
          setTimeout(() => {
            window.location.href = `${shareBookUrl}`;
          }, 2000);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [shareId]);

  useEffect(() => {
    if (multiPath.length) {
      setExpandedKeys([
        ...new Set([...expandedKeys, ...multiPath.map((v) => v.id)]),
      ]);
    }
  }, [multiPath]); // 保证treeData数据更新后，重新设置expandedKeys

  useEffect(() => {
    setExpandedKeys([...new Set([...expandedKeys])]);
  }, [treeData]);

  useEffect(() => {
    setSelectedKey(currPageId);
  }, [currPageId]);

  const onSelect = (selectedKeys) => {
    // navigate(`/knowledge/share/book/${shareId}/${selectedKeys[0]}`);

    window.__OmegaEvent('ep_dkpc_pageview_ck');
  };

  const onExpand = (v) => {
    setExpandedKeys(v);

    window.__OmegaEvent('ep_dkpc_page_xialaicon_ck');
  };

  const onLoadData = async ({ key, children }) => {
    if (children.length) {
      return;
    }
    try {
      let res = await service.getSharePageTreeData({
        pageId: key,
        type: 1,
        shareId,
      });
      setTreeData((list) => updateTreeData(list, key, formatTree(res)));
    } catch (error) {
      const errorCodeList = [300002, 501082, 301052]; // 资源不存在 // 无权限 // 移动后资源位置不对
      if (errorCodeList.includes(error.errorCode)) {
        notification(
          NotificationStatus.ERROR,
          intl.t('请稍后，即将为您跳转到第一个页面'),
        );
        let shareBookUrl = pathname.replace(`/${currPageId}`, '');
        setTimeout(() => {
          window.location.href = `${shareBookUrl}`;
        }, 2000);
      }
    }
  };

  return (
    <div
      className={`${cm('catalog-page-wrap')}`}
      style={{ height: 'calc(100% - 206px)', padding: 0 }}
    // ref={catalogContainer}
    >
      {loading ? (
        <div className={cm('aside-catalog-loading')}>
          <Spin />
        </div>
      ) : (
        <Tree
          draggable={false}
          // height={height}
          className="draggable-tree"
          expandedKeys={expandedKeys}
          selectedKeys={[selectedKey]}
          treeData={treeData}
          switcherIcon={<OutlinedIcon />}
          onSelect={onSelect}
          onExpand={onExpand}
          blockNode
          loadData={onLoadData}
          titleRender={(nodeData) => <TreeNode nodeData={nodeData} />}
        />
      )}
    </div>
  );
}

export default CatalogTree;
