import { inPhone, isDC } from '@/utils';
import { intl, setLocale } from 'di18n-react';
import enUS from './language/en-US.json';
import zhCN from './language/zh-CN.json';

function getMobileLang() {
  return new Promise((resolve) => {
    window.dcH5Sdk?.app?.getLang({
      onSuccess: ({ lang }) => resolve(lang),
      onFail: () => resolve('NONE'),
    });
  });
}

const initLocal = async () => {
  let locale = intl.determineLocale({
    cookieLocaleKey: 'local_language',
  });

  locale = ['zh-CN', 'en-US'].includes(locale) ? locale : 'zh-CN';

  Object.assign(intl.options, {
    locales: { 'en-US': enUS, 'zh-CN': zhCN },
    currentLocale: locale,
  });


  if (isDC()) {
    const dcLocale = await window.dcJSSDK.getAppLanguage();
    if (['zh-CN', 'en-US'].includes(dcLocale)) {
      locale = dcLocale;
    }
  }

  if (inPhone()) {
    const dcLocale = await getMobileLang();
    if (['zh-CN', 'en-US'].includes(dcLocale)) {
      locale = dcLocale;
    }
  }

  setLocale(locale, { cookieLocaleKey: 'local_language' });
}

initLocal();
