import { intl } from 'di18n-react';
/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-11-08 15:17:24
 * @LastEditTime: 2023-11-08 16:17:10
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/serviceComponents/AsideDK/AsideShareContent/index.js
 *
 */
import { useContext, useEffect, useMemo, useState } from 'react';
import { Tooltip } from 'antd';
import classBind from 'classnames/bind';
import { DKDefaultIcon } from '@/assets/icon/index';
import * as service from '@/service/knowledge/share';
import { ShareBackgroundIcon } from '@/assets/icon';
import stylesCommon from '@/components/serviceComponents/AsideDK/catalogCommon.module.less';
import styleAside from '@/components/serviceComponents/AsideDK/AsideContent/style.module.less';
import CatalogTree from '@/pages/knowledge/ShareBook/CatalogTree';
import PageCatalogTree from '@/pages/knowledge/ShareBook/PageCatalogTree';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import styles from './style.module.less';

const cs = classBind.bind(styles);
const cm = classBind.bind(stylesCommon);
const cx = classBind.bind(styleAside);

export default function AsideShareContent() {
  const [dkDetail, setDKDetail] = useState({});
  const { shareId } = useContext(LayoutContext);
  const [height, setHeight] = useState(document.body.clientHeight);

  useEffect(() => {
    service.getShareDkDetail({ shareId }).then((res) => {
      setDKDetail(res);
    });
    const updateHeight = () => {
      setHeight(document.body.clientHeight);
    };
    window.addEventListener('resize', updateHeight);
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  const isDkShare = useMemo(() => {
    return window.location.pathname.indexOf('book') !== -1;
  }, []);

  return (
    <div
      className={`${cs('aside-container')} ${cm('aside-container-common')} ${cs(
        'aside-share-wrap',
      )}`}
      style={{ background: `url(${ShareBackgroundIcon}) no-repeat top right` }}
    >
      <div className={cs('dk-overview')}>
        {
          dkDetail.exPicture ? (
            <img
              src={dkDetail.exPicture || DKDefaultIcon}
              className={cs('dk-picture')}
              alt={'知识库图标'}
            />) : null
          }

        {isDkShare && (
          <Tooltip
            title={dkDetail.spaceName}
            placement="top"
            overlayClassName="dk-ant-tooltip__reset"
          >
            <p className={cs('dk-name')}><div className={cs('hidden')}>{dkDetail.spaceName}</div></p>
          </Tooltip>
        )}
      </div>

      <li className={`${cx('catalog-title-li')} ${cm('aside-menu-li-common')}`}>
        <div
          className={`${cx('catalog-li-item')} ${cm({
            'aside-li-item': true,
          })}`}
        >
          <i
            className={cm({
              'dk-iconfont': true,
              'dk-icon-a-mulu12x': true,
              'menu-root-icon': true,
            })}
          />
          <div className={cm('aside-li-text')}>{intl.t('目录')}</div>
        </div>
      </li>

      {isDkShare ? (
        <CatalogTree height={height - 216} />
      ) : (
        <PageCatalogTree height={height - 216} />
      )}
    </div>
  );
}
