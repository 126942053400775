import { intl } from 'di18n-react';
import { CooperTipImg } from '@/assets/icon/index';
import { Popover } from 'antd';
import { Fragment } from 'react';
import './index.less';

function CooperTip(props) {
  const _renderContent = () => {
    return (
      <div className="tip-content">
        <div className="left">
          <img src={CooperTipImg} />
        </div>
        <div className="right">
          {intl.t(
            'Cooper作为企业办公场景文档协作平台，禁止上传用户隐私数据，请确保存储数据无违法违规内容。',
          )}
        </div>
      </div>
    );
  };
  const onVisibleChange = (visible) => {
    if (visible) {
      window.__OmegaEvent('ep_leftbar_safety_ck');
    }
  };
  return (
    <Fragment>
      <div
        className={`
        tip-btn-knowledge
        ${props.type === 'small' ? 'tip-btn-small' : ''}
        `}
      >
        <Popover
          content={_renderContent()}
          placement={props.type === 'small' ? 'rightTop' : 'bottomRight'}
          overlayClassName="cooper-tip-popover dk-ant-popover__reset"
          onVisibleChange={onVisibleChange}
        >
          <img src={CooperTipImg} />
          <span className="cooper-tip-name">{intl.t('数据安全')}</span>
        </Popover>
      </div>
    </Fragment>
  );
}

export default CooperTip;
