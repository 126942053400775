import { get, post } from '@/utils/request/other';
import api from '@/utils/request/api/DkApi';

export function uploadCover(params) {
  return post(api.UPLOAD_COVER, params, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export function getDidocDetail(guid) {
  return get(api.DIDOC_DETAIL.replace(':guid', guid));
}

export function getDidocHistory(guid) {
  return get(api.DIDOC_HISTORY.replace(':guid', guid).replace(':timestamp', Date.now()))
}

export function renameDoc({ docId, title }) {
  const params = {
    title,
    noHistory: true,
  };
  return post(api.RENAME_DOC.replace(':docId', docId), params);
}
