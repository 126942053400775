module.exports = `
<style>
.home-skeleton-wrap {
  min-width: 667px
}

.home-skeleton-wrap .quick-access-skeleton {
  min-width: 667px;
  margin: 0 auto 8px;
  padding: 0 32px
}

.home-skeleton-wrap .quick-access-skeleton-title {
  font-family: PingFangSC-Medium;
  font-size: 16px;
  font-weight: 400;
  color: #222a35;
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  height: 24px;
  background-color: #fff;
  display: block;
  margin-bottom: 0;
  padding: 0 0 12px;
  box-sizing: content-box
}

.home-skeleton-wrap .recent-act-skeleton {
  margin: 0 auto;
  min-width: 667px
}

.home-skeleton-wrap .recent-act-skeleton .ant-tabs-nav {
  padding: 0 32px 2px !important
}

.home-skeleton-wrap .recent-act-skeleton .file-skeleton-box {
  min-width: 667px !important;
  margin: -8px auto 0 !important
}

.home-skeleton-wrap .recent-act-skeleton .file-header-skeleton-box {
  padding-left: 27px !important;
  padding-right: 32px !important
}

.home-skeleton-wrap .recent-act-skeleton .file-body-skeleton-box {
  padding-left: 27px !important;
  padding-right: 28px !important;
  padding-top: 8px !important
}

.quick-access-skeleton-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start
}

.quick-access-skeleton-box .quick-access-skeleton-button * {
  min-width: auto !important;
  width: 100% !important;
  height: 100% !important
}

.quick-access-skeleton-box .quick-access-skeleton-box-item {
  flex: 1;
  height: 46px;
  border-radius: 4px;
  margin: 0 12px 10px 0;
  border: 1px solid #f2f3f3;
  background-color: #fff;
  padding: 12px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: calc(25% - 9px);
  min-width: calc(25% - 9px);
  max-width: calc(25% - 9px);
  position: relative
}

.quick-access-skeleton-box .quick-access-skeleton-box-item:nth-child(4n) {
  margin-right: 0
}

.quick-access-skeleton-box .quick-access-skeleton-box-item .quick-access-skeleton-logo {
  position: absolute;
  top: 14.4px;
  left: 18.4px;
  width: 19.2px;
  height: 19.2px
}

.quick-access-skeleton-box .quick-access-skeleton-box-item .quick-access-skeleton-title {
  position: absolute;
  top: 17px;
  left: 48px;
  width: calc(100% - 70px);
  height: 14px
}
</style>
<div class="home-skeleton-wrap">
<div class="quick-access-skeleton">
  <div class="quick-access-skeleton-title">快速访问</div>
  <div class="quick-access-skeleton-box">
    <div class="quick-access-skeleton-box-item">
      <div class="quick-access-skeleton-logo quick-access-skeleton-button">
        <div class="ant-skeleton ant-skeleton-element">
          <span class="ant-skeleton-button ant-skeleton-button-square"></span>
        </div>
      </div>
      <div class="quick-access-skeleton-title quick-access-skeleton-button">
        <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
          <span class="ant-skeleton-button ant-skeleton-button-round"></span>
        </div>
      </div>
    </div>
    <div class="quick-access-skeleton-box-item">
      <div class="quick-access-skeleton-logo quick-access-skeleton-button">
        <div class="ant-skeleton ant-skeleton-element">
          <span class="ant-skeleton-button ant-skeleton-button-square"></span>
        </div>
      </div>
      <div class="quick-access-skeleton-title quick-access-skeleton-button">
        <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
          <span class="ant-skeleton-button ant-skeleton-button-round"></span>
        </div>
      </div>
    </div>
    <div class="quick-access-skeleton-box-item">
      <div class="quick-access-skeleton-logo quick-access-skeleton-button">
        <div class="ant-skeleton ant-skeleton-element">
          <span class="ant-skeleton-button ant-skeleton-button-square"></span>
        </div>
      </div>
      <div class="quick-access-skeleton-title quick-access-skeleton-button">
        <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
          <span class="ant-skeleton-button ant-skeleton-button-round"></span>
        </div>
      </div>
    </div>
    <div class="quick-access-skeleton-box-item">
      <div class="quick-access-skeleton-logo quick-access-skeleton-button">
        <div class="ant-skeleton ant-skeleton-element">
          <span class="ant-skeleton-button ant-skeleton-button-square"></span>
        </div>
      </div>
      <div class="quick-access-skeleton-title quick-access-skeleton-button">
        <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
          <span class="ant-skeleton-button ant-skeleton-button-round"></span>
        </div>
      </div>
    </div>
    <div class="quick-access-skeleton-box-item">
      <div class="quick-access-skeleton-logo quick-access-skeleton-button">
        <div class="ant-skeleton ant-skeleton-element">
          <span class="ant-skeleton-button ant-skeleton-button-square"></span>
        </div>
      </div>
      <div class="quick-access-skeleton-title quick-access-skeleton-button">
        <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
          <span class="ant-skeleton-button ant-skeleton-button-round"></span>
        </div>
      </div>
    </div>
    <div class="quick-access-skeleton-box-item">
      <div class="quick-access-skeleton-logo quick-access-skeleton-button">
        <div class="ant-skeleton ant-skeleton-element">
          <span class="ant-skeleton-button ant-skeleton-button-square"></span>
        </div>
      </div>
      <div class="quick-access-skeleton-title quick-access-skeleton-button">
        <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
          <span class="ant-skeleton-button ant-skeleton-button-round"></span>
        </div>
      </div>
    </div>
    <div class="quick-access-skeleton-box-item">
      <div class="quick-access-skeleton-logo quick-access-skeleton-button">
        <div class="ant-skeleton ant-skeleton-element">
          <span class="ant-skeleton-button ant-skeleton-button-square"></span>
        </div>
      </div>
      <div class="quick-access-skeleton-title quick-access-skeleton-button">
        <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
          <span class="ant-skeleton-button ant-skeleton-button-round"></span>
        </div>
      </div>
    </div>
    <div class="quick-access-skeleton-box-item">
      <div class="quick-access-skeleton-logo quick-access-skeleton-button">
        <div class="ant-skeleton ant-skeleton-element">
          <span class="ant-skeleton-button ant-skeleton-button-square"></span>
        </div>
      </div>
      <div class="quick-access-skeleton-title quick-access-skeleton-button">
        <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
          <span class="ant-skeleton-button ant-skeleton-button-round"></span>
        </div>
      </div>
    </div>
    <div class="quick-access-skeleton-box-item">
      <div class="quick-access-skeleton-logo quick-access-skeleton-button">
        <div class="ant-skeleton ant-skeleton-element">
          <span class="ant-skeleton-button ant-skeleton-button-square"></span>
        </div>
      </div>
      <div class="quick-access-skeleton-title quick-access-skeleton-button">
        <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
          <span class="ant-skeleton-button ant-skeleton-button-round"></span>
        </div>
      </div>
    </div>
    <div class="quick-access-skeleton-box-item">
      <div class="quick-access-skeleton-logo quick-access-skeleton-button">
        <div class="ant-skeleton ant-skeleton-element">
          <span class="ant-skeleton-button ant-skeleton-button-square"></span>
        </div>
      </div>
      <div class="quick-access-skeleton-title quick-access-skeleton-button">
        <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
          <span class="ant-skeleton-button ant-skeleton-button-round"></span>
        </div>
      </div>
    </div>
    <div class="quick-access-skeleton-box-item">
      <div class="quick-access-skeleton-logo quick-access-skeleton-button">
        <div class="ant-skeleton ant-skeleton-element">
          <span class="ant-skeleton-button ant-skeleton-button-square"></span>
        </div>
      </div>
      <div class="quick-access-skeleton-title quick-access-skeleton-button">
        <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
          <span class="ant-skeleton-button ant-skeleton-button-round"></span>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
`
