import { intl } from 'di18n-react';
import classBind from 'classnames/bind';
import styles from './style.module.less';

const cx = classBind.bind(styles);

const NoMore = ({ customClass }) => {
  return (
    <div className={cx('noMore', customClass)}>{intl.t('没有更多了')}</div>
  );
};
export default NoMore;
