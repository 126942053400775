import { cloneDeep } from 'lodash-es';
import * as templateService from '@/service/knowledge/template';
import { useParams } from 'react-router-dom';
import { countRecentTemplate } from '@/service/knowledge/template';

const getDefaultState = () => ({
  recentTemplates: [],
  knowledgeTemplates: [],
  defaultTemplates: [],
  templateVisible: false,
  templateModalVisible: false,
});

export default {
  state: getDefaultState(),
  reducers: {
    updateTemplateName(state, { templateId, name }) {
      const knowledgeTemplates = cloneDeep(state.knowledgeTemplates);
      knowledgeTemplates.forEach((item) => {
        if (item.templateId === templateId) {
          item.name = name;
        }
      });
      return {
        ...state,
        knowledgeTemplates,
      };
    },
    changeRecentTemplates(state, recentTemplates) {
      return {
        ...state,
        recentTemplates,
      };
    },
    changeknowledgeTemplates(state, knowledgeTemplates) {
      return {
        ...state,
        knowledgeTemplates,
      };
    },
    changeDefaultTemplates(state, defaultTemplates) {
      return {
        ...state,
        defaultTemplates,
      };
    },
    deleteknowledgeTemplate(state, { templateId }) {
      const knowledgeTemplates = state.knowledgeTemplates.filter((item) => item.templateId !== templateId);
      return {
        ...state,
        knowledgeTemplates,
      };
    },
    changeTemplateVisible(state, templateVisible) {
      return {
        ...state,
        templateVisible,
      };
    },
    toggleTemplateModalVisible(state) {
      return {
        ...state,
        templateModalVisible: !state.templateModalVisible,
      };
    },
  },
  effects: {
    async getRecentTemplates(knowledgeId) {
      const { items } = await templateService.getRecentTemplates(knowledgeId);
      this.changeRecentTemplates(items);
      return items;
    },
    async getKnowledgeTemplates(knowledgeId) {
      const { items } = await templateService.getKnowledgeTemplates(knowledgeId);
      this.changeknowledgeTemplates(items);
      return items;
    },
    async getDefaultTemplates(knowledgeId) {
      const { items } = await templateService.getDefaultTemplates(knowledgeId);
      this.changeDefaultTemplates(items);
      return items;
    },
    async deleteTemplate(params) {
      this.deleteknowledgeTemplate(params);
      return templateService.deleteTemplate(params);
    },
    async renameTemplate(params) {
      const data = await templateService.updateTemplate(params);
      return data;
    },
    async applyTemplate({ view, ...params }) {
      const { content } = await templateService.getTemplateDetail(params);
      content && view.template.import(JSON.parse(content));
    },
    async countRecentTemplate(params) {
      return templateService.countRecentTemplate(params);
    },
  },
};
