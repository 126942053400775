/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-11-01 12:03:14
 * @LastEditTime: 2023-11-15 14:54:37
 * @Description: 知识库redux
 * @FilePath: /knowledgeforge/src/model/models/knowledge/knowledgeData.js
 *
 */
import { ASIDE_SHOW_TYPE, TREE_VIEW_TYPE } from '@/constants/index';
import { getDkOwnerInfo, getKnowledgeDetail, getKnowledgeMemberList } from '@/service/knowledge/knowledgeData';

export default {
  name: 'KnowledgeData',
  state: {
    permission: {},
    knowledgeMember: [],
    knowledgeDetail: {},
    // Tag, CATALOG
    treeViewType: TREE_VIEW_TYPE.PAGE,
    asideType: ASIDE_SHOW_TYPE.TILE,
    isSharing: false,
    dkOwnerInfo: {},
    hasCompleteLinkPerm: {},
    knowledgeSpaceType: '', // 知识库所在的空间类型 /PERSONAL_SPACE、TEAM_SPACE
    hasTeamParam: undefined,
    dkPerm: null,
  },
  reducers: {
    setState(state, payload = {}) {
      return {
        ...state,
        ...payload,
      };
    },
    setHasCompleteLinkPerm(state, hasCompleteLinkPerm) {
      return { ...state, hasCompleteLinkPerm };
    },
    setDkPerm(state, dkPerm) {
      return { ...state, dkPerm };
    },
    setKnowledgeSpaceType(state, knowledgeSpaceType) {
      return { ...state, knowledgeSpaceType };
    },
    setHasTeamParam(state, hasTeamParam) {
      return { ...state, hasTeamParam };
    },
    setPermission(state, permission) {
      return { ...state, permission };
    },
    setKnowledgeMember(state, knowledgeMember) {
      return { ...state, knowledgeMember };
    },
    setknowledgeDetail(state, knowledgeDetail) {
      return { ...state, knowledgeDetail };
    },
    setTreeViewType(state, treeViewType) {
      return { ...state, treeViewType };
    },
    setAsideType(state, asideType) {
      return { ...state, asideType };
    },
    setIsSharing(state, isSharing) {
      return { ...state, isSharing };
    },
    setDkOwnerInfo(state, dkOwnerInfo) {
      return { ...state, dkOwnerInfo };
    },
  },
  effects: () => ({

    async getKnowledgeMember(id) {
      const data = await getKnowledgeMemberList(id);
      if (data) {
        this.setKnowledgeMember(data.items);
      }
      return data?.items ?? [];
    },
    async getKnowledgeDetail(id) {
      const data = await getKnowledgeDetail(id);
      if (data) {
        this.setknowledgeDetail(data);
        this.setPermission(data.premInfo);
      }
      return data;
    },
    async updateDkOwnerInfo(knowledgeId) {
      const data = await getDkOwnerInfo(knowledgeId);
      if (data) {
        this.setDkOwnerInfo(data);
      }
    },
  }),
};


