import { intl } from 'di18n-react';
import { useState, useEffect, useMemo } from 'react';
import classBind from 'classnames/bind';
import NoMore from '@/components/NoMore';
import { getSearchTypeMap } from '@/constants/search';
import InfiniteScroll from 'react-infinite-scroller';
import ErrorTips from '@/components/ErrorTips';
import { PAGE_SIZE } from '@/constants';
import SpinRender from '@/components/SpinRender';
import ScopeItem from '@/components/common/ListOptions/ScopeItem';
import useDebounceFn from '@/hooks/useDebounceFn';
import {
  getSearchData,
  getVisitedDK,
  getVisitedTeam,
} from '@/service/knowledge/search';
import styles from './style.module.less';
import { CooperTabs, CooperTabsPane } from '@/components/common/CooperTabs';
import { isDiDiTenant } from '@/utils/entryEnhance'
import { IN_OUT } from '@/constants/space';


const cx = classBind.bind(styles);

const PositionOptions = (props) => {
  const { searchValue, onChoose } = props;
  const searchTypeMap = getSearchTypeMap();
  const [activeKey, setActiveKey] = useState('space');
  const [optionList, setOptionList] = useState({
    data: [],
    keyWord: null,
  });
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const tabInfo = [
    {
      name: intl.t('团队空间'),
      key: 'space',
    },
    {
      name: intl.t('知识库'),
      key: 'metis',
    },
  ];

  useEffect(() => {
    setLoading(true);
    setOptionList({
      ...optionList,
      data: [],
    });
    getOptionList(true);
  }, [activeKey, searchValue]);

  const getOptionListReq = async (refresh = false) => {
    const pageNum = refresh ? 0 : pageNumber;
    let currentData = refresh ? [] : [...optionList.data];
    setLoading(true);

    let res;
    let arr = [];

    if (searchValue && searchValue !== '') {
      let opts = {
        pageNum,
        pageSize: PAGE_SIZE,
        key: searchValue,
        searchType:
          activeKey === 'space'
            ? searchTypeMap.space?.type
            : searchTypeMap.metis?.type,
        isContainPerson: 0,
      };
      res = await getSearchData(opts);

      arr = res?.[activeKey]?.items.map((v) => ({
        label: v?.resourceName || '--',
        value: v?.resourceId || '--',
        spaceType: v?.resourceType || null, // SPACE KNOWLEDGE  PERSONAL_SPACE
        iconImage: v?.iconImage || null,
        isDepart: (v?.relationTypeTags || []).includes('SPACE_DEPART'),
        isShowOuter: (v?.relationTypeTags || []).includes('IN_OUT'),
      }));

      setOptionList({
        data: [...currentData, ...arr],
        keyWord: searchValue,
      });
      setHasMore((arr ?? []).length === PAGE_SIZE);
    } else {
      res = activeKey === 'metis'
        ? await getVisitedDK({ maxSize: 100 })
        : await getVisitedTeam({ maxSize: 100, isContainPerson: 0 });

      arr = (res ?? [])?.map((v) => ({
        label: v.name,
        value: v.id,
        spaceType: v?.spaceType || null, // TEAM_SPACE PERSONAL_SPACE  METIS_SPACE
        iconImage: v?.iconImage || null,
        isDepart: (v?.relationTypeTags || []).includes('SPACE_DEPART'),
        isShowOuter: (v?.relationTypeTags || []).includes('IN_OUT'),
      }));
      setHasMore(false); // 这个接口不能分页
      setOptionList({
        data: [...currentData, ...arr],
        keyWord: null,
      });
    }

    setPageNumber(pageNum + 1);
    setLoading(false);
  };

  const { run: getOptionList } = useDebounceFn(getOptionListReq);

  const onTabChange = (v) => {
    setActiveKey(v);
  };

  return (
    <div className={cx('position-content')}>
      {isDiDiTenant() && (
        <CooperTabs
          className={cx('position-tab')}
          defaultActiveKey={'space'}
          // destroyInactiveTabPane={true}
          onChange={onTabChange}
          tabsize="small"
        >
          {tabInfo.map((item) => {
            return (
              <CooperTabsPane
                tab={item.name}
                key={item.key}>
                <div className={cx('options-list-wrap')}>
                  {(optionList.keyWord
                    ? optionList.keyWord === searchValue
                    : true)
                    && optionList.data.length > 0 && (
                      <InfiniteScroll
                        initialLoad={false}
                        pageStart={0}
                        loadMore={() => getOptionList(false)}
                        hasMore={!loading && hasMore}
                        useWindow={false}
                        getScrollParent={() => document.querySelector('.share-form-me-scroll')
                        }
                      >
                        {optionList.data.map((v) => {
                          return (
                            <li
                              key={v.value}
                              className={cx('scope-item')}
                              onClick={() => onChoose(v)}
                            >
                              {ScopeItem(
                                {
                                  name: v.label,
                                  isDepart: v.isDepart,
                                  iconImage: v.iconImage,
                                  isShowOuter: v?.isShowOuter,
                                },
                                activeKey ?? 'space',
                              )}
                              {/* {currentId === v.value && (
                            <i
                              className={cx('dk-icon-gou', 'dk-iconfont', 'check-icon')}
                          />
                          )} */}
                            </li>
                          );
                        })}
                        {!hasMore
                          && !loading
                          && searchValue
                          && searchValue !== '' && (
                            <NoMore customClass={cx('noMore')} />
                        )}
                      </InfiniteScroll>
                  )}
                  <SpinRender loading={loading} />
                  {!loading
                    && !optionList.data.length
                    && (optionList.keyWord
                      ? optionList.keyWord === searchValue
                      : true) && (
                        <ErrorTips
                          title={intl.t(
                            searchValue && searchValue.length > 0
                              ? intl.t('暂无搜索内容')
                              : intl.t('暂无最近访问记录'),
                          )}
                      />
                  )}
                </div>
              </CooperTabsPane>
            );
          })}
        </CooperTabs>
      )}
      {
        !isDiDiTenant() && <div className={cx('options-list-wrap')}>
          {(optionList.keyWord
            ? optionList.keyWord === searchValue
            : true)
          && optionList.data.length > 0 && (
            <InfiniteScroll
              initialLoad={false}
              pageStart={0}
              loadMore={() => getOptionList(false)}
              hasMore={!loading && hasMore}
              useWindow={false}
              getScrollParent={() => document.querySelector('.share-form-me-scroll')
              }
            >
              {optionList.data.map((v) => {
                return (
                  <li
                    key={v.value}
                    className={cx('scope-item')}
                    onClick={() => onChoose(v)}
                  >
                    {ScopeItem(
                      {
                        name: v.label,
                        isDepart: v.isDepart,
                        iconImage: v.iconImage,
                        isShowOuter: v?.isShowOuter,
                      },
                      activeKey ?? 'space',
                    )}
                    {/* {currentId === v.value && (
                  <i
                    className={cx('dk-icon-gou', 'dk-iconfont', 'check-icon')}
                />
                )} */}
                  </li>
                );
              })}
              {!hasMore
                && !loading
                && searchValue
                && searchValue !== '' && (
                  <NoMore customClass={cx('noMore')} />
              )}
            </InfiniteScroll>
          )}
          <SpinRender loading={loading} />
          {!loading
          && !optionList.data.length
          && (optionList.keyWord
            ? optionList.keyWord === searchValue
            : true) && (
              <ErrorTips
                title={intl.t(
                  searchValue && searchValue.length > 0
                    ? '暂无搜索内容'
                    : '暂无最近访问记录',
                )}
            />
          )}
        </div>
      }
    </div>
  );
};

export default PositionOptions;
