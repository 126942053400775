import qs from 'qs';
import { removePropertyOfNull, setImgUrl } from '@/utils/cooperutils';
import api from '@/utils/request/api/CooperApi';
import { get, put } from '@/utils/request/cooper';
import { intl } from 'di18n-react';
import { message } from 'antd';

export async function getQuickVisit() {
  const res = await get(api.QUICK_VISIT, {
    needDD05: true,
  });
  return formateFileProperty(res);
}

export async function getOperationAuthority(id) {
  if (!id) return
  const res = await get(api.PERM.replace(':resourceId', id));
  return res;
}

export async function getOperationAuth(id) {
  const res = await get(api.RESOURCE_PERM.replace(':resourceId', id));
  return res;
}

export async function getOperationTrashAuth(spaceId, id) {
  const res = await get(api.RESOURCE_TRASH_PERM.replace(':resourceId', id).replace(':spaceId', spaceId));
  return res;
}

export async function getOperationShareAuth(shareId, shareType, id) {
  const res = await get(api.RESOURCE_SHARE_PERM.replace(':resourceId', id).replace(':shareId', shareId).replace(':shareType', shareType));
  return res;
}

function formateFileProperty(files) {
  let result = [];
  if (files.length > 0) {
    result = files.map((file) => ({
      id: file.resource_id || file.objectId,
      teamId: file.team_id,
      tiny_image: file.tinyImage,
      name: file.resource_name || file.objectName,
      image: setImgUrl(file),

      type: file.resource_type || file.objectType,
      mimeType: file.mime_type,

      isDeleted: file.is_deleted,

      shareId: file.share_id,
      shareType: file.share_type,
      shareLink: file.share_link,
      shareStatus: file.share_status,
      markedStar: file.marked_star,
      ...file,
    }));
  }

  return result;
}

export async function setQuickOrder(ids) {
  const res = await put(api.QUICK_VISIT, {
    quickIds: ids,
  }, {
    needDD05: true,
  });

  return res;
}

export async function getFileTableData(params, url) {
  const data = await get(url, {
    params: {
      ...removePropertyOfNull(params),
    },
    headers: {
      'X-App-Id': 2,
    },
  });
  const files = {
    ...data,
    items: formateFileProperty(data.items || []),
  }
  return files;
}

export async function docDonwnload(id, params) {
  const res = await get(`${api.DOC_DOWNLOAD.replace(':resourceId', id)}?${qs.stringify(params)}`);
  message.loading(intl.t('下载中，内容如包含敏感信息请及时删除'), 3)
  return res;
}

// getDownloadProcess, getDownloadUrlReq 

export const getDownloadProcess = (id) => {
  return get(api.GET_DOWNLOAD_PROCESS.replace(':id', id));
};

export const getDownloadUrlReq = (id) => {
  message.loading(intl.t('下载中，内容如包含敏感信息请及时删除'), 3)
  return get(`${api.DOWNLOAD_URL.replace(':id', id)}`);
};
// 查询文件目录全路径
export async function getGlobalFileDir(parentId, teamId) {
  // parentId === 0： 代表无父目录，返回teamId
  // parentId !== 0： 文件夹，parentId
  // team_id === 0: 代表个人空间
  let id = '';
  let type = '';
  if (parentId === 0 || parentId === null) {
    id = teamId;
    type = 'SPACE';
  } else {
    id = parentId;
    type = 'DIR';
  }
  let params = {
    id, // 资源Id/空间Id，
    type, // DIR/SPACE
  };
  const data = await get(api.QUERY_FILE_DIR, {
    params: {
      ...params,
    },
    headers: {
      'X-App-Id': 2,
    },
  });
  return data;
}

export function matchResourceTypeParams(typeValue) {
  let paramsType = 'all';
  switch (parseInt(typeValue)) {
    case 0:
      paramsType = 'all';
      break;
    case 1:
      paramsType = 'coo_doc';
      break;
    case 2:
      paramsType = 'coo_sheet';
      break;
    case 3:
      paramsType = 'coo_ppt';
      break;
    case 4:
      paramsType = 'coo_file';
      break;
    default:
      break;
  }
  return paramsType;
}

export function matchStartTimeParams(timeValue) {
  let paramsFormTime = 0;
  let nowDate = new Date();
  let days7 = new Date(nowDate - 7 * 24 * 3600 * 1000).getTime();
  let day30 = new Date(nowDate - 30 * 24 * 3600 * 1000).getTime();
  let day90 = new Date(nowDate - 90 * 24 * 3600 * 1000).getTime();

  switch (parseInt(timeValue)) {
    case 0:
      paramsFormTime = 0;
      break;
    case 1: // 7天内
      paramsFormTime = days7;
      break;
    case 2: // 一个月内
      paramsFormTime = day30;
      break;
    case 3: // 3个月内
      paramsFormTime = day90;
      break;
    case 4:// 更早
      paramsFormTime = null;
      break;
    default:
      break;
  }
  return paramsFormTime;
}

