import { post, get } from '@/utils/request';
import api from '@/utils/request/api/DkApi';


export async function changeShareSetting(params) {
  const res = await post(api.SHARE_CONTROL, params);
  return res;
}

export async function getShareDkDetail({ shareId }) {
  const res = await get(api.SHARE_DK_DETAIL.replace(':shareId', shareId));
  return {
    exPicture: res.exPicture,
    spaceName: res.spaceName,
  };
}

export async function getShareSetting(params) {
  const res = await get(api.SHARE_MODAL_SETTING
    .replace(':resourceId', params.resourceId)
    .replace(':resourceType', params.resourceType), { keepSilent: true });
  return res;
}


export function getSharePreviewUrl(shareId, params) {
  return post(api.GET_SHARE_PREVIEW_URL.replace(':shareId', shareId), params);
}

export function getBookSharePreviewUrl(shareId, params) {
  return post(api.GET_BOOK_SHARE_PREVIEW_URL.replace(':shareId', shareId), params);
}

export function getSharePreviewFileInfo(pageId, shareId, params) {
  return get(api.GET_SHARE_PREVIEW_FILE_INFO.replace(':pageId', pageId).replace(':shareId', shareId), { params });
}

export function getBookSharePreviewFileInfo(pageId, shareId, params) {
  return get(api.GET_BOOK_SHARE_PREVIEW_FILE_INFO.replace(':pageId', pageId).replace(':shareId', shareId), { params });
}

export function getPageIdFormShare({ shareId }) {
  return get(api.PAGEID_FROM_SHAREID.replace(':shareId', shareId))
}
