import { message } from 'antd';

function handleHttpError(err) {
  if (err.response && err.response.data) {
    message.error(err.response.data.message);
  } else {
    message.error(err.message);
  }
}

export default handleHttpError;
