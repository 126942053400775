import { intl } from 'di18n-react';

const CATAlLOG = [
  {
    key: 0,
    title: () => intl.t('首页'),
    iconName: 'dk-icon-zhishikumulushouye',
    iconNameActive: 'dk-icon-zhishikumulushouye1',
    link: '/home',
    menuFlag: 'home',
    isFixed: false,
  },
  {
    key: 1,
    title: () => intl.t('目录'),
    iconName: 'dk-icon-a-mulu12x',
    link: '/page',
    menuFlag: 'page',
    isFixed: false,
  },
  {
    key: 2,
  },
];

const BOTTOM_MENU = [
  {
    key: 1,
    title: () => intl.t('设置'),
    iconName: 'dk-icon-shezhi4',
    link: '/setUp',
    menuFlag: 'setUp',
    isFixed: true,
  },
  {
    key: 2,
    title: () => intl.t('数据看板'),
    iconName: 'dk-icon-a-shujukanban2x',
    link: '/dataBoard',
    menuFlag: 'dataBoard',
    isFixed: true,
  },
  {
    key: 0,
    title: () => intl.t('回收站'),
    iconName: 'dk-icon-huishouzhan4px',
    link: '/recycleBin',
    menuFlag: 'recycleBin',
    isFixed: true,
  },
];

const CATALOG_MENHU = [
  {
    key: 0,
    title: () => intl.t('知识库-大写'),
    iconName: 'dk-icon-zhishiku1',
    iconNameDC: 'dk-icon-dc-zhishiku',
    link: '/knowledge',
    menuFlag: 'knowledgehome',
  },
  {
    key: 1,
    title: () => intl.t('最近'),
    iconName: 'dk-icon-a-cebianlan-zuijin1',
    iconNameDC: 'dk-icon-juxing',
    link: '/knowledge/recent',
    menuFlag: 'recent',
  },
  {
    key: 2,
    title: () => intl.t('收藏'),
    iconName: 'dk-icon-yishoucang1',
    iconNameDC: 'dk-icon-yishoucang1',
    link: '/knowledge/star',
    menuFlag: 'star',
  },
  {
    key: 3,
    title: () => intl.t('知识门户'),
    iconName: 'dk-icon-menhu_xuanzhong2',
    iconNameDC: 'dk-icon-menhu-biaoti',
    link: '/knowledge/portalList',
    menuFlag: 'portalList',
  },
  // {
  //   key: 1,
  //   title: '我收藏的',
  //   iconName: 'dk-icon-muluyemian',
  //   link: '/page',
  //   menuFlag: 'page',
  //   isFixed: false,
  // },
  // {
  //   key: 2,
  //   title: '回收站',
  //   iconName: 'dk-icon-huishouzhan',
  //   link: '/recycleBin',
  //   menuFlag: 'recycleBin',
  //   isFixed: true,
  // },
];

const OPERATE_CONTENT_DATA = {
  hiddenStep: 1,
  showUlContentStep: 2,
  showRenameStep: 3,
};

const REQUEST_SUB_DATA = 1;
const REQUEST_CURR_DATA = 0;

export {
  CATAlLOG,
  BOTTOM_MENU,
  CATALOG_MENHU,
  REQUEST_SUB_DATA,
  REQUEST_CURR_DATA,
  OPERATE_CONTENT_DATA,
};
