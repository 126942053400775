
import { Spin } from 'antd';


function SkeletonPage() {
  return (
    <div className={'page-detail-loading'}>
      <Spin />
    </div>
  );
}

export default SkeletonPage;
