/* eslint-disable */
import { intl } from 'di18n-react';
import { Input, Tooltip } from 'antd';
import { debounce } from 'lodash-es';
import { getSearchDataDK, getVisitedDKV2 } from '@/service/knowledge/search';
import EmptyRender from '@/components/SpinRender';
import classBind from 'classnames/bind';
import { highlight } from '@/utils';
import { departmentTagIcon } from '@/assets/icon';
import styles from './style.module.less';
import { useEffect, useMemo } from 'react';

const cx = classBind.bind(styles);

function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState(props.data || []);
  const [isRecommend, setRecommend] = React.useState(true);
  const fetchRef = React.useRef(0);
  const loadOptions = (value) => {
    fetchRef.current += 1;
    const fetchId = fetchRef.current;
    setOptions([]);
    setFetching(true);
    fetchOptions(value).then((newOptions) => {
      if (fetchId !== fetchRef.current) {
        return;
      }
      setRecommend(!value.target.value);
      // 最近浏览
      if (!value.target.value && props?.value) {
        let currentIndex = newOptions.findIndex((v) => v.value === props.value);
        if (currentIndex > -1) {
          // 列表中有元素
          let current = newOptions.splice(currentIndex, 1)[0];
          newOptions.unshift(current);
        }
      }
      setOptions(newOptions);
      setFetching(false);
    });
  };
  const debounceFetcher = React.useMemo(() => {
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  const onValueChange = (v) => {
    let currentValue = { ...v };
    currentValue.label = currentValue.label
      ? currentValue.label.replace(/§/g, '')
      : '';
    props.onChange(currentValue, isRecommend);
  };

  useEffect(() => {
    if (!props.data) {
      loadOptions({ target: {} });
    }
  }, []);

  const _nodeItem = (nodeDate) => {
    return (
      <>
        <span>{highlight(nodeDate.label)}</span>
        {nodeDate.relationTypeTags &&
          nodeDate.relationTypeTags.indexOf('SPACE_DEPART') > -1 && (
            <span className={cx('department')}>
              {intl.t('部门')}
            </span>
            // <img src={departmentTagIcon} className={cx('departmentTag')} />
          )}
      </>
    );
  };

  return (
    <div className={cx('search-popover-content')}>
      <i
        className={`${cx('dk-icon-sousuo', 'dk-iconfont')} ${cx(
          'switch-search-icon'
        )}`}
      />

      <Input
        className={cx('switch-search-input')}
        onChange={debounceFetcher}
        placeholder={intl.t('搜索知识库')}
      />

      {options.length ? (
        <ul style={props.height ? { height: props.height } : undefined}>
          {options.map((v, i) => (
            <li
              key={i}
              className={
                props.defaultSelected && props?.value == v.value ? 'active' : ''
              }
              onClick={() => onValueChange(v)}
            >
              {' '}
              {v.label.length > 22 ? (
                <Tooltip title={v?.label?.replace(/§/g, '')}>
                  {_nodeItem(v)}
                </Tooltip>
              ) : (
                _nodeItem(v)
              )}
              {props.defaultSelected && props?.value == v.value && (
                <i className={cx('dk-icon-gou', 'dk-iconfont')}></i>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <>
          {fetching ? (
            <EmptyRender loading={true} />
          ) : (
            <div style={{ textAlign: 'center', padding: 4, color: '#bbb' }}>
              {intl.t('没有搜索到您想要的知识库')}
            </div>
          )}
        </>
      )}
    </div>
  );
}

async function fetchUserList(e, api) {
  let res;
  if (e?.target?.value || api?.dkList) {
    if (api?.dkList) {
      return await api.dkList({ searchType: 2, key: e.target.value });
    } else {
      res = await getSearchDataDK(
        { searchType: 2, key: e.target.value },
        api?.mode
      );
      return res.metis.items.map((v) => ({
        label: v.resourceName,
        value: v.metisId,
        relationTypeTags: v.relationTypeTags,
      }));
    }
  }
  res = await getVisitedDKV2();
  return res.map((v) => ({
    label: v.name,
    value: v.id,
    relationTypeTags: v.relationTypeTags,
  }));
}

const KnowledgeSearch = (props) => {
  const { defaultSelected = true } = props;
  return (
    <DebounceSelect
      value={props.value}
      fetchOptions={(e) => fetchUserList(e, props.api)}
      onChange={props.onChange}
      data={props.data}
      height={props.height}
      defaultSelected={defaultSelected}
    />
  );
};

export default KnowledgeSearch;
